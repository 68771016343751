import axios, { AxiosInstance } from 'axios';
import ApolloEvent from '../apollo/ApolloEvents';
import Storage from '../storage/Storage';

axios.defaults.baseURL = process.env.REACT_APP_REST_SERVER || '';
let callsNumeber = 0;
let loaderTimeout: NodeJS.Timeout;

function loaderTimer(stopLoader: () => void) {
  loaderTimeout && clearTimeout(loaderTimeout);
  loaderTimeout = setTimeout(() => {
    callsNumeber = 0;
    stopLoader();
  }, 5000);
}

export const httpInterceptor = (
  startLoader: () => void,
  stopLoader: () => void,
  onError: (err: Error) => void
) => {
  interceptor(axios, startLoader, stopLoader, onError);

  ApolloEvent.request.push(data => {
    callsNumeber === 0 && startLoader();
    callsNumeber++;
    loaderTimer(stopLoader);
  });
  ApolloEvent.response.push(data => {
    callsNumeber > 0 && callsNumeber--;
    callsNumeber === 0 && stopLoader();
  });

  ApolloEvent.errors.push(error => {
    callsNumeber > 0 && callsNumeber--;
    if (error.graphQLErrors) {
      onError(error.graphQLErrors[0]);
    } else if (error.networkError) {
      onError(error.networkError);
    }
    callsNumeber === 0 && stopLoader();
  });
};

function interceptor(
  axiosInstance: AxiosInstance,
  startLoader: () => void,
  stopLoader: () => void,
  onError?: (err: Error) => void
) {
  axiosInstance.interceptors.request.use(
    config => {
      const token = Storage.readToken();
      if (token) {
        config.headers['authorization'] = `Bearer ${token}`;
      }

      if (callsNumeber === 0) {
        startLoader();
      }
      callsNumeber++;
      loaderTimer(stopLoader);
      return config;
    },
    error => {
      callsNumeber--;
      if (callsNumeber === 0) {
        stopLoader();
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    response => {
      callsNumeber--;
      if (callsNumeber === 0) {
        stopLoader();
      }
      return response;
    },
    async error => {
      callsNumeber--;
      if (callsNumeber === 0) {
        stopLoader();
      }

      onError && onError(error);
      return Promise.reject(error);
    }
  );
  return callsNumeber;
}
