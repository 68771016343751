import React, { useEffect, useMemo, useRef } from 'react';

function BTHtmlWrapper(
  props: Omit<React.HTMLAttributes<any>, 'dangerouslySetInnerHTML'> & {
    content?: string;
  }
) {
  const { content } = props;
  const fixContent = useMemo(() => {
    return content
      ? content
          .replaceAll('<img', '<img style="width: 100%"')
          .replaceAll(
            '<iframe',
            '<iframe style="width: 100%; height: 100%; padding: 10px"'
          )
      : '';
  }, [content]);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!fixContent) return;

    const slotHtml = document
      .createRange()
      .createContextualFragment(fixContent);
    (divRef?.current as any).innerHTML = '';
    divRef?.current?.appendChild(slotHtml);
  }, [fixContent]);

  return <>{content && <div {...props} ref={divRef} />}</>;
}

export default BTHtmlWrapper;
