import { useTranslation } from 'react-i18next';
import { tokenIdVar } from '../core/apollo/Cache';
import {
  useGenerateTokenLoginMutation,
  useResetCustomerPasswordMutation,
} from '../core/apollo/gql-generate';
import { upsertToken } from '../core/apollo/operations/mutations/token';
import { useCart } from '../core/contexts/CartContext';
import { addSuccesNotification } from '../core/notification/Notification';

const upsertNewToken = upsertToken(tokenIdVar);

const useLogin = () => {
  const { t } = useTranslation();
  const [loginMut] = useGenerateTokenLoginMutation();
  const [resetPasswordMut] = useResetCustomerPasswordMutation();
  const { loggedIn } = useCart();

  async function login(email: string, password: string) {
    try {
      const { data: loginRes } = await loginMut({
        variables: {
          email,
          password,
        },
      });
      const newToken = loginRes?.generateCustomerToken?.token as string;
      upsertNewToken(newToken);
      await loggedIn();
      addSuccesNotification({
        message: t('successMessage.customerLoginOk'),
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async function resetPassword(email: string) {
    try {
      await resetPasswordMut({
        variables: {
          email,
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  return { login, resetPassword };
};

export default useLogin;
