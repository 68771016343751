import {
  faCalendar,
  faChevronCircleRight,
  faEye,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BTPagination from '../../components/pagination/BTPagination';
import Title from '../../components/title/Title';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import useMpBlog from '../../hooks/useMpBlog';
import MpBlog from '../../models/MpBlog';
import styles from './News.module.scss';

function News() {
  const queryParams = new URLSearchParams(useLocation().search);
  const paginationParams = Number(queryParams.get('p') || '1');

  const { t } = useTranslation();
  const history = useHistory();

  const { loading, mpBlogsList, categories } = useMpBlog();

  const [categoryID, setCategoryID] = useState<number>(-1);

  const mpBlogListFilterd = useMemo(
    () =>
      categoryID === -1
        ? mpBlogsList
        : mpBlogsList.filter(mp => mp.category_ids.includes(categoryID)),
    [categoryID, mpBlogsList]
  );

  function handlePageChange(page: number) {
    history.replace(`${history.location.pathname}?p=${page}`);
  }

  return (
    <>
      <Title className={`mt-4`} name={t('news.title')} />
      {!loading && (
        <>
          <BTContainer className="pt-3">
            <BTGreenForm>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="categories"
                  onChange={e => setCategoryID(Number(e.target.value))}
                >
                  <option value={-1}>Tutte</option>
                  {categories
                    ?.filter(i => i?.enabled === 1)
                    .map((value, index) => (
                      <option key={index} value={value?.category_id!}>
                        {value?.name!}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </BTGreenForm>
          </BTContainer>
          <NewsPagination
            mpBlogs={mpBlogListFilterd}
            currentPage={paginationParams}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  );
}
type PaginationNewsPros = {
  mpBlogs: MpBlog[];
  currentPage: number;
  onPageChange: (page: number) => void;
};

function NewsPagination(props: PaginationNewsPros) {
  const { mpBlogs, currentPage: cPage, onPageChange } = props;
  const paginationItemsView = 5;

  const totalPages = Math.ceil(mpBlogs.length / paginationItemsView);

  const [currentPage, setCurrentPage] = useState<number>(cPage);

  const mpBlogsPaginated = useMemo(() => {
    return paginate(mpBlogs, paginationItemsView, currentPage);
  }, [currentPage, mpBlogs]);

  useEffect(() => {
    setCurrentPage(cPage);
  }, [mpBlogs, cPage]);

  function onBack() {
    setCurrentPage(prev => prev - 1);
    onPageChange(currentPage - 1);
  }

  function onNext() {
    setCurrentPage(prev => prev + 1);
    onPageChange(currentPage + 1);
  }

  function paginate(array: MpBlog[], pageSize: number, pageNumber: number) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  return (
    <>
      <BTContainer>
        {!isEmpty(mpBlogsPaginated) &&
          mpBlogsPaginated.map(mpBlog => (
            <div key={mpBlog.id} className="my-4">
              <NewsCard mpBlog={mpBlog} />
            </div>
          ))}
      </BTContainer>
      <BTPagination
        totalPages={totalPages}
        size={mpBlogs.length}
        currentPage={currentPage}
        onBack={onBack}
        onNext={onNext}
      />
    </>
  );
}

type NewsCardPros = {
  mpBlog: MpBlog;
};

function NewsCard(props: NewsCardPros) {
  const { mpBlog } = props;
  const history = useHistory();
  return (
    <BTContainer className={`${styles.newsCard}`}>
      <Row className={`p-1`}>
        <Col sm={12} md={5}>
          <div
            className="d-flex flex-column"
            onClick={() => history.push(`/news/${mpBlog.id}`)}
          >
            <img
              className={`${styles.image}`}
              src={`${process.env.REACT_APP_MAGENTO_SERVER}/media/mageplaza/blog/post/resize/600x/${mpBlog.image}`}
              alt={`${mpBlog.id}`}
            />
          </div>
        </Col>
        <Col sm={12} md={7}>
          <div className="d-flex flex-column">
            <div className={`${styles.title}`}>
              {mpBlog.meta_title || mpBlog.name}
            </div>
            <div className={`d-flex flex-row mt-3 ${styles.infos}`}>
              <div>
                <FontAwesomeIcon className="mr-1" icon={faCalendar} />
                {moment(mpBlog.publish_date).format('DD/MM/YYYY')} |
              </div>
              <div className="ml-1">
                <FontAwesomeIcon className="mr-1" icon={faUser} />
                {mpBlog.author_name} |
              </div>
              <div className="ml-1">
                <FontAwesomeIcon className="mr-1" icon={faEye} />
                {mpBlog.view_traffic}
              </div>
            </div>
            <div className={`${styles.text} mt-3`}>
              {mpBlog.short_description || mpBlog.meta_description}
            </div>
            <div className="text-right mt-3">
              <BTButton
                variant="link p-0"
                onPress={() => history.push(`/news/${mpBlog.id}`)}
              >
                <FontAwesomeIcon icon={faChevronCircleRight} size="2x" />
              </BTButton>
            </div>
          </div>
        </Col>
      </Row>
    </BTContainer>
  );
}
News.displayName = 'News';
export default News;
