import { gql, ReactiveVar } from '@apollo/client';
import Storage from '../../../storage/Storage';
import { TOKEN_FIELDS } from '../fragments/token';

export const GENERATE_TOKEN_LOGIN = gql`
  ${TOKEN_FIELDS}
  mutation GenerateTokenLogin($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      ...TokenFields
    }
  }
`;

export function upsertToken(tokenVar: ReactiveVar<string | null>) {
  return (token: string) => {
    Storage.saveToken(token);
    tokenVar(token);
  };
}
export function cleanToken(tokenVar: ReactiveVar<string | null>) {
  return () => {
    Storage.clean();
    tokenVar(null);
  };
}

export const REVOKE_CUSTOMER_TOKEN = gql`
  mutation revokeCustomerToken {
    revokeCustomerToken {
      result
    }
  }
`;
