import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/title/Title';
import WishlistPagination, {
  WishlistViewInputType,
} from '../../components/wishlist_pagination/WishlistPagination';

export function WishlistDetail() {
  const { t } = useTranslation();

  const query: WishlistViewInputType = {
    currentPage: 1,
    pageSize: 8,
  };

  return (
    <>
      <Title name={t('account.whishlist')} />
      <WishlistPagination input={query} />
    </>
  );
}
WishlistDetail.displayName = 'WishlistDetail';
export default WishlistDetail;
