import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrashOutline } from 'react-ionicons';
import * as yup from 'yup';
import BTButton from '../../core/bt_button/BTButton';

export type CouponFormValueType = {
  couponCode: string;
};

type CouponFormValidation = {
  [key in keyof CouponFormValueType]: yup.AnySchema;
};

export type CouponFormPropsType = {
  initialValues: CouponFormValueType;
  onSubmit: (value: CouponFormValueType) => void;
  onRemove: () => void;
  styles: { readonly [key: string]: string };
};
function CouponForm(props: CouponFormPropsType) {
  const { t } = useTranslation();
  const { initialValues, onSubmit, onRemove, styles } = props;
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  const formik = useFormik<CouponFormValueType>({
    initialValues,
    validationSchema: yup.object().shape<CouponFormValidation>({
      couponCode: yup.string(),
    }),
    onSubmit: values => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    setIsCouponApplied(
      initialValues.couponCode && initialValues.couponCode !== '' ? true : false
    );
    formik.values.couponCode = initialValues.couponCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.couponCode]);

  return (
    <>
      {!isCouponApplied && (
        <>
          <Col className={'col-12'}>
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-around align-items-center">
                <Form.Group className="m-0">
                  <Form.Control
                    type="text"
                    className={`${styles.input} ${styles.smallText}`}
                    name="couponCode"
                    placeholder={t('cart.couponTextInput')}
                    value={formik.values.couponCode}
                    onChange={event => formik.handleChange(event)}
                    isInvalid={!!formik.errors.couponCode}
                    autoComplete={'off'}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.couponCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <BTButton
                  disabled={isEmpty(formik.values.couponCode)}
                  variant="outline-primary"
                  className={`${styles.smallBoldText} ${styles.checkButton} `}
                  type="submit"
                >
                  {t('cart.couponTextButton')}
                </BTButton>
              </div>
            </Form>
          </Col>
        </>
      )}
      {isCouponApplied && (
        <>
          <Col className={`${styles.smallBoldText} col-8 align-self-center`}>
            {`${t('cart.couponTextApplied')}: ${formik.values.couponCode}`}
          </Col>
          <Col className="text-right col-4">
            <TrashOutline
              color={'var(--primary)'}
              height="26px"
              width="26px"
              onClick={onRemove}
            />
          </Col>
        </>
      )}
    </>
  );
}

export default CouponForm;
