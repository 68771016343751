import { InMemoryCache, makeVar } from '@apollo/client';
import MpBlog from '../../models/MpBlog';
import Storage from '../storage/Storage';
import { AppState } from './types';
export const cache: InMemoryCache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        appState: {
          read() {
            return appStateVar();
          },
        },
      },
    },
  },
});
export const tokenIdVar = makeVar<string | null>(Storage.readToken());
export const appStateVar = makeVar<AppState>({
  hideDock: false,
  hideHeader: false,
  dockStatus: 'default',
  showCategoryFilter: false,
});

export const mpBlogsListVar = makeVar<MpBlog[]>([]);
