import React from 'react';
import BTContainer from '../../core/bt_container/BTContainer';
import styles from './Title.module.scss';

type TitleProps = {
  className?: string;
  name: string;
  color?: any;
  font?: any;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
};

function Title(props: TitleProps) {
  const { name, color, font, className, leftElement, rightElement } = props;
  return (
    <BTContainer
      className={`mt-2 ${styles.title} ${className}`}
      style={{ color, font }}
    >
      <div className={`d-flex justify-content-around`}>
        <div className={`p-0 d-flex align-items-center justify-content-center`}>
          {leftElement}
        </div>

        <div className={'text-center title'}>{name}</div>

        <div className={`p-0 d-flex align-items-center justify-content-center`}>
          {rightElement}
        </div>
      </div>
    </BTContainer>
  );
}

export default Title;
