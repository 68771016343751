import Account from './pages/account/Account';
import AccountDelete from './pages/account_delete/AccountDelete';
import AddressManagement from './pages/address_management/AddressManagement';
import AddressUpsert from './pages/address_upsert/AddressUpsert';
import AxerveCancel from './pages/axerve/AxerveCancel';
import Cart from './pages/cart/Cart';
import Category from './pages/category/Category';
import Checkout from './pages/checkout/Checkout';
import CmsBlocks from './pages/cms_blocks/CmsBlocks';
import Home from './pages/home/Home';
import Menu from './pages/menu/Menu';
import News from './pages/news/News';
import NewsDetail from './pages/news/NewsDetail';
import Offers from './pages/offers/Offers';
import Orders from './pages/orders/Orders';
import OrderCompleted from './pages/order_complited/OrderCompleted';
import OrderDetail from './pages/order_detail/OrderDetail';
import PayPalCancel from './pages/paypal/PayPalCancel';
import PayPalReturn from './pages/paypal/PayPalReturn';
import ProductDetail from './pages/product_detail/ProductDetail';
import SearchProduct from './pages/search_products/SearchProducts';
import StateSearchProduct from './pages/search_products/StateSearchProducts';
import TrackingData from './pages/tracking-data/TrackingData';
import TrackingInfo from './pages/tracking-info/TrackingInfo';
import UserRegistry from './pages/user_registry/UserRegistry';
import Version from './pages/version/Version';
import WishlistDetail from './pages/wishlist_detail/WishlistDetail';

export type BTRouter = {
  path: string;
  component: () => JSX.Element;
  exact?: boolean;
};

const routes: BTRouter[] = [
  {
    path: '/categories/:uid',
    component: Category,
  },
  {
    path: '/menu',
    component: Menu,
  },
  {
    path: '/cart/checkout',
    component: Checkout,
  },
  {
    path: '/cart',
    component: Cart,
    exact: true,
  },
  {
    path: '/accounts/user-registry',
    component: UserRegistry,
  },
  {
    path: '/accounts/upsert_address',
    component: AddressUpsert,
  },
  {
    path: '/accounts/address',
    component: AddressManagement,
  },
  {
    path: '/accounts/orders/:number',
    component: OrderDetail,
  },
  {
    path: '/accounts/orders',
    component: Orders,
    exact: true,
  },
  {
    path: '/accounts/wishlist',
    component: WishlistDetail,
  },
  {
    path: '/accounts/delete',
    component: AccountDelete,
  },
  {
    path: '/accounts',
    component: Account,
    exact: true,
  },
  {
    path: '/products/:sku',
    component: ProductDetail,
  },
  {
    path: '/products-search/:text',
    component: SearchProduct,
  },
  {
    path: '/products-state-search',
    component: StateSearchProduct,
    exact: true,
  },
  {
    path: '/offers',
    component: Offers,
  },
  {
    path: '/paypal/success',
    component: PayPalReturn,
  },
  {
    path: '/paypal/cancel',
    component: PayPalCancel,
  },
  {
    path: '/axerve/return',
    component: OrderCompleted,
  },
  {
    path: '/axerve/cancel',
    component: AxerveCancel,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/trackings/info',
    component: TrackingInfo,
  },
  {
    path: '/trackings/data/:orderNumber/:customerEmail',
    component: TrackingData,
  },
  {
    path: '/version',
    component: Version,
  },
  {
    path: '/cms-blocks',
    component: CmsBlocks,
  },
  {
    path: '/news/:id',
    component: NewsDetail,
  },
  {
    path: '/news',
    component: News,
  },
  {
    path: '/order-complited',
    component: OrderCompleted,
  },
];

export default routes;
