import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './BTModal.module.scss';

export type BTModalProps = {
  show: boolean;
  title?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
  closeButton?: boolean;
};

const BTModal = (props: BTModalProps) => {
  const { show, body, title, closeButton, footer, onClose } = props;
  return (
    <Modal
      className={`${styles['modal-bt']}`}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      {title !== undefined && (
        <Modal.Header
          className={`${styles['modal-header']}`}
          closeButton={closeButton}
        >
          <Modal.Title className={`${styles['modal-title']}`}>
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      {body !== undefined && (
        <Modal.Body className={`${styles['modal-body']}`}>{body}</Modal.Body>
      )}
      {footer !== undefined && (
        <Modal.Footer className={`${styles['modal-footer']}`}>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default BTModal;
