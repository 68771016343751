import { ErrorResponse } from '@apollo/client/link/error';

interface ApolloEventsModel {
  connect: (() => void)[];
  request: ((request: any) => void)[];
  response: ((response: any) => void)[];
  errors: ((error: ErrorResponse) => void)[];
}
const ApolloEvent: ApolloEventsModel = {
  connect: [],
  request: [],
  response: [],
  errors: [],
};

export default ApolloEvent;
