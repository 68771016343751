import { useReactiveVar } from '@apollo/client';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { initReactI18next } from 'react-i18next';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import './App.scss';
import Dock from './components/dock/Dock';
import Header from './components/header/Header';
import InstallPWA from './components/install_pwa/InstallPWA';
import { appStateVar } from './core/apollo/Cache';
import BTLoader from './core/bt_loader/BTLoader';
import CartProvider from './core/contexts/CartContext';
import { httpInterceptor } from './core/interceptor/httpInterceptor';
import Scroll2Top from './core/scroll2Top/Scroll2Top';
import useIsIOS from './hooks/useIsIOS';
import { I18nResorces } from './i18n/Resources';
import it from './i18n/it';
import NotFound from './pages/not_found/NotFound';
import SplashScreen from './pages/splash_screen/SplashScreen';
import routes from './routers';
import VersionCheck from './components/VersionCheck';

SwiperCore.use([Autoplay, Pagination, Navigation]);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      it,
    } as I18nResorces,
    lng: 'it', // if you're using a language detector, do not define the lng option
    fallbackLng: 'it',

    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  const { prompt } = useIsIOS();
  const [loading, setLoading] = useState(false);
  const appState = useReactiveVar(appStateVar);

  useEffect(() => {
    httpInterceptor(
      () => setLoading(true),
      () => setLoading(false),
      err => {
        console.error(err);
      }
    );
  }, []);

  return (
    <>
      <CookiesProvider>
        <CartProvider>
          <ReactNotification isMobile={true} />
          {prompt && <InstallPWA />}
          <BTLoader show={loading} />
          <Router basename="/bytecno">
            <VersionCheck />
            <Scroll2Top />
            {!appState.hideHeader && <Header />}
            <div {...(!appState.hideDock && { className: 'mb-4' })}>
              <Switch>
                {routes.map((route, index) => (
                  <Route key={index} {...route} />
                ))}
                <Route exact path="/" component={SplashScreen} />
                <Route component={NotFound} />
              </Switch>
            </div>
            {!appState.hideDock && <Dock />}
          </Router>
        </CartProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
