import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import AddressForm, {
  AddressFormCountryEnum,
  AddressFormValueType,
} from '../../components/address_form/AddressForm';
import Title from '../../components/title/Title';
import {
  CustomerAddressAttributeInput,
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import { addSuccesNotification } from '../../core/notification/Notification';
import { Address } from '../../models/Address';
import styles from './AddressUpsert.module.scss';

export function AddressUpsert() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    state,
  }: { state: { newAddress: boolean; address: Address; addressType: string } } =
    useLocation();

  const [createAddress] = useCreateAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();

  const addressForm = useMemo<AddressFormValueType>(() => {
    const customer_type =
      state.address?.custom_attributes?.find(
        item => item.attribute_code === 'customer_type'
      )?.value || '0';
    const codice_fiscale =
      state.address?.custom_attributes?.find(
        item => item.attribute_code === 'codice_fiscale'
      )?.value || '';
    const sdi_pec =
      state.address?.custom_attributes?.find(
        item => item.attribute_code === 'sdi_pec'
      )?.value || '';

    return {
      firstname: state.address?.firstname || '',
      lastname: state.address?.lastname || '',
      region_id: state.address?.region?.region_id || 0,
      city: state.address?.city || '',
      country_id: AddressFormCountryEnum.IT,
      default_billing: state.address?.default_billing || false,
      default_shipping: state.address?.default_shipping || false,
      postcode: state.address?.postcode || '',
      street: state.address?.street[0] || '',
      telephone: state.address?.telephone || '',
      company: state.address?.company || '',
      vat_id: state.address?.vat_id || '',
      customer_type: customer_type,
      codice_fiscale: codice_fiscale,
      sdi_pec: sdi_pec,
    };
  }, [state]);

  async function onSubmit(value: AddressFormValueType) {
    const custom_attributes = [
      { attribute_code: 'customer_type', value: value.customer_type },
      { attribute_code: 'codice_fiscale', value: value.codice_fiscale },
      { attribute_code: 'sdi_pec', value: value.sdi_pec },
    ] as CustomerAddressAttributeInput[];

    try {
      if (state.newAddress && value) {
        await createAddress({
          variables: {
            firstname: value.firstname,
            lastname: value.lastname,
            region_id: value.region_id,
            city: value.city,
            default_billing: value.default_billing,
            default_shipping: value.default_shipping,
            postcode: value.postcode,
            street: [value.street],
            telephone: value.telephone,
            company: value.company,
            vat_id: value.vat_id,
            custom_attributes: custom_attributes,
          },
        });
      } else if (value) {
        await updateAddress({
          variables: {
            id: state.address.id,
            firstname: value.firstname,
            lastname: value.lastname,
            region_id: value.region_id,
            city: value.city,
            default_billing: value.default_billing,
            default_shipping: value.default_shipping,
            postcode: value.postcode,
            street: [value.street],
            telephone: value.telephone,
            company: value.company,
            vat_id: value.vat_id,
            custom_attributes: custom_attributes,
          },
        });
      }

      addSuccesNotification({
        message: t('successMessage.customerOperationOk'),
      });

      //history.goBack();
      history.push({
        pathname: '/accounts/address',
        state: {
          isToRefetch: true,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <>
      <Title name={t('addressManagement.title')} />
      <BTContainer className={`mt-5 mb-4`}>
        <Row>
          <Col className={`${styles.addressType} text-left`}>
            {state.newAddress
              ? t('addressCreation.title')
              : t('addressModify.title')}
          </Col>
        </Row>
        <Row className={`${styles.addressType} mt-4`}>
          <Col className={'text-left'}>
            {state.newAddress && t('addressCreation.newAddress')}
          </Col>
        </Row>

        <AddressForm
          initialValues={addressForm}
          onSubmit={onSubmit}
          submitText={
            state.newAddress ? t('addressCreation.create') : t('common.save')
          }
        />
      </BTContainer>
    </>
  );
}
AddressUpsert.displayName = 'AddressUpsert';

export default AddressUpsert;
