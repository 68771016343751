import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import Title from '../../components/title/Title';
import {
  CmsBlocks,
  useGetCmsBlocksQuery,
} from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';

function TrackingInfo() {
  const { t } = useTranslation();
  const TRACKING_BLOCK = 'tracking-creative';

  const [tracking, setTracking] = useState({
    orderNumber: '',
    email: '',
  } as { orderNumber: string; email: string });

  const [data, setData] = useState<CmsBlocks>({} as CmsBlocks);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useGetCmsBlocksQuery({
    variables: {
      blockIds: [TRACKING_BLOCK],
    },
    onCompleted: data => {
      const { cmsBlocks } = data;
      setData(cmsBlocks as CmsBlocks);
      setLoading(false);
    },
  });

  function onSubmit() {
    const { orderNumber, email } = tracking;
    history.push({
      pathname: generatePath('/trackings/data/:orderNumber/:customerEmail', {
        orderNumber,
        customerEmail: email,
      }),
    });
  }

  function onChangeHandler(key: string, value: string) {
    setTracking({ ...tracking, [key]: value });
  }

  return (
    <>
      <Title className={`mt-4`} name={t('tracking.title')} />
      {!loading && (
        <BTContainer className={`mt-3`}>
          {data.items?.map((item, index: number) => (
            <BTHtmlWrapper content={item?.content} key={index} />
          ))}
          <BTGreenForm onSubmit={onSubmit} className={`mt-4`}>
            <Form.Group controlId="formOrder">
              <Form.Control
                type="input"
                name="orderNumber"
                placeholder={t('tracking.orderNumber')}
                value={tracking.orderNumber}
                onChange={event =>
                  onChangeHandler(event.target.name, event.target.value)
                }
                required={true}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                name="email"
                placeholder={t('login.user')}
                value={tracking.email}
                onChange={event =>
                  onChangeHandler(event.target.name, event.target.value)
                }
                required={true}
              />
            </Form.Group>
            <BTButton
              className={`btn-block mb-2`}
              variant="primary"
              type="submit"
            >
              {t('tracking.find')}
            </BTButton>
          </BTGreenForm>
        </BTContainer>
      )}
    </>
  );
}
TrackingInfo.displayName = 'TrackingInfo';
export default TrackingInfo;
