import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { appStateVar } from '../../core/apollo/Cache';
import {
  CmsBlocks,
  useGetCmsBlocksQuery,
} from '../../core/apollo/gql-generate';
import { updateAppState } from '../../core/apollo/operations/mutations/appState';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';

const SPLASH_SCREEN_BLOCK = 'mobile_splash-screen';

function SplashScreen({ setHidden }: { setHidden: (hidden: boolean) => void }) {
  const [data, setData] = useState({} as CmsBlocks);
  const appState = useReactiveVar(appStateVar);

  const updateAppStateDispatch = updateAppState(appStateVar);

  useGetCmsBlocksQuery({
    variables: {
      blockIds: [SPLASH_SCREEN_BLOCK],
    },
    onCompleted: data => {
      const { cmsBlocks } = data;
      setData(cmsBlocks as CmsBlocks);
    },
  });

  useEffect(() => {
    updateAppStateDispatch({
      ...appState,
      hideDock: true,
      hideHeader: true,
    });
    return () => {
      updateAppStateDispatch({
        ...appState,
        hideDock: false,
        hideHeader: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column">
      {data.items?.map((item, index: number) => (
        <BTHtmlWrapper content={item?.content} key={index} />
      ))}
    </div>
  );
}

export default SplashScreen;
