import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { ProductLightFieldFragment } from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import PriceText from '../../core/price_text/PriceText';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import styles from './SmallProductCard.module.scss';

type SmallProductCardProps = {
  product: ProductLightFieldFragment;
  width?: number;
};

function SmallProductCard(props: SmallProductCardProps) {
  const { product, width } = props;

  const history = useHistory();

  const { blocks } = useCmdBlocks(
    product.bloccoetichetta1 ? [product.bloccoetichetta1] : []
  );

  return (
    <>
      <BTButton
        variant="light"
        className={`${styles.container} p-0 m-0 d-flex align-items-top`}
        onPress={() =>
          history.push({
            pathname: generatePath('/products/:sku', {
              sku: product?.sku!,
            }),
          })
        }
        style={{ width }}
      >
        <BTContainer className="d-flex flex-column bd-highlight p-2">
          <div className={`p-0`}>
            <img
              className={`${styles.image}`}
              src={product.image?.url}
              alt="icon"
            />
            {product.bloccoetichetta1 && (
              <BTHtmlWrapper
                className={`${styles.etichetta}`}
                content={blocks[product.bloccoetichetta1]}
                style={{
                  position: 'absolute',
                }}
              />
            )}
          </div>
          <div className={`text-left ${styles.description} pt-2`}>
            {product.short_description?.html}
          </div>
          <div className={`${styles.price} pt-1 px-1`}>
            {/*product.special_price && (
              <div className={`${styles.strikethroughText} pr-0`}>
                <PriceText
                  value={product.price?.regularPrice?.amount?.value || ''}
                  position="right"
                />
              </div>
            )*/}
            <div className={'pl-0'}>
              <PriceText
                value={
                  product?.special_price ||
                  product?.price_range.maximum_price?.final_price.value ||
                  ''
                }
                position="right"
              />
            </div>
          </div>
        </BTContainer>
      </BTButton>
    </>
  );
}

export default SmallProductCard;
