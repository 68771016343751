import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Title from '../../components/title/Title';
import {
  CustomerFieldsFragment,
  useChangeCustomerPasswordMutation,
  useGetCustomerQuery,
} from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import { addSuccesNotification } from '../../core/notification/Notification';
import styles from './UserRegistry.module.scss';

type UserFormValueType = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

type UserFormValidation = {
  [key in keyof UserFormValueType]: yup.AnySchema;
};

function UserRegistry() {
  const { t } = useTranslation();

  const [customer, setCustomer] = useState({} as CustomerFieldsFragment);
  //const [user, setUser] = useState({} as CustomerCredentialsType);

  const [changePassword] = useChangeCustomerPasswordMutation();

  const { loading } = useGetCustomerQuery({
    onCompleted: data => {
      setCustomer(data?.customer as CustomerFieldsFragment);
    },
  });

  const initialValues: UserFormValueType = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const formik = useFormik<UserFormValueType>({
    initialValues,
    validationSchema: yup.object().shape<UserFormValidation>({
      oldPassword: yup.string().required(t('formMessage.required')),
      password: yup.string().required(t('formMessage.required')),
      confirmPassword: yup
        .string()
        .required(t('formMessage.required'))
        .oneOf(
          [yup.ref('password'), null],
          t('errorMessage.confirmPasswordFailed')
        ),
    }),
    onSubmit: values => {
      onSubmit(values);
    },
  });

  async function onSubmit(event: UserFormValueType) {
    try {
      await changePassword({
        variables: {
          currentPassword: event.oldPassword,
          newPassword: event.password,
        },
      });
      addSuccesNotification({
        message: t('successMessage.customerChangePasswordOk'),
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Title name={t('account.anagraphic.title')} />
      {!loading && (
        <BTContainer>
          <Row>
            <Col className={`${styles.subTitle} mt-5`}>
              {t('account.anagraphic.subTitle')}
            </Col>
          </Row>
          <Row>
            <Col className={`${styles.user} mt-5`}>
              {customer.firstname} {customer.lastname}
            </Col>
          </Row>
          <Row>
            <Col>
              <BTGreenForm onSubmit={formik.handleSubmit}>
                <Form.Group controlId="formBasicEmail" className={'mt-2'}>
                  <Form.Control
                    type="email"
                    placeholder="e-mail"
                    name="email"
                    value={customer.email}
                    required={true}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder={t('login.oldPassword')}
                      name="oldPassword"
                      value={formik.values.oldPassword}
                      onChange={event => formik.handleChange(event)}
                      required={true}
                      isInvalid={!!formik.errors.oldPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.oldPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder={t('login.newPassword')}
                      name="password"
                      value={formik.values.password}
                      onChange={event => formik.handleChange(event)}
                      required={true}
                      isInvalid={!!formik.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      placeholder={t('login.confirmPassword')}
                      onChange={event => formik.handleChange(event)}
                      required={true}
                      isInvalid={!!formik.errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.confirmPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <BTButton
                  type="submit"
                  className={`btn-block mt-5 ${styles.subTitle}`}
                  variant="primary"
                  size="sm"
                >
                  {t('common.modify')}
                </BTButton>
              </BTGreenForm>
            </Col>
          </Row>
        </BTContainer>
      )}
    </>
  );
}
UserRegistry.displayName = 'UserRegistry';
export default UserRegistry;
