import isNil from 'lodash.isnil';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import OrderConfirm from '../../components/order_confirm/OrderConfirm';
import { COOKIE_ORDER } from '../../constants/cookies';
import BTContainer from '../../core/bt_container/BTContainer';
import { useCart } from '../../core/contexts/CartContext';
import { OrderSummaryCookie } from '../../models/Cookies';

const OrderCompleted = () => {
  const history = useHistory();

  const { reloadCart } = useCart();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _, removeCookie] = useCookies([COOKIE_ORDER]);

  const order = useMemo<OrderSummaryCookie>(
    () => (!isNil(cookies.COOKIE_ORDER) ? cookies.COOKIE_ORDER : undefined),
    [cookies]
  );

  useEffect(() => {
    return () => {
      reloadCart().then(() => {
        cookies.COOKIE_ORDER && removeCookie('COOKIE_ORDER');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNil(cookies.COOKIE_ORDER)) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  return (
    <BTContainer className="my-4">
      {order && <OrderConfirm order={order} />}
    </BTContainer>
  );
};
OrderCompleted.displayName = 'OrderCompleted';
export default OrderCompleted;
