import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import BTButton from '../../core/bt_button/BTButton';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import useLogin from '../../hooks/useLogin';
import SocialLogin from '../social_login/SocialLogin';

type LoginProps = {
  onCompleted?: () => void;
  socialLogin?: boolean;
  className?: string;
  socialComponent?: React.ReactNode;
};

type LoginFormValueType = {
  username: string;
  password: string;
};

type LoginFormValidation = {
  [key in keyof LoginFormValueType]: yup.AnySchema;
};

function Login(props: LoginProps) {
  const {
    onCompleted,
    socialLogin = false,
    className,
    socialComponent,
  } = props;

  const { t } = useTranslation();

  const { login, resetPassword } = useLogin();

  const [credentials, setCredentials] = useState<LoginFormValueType>({
    username: '',
    password: '',
  });

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const [message, setMessage] = useState<string>();

  const loginFormik = useFormik<LoginFormValueType>({
    initialValues: credentials,
    validationSchema: yup.object().shape<LoginFormValidation>({
      username: yup
        .string()
        .email(t('formMessage.email'))
        .required(t('formMessage.required')),
      password: yup.string().required(t('formMessage.required')),
    }),
    onSubmit: values => {
      onLoginSubmit(values);
    },
  });

  const resetFormik = useFormik<Omit<LoginFormValueType, 'password'>>({
    initialValues: credentials,
    validationSchema: yup
      .object()
      .shape<Omit<LoginFormValidation, 'password'>>({
        username: yup
          .string()
          .email(t('formMessage.email'))
          .required(t('formMessage.required')),
      }),
    onSubmit: values => {
      onResetSubmit(values);
    },
  });

  useEffect(() => {
    showResetPassword && setMessage(undefined);
  }, [showResetPassword]);

  /* function onChangeHandler(key: string, value: string) {
    setCredentials({ ...credentials, [key]: value });
  } */

  async function onLoginSubmit(event: LoginFormValueType) {
    try {
      await login(event.username, event.password);
      onCompleted && onCompleted();
    } catch (err) {
      console.error(err);
    }
  }

  async function onResetSubmit(event: Omit<LoginFormValueType, 'password'>) {
    try {
      await resetPassword(event.username);
      setMessage(
        `Se c'è un account associato con ${event.username} riceverai un'email con il link per reimpostare la password.`
      );
      setCredentials({ password: '', username: '' });
      setShowResetPassword(false);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className={className}>
      {message && <Alert variant="primary">{message}</Alert>}
      {!showResetPassword && (
        <BTGreenForm onSubmit={loginFormik.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                name="username"
                placeholder={t('login.user')}
                value={loginFormik.values.username}
                onChange={event => loginFormik.handleChange(event)}
                required={true}
                isInvalid={!!loginFormik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {loginFormik.errors.username}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <InputGroup hasValidation>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={loginFormik.values.password}
                onChange={event => loginFormik.handleChange(event)}
                required={true}
                autoComplete="new-password"
                isInvalid={!!loginFormik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {loginFormik.errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <div className={`text-right  mb-3`}>
            <BTButton variant="link" onPress={() => setShowResetPassword(true)}>
              {t('login.forgotPassword')}
            </BTButton>
          </div>
          <BTButton
            className={`btn-block mb-2`}
            variant="primary"
            type="submit"
          >
            {t('login.login')}
          </BTButton>
          {socialLogin && <SocialLogin />}
          {socialLogin && socialComponent}
        </BTGreenForm>
      )}
      {showResetPassword && (
        <BTGreenForm onSubmit={resetFormik.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                name="username"
                placeholder={t('login.user')}
                value={resetFormik.values.username}
                onChange={event => resetFormik.handleChange(event)}
                required={true}
                isInvalid={!!resetFormik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {loginFormik.errors.username}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <BTButton
            className={`btn-block mb-2`}
            variant="primary"
            type="submit"
          >
            Reset
          </BTButton>
          <BTButton
            className={`btn-block mb-2`}
            variant="outline-primary"
            onPress={() => setShowResetPassword(false)}
          >
            Torna alla login
          </BTButton>
        </BTGreenForm>
      )}
    </div>
  );
}

export default Login;
