import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddBundleProductsToCartInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<BundleProductCartItemInput>>;
};

export type AddBundleProductsToCartOutput = {
  __typename?: 'AddBundleProductsToCartOutput';
  cart: Cart;
};

export type AddConfigurableProductsToCartInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<ConfigurableProductCartItemInput>>;
};

export type AddConfigurableProductsToCartOutput = {
  __typename?: 'AddConfigurableProductsToCartOutput';
  cart: Cart;
};

export type AddDownloadableProductsToCartInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<DownloadableProductCartItemInput>>;
};

export type AddDownloadableProductsToCartOutput = {
  __typename?: 'AddDownloadableProductsToCartOutput';
  cart: Cart;
};

export type AddProductsToCartOutput = {
  __typename?: 'AddProductsToCartOutput';
  /** The cart after products have been added */
  cart: Cart;
  /** An error encountered while adding an item to the cart. */
  user_errors: Array<Maybe<CartUserInputError>>;
};

export type AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered */
export type AddProductsToWishlistOutput = {
  __typename?: 'AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added */
  wishlist: Wishlist;
};

export type AddSimpleProductsToCartInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
};

export type AddSimpleProductsToCartOutput = {
  __typename?: 'AddSimpleProductsToCartOutput';
  cart: Cart;
};

export type AddVirtualProductsToCartInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<VirtualProductCartItemInput>>;
};

export type AddVirtualProductsToCartOutput = {
  __typename?: 'AddVirtualProductsToCartOutput';
  cart: Cart;
};

export type Address = {
  id: Scalars['Int'];
};

/** A bucket that contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Aggregation = {
  __typename?: 'Aggregation';
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String'];
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>;
};

export type AggregationOption = AggregationOptionInterface & {
  __typename?: 'AggregationOption';
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

export type AmLinkedSocialAccounts = {
  __typename?: 'AmLinkedSocialAccounts';
  /** Customer Name */
  name?: Maybe<Scalars['String']>;
  /** Social platform type */
  type?: Maybe<Scalars['String']>;
};

export type AmSocialLoginButton = {
  __typename?: 'AmSocialLoginButton';
  /** Label */
  label?: Maybe<Scalars['String']>;
  /** Social Platform Type */
  type?: Maybe<Scalars['String']>;
  /** Social Url */
  url?: Maybe<Scalars['String']>;
};

export type AmUnlinkData = {
  __typename?: 'AmUnlinkData';
  /** Is unlink successful. */
  isSuccess?: Maybe<Scalars['Boolean']>;
  /** Result message. */
  message?: Maybe<Scalars['String']>;
};

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  code: Scalars['String'];
};

export type ApplyCouponToCartInput = {
  cart_id: Scalars['String'];
  coupon_code: Scalars['String'];
};

export type ApplyCouponToCartOutput = {
  __typename?: 'ApplyCouponToCartOutput';
  cart: Cart;
};

/** AreaInput defines the parameters which will be used for filter by specified location. */
export type AreaInput = {
  /** The radius for the search in KM. */
  radius: Scalars['Int'];
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String'];
};

export type Assets = {
  __typename?: 'Assets';
  /** The payment method logo url (descriptive) */
  descriptive?: Maybe<Scalars['String']>;
  /** The payment method logo url (standard) */
  standard?: Maybe<Scalars['String']>;
};

export type AssignCompareListToCustomerOutput = {
  __typename?: 'AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>;
  result: Scalars['Boolean'];
};

/** Attribute contains the attribute_type of the specified attribute_code and entity_type */
export type Attribute = {
  __typename?: 'Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute */
  attribute_type?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute */
  input_type?: Maybe<Scalars['String']>;
};

/** AttributeInput specifies the attribute_code and entity_type to search */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']>;
};

/** Attribute option. */
export type AttributeOption = {
  __typename?: 'AttributeOption';
  /** Attribute option label. */
  label?: Maybe<Scalars['String']>;
  /** Attribute option value. */
  value?: Maybe<Scalars['String']>;
};

export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod';
  /** The payment method code */
  code: Scalars['String'];
  /** The payment method title. */
  title: Scalars['String'];
};

export type AvailableShippingMethod = {
  __typename?: 'AvailableShippingMethod';
  amount: Money;
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>;
  carrier_code: Scalars['String'];
  carrier_title: Scalars['String'];
  error_message?: Maybe<Scalars['String']>;
  /** Could be null if method is not available */
  method_code?: Maybe<Scalars['String']>;
  /** Could be null if method is not available */
  method_title?: Maybe<Scalars['String']>;
  price_excl_tax: Money;
  price_incl_tax: Money;
};

export type BestsellersProductOutput = {
  __typename?: 'BestsellersProductOutput';
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Period. */
  period?: Maybe<Scalars['String']>;
  /** Product ID */
  product_id?: Maybe<Scalars['String']>;
  /** Product Image URL */
  product_image_url?: Maybe<Scalars['String']>;
  /** Product Name */
  product_name?: Maybe<Scalars['String']>;
  /** Product Price */
  product_price?: Maybe<Scalars['String']>;
  /** Product SKU */
  product_sku?: Maybe<Scalars['String']>;
  /** Product URL */
  product_url?: Maybe<Scalars['String']>;
  /** Order Quantity. */
  qty_ordered?: Maybe<Scalars['Int']>;
};

export type BillingAddressInput = {
  address?: Maybe<CartAddressInput>;
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Set billing address same as shipping */
  same_as_shipping?: Maybe<Scalars['Boolean']>;
  /** Deprecated: use `same_as_shipping` field instead */
  use_for_shipping?: Maybe<Scalars['Boolean']>;
};

export type BillingCartAddress = CartAddressInterface & {
  __typename?: 'BillingCartAddress';
  city: Scalars['String'];
  codice_fiscale?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  /** @deprecated The field is used only in shipping address */
  customer_notes?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['Int']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  sdi_pec?: Maybe<Scalars['String']>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  vat_id?: Maybe<Scalars['String']>;
};

export type BraintreeCcVaultInput = {
  device_data?: Maybe<Scalars['String']>;
  public_hash: Scalars['String'];
};

export type BraintreeInput = {
  /** Contains a fingerprint provided by Braintree JS SDK and should be sent with sale transaction details to the Braintree payment gateway. Should be specified only in a case if Kount (advanced fraud protection) is enabled for Braintree payment integration. */
  device_data?: Maybe<Scalars['String']>;
  /** States whether an entered by a customer credit/debit card should be tokenized for later usage. Required only if Vault is enabled for Braintree payment integration. */
  is_active_payment_token_enabler: Scalars['Boolean'];
  /** The one-time payment token generated by Braintree payment gateway based on card details. Required field to make sale transaction. */
  payment_method_nonce: Scalars['String'];
};

/** Breadcrumb item. */
export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  /**
   * Category ID.
   * @deprecated Use the `category_uid` argument instead.
   */
  category_id?: Maybe<Scalars['Int']>;
  /** Category level. */
  category_level?: Maybe<Scalars['Int']>;
  /** Category name. */
  category_name?: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  category_uid: Scalars['ID'];
  /** Category URL key. */
  category_url_key?: Maybe<Scalars['String']>;
  /** Category URL path. */
  category_url_path?: Maybe<Scalars['String']>;
};

export type BundleCartItem = CartItemInterface & {
  __typename?: 'BundleCartItem';
  bundle_options: Array<Maybe<SelectedBundleOption>>;
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

export type BundleCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'BundleCreditMemoItem';
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

export type BundleInvoiceItem = InvoiceItemInterface & {
  __typename?: 'BundleInvoiceItem';
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** BundleItem defines an individual item in a bundle product. */
export type BundleItem = {
  __typename?: 'BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>;
  /** he relative position of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']>;
};

/** BundleItemOption defines characteristics and options for a specific bundle item. */
export type BundleItemOption = {
  __typename?: 'BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated The `qty` is deprecated. Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /** Indicates the quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
};

export type BundleOptionInput = {
  id: Scalars['Int'];
  quantity: Scalars['Float'];
  value: Array<Maybe<Scalars['String']>>;
};

export type BundleOrderItem = OrderItemInterface & {
  __typename?: 'BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** BundleProduct defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProduct = ProductInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'BundleProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamic_price?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamic SK. */
  dynamic_sku?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamic_weight?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  /** An array containing information about individual bundle items. */
  items?: Maybe<Array<Maybe<BundleItem>>>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  price_view?: Maybe<PriceViewEnum>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** Indicates whether to ship bundle items together or individually. */
  ship_bundle_items?: Maybe<ShipBundleItemsEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** BundleProduct defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type BundleProductCartItemInput = {
  bundle_options: Array<Maybe<BundleOptionInput>>;
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  data: CartItemInput;
};

export type BundleShipmentItem = ShipmentItemInterface & {
  __typename?: 'BundleShipmentItem';
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
};

export type BundleWishlistItem = WishlistItemInterface & {
  __typename?: 'BundleWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** An array containing information about the selected bundle items */
  bundle_options?: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

export type Cart = {
  __typename?: 'Cart';
  /**
   * An array of coupons that have been applied to the cart
   * @deprecated Use applied_coupons instead
   */
  applied_coupon?: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** Available payment methods */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  billing_address?: Maybe<BillingCartAddress>;
  email?: Maybe<Scalars['String']>;
  /** The entered gift message for the cart */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for a `Cart` object */
  id: Scalars['ID'];
  is_virtual: Scalars['Boolean'];
  items?: Maybe<Array<Maybe<CartItemInterface>>>;
  prices?: Maybe<CartPrices>;
  selected_payment_method?: Maybe<SelectedPaymentMethod>;
  shipping_addresses: Array<Maybe<ShippingCartAddress>>;
  total_quantity: Scalars['Float'];
};

export type CartAddressCountry = {
  __typename?: 'CartAddressCountry';
  code: Scalars['String'];
  label: Scalars['String'];
};

export type CartAddressInput = {
  city: Scalars['String'];
  codice_fiscale: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country_code: Scalars['String'];
  customer_type?: Maybe<Scalars['Int']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true */
  save_in_address_book?: Maybe<Scalars['Boolean']>;
  sdi_pec: Scalars['String'];
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  vat_id: Scalars['String'];
};

export type CartAddressInterface = {
  city: Scalars['String'];
  codice_fiscale?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  customer_type?: Maybe<Scalars['Int']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  sdi_pec?: Maybe<Scalars['String']>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  vat_id?: Maybe<Scalars['String']>;
};

export type CartAddressRegion = {
  __typename?: 'CartAddressRegion';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
};

export type CartDiscount = {
  __typename?: 'CartDiscount';
  amount: Money;
  label: Array<Maybe<Scalars['String']>>;
};

export type CartItemInput = {
  /** An array of entered options for the base product, such as personalization text */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For child products, the SKU of its parent product */
  parent_sku?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  /** The selected options for the base product, such as color or size with  unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `ConfigurableProductOptionsValues`, etc. objects */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sku: Scalars['String'];
};

export type CartItemInterface = {
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

export type CartItemPrices = {
  __typename?: 'CartItemPrices';
  /** An array of discounts to be applied to the cart item */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  price: Money;
  row_total: Money;
  row_total_including_tax: Money;
  /** The total of all discounts applied to the item */
  total_item_discount?: Maybe<Money>;
};

/** Deprecated: `cart_items` field of `ShippingCartAddress` returns now  `CartItemInterface` instead of `CartItemQuantity` */
export type CartItemQuantity = {
  __typename?: 'CartItemQuantity';
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  cart_item_id: Scalars['Int'];
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  quantity: Scalars['Float'];
};

export type CartItemSelectedOptionValuePrice = {
  __typename?: 'CartItemSelectedOptionValuePrice';
  type: PriceTypeEnum;
  units: Scalars['String'];
  value: Scalars['Float'];
};

export type CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object */
  cart_item_uid?: Maybe<Scalars['ID']>;
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  gift_message?: Maybe<GiftMessageInput>;
  quantity?: Maybe<Scalars['Float']>;
};

export type CartPrices = {
  __typename?: 'CartPrices';
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>;
  /** @deprecated Use discounts instead  */
  discount?: Maybe<CartDiscount>;
  /** An array of applied discounts */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  grand_total?: Maybe<Money>;
  subtotal_excluding_tax?: Maybe<Money>;
  subtotal_including_tax?: Maybe<Money>;
  subtotal_with_discount_excluding_tax?: Maybe<Money>;
};

export type CartTaxItem = {
  __typename?: 'CartTaxItem';
  amount: Money;
  label: Scalars['String'];
};

/** An error encountered while adding an item to the the cart. */
export type CartUserInputError = {
  __typename?: 'CartUserInputError';
  /** Cart-specific error code */
  code: CartUserInputErrorType;
  /** A localized error message */
  message: Scalars['String'];
};

export type CartUserInputErrorType =
  | 'PRODUCT_NOT_FOUND'
  | 'NOT_SALABLE'
  | 'INSUFFICIENT_STOCK'
  | 'UNDEFINED';

export type Categories = {
  __typename?: 'Categories';
  /** The payment method assets */
  asset_urls?: Maybe<Array<Maybe<Assets>>>;
  /** The payment method identifier */
  identifier: Scalars['String'];
  /** The payment method name */
  name: Scalars['String'];
};

export type CategoriesFilterInput = {
  /** The category id. Customers use this name to identify the category. */
  category_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the category was created */
  created_at?: Maybe<FilterTypeInput>;
  /** Enabled */
  enabled?: Maybe<FilterTypeInput>;
  /** Import Source */
  import_source?: Maybe<FilterTypeInput>;
  /** The Category Level */
  level?: Maybe<FilterTypeInput>;
  /** The category name. Customers use this name to identify the category. */
  name?: Maybe<FilterTypeInput>;
  /** The parent category id. Customers use this name to identify the category. */
  parent_id?: Maybe<FilterTypeInput>;
  /** The Category Position */
  position?: Maybe<FilterTypeInput>;
  /** The Store Id */
  store_ids?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the category was updated */
  updated_at?: Maybe<FilterTypeInput>;
  /** The Category url_key */
  url_key?: Maybe<FilterTypeInput>;
};

export type CategoriesOutput = {
  __typename?: 'CategoriesOutput';
  /** An array of categories that match the specified search criteria */
  items?: Maybe<Array<Maybe<Category>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of categories returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type CategoriesOutputNoPage = {
  __typename?: 'CategoriesOutputNoPage';
  /** An array of categories that match the specified search criteria */
  items?: Maybe<Array<Maybe<Category>>>;
  /** The number of categories returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type Category = {
  __typename?: 'Category';
  /** An ID that uniquely identifies the category. */
  category_id?: Maybe<Scalars['Int']>;
  /** number of children categories. */
  children_count?: Maybe<Scalars['Int']>;
  /** Timestamp indicating when the category was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The description of the category. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether a category is enabled. */
  enabled?: Maybe<Scalars['Int']>;
  /** The import source of the category. */
  import_source?: Maybe<Scalars['String']>;
  /** The level of the category. */
  level?: Maybe<Scalars['Int']>;
  /** The meta description of the category. */
  meta_description?: Maybe<Scalars['String']>;
  /** The meta keywords of the category. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** The meta robot of the category. */
  meta_robots?: Maybe<Scalars['String']>;
  /** The meta title of the category. */
  meta_title?: Maybe<Scalars['String']>;
  /** The name of the category. */
  name?: Maybe<Scalars['String']>;
  /** An ID that identifies the parent category. */
  parent_id?: Maybe<Scalars['Int']>;
  /** The path of the category. */
  path?: Maybe<Scalars['String']>;
  /** The position of the category. */
  position?: Maybe<Scalars['Int']>;
  /** The post assigned to a category */
  posts?: Maybe<PostsOutputNoPage>;
  /** An ID that identifies the store. */
  store_ids?: Maybe<Scalars['String']>;
  /** Timestamp indicating when the category was updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key of the category. */
  url_key?: Maybe<Scalars['String']>;
};

/** CategoryFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type CategoryFilterInput = {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: Maybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: Maybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: Maybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: Maybe<FilterEqualTypeInput>;
};

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  am_exclude_from_filter?: Maybe<Scalars['Int']>;
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  children_count?: Maybe<Scalars['String']>;
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * Timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  fbuilder_font_class?: Maybe<Scalars['String']>;
  fbuilder_icon?: Maybe<Scalars['String']>;
  fbuilder_thumbnail?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use the `uid` argument instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** Category Path. */
  path?: Maybe<Scalars['String']>;
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  thumbnail?: Maybe<Scalars['String']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  umm_cat_label?: Maybe<Scalars['String']>;
  umm_cat_target?: Maybe<Scalars['String']>;
  umm_dd_block_bottom?: Maybe<Scalars['String']>;
  umm_dd_block_left?: Maybe<Scalars['String']>;
  umm_dd_block_right?: Maybe<Scalars['String']>;
  umm_dd_block_top?: Maybe<Scalars['String']>;
  umm_dd_columns?: Maybe<Scalars['Int']>;
  umm_dd_proportions?: Maybe<Scalars['String']>;
  umm_dd_type?: Maybe<Scalars['String']>;
  umm_dd_width?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceProductsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

/** The category products object returned in the Category query. */
export type CategoryProducts = {
  __typename?: 'CategoryProducts';
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
};

/** A collection of CategoryTree objects and pagination information. */
export type CategoryResult = {
  __typename?: 'CategoryResult';
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Category Tree implementation. */
export type CategoryTree = CategoryInterface & {
  __typename?: 'CategoryTree';
  am_exclude_from_filter?: Maybe<Scalars['Int']>;
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** Child categories tree. */
  children?: Maybe<Array<Maybe<CategoryTree>>>;
  children_count?: Maybe<Scalars['String']>;
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * Timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  fbuilder_font_class?: Maybe<Scalars['String']>;
  fbuilder_icon?: Maybe<Scalars['String']>;
  fbuilder_thumbnail?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use the `uid` argument instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** Category Path. */
  path?: Maybe<Scalars['String']>;
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  thumbnail?: Maybe<Scalars['String']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  umm_cat_label?: Maybe<Scalars['String']>;
  umm_cat_target?: Maybe<Scalars['String']>;
  umm_dd_block_bottom?: Maybe<Scalars['String']>;
  umm_dd_block_left?: Maybe<Scalars['String']>;
  umm_dd_block_right?: Maybe<Scalars['String']>;
  umm_dd_block_top?: Maybe<Scalars['String']>;
  umm_dd_columns?: Maybe<Scalars['Int']>;
  umm_dd_proportions?: Maybe<Scalars['String']>;
  umm_dd_type?: Maybe<Scalars['String']>;
  umm_dd_width?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** Category Tree implementation. */
export type CategoryTreeProductsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

/** Defines all Checkout Agreement information */
export type CheckoutAgreement = {
  __typename?: 'CheckoutAgreement';
  /** Checkout Agreement identifier */
  agreement_id: Scalars['Int'];
  /** Checkout Agreement checkbox text */
  checkbox_text: Scalars['String'];
  /** Checkout Agreement content */
  content: Scalars['String'];
  /** Checkout Agreement content height */
  content_height?: Maybe<Scalars['String']>;
  /** Is Checkout Agreement content in HTML format */
  is_html: Scalars['Boolean'];
  mode: CheckoutAgreementMode;
  /** Checkout Agreement name */
  name: Scalars['String'];
};

export type CheckoutAgreementMode =
  | 'AUTO'
  | 'MANUAL';

export type CheckoutSessionConfigOutput = {
  __typename?: 'CheckoutSessionConfigOutput';
  button_color?: Maybe<Scalars['String']>;
  checkout_payload?: Maybe<Scalars['String']>;
  checkout_signature?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  login_payload?: Maybe<Scalars['String']>;
  login_signature?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  pay_only?: Maybe<Scalars['Boolean']>;
  paynow_payload?: Maybe<Scalars['String']>;
  paynow_signature?: Maybe<Scalars['String']>;
  public_key_id?: Maybe<Scalars['String']>;
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type CheckoutSessionDetailsOutput = {
  __typename?: 'CheckoutSessionDetailsOutput';
  response: Scalars['String'];
};

export type CheckoutSessionSignInOutput = {
  __typename?: 'CheckoutSessionSignInOutput';
  customer_bearer_token?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_firstname?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_last?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An error encountered while adding an item the the cart. */
export type CheckoutUserInputError = {
  __typename?: 'CheckoutUserInputError';
  /** Checkout-specific error code */
  code: CheckoutUserInputErrorCodes;
  /** Localized error message */
  message: Scalars['String'];
  /** Path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']>>;
};

export type CheckoutUserInputErrorCodes =
  | 'REORDER_NOT_AVAILABLE'
  | 'PRODUCT_NOT_FOUND'
  | 'NOT_SALABLE'
  | 'INSUFFICIENT_STOCK'
  | 'UNDEFINED';

/** CMS block defines all CMS block information */
export type CmsBlock = {
  __typename?: 'CmsBlock';
  /** CMS block content */
  content?: Maybe<Scalars['String']>;
  /** CMS block identifier */
  identifier?: Maybe<Scalars['String']>;
  /** CMS block title */
  title?: Maybe<Scalars['String']>;
};

/** CMS blocks information */
export type CmsBlocks = {
  __typename?: 'CmsBlocks';
  /** An array of CMS blocks */
  items?: Maybe<Array<Maybe<CmsBlock>>>;
};

/** CMS page defines all CMS page information */
export type CmsPage = {
  __typename?: 'CmsPage';
  /** CMS page content */
  content?: Maybe<Scalars['String']>;
  /** CMS page content heading */
  content_heading?: Maybe<Scalars['String']>;
  /** Identifier of the CMS page */
  identifier?: Maybe<Scalars['String']>;
  /** CMS page meta description */
  meta_description?: Maybe<Scalars['String']>;
  /** CMS page meta keywords */
  meta_keywords?: Maybe<Scalars['String']>;
  /** CMS page meta title */
  meta_title?: Maybe<Scalars['String']>;
  /** CMS page content heading */
  page_layout?: Maybe<Scalars['String']>;
  /** CMS page title */
  title?: Maybe<Scalars['String']>;
  /** URL key of CMS page */
  url_key?: Maybe<Scalars['String']>;
};

export type ColorSwatchData = SwatchDataInterface & {
  __typename?: 'ColorSwatchData';
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  /** An ID that uniquely identifies the comment. */
  comment_id?: Maybe<Scalars['Int']>;
  /** The content of the comment. */
  content?: Maybe<Scalars['String']>;
  /** Timestamp indicating when the comment was created. */
  created_at?: Maybe<Scalars['String']>;
  /** An ID that identifies the customer. */
  entity_id?: Maybe<Scalars['Int']>;
  /** Indicates whether a comment has replied. */
  has_reply?: Maybe<Scalars['Int']>;
  /** The import source of the comment. */
  import_source?: Maybe<Scalars['String']>;
  /** Indicates whether a comment is replied. */
  is_reply?: Maybe<Scalars['Int']>;
  /** An ID that identifies the post. */
  post_id?: Maybe<Scalars['Int']>;
  /** The status of the comment. */
  status?: Maybe<Scalars['Int']>;
  /** An ID that identifies the store. */
  store_ids?: Maybe<Scalars['String']>;
  /** The email of the user. */
  user_email?: Maybe<Scalars['String']>;
  /** The name of the user. */
  user_name?: Maybe<Scalars['String']>;
};

export type CommentsFilterInput = {
  /** The comment id. Customers use this name to identify the comment. */
  comment_id?: Maybe<FilterTypeInput>;
  /** Customer Id the comment belongs to */
  entity_id?: Maybe<FilterTypeInput>;
  /** Indicates whether the comment has reply */
  has_reply?: Maybe<FilterTypeInput>;
  /** Import Source */
  import_source?: Maybe<FilterTypeInput>;
  /** Indicates whether the comment is reply */
  is_reply?: Maybe<FilterTypeInput>;
  /** Post Id the comment belongs to. */
  post_id?: Maybe<FilterTypeInput>;
  /** Reply Id */
  reply_id?: Maybe<FilterTypeInput>;
  /** Comment Status */
  status?: Maybe<FilterTypeInput>;
  /** Store Id */
  store_ids?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the comment was updated */
  updated_at?: Maybe<FilterTypeInput>;
  /** User Email */
  user_email?: Maybe<FilterTypeInput>;
  /** User Name */
  user_name?: Maybe<FilterTypeInput>;
};

export type CommentsOutput = {
  __typename?: 'CommentsOutput';
  /** An array of comments that match the specified search criteria */
  items?: Maybe<Array<Maybe<Comment>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of comments returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type ComparableAttribute = {
  __typename?: 'ComparableAttribute';
  /** An attribute code that is enabled for product comparisons */
  code: Scalars['String'];
  /** The label of the attribute code */
  label: Scalars['String'];
};

export type ComparableItem = {
  __typename?: 'ComparableItem';
  /** An array of product attributes that can be used to compare products */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Contains details about a product in a compare list */
  product: ProductInterface;
  /** The unique ID of an item in a compare list */
  uid: Scalars['ID'];
};

export type CompareList = {
  __typename?: 'CompareList';
  /** An array of attributes that can be used for comparing products */
  attributes?: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list */
  item_count: Scalars['Int'];
  /** An array of products to compare */
  items?: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list */
  uid: Scalars['ID'];
};

export type CompleteCheckoutSessionOutput = {
  __typename?: 'CompleteCheckoutSessionOutput';
  increment_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ComplexTextValue = {
  __typename?: 'ComplexTextValue';
  /** HTML format */
  html: Scalars['String'];
};

export type ConfigsOutput = {
  __typename?: 'ConfigsOutput';
  /** General config. */
  general?: Maybe<General>;
  /** Seo config. */
  seo?: Maybe<Seo>;
  /** Sidebar config. */
  sidebar?: Maybe<Sidebar>;
};

/** ConfigurableAttributeOption contains the value_index (and other related information) assigned to a configurable product option */
export type ConfigurableAttributeOption = {
  __typename?: 'ConfigurableAttributeOption';
  /** The ID assigned to the attribute */
  code?: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option */
  value_index?: Maybe<Scalars['Int']>;
};

export type ConfigurableCartItem = CartItemInterface & {
  __typename?: 'ConfigurableCartItem';
  configurable_options: Array<Maybe<SelectedConfigurableOption>>;
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

/** Configurable option available for further selection based on current selection. */
export type ConfigurableOptionAvailableForSelection = {
  __typename?: 'ConfigurableOptionAvailableForSelection';
  /** Attribute code that uniquely identifies configurable option. */
  attribute_code: Scalars['String'];
  /** Configurable option values available for further selection. */
  option_value_uids: Array<Maybe<Scalars['ID']>>;
};

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProduct = ProductInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'ConfigurableProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** An array of linked simple product items */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /** Metadata for the specified configurable options selection */
  configurable_product_options_selection?: Maybe<ConfigurableProductOptionsSelection>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** An array of variants of products */
  variants?: Maybe<Array<Maybe<ConfigurableVariant>>>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductConfigurable_Product_Options_SelectionArgs = {
  configurableOptionValueUids?: Maybe<Array<Scalars['ID']>>;
};


/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type ConfigurableProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  data: CartItemInput;
  /** Configurable product SKU. */
  parent_sku?: Maybe<Scalars['String']>;
  /** Deprecated. Use CartItemInput.sku instead. */
  variant_sku?: Maybe<Scalars['String']>;
};

/** ConfigurableProductOptions defines configurable attributes for the specified product */
export type ConfigurableProductOptions = {
  __typename?: 'ConfigurableProductOptions';
  /** A string that identifies the attribute */
  attribute_code?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id_v2?: Maybe<Scalars['Int']>;
  /** The unique ID for a `Attribute` object */
  attribute_uid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system
   * @deprecated Use uid instead
   */
  id?: Maybe<Scalars['Int']>;
  /** A string that describes the configurable product option, which is displayed on the UI */
  label?: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed */
  position?: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's id field
   * @deprecated `product_id` is not needed and can be obtained from it's parent
   */
  product_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default */
  use_default?: Maybe<Scalars['Boolean']>;
  /** An array that defines the value_index codes assigned to the configurable product */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
};

/** Metadata corresponding to the configurable options selection. */
export type ConfigurableProductOptionsSelection = {
  __typename?: 'ConfigurableProductOptionsSelection';
  /** Product images and videos corresponding to the specified configurable options selection. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** Configurable options available for further selection based on current selection. */
  options_available_for_selection?: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /** Variant represented by the specified configurable options selection. It is expected to be null, until selections are made for each configurable option. */
  variant?: Maybe<SimpleProduct>;
};

/** ConfigurableProductOptionsValues contains the index number assigned to a configurable product option */
export type ConfigurableProductOptionsValues = {
  __typename?: 'ConfigurableProductOptionsValues';
  /** The label of the product on the default store */
  default_label?: Maybe<Scalars['String']>;
  /** The label of the product */
  label?: Maybe<Scalars['String']>;
  /** The label of the product on the current store */
  store_label?: Maybe<Scalars['String']>;
  /** Swatch data for configurable product option */
  swatch_data?: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  uid?: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label */
  use_default_value?: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option
   * @deprecated Use `uid` instead
   */
  value_index?: Maybe<Scalars['Int']>;
};

/** An array containing all the simple product variants of a configurable product */
export type ConfigurableVariant = {
  __typename?: 'ConfigurableVariant';
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  product?: Maybe<SimpleProduct>;
};

/** A configurable product wish list item */
export type ConfigurableWishlistItem = WishlistItemInterface & {
  __typename?: 'ConfigurableWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** The SKU of the simple product corresponding to a set of selected configurable options */
  child_sku: Scalars['String'];
  /** An array of selected configurable options */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

export type Country = {
  __typename?: 'Country';
  available_regions?: Maybe<Array<Maybe<Region>>>;
  full_name_english?: Maybe<Scalars['String']>;
  full_name_locale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']>;
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  two_letter_abbreviation?: Maybe<Scalars['String']>;
};

/** The list of countries codes */
export type CountryCodeEnum =
  /** Afghanistan */
  | 'AF'
  /** Åland Islands */
  | 'AX'
  /** Albania */
  | 'AL'
  /** Algeria */
  | 'DZ'
  /** American Samoa */
  | 'AS'
  /** Andorra */
  | 'AD'
  /** Angola */
  | 'AO'
  /** Anguilla */
  | 'AI'
  /** Antarctica */
  | 'AQ'
  /** Antigua & Barbuda */
  | 'AG'
  /** Argentina */
  | 'AR'
  /** Armenia */
  | 'AM'
  /** Aruba */
  | 'AW'
  /** Australia */
  | 'AU'
  /** Austria */
  | 'AT'
  /** Azerbaijan */
  | 'AZ'
  /** Bahamas */
  | 'BS'
  /** Bahrain */
  | 'BH'
  /** Bangladesh */
  | 'BD'
  /** Barbados */
  | 'BB'
  /** Belarus */
  | 'BY'
  /** Belgium */
  | 'BE'
  /** Belize */
  | 'BZ'
  /** Benin */
  | 'BJ'
  /** Bermuda */
  | 'BM'
  /** Bhutan */
  | 'BT'
  /** Bolivia */
  | 'BO'
  /** Bosnia & Herzegovina */
  | 'BA'
  /** Botswana */
  | 'BW'
  /** Bouvet Island */
  | 'BV'
  /** Brazil */
  | 'BR'
  /** British Indian Ocean Territory */
  | 'IO'
  /** British Virgin Islands */
  | 'VG'
  /** Brunei */
  | 'BN'
  /** Bulgaria */
  | 'BG'
  /** Burkina Faso */
  | 'BF'
  /** Burundi */
  | 'BI'
  /** Cambodia */
  | 'KH'
  /** Cameroon */
  | 'CM'
  /** Canada */
  | 'CA'
  /** Cape Verde */
  | 'CV'
  /** Cayman Islands */
  | 'KY'
  /** Central African Republic */
  | 'CF'
  /** Chad */
  | 'TD'
  /** Chile */
  | 'CL'
  /** China */
  | 'CN'
  /** Christmas Island */
  | 'CX'
  /** Cocos (Keeling) Islands */
  | 'CC'
  /** Colombia */
  | 'CO'
  /** Comoros */
  | 'KM'
  /** Congo-Brazzaville */
  | 'CG'
  /** Congo-Kinshasa */
  | 'CD'
  /** Cook Islands */
  | 'CK'
  /** Costa Rica */
  | 'CR'
  /** Côte d’Ivoire */
  | 'CI'
  /** Croatia */
  | 'HR'
  /** Cuba */
  | 'CU'
  /** Cyprus */
  | 'CY'
  /** Czech Republic */
  | 'CZ'
  /** Denmark */
  | 'DK'
  /** Djibouti */
  | 'DJ'
  /** Dominica */
  | 'DM'
  /** Dominican Republic */
  | 'DO'
  /** Ecuador */
  | 'EC'
  /** Egypt */
  | 'EG'
  /** El Salvador */
  | 'SV'
  /** Equatorial Guinea */
  | 'GQ'
  /** Eritrea */
  | 'ER'
  /** Estonia */
  | 'EE'
  /** Ethiopia */
  | 'ET'
  /** Falkland Islands */
  | 'FK'
  /** Faroe Islands */
  | 'FO'
  /** Fiji */
  | 'FJ'
  /** Finland */
  | 'FI'
  /** France */
  | 'FR'
  /** French Guiana */
  | 'GF'
  /** French Polynesia */
  | 'PF'
  /** French Southern Territories */
  | 'TF'
  /** Gabon */
  | 'GA'
  /** Gambia */
  | 'GM'
  /** Georgia */
  | 'GE'
  /** Germany */
  | 'DE'
  /** Ghana */
  | 'GH'
  /** Gibraltar */
  | 'GI'
  /** Greece */
  | 'GR'
  /** Greenland */
  | 'GL'
  /** Grenada */
  | 'GD'
  /** Guadeloupe */
  | 'GP'
  /** Guam */
  | 'GU'
  /** Guatemala */
  | 'GT'
  /** Guernsey */
  | 'GG'
  /** Guinea */
  | 'GN'
  /** Guinea-Bissau */
  | 'GW'
  /** Guyana */
  | 'GY'
  /** Haiti */
  | 'HT'
  /** Heard &amp; McDonald Islands */
  | 'HM'
  /** Honduras */
  | 'HN'
  /** Hong Kong SAR China */
  | 'HK'
  /** Hungary */
  | 'HU'
  /** Iceland */
  | 'IS'
  /** India */
  | 'IN'
  /** Indonesia */
  | 'ID'
  /** Iran */
  | 'IR'
  /** Iraq */
  | 'IQ'
  /** Ireland */
  | 'IE'
  /** Isle of Man */
  | 'IM'
  /** Israel */
  | 'IL'
  /** Italy */
  | 'IT'
  /** Jamaica */
  | 'JM'
  /** Japan */
  | 'JP'
  /** Jersey */
  | 'JE'
  /** Jordan */
  | 'JO'
  /** Kazakhstan */
  | 'KZ'
  /** Kenya */
  | 'KE'
  /** Kiribati */
  | 'KI'
  /** Kuwait */
  | 'KW'
  /** Kyrgyzstan */
  | 'KG'
  /** Laos */
  | 'LA'
  /** Latvia */
  | 'LV'
  /** Lebanon */
  | 'LB'
  /** Lesotho */
  | 'LS'
  /** Liberia */
  | 'LR'
  /** Libya */
  | 'LY'
  /** Liechtenstein */
  | 'LI'
  /** Lithuania */
  | 'LT'
  /** Luxembourg */
  | 'LU'
  /** Macau SAR China */
  | 'MO'
  /** Macedonia */
  | 'MK'
  /** Madagascar */
  | 'MG'
  /** Malawi */
  | 'MW'
  /** Malaysia */
  | 'MY'
  /** Maldives */
  | 'MV'
  /** Mali */
  | 'ML'
  /** Malta */
  | 'MT'
  /** Marshall Islands */
  | 'MH'
  /** Martinique */
  | 'MQ'
  /** Mauritania */
  | 'MR'
  /** Mauritius */
  | 'MU'
  /** Mayotte */
  | 'YT'
  /** Mexico */
  | 'MX'
  /** Micronesia */
  | 'FM'
  /** Moldova */
  | 'MD'
  /** Monaco */
  | 'MC'
  /** Mongolia */
  | 'MN'
  /** Montenegro */
  | 'ME'
  /** Montserrat */
  | 'MS'
  /** Morocco */
  | 'MA'
  /** Mozambique */
  | 'MZ'
  /** Myanmar (Burma) */
  | 'MM'
  /** Namibia */
  | 'NA'
  /** Nauru */
  | 'NR'
  /** Nepal */
  | 'NP'
  /** Netherlands */
  | 'NL'
  /** Netherlands Antilles */
  | 'AN'
  /** New Caledonia */
  | 'NC'
  /** New Zealand */
  | 'NZ'
  /** Nicaragua */
  | 'NI'
  /** Niger */
  | 'NE'
  /** Nigeria */
  | 'NG'
  /** Niue */
  | 'NU'
  /** Norfolk Island */
  | 'NF'
  /** Northern Mariana Islands */
  | 'MP'
  /** North Korea */
  | 'KP'
  /** Norway */
  | 'NO'
  /** Oman */
  | 'OM'
  /** Pakistan */
  | 'PK'
  /** Palau */
  | 'PW'
  /** Palestinian Territories */
  | 'PS'
  /** Panama */
  | 'PA'
  /** Papua New Guinea */
  | 'PG'
  /** Paraguay */
  | 'PY'
  /** Peru */
  | 'PE'
  /** Philippines */
  | 'PH'
  /** Pitcairn Islands */
  | 'PN'
  /** Poland */
  | 'PL'
  /** Portugal */
  | 'PT'
  /** Qatar */
  | 'QA'
  /** Réunion */
  | 'RE'
  /** Romania */
  | 'RO'
  /** Russia */
  | 'RU'
  /** Rwanda */
  | 'RW'
  /** Samoa */
  | 'WS'
  /** San Marino */
  | 'SM'
  /** São Tomé & Príncipe */
  | 'ST'
  /** Saudi Arabia */
  | 'SA'
  /** Senegal */
  | 'SN'
  /** Serbia */
  | 'RS'
  /** Seychelles */
  | 'SC'
  /** Sierra Leone */
  | 'SL'
  /** Singapore */
  | 'SG'
  /** Slovakia */
  | 'SK'
  /** Slovenia */
  | 'SI'
  /** Solomon Islands */
  | 'SB'
  /** Somalia */
  | 'SO'
  /** South Africa */
  | 'ZA'
  /** South Georgia & South Sandwich Islands */
  | 'GS'
  /** South Korea */
  | 'KR'
  /** Spain */
  | 'ES'
  /** Sri Lanka */
  | 'LK'
  /** St. Barthélemy */
  | 'BL'
  /** St. Helena */
  | 'SH'
  /** St. Kitts & Nevis */
  | 'KN'
  /** St. Lucia */
  | 'LC'
  /** St. Martin */
  | 'MF'
  /** St. Pierre & Miquelon */
  | 'PM'
  /** St. Vincent & Grenadines */
  | 'VC'
  /** Sudan */
  | 'SD'
  /** Suriname */
  | 'SR'
  /** Svalbard & Jan Mayen */
  | 'SJ'
  /** Swaziland */
  | 'SZ'
  /** Sweden */
  | 'SE'
  /** Switzerland */
  | 'CH'
  /** Syria */
  | 'SY'
  /** Taiwan */
  | 'TW'
  /** Tajikistan */
  | 'TJ'
  /** Tanzania */
  | 'TZ'
  /** Thailand */
  | 'TH'
  /** Timor-Leste */
  | 'TL'
  /** Togo */
  | 'TG'
  /** Tokelau */
  | 'TK'
  /** Tonga */
  | 'TO'
  /** Trinidad & Tobago */
  | 'TT'
  /** Tunisia */
  | 'TN'
  /** Turkey */
  | 'TR'
  /** Turkmenistan */
  | 'TM'
  /** Turks & Caicos Islands */
  | 'TC'
  /** Tuvalu */
  | 'TV'
  /** Uganda */
  | 'UG'
  /** Ukraine */
  | 'UA'
  /** United Arab Emirates */
  | 'AE'
  /** United Kingdom */
  | 'GB'
  /** United States */
  | 'US'
  /** Uruguay */
  | 'UY'
  /** U.S. Outlying Islands */
  | 'UM'
  /** U.S. Virgin Islands */
  | 'VI'
  /** Uzbekistan */
  | 'UZ'
  /** Vanuatu */
  | 'VU'
  /** Vatican City */
  | 'VA'
  /** Venezuela */
  | 'VE'
  /** Vietnam */
  | 'VN'
  /** Wallis & Futuna */
  | 'WF'
  /** Western Sahara */
  | 'EH'
  /** Yemen */
  | 'YE'
  /** Zambia */
  | 'ZM'
  /** Zimbabwe */
  | 'ZW';

export type CreateCompareListInput = {
  /** An array of product IDs to add to the compare list */
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename?: 'CreatePayflowProTokenOutput';
  response_message: Scalars['String'];
  result: Scalars['Int'];
  result_code: Scalars['Int'];
  secure_token: Scalars['String'];
  secure_token_id: Scalars['String'];
};

export type CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String'];
  /** Ratings details by category. e.g price: 5, quality: 4 etc */
  ratings: Array<Maybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product */
  sku: Scalars['String'];
  /** The summary (title) of the review */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

export type CreateProductReviewOutput = {
  __typename?: 'CreateProductReviewOutput';
  /** Contains the completed product review */
  review: ProductReview;
};

/** Required fields for Payflow Pro and Payments Pro credit card payments */
export type CreditCardDetailsInput = {
  /** Credit card expiration month */
  cc_exp_month: Scalars['Int'];
  /** Credit card expiration year */
  cc_exp_year: Scalars['Int'];
  /** Last 4 digits of the credit card */
  cc_last_4: Scalars['Int'];
  /** Credit card type */
  cc_type: Scalars['String'];
};

/** Credit memo details */
export type CreditMemo = {
  __typename?: 'CreditMemo';
  /** Comments on the credit memo */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object */
  id: Scalars['ID'];
  /** An array containing details about refunded items */
  items?: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number */
  number: Scalars['String'];
  /** Contains details about the total refunded amount */
  total?: Maybe<CreditMemoTotal>;
};

export type CreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'CreditMemoItem';
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Credit memo item details */
export type CreditMemoItemInterface = {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Credit memo price details */
export type CreditMemoTotal = {
  __typename?: 'CreditMemoTotal';
  /** An adjustment manually applied to the order */
  adjustment: Money;
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the credit memo */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the credit memo */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The credit memo tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo */
  total_shipping: Money;
  /** The amount of tax applied to the credit memo */
  total_tax: Money;
};

export type Currency = {
  __typename?: 'Currency';
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  base_currency_code?: Maybe<Scalars['String']>;
  base_currency_symbol?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_symbol`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']>;
  default_display_currency_code?: Maybe<Scalars['String']>;
  default_display_currency_symbol?: Maybe<Scalars['String']>;
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>;
};

/** The list of available currency codes */
export type CurrencyEnum =
  | 'AFN'
  | 'ALL'
  | 'AZN'
  | 'DZD'
  | 'AOA'
  | 'ARS'
  | 'AMD'
  | 'AWG'
  | 'AUD'
  | 'BSD'
  | 'BHD'
  | 'BDT'
  | 'BBD'
  | 'BYN'
  | 'BZD'
  | 'BMD'
  | 'BTN'
  | 'BOB'
  | 'BAM'
  | 'BWP'
  | 'BRL'
  | 'GBP'
  | 'BND'
  | 'BGN'
  | 'BUK'
  | 'BIF'
  | 'KHR'
  | 'CAD'
  | 'CVE'
  | 'CZK'
  | 'KYD'
  | 'GQE'
  | 'CLP'
  | 'CNY'
  | 'COP'
  | 'KMF'
  | 'CDF'
  | 'CRC'
  | 'HRK'
  | 'CUP'
  | 'DKK'
  | 'DJF'
  | 'DOP'
  | 'XCD'
  | 'EGP'
  | 'SVC'
  | 'ERN'
  | 'EEK'
  | 'ETB'
  | 'EUR'
  | 'FKP'
  | 'FJD'
  | 'GMD'
  | 'GEK'
  | 'GEL'
  | 'GHS'
  | 'GIP'
  | 'GTQ'
  | 'GNF'
  | 'GYD'
  | 'HTG'
  | 'HNL'
  | 'HKD'
  | 'HUF'
  | 'ISK'
  | 'INR'
  | 'IDR'
  | 'IRR'
  | 'IQD'
  | 'ILS'
  | 'JMD'
  | 'JPY'
  | 'JOD'
  | 'KZT'
  | 'KES'
  | 'KWD'
  | 'KGS'
  | 'LAK'
  | 'LVL'
  | 'LBP'
  | 'LSL'
  | 'LRD'
  | 'LYD'
  | 'LTL'
  | 'MOP'
  | 'MKD'
  | 'MGA'
  | 'MWK'
  | 'MYR'
  | 'MVR'
  | 'LSM'
  | 'MRO'
  | 'MUR'
  | 'MXN'
  | 'MDL'
  | 'MNT'
  | 'MAD'
  | 'MZN'
  | 'MMK'
  | 'NAD'
  | 'NPR'
  | 'ANG'
  | 'YTL'
  | 'NZD'
  | 'NIC'
  | 'NGN'
  | 'KPW'
  | 'NOK'
  | 'OMR'
  | 'PKR'
  | 'PAB'
  | 'PGK'
  | 'PYG'
  | 'PEN'
  | 'PHP'
  | 'PLN'
  | 'QAR'
  | 'RHD'
  | 'RON'
  | 'RUB'
  | 'RWF'
  | 'SHP'
  | 'STD'
  | 'SAR'
  | 'RSD'
  | 'SCR'
  | 'SLL'
  | 'SGD'
  | 'SKK'
  | 'SBD'
  | 'SOS'
  | 'ZAR'
  | 'KRW'
  | 'LKR'
  | 'SDG'
  | 'SRD'
  | 'SZL'
  | 'SEK'
  | 'CHF'
  | 'SYP'
  | 'TWD'
  | 'TJS'
  | 'TZS'
  | 'THB'
  | 'TOP'
  | 'TTD'
  | 'TND'
  | 'TMM'
  | 'USD'
  | 'UGX'
  | 'UAH'
  | 'AED'
  | 'UYU'
  | 'UZS'
  | 'VUV'
  | 'VEB'
  | 'VEF'
  | 'VND'
  | 'CHE'
  | 'CHW'
  | 'XOF'
  | 'WST'
  | 'YER'
  | 'ZMK'
  | 'ZWD'
  | 'TRY'
  | 'AZM'
  | 'ROL'
  | 'TRL'
  | 'XPF';

/** CustomAttributeMetadata defines an array of attribute_codes and entity_types */
export type CustomAttributeMetadata = {
  __typename?: 'CustomAttributeMetadata';
  /** An array of attributes */
  items?: Maybe<Array<Maybe<Attribute>>>;
};

/** CustomOptionValue defines all info of custom option. */
export type CustomOptionDetail = {
  __typename?: 'CustomOptionDetail';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
};

/** CustomOption defines primary info of custom option. */
export type CustomOptionValue = {
  __typename?: 'CustomOptionValue';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array of options for a customizable product. */
  value?: Maybe<Array<Maybe<CustomOptionDetail>>>;
};

/** Customer defines the customer name and address and other details */
export type Customer = {
  __typename?: 'Customer';
  /** An array containing the customer's shipping and billing addresses */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance: Scalars['Boolean'];
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>;
  /** Timestamp indicating when the account was created */
  created_at?: Maybe<Scalars['String']>;
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address */
  default_billing?: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address */
  default_shipping?: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth
   * @deprecated Use `date_of_birth` instead
   */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios */
  group_id?: Maybe<Scalars['Int']>;
  /**
   * The ID assigned to the customer
   * @deprecated id is not needed as part of Customer because on server side it can be identified based on customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  orders?: Maybe<CustomerOrders>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** Contains the customer's product reviews */
  reviews: ProductReviews;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
  /**
   * Contains a customer's wish lists
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2`
   */
  wishlist: Wishlist;
  /** Retrieve the specified wish list identified by the unique ID for a `Wishlist` object */
  wishlist_v2?: Maybe<Wishlist>;
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Magento Commerce */
  wishlists: Array<Maybe<Wishlist>>;
};


/** Customer defines the customer name and address and other details */
export type CustomerOrdersArgs = {
  filter?: Maybe<CustomerOrdersFilterInput>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlist_V2Args = {
  id: Scalars['ID'];
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlistsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** The city or town */
  city?: Maybe<Scalars['String']>;
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>;
  /**
   * The customer's country
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into container */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated customer_id is not needed as part of CustomerAddress, address ID (id) is unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** Address extension attributes */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>;
  /** The ID assigned to the address object */
  id?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
};

export type CustomerAddressAttribute = {
  __typename?: 'CustomerAddressAttribute';
  /** Attribute code */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute value */
  value?: Maybe<Scalars['String']>;
};

export type CustomerAddressAttributeInput = {
  /** Attribute code */
  attribute_code: Scalars['String'];
  /** Attribute value */
  value: Scalars['String'];
};

export type CustomerAddressInput = {
  /** The city or town */
  city?: Maybe<Scalars['String']>;
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  country_id?: Maybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>;
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>;
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
};

/** CustomerAddressRegion defines the customer's state or province */
export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion';
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The address region code */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']>;
};

/** CustomerAddressRegionInput defines the customer's state or province */
export type CustomerAddressRegionInput = {
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The address region code */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']>;
};

export type CustomerCreateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required for customer creation */
  email: Scalars['String'];
  /** The customer's first name */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname: Scalars['String'];
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
};

export type CustomerDeleteStatusOutput = {
  __typename?: 'CustomerDeleteStatusOutput';
  result: Scalars['String'];
};

export type CustomerDownloadableProduct = {
  __typename?: 'CustomerDownloadableProduct';
  date?: Maybe<Scalars['String']>;
  download_url?: Maybe<Scalars['String']>;
  order_increment_id?: Maybe<Scalars['String']>;
  remaining_downloads?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CustomerDownloadableProducts = {
  __typename?: 'CustomerDownloadableProducts';
  /** List of purchased downloadable items */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
};

export type CustomerInput = {
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required for customer creation */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
};

/** Contains details about each of the customer's orders */
export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  /** The billing address for the order */
  billing_address?: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** @deprecated Use the order_date attribute instead */
  created_at?: Maybe<Scalars['String']>;
  /** A list of credit memos */
  credit_memos?: Maybe<Array<Maybe<CreditMemo>>>;
  /** The entered gift message for the order */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use the totals.grand_total attribute instead */
  grand_total?: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object */
  id: Scalars['ID'];
  /** @deprecated Use the id attribute instead */
  increment_id?: Maybe<Scalars['String']>;
  /** A list of invoices for the order */
  invoices: Array<Maybe<Invoice>>;
  /** An array containing the items purchased in this order */
  items?: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** The order number */
  number: Scalars['String'];
  /** The date the order was placed */
  order_date: Scalars['String'];
  /** @deprecated Use the number attribute instead */
  order_number: Scalars['String'];
  /** Payment details for the order */
  payment_methods?: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** A list of shipments for the order */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order */
  shipping_address?: Maybe<OrderAddress>;
  /** The delivery method for the order */
  shipping_method?: Maybe<Scalars['String']>;
  /** The current status of the order */
  status: Scalars['String'];
  /** Contains details about the calculated totals for this order */
  total?: Maybe<OrderTotal>;
};

/** The collection of orders that match the conditions defined in the filter */
export type CustomerOrders = {
  __typename?: 'CustomerOrders';
  /** An array of customer orders */
  items: Array<Maybe<CustomerOrder>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders */
  total_count?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering orders. */
export type CustomerOrdersFilterInput = {
  /** Filters by order number. */
  number?: Maybe<FilterStringTypeInput>;
};

export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  customer: Customer;
};

export type CustomerPaymentTokens = {
  __typename?: 'CustomerPaymentTokens';
  /** An array of payment tokens */
  items: Array<Maybe<PaymentToken>>;
};

export type CustomerToken = {
  __typename?: 'CustomerToken';
  /** The customer token */
  token?: Maybe<Scalars['String']>;
};

export type CustomerUpdateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
};

/** CustomizableAreaOption contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableAreaOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>;
};

/** CustomizableAreaValue defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename?: 'CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableCheckbbixOption contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableCheckboxOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
};

/** CustomizableCheckboxValue defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename?: 'CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableDateOption contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDateOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>;
};

/** CustomizableDateValue defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename?: 'CustomizableDateValue';
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableDropDownOption contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDropDownOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
};

/** CustomizableDropDownValue defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename?: 'CustomizableDropDownValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableFieldOption contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFieldOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>;
};

/** CustomizableFieldValue defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename?: 'CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableFileOption contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFileOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>;
};

/** CustomizableFileValue defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename?: 'CustomizableFileValue';
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
};

/** CustomizableMultipleOption contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableMultipleOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
};

/** CustomizableMultipleValue defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename?: 'CustomizableMultipleValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
};

export type CustomizableOptionInput = {
  /** The customizable option id of the product */
  id?: Maybe<Scalars['Int']>;
  /** The string value of the option */
  value_string: Scalars['String'];
};

/** The CustomizableOptionInterface contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
};

/** CustomizableProductInterface contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
};

/** CustomizableRadioOption contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableRadioOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>;
};

/** CustomizableRadioValue defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename?: 'CustomizableRadioValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
};

export type DeleteCompareListOutput = {
  __typename?: 'DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted */
  result: Scalars['Boolean'];
};

export type DeleteCustomerOutput = {
  __typename?: 'DeleteCustomerOutput';
  /** Response Message */
  response?: Maybe<Scalars['String']>;
};

export type DeletePaymentTokenOutput = {
  __typename?: 'DeletePaymentTokenOutput';
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  result: Scalars['Boolean'];
};

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename?: 'Discount';
  /** The amount of the discount */
  amount: Money;
  /** A description of the discount */
  label: Scalars['String'];
};

/** Downloadable Cart Item */
export type DownloadableCartItem = CartItemInterface & {
  __typename?: 'DownloadableCartItem';
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  /** An array containing information about the links for the added to cart downloadable product */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** DownloadableProductSamples defines characteristics of a downloadable product */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

export type DownloadableCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'DownloadableCreditMemoItem';
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

export type DownloadableFileTypeEnum =
  | 'FILE'
  | 'URL';

export type DownloadableInvoiceItem = InvoiceItemInterface & {
  __typename?: 'DownloadableInvoiceItem';
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** DownloadableProductLinks defines characteristics of a downloadable product */
export type DownloadableItemsLinks = {
  __typename?: 'DownloadableItemsLinks';
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
};

export type DownloadableOrderItem = OrderItemInterface & {
  __typename?: 'DownloadableOrderItem';
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** DownloadableProduct defines a product that the customer downloads */
export type DownloadableProduct = ProductInterface & CustomizableProductInterface & {
  __typename?: 'DownloadableProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  /** An array containing information about the links for this downloadable product */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadable_product_samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  /** A value of 1 indicates that each link in the array must be purchased separately */
  links_purchased_separately?: Maybe<Scalars['Int']>;
  /** The heading above the list of downloadable products */
  links_title?: Maybe<Scalars['String']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/** DownloadableProduct defines a product that the customer downloads */
export type DownloadableProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type DownloadableProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  data: CartItemInput;
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinksInput>>>;
};

/** DownloadableProductLinks defines characteristics of a downloadable product */
export type DownloadableProductLinks = {
  __typename?: 'DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend */
  is_shareable?: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend */
  number_of_downloads?: Maybe<Scalars['Int']>;
  /** The price of the downloadable product */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
};

export type DownloadableProductLinksInput = {
  link_id: Scalars['Int'];
};

/** DownloadableProductSamples defines characteristics of a downloadable product */
export type DownloadableProductSamples = {
  __typename?: 'DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the sample */
  title?: Maybe<Scalars['String']>;
};

/** A downloadable product wish list item */
export type DownloadableWishlistItem = WishlistItemInterface & {
  __typename?: 'DownloadableWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** An array containing information about the selected links */
  links_v2?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
  /** An array containing information about the selected samples */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
};

/** Defines a customer-entered option */
export type EnteredOptionInput = {
  /** The unique ID for a `CustomizableFieldOption`, `CustomizableFileOption`, `CustomizableAreaOption`, etc. of `CustomizableOptionInterface` objects */
  uid: Scalars['ID'];
  /** Text the customer entered */
  value: Scalars['String'];
};

/** EntityUrl is an output object containing the `id`, `relative_url`, and `type` attributes */
export type EntityUrl = {
  __typename?: 'EntityUrl';
  /** @deprecated The canonical_url field is deprecated, use relative_url instead. */
  canonical_url?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, etc. object associated with the specified url. This could be a product UID, category UID, or cms page UID. */
  entity_uid?: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** 301 or 302 HTTP code for url permanent or temporary redirect or 0 for the 200 no redirect */
  redirectCode?: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified  url is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  currency_to?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /** A string to filter on */
  eq?: Maybe<Scalars['String']>;
  /** An array of values to filter on */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /** One or more words to filter on */
  match?: Maybe<Scalars['String']>;
};

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** The beginning of the range */
  from?: Maybe<Scalars['String']>;
  /** The end of the range */
  to?: Maybe<Scalars['String']>;
};

/** Defines a filter for an input string. */
export type FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: Maybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: Maybe<Scalars['String']>;
};

/** FilterTypeInput specifies which action will be performed in a query */
export type FilterTypeInput = {
  /** Equals */
  eq?: Maybe<Scalars['String']>;
  finset?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** From. Must be used with 'to' */
  from?: Maybe<Scalars['String']>;
  /** Greater than */
  gt?: Maybe<Scalars['String']>;
  /** Greater than or equal to */
  gteq?: Maybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters */
  like?: Maybe<Scalars['String']>;
  /** Less than */
  lt?: Maybe<Scalars['String']>;
  /** Less than or equal to */
  lteq?: Maybe<Scalars['String']>;
  /** More than or equal to */
  moreq?: Maybe<Scalars['String']>;
  /** Not equal to */
  neq?: Maybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not null */
  notnull?: Maybe<Scalars['String']>;
  /** Is null */
  null?: Maybe<Scalars['String']>;
  /** To. Must be used with 'from' */
  to?: Maybe<Scalars['String']>;
};

/** A single FPT that can be applied to a product price. */
export type FixedProductTax = {
  __typename?: 'FixedProductTax';
  /** Amount of the FPT as a money object. */
  amount?: Maybe<Money>;
  /** The label assigned to the FPT to be displayed on the frontend. */
  label?: Maybe<Scalars['String']>;
};

/** This enumeration display settings for the fixed product tax */
export type FixedProductTaxDisplaySettings =
  /** The displayed price includes the FPT amount without displaying the ProductPrice.fixed_product_taxes values. This value corresponds to 'Including FPT only' */
  | 'INCLUDE_FPT_WITHOUT_DETAILS'
  /** The displayed price includes the FPT amount while displaying the values of ProductPrice.fixed_product_taxes separately. This value corresponds to 'Including FPT and FPT description' */
  | 'INCLUDE_FPT_WITH_DETAILS'
  /** The displayed price does not include the FPT amount. The values of ProductPrice.fixed_product_taxes and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price' */
  | 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS'
  /** The displayed price does not include the FPT amount. The values from ProductPrice.fixed_product_taxes are not displayed. This value corresponds to 'Excluding FPT' */
  | 'EXCLUDE_FPT_WITHOUT_DETAILS'
  /** The FPT feature is not enabled. You can omit  ProductPrice.fixed_product_taxes from your query */
  | 'FPT_DISABLED';

export type GdprConfigs = {
  __typename?: 'GdprConfigs';
  /** General configs */
  general?: Maybe<GeneralConfig>;
};

export type General = {
  __typename?: 'General';
  /** Display author. */
  display_author?: Maybe<Scalars['Int']>;
  /** Display Blogs Mode. */
  display_style?: Maybe<Scalars['Int']>;
  /** Choose blog's color. */
  font_color?: Maybe<Scalars['String']>;
  /** Blog Name. */
  name?: Maybe<Scalars['String']>;
  /** Show Blog Link in Top Menu. */
  toplinks?: Maybe<Scalars['Int']>;
};

export type GenerateCustomerTokenAsAdminInput = {
  /** The email address of the customer requesting remote shopping assistance */
  customer_email: Scalars['String'];
};

export type GenerateCustomerTokenAsAdminOutput = {
  __typename?: 'GenerateCustomerTokenAsAdminOutput';
  /** The generated customer token */
  customer_token: Scalars['String'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessage = {
  __typename?: 'GiftMessage';
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessageInput = {
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** GroupedProduct defines a grouped product */
export type GroupedProduct = ProductInterface & PhysicalProductInterface & {
  __typename?: 'GroupedProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  /** An array containing grouped product items */
  items?: Maybe<Array<Maybe<GroupedProductItem>>>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** GroupedProduct defines a grouped product */
export type GroupedProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** GroupedProductItem contains information about an individual grouped product item */
export type GroupedProductItem = {
  __typename?: 'GroupedProductItem';
  /** The relative position of this item compared to the other group items */
  position?: Maybe<Scalars['Int']>;
  /** The ProductInterface object, which contains details about this product option */
  product?: Maybe<ProductInterface>;
  /** The quantity of this grouped product item */
  qty?: Maybe<Scalars['Float']>;
};

/** A grouped product wish list item */
export type GroupedProductWishlistItem = WishlistItemInterface & {
  __typename?: 'GroupedProductWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type HostedProInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Contains secure URL used for Payments Pro Hosted Solution payment method. */
export type HostedProUrl = {
  __typename?: 'HostedProUrl';
  /** Secure Url generated by PayPal */
  secure_form_url?: Maybe<Scalars['String']>;
};

/** The required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
};

/** The object details of target path parameters */
export type HttpQueryParameter = {
  __typename?: 'HttpQueryParameter';
  /** Parameter name */
  name?: Maybe<Scalars['String']>;
  /** Parameter value */
  value?: Maybe<Scalars['String']>;
};

export type ImageSwatchData = SwatchDataInterface & {
  __typename?: 'ImageSwatchData';
  /** Thumbnail swatch image URL */
  thumbnail?: Maybe<Scalars['String']>;
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
};

/** Invoice details */
export type Invoice = {
  __typename?: 'Invoice';
  /** Comments on the invoice */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object */
  id: Scalars['ID'];
  /** Invoiced product details */
  items?: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number */
  number: Scalars['String'];
  /** Invoice total amount details */
  total?: Maybe<InvoiceTotal>;
};

export type InvoiceItem = InvoiceItemInterface & {
  __typename?: 'InvoiceItem';
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Invoice item details */
export type InvoiceItemInterface = {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Contains price details from an invoice */
export type InvoiceTotal = {
  __typename?: 'InvoiceTotal';
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the invoice */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the invoice */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The invoice tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice */
  total_shipping: Money;
  /** The amount of tax applied to the invoice */
  total_tax: Money;
};

export type IsEmailAvailableOutput = {
  __typename?: 'IsEmailAvailableOutput';
  /** Is email availabel value */
  is_email_available?: Maybe<Scalars['Boolean']>;
};

/** A list of options of the selected bundle product */
export type ItemSelectedBundleOption = {
  __typename?: 'ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The label of the option */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option */
  values?: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
};

/** A list of values for the selected bundle product */
export type ItemSelectedBundleOptionValue = {
  __typename?: 'ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The price of the child bundle product */
  price: Money;
  /** The name of the child bundle product */
  product_name: Scalars['String'];
  /** The SKU of the child bundle product */
  product_sku: Scalars['String'];
  /** Indicates how many of this bundle product were ordered */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object */
  uid: Scalars['ID'];
};

/** The key-value type */
export type KeyValue = {
  __typename?: 'KeyValue';
  /** The name part of the name/value pair */
  name?: Maybe<Scalars['String']>;
  /** The value part of the name/value pair */
  value?: Maybe<Scalars['String']>;
};

export type KlarnaInput = {
  /** The authorization token must be provided to set any Klarna Payments method */
  authorization_token: Scalars['String'];
};

export type LayerFilter = {
  __typename?: 'LayerFilter';
  /**
   * Array of filter items.
   * @deprecated Use Aggregation.options instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * Count of filter items in filter group.
   * @deprecated Use Aggregation.count instead.
   */
  filter_items_count?: Maybe<Scalars['Int']>;
  /**
   * Layered navigation filter name.
   * @deprecated Use Aggregation.label instead.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * Request variable name for filter query.
   * @deprecated Use Aggregation.attribute_code instead.
   */
  request_var?: Maybe<Scalars['String']>;
};

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename?: 'LayerFilterItem';
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type LayerFilterItemInterface = {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type LikesOutput = {
  __typename?: 'LikesOutput';
  /** The total like */
  total?: Maybe<Scalars['Int']>;
};

/** MediaGalleryEntry defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename?: 'MediaGalleryEntry';
  /** Contains a ProductMediaGalleryEntriesContent object. */
  content?: Maybe<ProductMediaGalleryEntriesContent>;
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The alt text displayed on the UI when the user points to the image. */
  label?: Maybe<Scalars['String']>;
  /** image or video. */
  media_type?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

/** A Money object defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename?: 'Money';
  /** A three-letter currency code, such as USD or EUR */
  currency?: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value */
  value?: Maybe<Scalars['Float']>;
};

export type MonthlyArchive = {
  __typename?: 'MonthlyArchive';
  /** An array of post that match the specified search criteria. */
  items?: Maybe<Array<Maybe<Post>>>;
  /** The label of Monthly Archive */
  label?: Maybe<Scalars['String']>;
  /** The quantity of Monthly Archive */
  quantity?: Maybe<Scalars['Int']>;
};

export type MonthlyArchiveOutput = {
  __typename?: 'MonthlyArchiveOutput';
  /** An array of post that match the specified search criteria. */
  items?: Maybe<Array<Maybe<MonthlyArchive>>>;
  /** The number of monthly archive returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type MpFilters = {
  /** Filter From Date. */
  from: Scalars['String'];
  /** Period. */
  period_type?: Maybe<Scalars['String']>;
  /** Show Empty Rows. */
  show_empty_rows?: Maybe<Scalars['Boolean']>;
  /** Filter Store Id. */
  store_id?: Maybe<Scalars['Int']>;
  /** Filter To Date. */
  to: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>;
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>;
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>;
  /** Add any type of product to the cart */
  addProductsToCart?: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list */
  addProductsToCompareList?: Maybe<CompareList>;
  /** Adds one or more products to the specified wish list. This mutation supports all product types */
  addProductsToWishlist?: Maybe<AddProductsToWishlistOutput>;
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>;
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>;
  /** Link account */
  amSocialLoginLinkAccount?: Maybe<AmUnlinkData>;
  /** Unlink account */
  amSocialLoginUnlinkAccount?: Maybe<AmUnlinkData>;
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>;
  /** Assign the specified compare list to the logged in customer */
  assignCompareListToCustomer?: Maybe<AssignCompareListToCustomerOutput>;
  /** Changes the password for the logged-in customer */
  changeCustomerPassword?: Maybe<Customer>;
  /** Complete Checkout Session */
  completeCheckoutSession?: Maybe<CompleteCheckoutSessionOutput>;
  /** Creates Client Token for Braintree Javascript SDK initialization. */
  createBraintreeClientToken: Scalars['String'];
  /** Creates a new compare list. The compare list is saved for logged in customers */
  createCompareList?: Maybe<CompareList>;
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>;
  /** Create customer address */
  createCustomerAddress?: Maybe<CustomerAddress>;
  /** Create customer account */
  createCustomerV2?: Maybe<CustomerOutput>;
  /** Creates an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>;
  /** Creates a Klarna Payments Session. */
  createKlarnaPaymentsSession?: Maybe<CreateKlarnaPaymentsSessionOutput>;
  /** Initiates a transaction and receives a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>;
  /** Initiates an Express Checkout transaction and receives a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>;
  /** Creates a product review for the specified SKU */
  createProductReview: CreateProductReviewOutput;
  /** Delete the specified compare list */
  deleteCompareList?: Maybe<DeleteCompareListOutput>;
  /** Delete customer address */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  /** Delete a customer payment token */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>;
  /** Delete Customer By Email */
  delete_customer?: Maybe<DeleteCustomerOutput>;
  /** Retrieve the customer token */
  generateCustomerToken?: Maybe<CustomerToken>;
  /** Request a customer token so that an administrator can perform remote shopping assistance */
  generateCustomerTokenAsAdmin?: Maybe<GenerateCustomerTokenAsAdminOutput>;
  /** Handles payment response and saves payment in Quote. Use this mutations for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>;
  /** Merges the source cart into the destination cart */
  mergeCarts: Cart;
  /** Request delete customer account */
  mpGdprDeleteCustomerAccountRequest?: Maybe<CustomerDeleteStatusOutput>;
  /** Request delete default address */
  mpGdprDeleteDefaultAddressRequest?: Maybe<Scalars['Boolean']>;
  placeOrder?: Maybe<PlaceOrderOutput>;
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>;
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>;
  /** Remove products from the specified compare list */
  removeProductsFromCompareList?: Maybe<CompareList>;
  /** Removes one or more products from the specified wish list */
  removeProductsFromWishlist?: Maybe<RemoveProductsFromWishlistOutput>;
  /** Adds all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']>;
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using requestPasswordResetEmail. */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** Revoke the customer token */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>;
  /** Recommends Product by Sending Single/Multiple Email */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>;
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>;
  /** Set Customer Link */
  setCustomerLink?: Maybe<SetCustomerLinkOutput>;
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>;
  /** @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request. */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>;
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>;
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>;
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>;
  /** Subscribes the specified email to a newsletter */
  subscribeEmailToNewsletter?: Maybe<SubscribeEmailToNewsletterOutput>;
  updateCartItems?: Maybe<UpdateCartItemsOutput>;
  /** Update Checkout Session */
  updateCheckoutSession?: Maybe<UpdateCheckoutSessionOutput>;
  /** Deprecated. Use UpdateCustomerV2 instead. */
  updateCustomer?: Maybe<CustomerOutput>;
  /** Update customer address */
  updateCustomerAddress?: Maybe<CustomerAddress>;
  updateCustomerEmail?: Maybe<CustomerOutput>;
  /** Update the customer's personal information */
  updateCustomerV2?: Maybe<CustomerOutput>;
  /** Updates one or more products in the specified wish list */
  updateProductsInWishlist?: Maybe<UpdateProductsInWishlistOutput>;
};


export type MutationAddBundleProductsToCartArgs = {
  input?: Maybe<AddBundleProductsToCartInput>;
};


export type MutationAddConfigurableProductsToCartArgs = {
  input?: Maybe<AddConfigurableProductsToCartInput>;
};


export type MutationAddDownloadableProductsToCartArgs = {
  input?: Maybe<AddDownloadableProductsToCartInput>;
};


export type MutationAddProductsToCartArgs = {
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput>;
};


export type MutationAddProductsToCompareListArgs = {
  input?: Maybe<AddProductsToCompareListInput>;
};


export type MutationAddProductsToWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput>;
};


export type MutationAddSimpleProductsToCartArgs = {
  input?: Maybe<AddSimpleProductsToCartInput>;
};


export type MutationAddVirtualProductsToCartArgs = {
  input?: Maybe<AddVirtualProductsToCartInput>;
};


export type MutationAmSocialLoginLinkAccountArgs = {
  type: Scalars['String'];
};


export type MutationAmSocialLoginUnlinkAccountArgs = {
  type: Scalars['String'];
};


export type MutationApplyCouponToCartArgs = {
  input?: Maybe<ApplyCouponToCartInput>;
};


export type MutationAssignCompareListToCustomerArgs = {
  uid: Scalars['ID'];
};


export type MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCompleteCheckoutSessionArgs = {
  cartId: Scalars['String'];
  amazonSessionId: Scalars['String'];
};


export type MutationCreateCompareListArgs = {
  input?: Maybe<CreateCompareListInput>;
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreateCustomerAddressArgs = {
  input: CustomerAddressInput;
};


export type MutationCreateCustomerV2Args = {
  input: CustomerCreateInput;
};


export type MutationCreateEmptyCartArgs = {
  input?: Maybe<CreateEmptyCartInput>;
};


export type MutationCreateKlarnaPaymentsSessionArgs = {
  input?: Maybe<CreateKlarnaPaymentsSessionInput>;
};


export type MutationCreatePayflowProTokenArgs = {
  input: PayflowProTokenInput;
};


export type MutationCreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput;
};


export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput;
};


export type MutationDeleteCompareListArgs = {
  uid: Scalars['ID'];
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String'];
};


export type MutationDelete_CustomerArgs = {
  token: Scalars['String'];
};


export type MutationGenerateCustomerTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationGenerateCustomerTokenAsAdminArgs = {
  input: GenerateCustomerTokenAsAdminInput;
};


export type MutationHandlePayflowProResponseArgs = {
  input: PayflowProResponseInput;
};


export type MutationMergeCartsArgs = {
  source_cart_id: Scalars['String'];
  destination_cart_id?: Maybe<Scalars['String']>;
};


export type MutationMpGdprDeleteDefaultAddressRequestArgs = {
  input: Address;
};


export type MutationPlaceOrderArgs = {
  input?: Maybe<PlaceOrderInput>;
};


export type MutationRemoveCouponFromCartArgs = {
  input?: Maybe<RemoveCouponFromCartInput>;
};


export type MutationRemoveItemFromCartArgs = {
  input?: Maybe<RemoveItemFromCartInput>;
};


export type MutationRemoveProductsFromCompareListArgs = {
  input?: Maybe<RemoveProductsFromCompareListInput>;
};


export type MutationRemoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
};


export type MutationReorderItemsArgs = {
  orderNumber: Scalars['String'];
};


export type MutationRequestPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationSendEmailToFriendArgs = {
  input?: Maybe<SendEmailToFriendInput>;
};


export type MutationSetBillingAddressOnCartArgs = {
  input?: Maybe<SetBillingAddressOnCartInput>;
};


export type MutationSetCustomerLinkArgs = {
  buyerToken: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSetGuestEmailOnCartArgs = {
  input?: Maybe<SetGuestEmailOnCartInput>;
};


export type MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: Maybe<SetPaymentMethodAndPlaceOrderInput>;
};


export type MutationSetPaymentMethodOnCartArgs = {
  input?: Maybe<SetPaymentMethodOnCartInput>;
};


export type MutationSetShippingAddressesOnCartArgs = {
  input?: Maybe<SetShippingAddressesOnCartInput>;
};


export type MutationSetShippingMethodsOnCartArgs = {
  input?: Maybe<SetShippingMethodsOnCartInput>;
};


export type MutationSubscribeEmailToNewsletterArgs = {
  email: Scalars['String'];
};


export type MutationUpdateCartItemsArgs = {
  input?: Maybe<UpdateCartItemsInput>;
};


export type MutationUpdateCheckoutSessionArgs = {
  cartId: Scalars['String'];
  amazonSessionId: Scalars['String'];
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int'];
  input?: Maybe<CustomerAddressInput>;
};


export type MutationUpdateCustomerEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateCustomerV2Args = {
  input: CustomerUpdateInput;
};


export type MutationUpdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemUpdateInput>;
};

export type Order = {
  __typename?: 'Order';
  /** @deprecated The order_id field is deprecated, use order_number instead. */
  order_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `Order` object. */
  order_number: Scalars['String'];
};

/** OrderAddress contains detailed information about an order's billing and shipping addresses */
export type OrderAddress = {
  __typename?: 'OrderAddress';
  /** The city or town */
  city: Scalars['String'];
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname: Scalars['String'];
  /** The family name of the person associated with the shipping/billing address */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's order ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region */
  region_id?: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone: Scalars['String'];
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
};

export type OrderItem = OrderItemInterface & {
  __typename?: 'OrderItem';
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Order item details */
export type OrderItemInterface = {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Represents order item options like selected or entered */
export type OrderItemOption = {
  __typename?: 'OrderItemOption';
  /** The name of the option */
  label: Scalars['String'];
  /** The value of the option */
  value: Scalars['String'];
};

/** Contains details about the payment method used to pay for the order */
export type OrderPaymentMethod = {
  __typename?: 'OrderPaymentMethod';
  /** Additional data per payment method type */
  additional_data?: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for */
  type: Scalars['String'];
};

/** Order shipment details */
export type OrderShipment = {
  __typename?: 'OrderShipment';
  /** Comments added to the shipment */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object */
  id: Scalars['ID'];
  /** Contains items included in the shipment */
  items?: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number */
  number: Scalars['String'];
  /** Contains shipment tracking details */
  tracking?: Maybe<Array<Maybe<ShipmentTracking>>>;
};

/** Contains details about the sales total amounts used to calculate the final price */
export type OrderTotal = {
  __typename?: 'OrderTotal';
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the order */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the order */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The order tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the order */
  total_shipping: Money;
  /** The amount of tax applied to the order */
  total_tax: Money;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** Allows to using paging it start with 1. */
  currentPage?: Maybe<Scalars['Int']>;
  /** End page */
  endPage?: Maybe<Scalars['Int']>;
  /** Is next page */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** Is previous page */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** How many items should show on the page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** Start page */
  startPage?: Maybe<Scalars['Int']>;
};

/** Required input for Payflow Express Checkout payments */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String'];
};

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Mode for payment: TEST or LIVE. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkMode =
  | 'TEST'
  | 'LIVE';

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkToken = {
  __typename?: 'PayflowLinkToken';
  /** Mode for Payflow transaction */
  mode?: Maybe<PayflowLinkMode>;
  /** PayPal URL used for requesting Payflow form */
  paypal_url?: Maybe<Scalars['String']>;
  /** Secure token generated by PayPal */
  secure_token?: Maybe<Scalars['String']>;
  /** Secure token ID generated by PayPal */
  secure_token_id?: Maybe<Scalars['String']>;
};

/** Input required to fetch payment token information for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
};

/** Required input for Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information */
  cc_details: CreditCardDetailsInput;
  /** States whether details about the customer's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: Maybe<Scalars['Boolean']>;
};

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  cart_id: Scalars['String'];
  paypal_payload: Scalars['String'];
};

export type PayflowProResponseOutput = {
  __typename?: 'PayflowProResponseOutput';
  cart: Cart;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename?: 'PayflowProToken';
  response_message: Scalars['String'];
  result: Scalars['Int'];
  result_code: Scalars['Int'];
  secure_token: Scalars['String'];
  secure_token_id: Scalars['String'];
};

/** Input required to fetch payment token information for Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
};

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Pro and Payment Pro payment methods. */
export type PayflowProUrlInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

export type PaymentMethodInput = {
  braintree?: Maybe<BraintreeInput>;
  braintree_cc_vault?: Maybe<BraintreeCcVaultInput>;
  /** Payment method code */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments */
  hosted_pro?: Maybe<HostedProInput>;
  klarna?: Maybe<KlarnaInput>;
  /** Required input for Payflow Express Checkout payments */
  payflow_express?: Maybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments */
  payflow_link?: Maybe<PayflowLinkInput>;
  /** Required input type for PayPal Payflow Pro and Payment Pro payments */
  payflowpro?: Maybe<PayflowProInput>;
  /** Required input type for PayPal Payflow Pro vault payments */
  payflowpro_cc_vault?: Maybe<VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments */
  paypal_express?: Maybe<PaypalExpressInput>;
  /** Purchase order number */
  purchase_order_number?: Maybe<Scalars['String']>;
  /** Required input for Stripe Payments */
  stripe_payments?: Maybe<StripePaymentsInput>;
};

/** The stored payment method available to the customer */
export type PaymentToken = {
  __typename?: 'PaymentToken';
  /** Stored account details */
  details?: Maybe<Scalars['String']>;
  /** The payment method code associated with the token */
  payment_method_code: Scalars['String'];
  /** The public hash of the token */
  public_hash: Scalars['String'];
  type: PaymentTokenTypeEnum;
};

/** The list of available payment token types */
export type PaymentTokenTypeEnum =
  | 'card'
  | 'account';

/** Required input for Express Checkout and Payments Standard payments */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String'];
};

/** Deprecated: use type `PaypalExpressTokenOutput` instead */
export type PaypalExpressToken = {
  __typename?: 'PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details
   * @deprecated Use field `paypal_urls` of type `PaypalExpressTokenOutput` instead
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal
   * @deprecated Use field `token` of type `PaypalExpressTokenOutput` instead
   */
  token?: Maybe<Scalars['String']>;
};

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** Payment method code */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false */
  express_button?: Maybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false */
  use_paypal_credit?: Maybe<Scalars['Boolean']>;
};

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenOutput = {
  __typename?: 'PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal */
  token?: Maybe<Scalars['String']>;
};

/** A set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type PaypalExpressUrlList = {
  __typename?: 'PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details */
  edit?: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page */
  start?: Maybe<Scalars['String']>;
};

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the page that PayPal will redirect to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: Maybe<Scalars['String']>;
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: Maybe<Scalars['String']>;
};

/** PhysicalProductInterface contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};

/** Defines Pickup Location information. */
export type PickupLocation = {
  __typename?: 'PickupLocation';
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export type PickupLocationFilterInput = {
  /** Filter by city. */
  city?: Maybe<FilterTypeInput>;
  /** Filter by country. */
  country_id?: Maybe<FilterTypeInput>;
  /** Filter by pickup location name. */
  name?: Maybe<FilterTypeInput>;
  /** Filter by pickup location code. */
  pickup_location_code?: Maybe<FilterTypeInput>;
  /** Filter by postcode. */
  postcode?: Maybe<FilterTypeInput>;
  /** Filter by region. */
  region?: Maybe<FilterTypeInput>;
  /** Filter by region id. */
  region_id?: Maybe<FilterTypeInput>;
  /** Filter by street. */
  street?: Maybe<FilterTypeInput>;
};

/** PickupLocationSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type PickupLocationSortInput = {
  /** City where pickup location is placed. */
  city?: Maybe<SortEnum>;
  /** Name of the contact person. */
  contact_name?: Maybe<SortEnum>;
  /** Id of the country in two letters. */
  country_id?: Maybe<SortEnum>;
  /** Description of the pickup location. */
  description?: Maybe<SortEnum>;
  /** Distance to the address, requested by distance filter. Applicable only with distance filter. If distance sort order is present, all other sort orders will be ignored. */
  distance?: Maybe<SortEnum>;
  /** Contact email of the pickup location. */
  email?: Maybe<SortEnum>;
  /** Contact fax of the pickup location. */
  fax?: Maybe<SortEnum>;
  /** Geographic latitude where pickup location is placed. */
  latitude?: Maybe<SortEnum>;
  /** Geographic longitude where pickup location is placed. */
  longitude?: Maybe<SortEnum>;
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: Maybe<SortEnum>;
  /** Contact phone number of the pickup location. */
  phone?: Maybe<SortEnum>;
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: Maybe<SortEnum>;
  /** Postcode where pickup location is placed. */
  postcode?: Maybe<SortEnum>;
  /** Name of the region. */
  region?: Maybe<SortEnum>;
  /** Id of the region. */
  region_id?: Maybe<SortEnum>;
  /** Street where pickup location is placed. */
  street?: Maybe<SortEnum>;
};

/** Top level object returned in a pickup locations search. */
export type PickupLocations = {
  __typename?: 'PickupLocations';
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<PickupLocation>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>;
};

export type PlaceOrderInput = {
  cart_id: Scalars['String'];
};

export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput';
  order: Order;
};

export type Post = {
  __typename?: 'Post';
  /** Indicates whether users can write a comment. */
  allow_comment?: Maybe<Scalars['Int']>;
  /** An ID that identifies the author. */
  author_id?: Maybe<Scalars['Int']>;
  /** The name of the author. */
  author_name?: Maybe<Scalars['String']>;
  /** The full url of the author. */
  author_url?: Maybe<Scalars['String']>;
  /** The url key of the author. */
  author_url_key?: Maybe<Scalars['String']>;
  /** The categories assigned to a post */
  categories?: Maybe<CategoriesOutputNoPage>;
  /** Timestamp indicating when the post was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Indicates whether a post is enabled. */
  enabled?: Maybe<Scalars['Int']>;
  /** The image of the post. */
  image?: Maybe<Scalars['String']>;
  /** The import source of the post. */
  import_source?: Maybe<Scalars['String']>;
  /** Indicates whether a post in rss. */
  in_rss?: Maybe<Scalars['Int']>;
  /** The layout of the post */
  layout?: Maybe<Scalars['String']>;
  /** The meta description of the post. */
  meta_description?: Maybe<Scalars['String']>;
  /** The meta keywords of the post. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** The meta robot of the post. */
  meta_robots?: Maybe<Scalars['String']>;
  /** The meta title of the post. */
  meta_title?: Maybe<Scalars['String']>;
  /** The name of the post. */
  name?: Maybe<Scalars['String']>;
  /** The content of the post. */
  post_content?: Maybe<Scalars['String']>;
  /** An ID that uniquely identifies the post. */
  post_id?: Maybe<Scalars['Int']>;
  /** The related post assigned to a post */
  posts?: Maybe<PostsOutputNoPage>;
  /** The products assigned to a post */
  products?: Maybe<ProductsOutputNoPage>;
  /** Timestamp indicating when the post was publish. */
  publish_date?: Maybe<Scalars['String']>;
  /** The short description of the post. */
  short_description?: Maybe<Scalars['String']>;
  /** An ID that identifies the store. */
  store_ids?: Maybe<Scalars['String']>;
  /** The tags assigned to a post */
  tags?: Maybe<TagsOutputNoPage>;
  /** The topics assigned to a post */
  topics?: Maybe<TopicsOutputNoPage>;
  /** Timestamp indicating when the post was updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key of the post. */
  url_key?: Maybe<Scalars['String']>;
  /** The view count of the post. */
  view_traffic?: Maybe<Scalars['Int']>;
};

export type PostsFilterInput = {
  /** Indicates whether the post allow comment */
  allow_comment?: Maybe<FilterTypeInput>;
  /** Author Id the post belongs to. */
  author_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the post was created */
  created_at?: Maybe<FilterTypeInput>;
  /** Enabled */
  enabled?: Maybe<FilterTypeInput>;
  /** Import Source */
  import_source?: Maybe<FilterTypeInput>;
  /** The post name. Customers use this name to identify the post. */
  name?: Maybe<FilterTypeInput>;
  /** The post id. Customers use this name to identify the post. */
  post_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the post was publish */
  publish_date?: Maybe<FilterTypeInput>;
  /** The store id */
  store_ids?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the post was updated */
  updated_at?: Maybe<FilterTypeInput>;
  /** The post url_key */
  url_key?: Maybe<FilterTypeInput>;
};

export type PostsOutput = {
  __typename?: 'PostsOutput';
  /** An array of post that match the specified search criteria */
  items?: Maybe<Array<Maybe<Post>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of posts returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type PostsOutputNoPage = {
  __typename?: 'PostsOutputNoPage';
  /** An array of post that match the specified search criteria */
  items?: Maybe<Array<Maybe<Post>>>;
  /** The number of posts returned */
  total_count?: Maybe<Scalars['Int']>;
};

/** Price is deprecated, replaced by ProductPrice. The Price object defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename?: 'Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  amount?: Maybe<Money>;
};

/** PriceAdjustment is deprecated. Taxes will be included or excluded in the price. The PricedAdjustment object defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated PriceAdjustment is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated PriceAdjustment is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>;
};

/** PriceAdjustment.code is deprecated. This enumeration contains values defined in modules other than the Catalog module. */
export type PriceAdjustmentCodesEnum =
  | 'TAX'
  | 'WEEE'
  | 'WEEE_TAX';

/** PriceAdjustmentDescriptionEnum is deprecated. This enumeration states whether a price adjustment is included or excluded. */
export type PriceAdjustmentDescriptionEnum =
  | 'INCLUDED'
  | 'EXCLUDED';

/** Price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename?: 'PriceRange';
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice;
};

/** This enumeration the price type. */
export type PriceTypeEnum =
  | 'FIXED'
  | 'PERCENT'
  | 'DYNAMIC';

/** This enumeration defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export type PriceViewEnum =
  | 'PRICE_RANGE'
  | 'AS_LOW_AS';

export type Product = {
  __typename?: 'Product';
  /** The attribute set assigned to the product. */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The ID number assigned to the product. */
  entity_id?: Maybe<Scalars['Int']>;
  /** Indicates whether a product option is available. */
  has_options?: Maybe<Scalars['Int']>;
  /** The images of the product. */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Indicates whether a product option is required. */
  required_options?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
  type_id?: Maybe<Scalars['String']>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key of the product. */
  url_key?: Maybe<Scalars['String']>;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute */
  value: Scalars['String'];
};

/** ProductAttributeFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductAttributeFilterInput = {
  /** Attribute label: attributi */
  attributi?: Maybe<FilterEqualTypeInput>;
  /** Category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: classe_efficienza_energetica */
  classe_efficienza_energetica?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Codice EAN */
  codice_ean?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: Colore */
  colore?: Maybe<FilterEqualTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>;
  /** Attribute label: Description */
  description?: Maybe<FilterMatchTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>;
  /** Attribute label: Marca */
  manufacturer?: Maybe<FilterEqualTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>;
  /** Attribute label: Product Name */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter product by news_from_date. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** Filter product by news_to_date. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>;
  /** Attribute label: pollici */
  pollici_schermo?: Maybe<FilterEqualTypeInput>;
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>;
  /** Attribute label: Prodotto Novità */
  prodotto_novita?: Maybe<FilterEqualTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>;
  /** Attribute label: SKU */
  sku?: Maybe<FilterEqualTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Eventi Speciali */
  vetrinatempo?: Maybe<FilterEqualTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>;
};

/** ProductAttributeSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type ProductAttributeSortInput = {
  /** Sort by the position assigned to each product. */
  position?: Maybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: Maybe<SortEnum>;
};

/** A discount applied to a product price. */
export type ProductDiscount = {
  __typename?: 'ProductDiscount';
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']>;
};

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductFilterInput = {
  /** Category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>;
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterTypeInput>;
  url_path?: Maybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>;
};

/** Product image information. Contains the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename?: 'ProductImage';
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

/** Product Information used for Pickup Locations search. */
export type ProductInfoInput = {
  /** Product SKU. */
  sku: Scalars['String'];
};

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterface = {
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** ProductLinks is an implementation of ProductLinksInterface. */
export type ProductLinks = ProductLinksInterface & {
  __typename?: 'ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** ProductLinks contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** ProductMediaGalleryEntriesContent contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename?: 'ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
};

/** ProductMediaGalleryEntriesVideoContent contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']>;
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']>;
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']>;
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']>;
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']>;
};

/** Represents a product price. */
export type ProductPrice = {
  __typename?: 'ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>;
  /** The final price of the product after discounts applied. */
  final_price: Money;
  /** The multiple FPTs that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The regular price of the product. */
  regular_price: Money;
};

/** ProductPrices is deprecated, replaced by PriceRange. The ProductPrices object contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type ProductPrices = {
  __typename?: 'ProductPrices';
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the to value.
   * @deprecated Use PriceRange.maximum_price.
   */
  maximalPrice?: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the from value.
   * @deprecated Use PriceRange.minimum_price.
   */
  minimalPrice?: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use regular_price from PriceRange.minimum_price or PriceRange.maximum_price.
   */
  regularPrice?: Maybe<Price>;
};

/** Details of a product review */
export type ProductReview = {
  __typename?: 'ProductReview';
  /** The average rating for product review. */
  average_rating: Scalars['Float'];
  /** Date indicating when the review was created. */
  created_at: Scalars['String'];
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String'];
  /** Contains details about the reviewed product */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value */
  ratings_breakdown: Array<Maybe<ProductReviewRating>>;
  /** The summary (title) of the review */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

export type ProductReviewRating = {
  __typename?: 'ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
};

export type ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value id. */
  value_id: Scalars['String'];
};

export type ProductReviewRatingMetadata = {
  __typename?: 'ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
};

export type ProductReviewRatingValueMetadata = {
  __typename?: 'ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded */
  value: Scalars['String'];
  /** An encoded rating value id. */
  value_id: Scalars['String'];
};

export type ProductReviewRatingsMetadata = {
  __typename?: 'ProductReviewRatingsMetadata';
  /** List of product reviews sorted by position */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
};

export type ProductReviews = {
  __typename?: 'ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo;
};

/** ProductSortInput is deprecated, use @ProductAttributeSortInput instead. ProductSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<SortEnum>;
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<SortEnum>;
  /** The label assigned to a product image. */
  image_label?: Maybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<SortEnum>;
  /** The price of the item. */
  price?: Maybe<SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<SortEnum>;
  /** The discounted price of the product. */
  special_price?: Maybe<SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<SortEnum>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<SortEnum>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<SortEnum>;
  url_path?: Maybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<SortEnum>;
};

/** This enumeration states whether a product stock status is in stock or out of stock */
export type ProductStockStatus =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK';

/** ProductTierPrices is deprecated and has been replaced by TierPrice. The ProductTierPrices object defines a tier price, which is a quantity discount offered to a specific customer group. */
export type ProductTierPrices = {
  __typename?: 'ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated customer_group_id is not relevant for storefront.
   */
  customer_group_id?: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.discount.
   */
  percentage_value?: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated ProductTierPrices is deprecated, use TierPrice.quantity.
   */
  qty?: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.final_price
   */
  value?: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated website_id is not relevant for storefront.
   */
  website_id?: Maybe<Scalars['Float']>;
};

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename?: 'ProductVideo';
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** The Products object is the top-level object returned in a product search. */
export type Products = {
  __typename?: 'Products';
  /** Layered navigation aggregations. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use aggregations instead
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>;
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
};

export type ProductsFilterInput = {
  /** Product Attribute Set Id the product belongs to */
  attribute_set_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was created */
  created_at?: Maybe<FilterTypeInput>;
  /** Product Id */
  entity_id?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product */
  has_options?: Maybe<FilterTypeInput>;
  /** Indicates whether the product has required options */
  required_options?: Maybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer */
  sku?: Maybe<FilterTypeInput>;
  /** Product Type the product belongs to */
  type_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the product was updated */
  updated_at?: Maybe<FilterTypeInput>;
};

export type ProductsOutput = {
  __typename?: 'ProductsOutput';
  /** An array of products that match the specified search criteria */
  items?: Maybe<Array<Maybe<Product>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of products returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type ProductsOutputNoPage = {
  __typename?: 'ProductsOutputNoPage';
  /** An array of products that match the specified search criteria */
  items?: Maybe<Array<Maybe<Product>>>;
  /** The number of products returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get configs */
  MpGdprConfigs?: Maybe<GdprConfigs>;
  /** List of linked accounts */
  amSocialLoginAccountData?: Maybe<Array<Maybe<AmLinkedSocialAccounts>>>;
  /** Button configuration */
  amSocialLoginButtonConfig?: Maybe<Array<Maybe<AmSocialLoginButton>>>;
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<StoreConfig>>>;
  /** Returns information about shopping cart */
  cart?: Maybe<Cart>;
  categories?: Maybe<CategoryResult>;
  /**
   * The category query searches for categories that match the criteria specified in the search and filter attributes.
   * @deprecated Use 'categoryList' query instead of 'category' query
   */
  category?: Maybe<CategoryTree>;
  /** Returns an array of categories based on the specified filters. */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>;
  /** The Checkout Agreements information */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>;
  checkoutSessionConfig?: Maybe<CheckoutSessionConfigOutput>;
  checkoutSessionDetails?: Maybe<CheckoutSessionDetailsOutput>;
  checkoutSessionSignIn?: Maybe<CheckoutSessionSignInOutput>;
  /** The CMS block query returns information about CMS blocks */
  cmsBlocks?: Maybe<CmsBlocks>;
  /** The CMS page query returns information about a CMS page */
  cmsPage?: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list */
  compareList?: Maybe<CompareList>;
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>;
  /** The currency query returns information about store currency. */
  currency?: Maybe<Currency>;
  /** The customAttributeMetadata query returns the attribute type, given an attribute code and entity type */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>;
  /** The customer query returns information about a customer account */
  customer?: Maybe<Customer>;
  /** Returns information about the customer shopping cart */
  customerCart: Cart;
  /** The query returns the contents of a customer's downloadable products */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>;
  /** @deprecated Use orders from customer instead */
  customerOrders?: Maybe<CustomerOrders>;
  /** Return a list of customer payment tokens */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  /** Retrieve secure PayPal url for Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>;
  /** Retrieve payment credentials for transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>;
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>;
  /** The mpBlogCategories query searches for Category that match the criteria specified in the filter. */
  mpBlogCategories?: Maybe<CategoriesOutput>;
  /** The mpBlogComments query searches for Category that match the criteria specified in the filter. */
  mpBlogComments?: Maybe<CommentsOutput>;
  /** The mpBlogConfigs query gets the config of Blog extension. */
  mpBlogConfigs?: Maybe<ConfigsOutput>;
  /** The mpBlogMonthlyArchive query gets posts follow monthly archive. */
  mpBlogMonthlyArchive?: Maybe<MonthlyArchiveOutput>;
  /** Returns the total number of likes for the post */
  mpBlogPostLikes?: Maybe<LikesOutput>;
  /** The mpBlogPosts query searches for post that match the criteria specified in the filter. */
  mpBlogPosts?: Maybe<PostsOutput>;
  /** The mpBlogProducts query searches for Category that match the criteria specified in the filter. */
  mpBlogProducts?: Maybe<ProductsOutput>;
  /** The mpBlogTags query searches for tag that match the criteria specified in the filter. */
  mpBlogTags?: Maybe<TagsOutput>;
  /** The mpBlogTopics query searches for topic that match the criteria specified in the filter. */
  mpBlogTopics?: Maybe<TopicsOutput>;
  /** Searches for Best Sellers information matches the filter. */
  mpSmtpBestsellers?: Maybe<SmtpBestsellersOutput>;
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<PickupLocations>;
  /** Retrieves metadata required by clients to render the Reviews section. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  /** comment for products fields */
  products?: Maybe<Products>;
  /** The store config query */
  storeConfig?: Maybe<StoreConfig>;
  /** The urlResolver query returns the relative URL for a specified product, category or CMS page, using as input a url_key appended by the url_suffix, if one exists */
  urlResolver?: Maybe<EntityUrl>;
  /**
   * The wishlist query returns the contents of a customer's wish list
   * @deprecated Moved under `Customer` `wishlist`
   */
  wishlist?: Maybe<WishlistOutput>;
};


export type QueryAvailableStoresArgs = {
  useCurrentGroup?: Maybe<Scalars['Boolean']>;
};


export type QueryCartArgs = {
  cart_id: Scalars['String'];
};


export type QueryCategoriesArgs = {
  filters?: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCategoryListArgs = {
  filters?: Maybe<CategoryFilterInput>;
};


export type QueryCheckoutSessionConfigArgs = {
  cartId?: Maybe<Scalars['String']>;
  omitPayloads?: Maybe<Scalars['Boolean']>;
};


export type QueryCheckoutSessionDetailsArgs = {
  amazonSessionId: Scalars['String'];
  queryTypes?: Maybe<Array<Scalars['String']>>;
};


export type QueryCheckoutSessionSignInArgs = {
  buyerToken: Scalars['String'];
};


export type QueryCmsBlocksArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCmsPageArgs = {
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
};


export type QueryCompareListArgs = {
  uid: Scalars['ID'];
};


export type QueryCountryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>;
};


export type QueryGetHostedProUrlArgs = {
  input: HostedProUrlInput;
};


export type QueryGetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput;
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryMpBlogCategoriesArgs = {
  action: Scalars['String'];
  filter?: Maybe<CategoriesFilterInput>;
  postId?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogCommentsArgs = {
  filter?: Maybe<CommentsFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogConfigsArgs = {
  storeId?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogMonthlyArchiveArgs = {
  storeId?: Maybe<Scalars['Int']>;
  monthly?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogPostLikesArgs = {
  postId?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogPostsArgs = {
  action: Scalars['String'];
  filter?: Maybe<PostsFilterInput>;
  authorName?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  categoryKey?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
};


export type QueryMpBlogProductsArgs = {
  filter?: Maybe<ProductsFilterInput>;
  postId?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogTagsArgs = {
  filter?: Maybe<TagsFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryMpBlogTopicsArgs = {
  filter?: Maybe<TopicsFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryMpSmtpBestsellersArgs = {
  app_id: Scalars['String'];
  secret_key: Scalars['String'];
  filters?: Maybe<MpFilters>;
};


export type QueryPickupLocationsArgs = {
  area?: Maybe<AreaInput>;
  filters?: Maybe<PickupLocationFilterInput>;
  sort?: Maybe<PickupLocationSortInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  productsInfo?: Maybe<Array<Maybe<ProductInfoInput>>>;
};


export type QueryProductsArgs = {
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};


export type QueryUrlResolverArgs = {
  url: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type RemoveCouponFromCartInput = {
  cart_id: Scalars['String'];
};

export type RemoveCouponFromCartOutput = {
  __typename?: 'RemoveCouponFromCartOutput';
  cart?: Maybe<Cart>;
};

export type RemoveItemFromCartInput = {
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object */
  cart_item_uid?: Maybe<Scalars['ID']>;
};

export type RemoveItemFromCartOutput = {
  __typename?: 'RemoveItemFromCartOutput';
  cart: Cart;
};

export type RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered */
export type RemoveProductsFromWishlistOutput = {
  __typename?: 'RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted */
  wishlist: Wishlist;
};

export type ReorderItemsOutput = {
  __typename?: 'ReorderItemsOutput';
  /** Contains detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
};

export type RevokeCustomerTokenOutput = {
  __typename?: 'RevokeCustomerTokenOutput';
  result: Scalars['Boolean'];
};

/** Comment item details */
export type SalesCommentItem = {
  __typename?: 'SalesCommentItem';
  /** The text of the message */
  message: Scalars['String'];
  /** The timestamp of the comment */
  timestamp: Scalars['String'];
};

export type SalesItemInterface = {
  __typename?: 'SalesItemInterface';
  /** The entered gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
};

/** SearchResultPageInfo provides navigation for the query response */
export type SearchResultPageInfo = {
  __typename?: 'SearchResultPageInfo';
  /** Specifies which page of results to return */
  current_page?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of items to return */
  page_size?: Maybe<Scalars['Int']>;
  /** Total pages */
  total_pages?: Maybe<Scalars['Int']>;
};

export type SelectedBundleOption = {
  __typename?: 'SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  values: Array<Maybe<SelectedBundleOptionValue>>;
};

export type SelectedBundleOptionValue = {
  __typename?: 'SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
};

export type SelectedConfigurableOption = {
  __typename?: 'SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object */
  configurable_product_option_uid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  configurable_product_option_value_uid: Scalars['ID'];
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_uid instead */
  id: Scalars['Int'];
  option_label: Scalars['String'];
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_value_uid instead */
  value_id: Scalars['Int'];
  value_label: Scalars['String'];
};

export type SelectedCustomOptionDetail = {
  __typename?: 'SelectedCustomOptionDetail';
  /** The unique ID for a `CustomizableMultipleValue`, `CustomizableRadioValue`, `CustomizableCheckboxValue`, `CustomizableDropDownValue`, etc. objects */
  customizable_option_value_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomOptionDetail.customizable_option_value_uid instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  price: CartItemSelectedOptionValuePrice;
  value: Scalars['String'];
};

export type SelectedCustomOptionValue = {
  __typename?: 'SelectedCustomOptionValue';
  /** The unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `CustomizableMultipleOption`, etc. of `CustomizableOptionInterface` objects */
  customizable_option_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomOptionValue.customizable_option_uid instead */
  id: Scalars['Int'];
  is_required: Scalars['Boolean'];
  label: Scalars['String'];
  sort_order: Scalars['Int'];
  type: Scalars['String'];
  values: Array<Maybe<SelectedCustomOptionDetail>>;
};

export type SelectedCustomizableOption = {
  __typename?: 'SelectedCustomizableOption';
  /** The unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `CustomizableMultipleOption`, etc. of `CustomizableOptionInterface` objects */
  customizable_option_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomizableOption.customizable_option_uid instead */
  id: Scalars['Int'];
  is_required: Scalars['Boolean'];
  label: Scalars['String'];
  sort_order: Scalars['Int'];
  type: Scalars['String'];
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
};

export type SelectedCustomizableOptionValue = {
  __typename?: 'SelectedCustomizableOptionValue';
  /** The unique ID for a `CustomizableMultipleValue`, `CustomizableRadioValue`, `CustomizableCheckboxValue`, `CustomizableDropDownValue`, etc. objects */
  customizable_option_value_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomizableOptionValue.customizable_option_value_uid instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  price: CartItemSelectedOptionValuePrice;
  value: Scalars['String'];
};

export type SelectedPaymentMethod = {
  __typename?: 'SelectedPaymentMethod';
  /** The payment method code */
  code: Scalars['String'];
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
};

export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod';
  amount: Money;
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>;
  carrier_code: Scalars['String'];
  carrier_title: Scalars['String'];
  method_code: Scalars['String'];
  method_title: Scalars['String'];
};

export type SendEmailToFriendInput = {
  product_id: Scalars['Int'];
  recipients: Array<Maybe<SendEmailToFriendRecipientInput>>;
  sender: SendEmailToFriendSenderInput;
};

export type SendEmailToFriendOutput = {
  __typename?: 'SendEmailToFriendOutput';
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  sender?: Maybe<SendEmailToFriendSender>;
};

export type SendEmailToFriendRecipient = {
  __typename?: 'SendEmailToFriendRecipient';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type SendEmailToFriendRecipientInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type SendEmailToFriendSender = {
  __typename?: 'SendEmailToFriendSender';
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SendEmailToFriendSenderInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SendFriendConfiguration = {
  __typename?: 'SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabled_for_customers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabled_for_guests: Scalars['Boolean'];
};

export type Seo = {
  __typename?: 'Seo';
  /** Meta description. */
  meta_description?: Maybe<Scalars['String']>;
  /** Meta title. */
  meta_title?: Maybe<Scalars['String']>;
};

export type SetBillingAddressOnCartInput = {
  billing_address: BillingAddressInput;
  cart_id: Scalars['String'];
};

export type SetBillingAddressOnCartOutput = {
  __typename?: 'SetBillingAddressOnCartOutput';
  cart: Cart;
};

export type SetCustomerLinkOutput = {
  __typename?: 'SetCustomerLinkOutput';
  customer_bearer_token?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_firstname?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_last?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SetGuestEmailOnCartInput = {
  cart_id: Scalars['String'];
  email: Scalars['String'];
};

export type SetGuestEmailOnCartOutput = {
  __typename?: 'SetGuestEmailOnCartOutput';
  cart: Cart;
};

export type SetPaymentMethodAndPlaceOrderInput = {
  cart_id: Scalars['String'];
  payment_method: PaymentMethodInput;
};

export type SetPaymentMethodOnCartInput = {
  cart_id: Scalars['String'];
  payment_method: PaymentMethodInput;
};

export type SetPaymentMethodOnCartOutput = {
  __typename?: 'SetPaymentMethodOnCartOutput';
  cart: Cart;
};

export type SetShippingAddressesOnCartInput = {
  cart_id: Scalars['String'];
  shipping_addresses: Array<Maybe<ShippingAddressInput>>;
};

export type SetShippingAddressesOnCartOutput = {
  __typename?: 'SetShippingAddressesOnCartOutput';
  cart: Cart;
};

export type SetShippingMethodsOnCartInput = {
  cart_id: Scalars['String'];
  shipping_methods: Array<Maybe<ShippingMethodInput>>;
};

export type SetShippingMethodsOnCartOutput = {
  __typename?: 'SetShippingMethodsOnCartOutput';
  cart: Cart;
};

/** This enumeration defines whether bundle items must be shipped together. */
export type ShipBundleItemsEnum =
  | 'TOGETHER'
  | 'SEPARATELY';

export type ShipmentItem = ShipmentItemInterface & {
  __typename?: 'ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
};

/** Order shipment item details */
export type ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
};

/** Order shipment tracking details */
export type ShipmentTracking = {
  __typename?: 'ShipmentTracking';
  /** The shipping carrier for the order delivery */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment */
  number?: Maybe<Scalars['String']>;
  /** The shipment tracking title */
  title: Scalars['String'];
};

export type ShippingAddressInput = {
  address?: Maybe<CartAddressInput>;
  customer_address_id?: Maybe<Scalars['Int']>;
  customer_notes?: Maybe<Scalars['String']>;
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: Maybe<Scalars['String']>;
};

export type ShippingCartAddress = CartAddressInterface & {
  __typename?: 'ShippingCartAddress';
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** @deprecated `cart_items_v2` should be used instead */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>;
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>;
  city: Scalars['String'];
  codice_fiscale?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  customer_notes?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['Int']>;
  firstname: Scalars['String'];
  /** @deprecated This information shoud not be exposed on frontend */
  items_weight?: Maybe<Scalars['Float']>;
  lastname: Scalars['String'];
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  sdi_pec?: Maybe<Scalars['String']>;
  selected_shipping_method?: Maybe<SelectedShippingMethod>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  vat_id?: Maybe<Scalars['String']>;
};

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type ShippingDiscount = {
  __typename?: 'ShippingDiscount';
  /** The amount of the discount */
  amount: Money;
};

/** The Shipping handling details */
export type ShippingHandling = {
  __typename?: 'ShippingHandling';
  /** The shipping amount, excluding tax */
  amount_excluding_tax?: Maybe<Money>;
  /** The shipping amount, including tax */
  amount_including_tax?: Maybe<Money>;
  /** The applied discounts to the shipping */
  discounts?: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Contains details about taxes applied for shipping */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping */
  total_amount: Money;
};

export type ShippingMethodInput = {
  carrier_code: Scalars['String'];
  method_code: Scalars['String'];
};

export type Sidebar = {
  __typename?: 'Sidebar';
  /** Number of most view posts. */
  number_mostview_posts?: Maybe<Scalars['Int']>;
  /** Number of recent posts. */
  number_recent_posts?: Maybe<Scalars['Int']>;
};

/** Simple Cart Item */
export type SimpleCartItem = CartItemInterface & {
  __typename?: 'SimpleCartItem';
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

/** A simple product is tangible and are usually sold as single units or in fixed quantities. */
export type SimpleProduct = ProductInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'SimpleProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** A simple product is tangible and are usually sold as single units or in fixed quantities. */
export type SimpleProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type SimpleProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  data: CartItemInput;
};

/** A simple product wish list Item */
export type SimpleWishlistItem = WishlistItemInterface & {
  __typename?: 'SimpleWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

export type SmtpBestsellersOutput = {
  __typename?: 'SmtpBestsellersOutput';
  /** Bestsellers Product Information. */
  mpBestsellers?: Maybe<Array<Maybe<BestsellersProductOutput>>>;
};

/** This enumeration indicates whether to return results in ascending or descending order */
export type SortEnum =
  | 'ASC'
  | 'DESC';

export type SortField = {
  __typename?: 'SortField';
  /** Label of sort field. */
  label?: Maybe<Scalars['String']>;
  /** Attribute code of sort field. */
  value?: Maybe<Scalars['String']>;
};

/** SortFields contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename?: 'SortFields';
  /** Default value of sort fields. */
  default?: Maybe<Scalars['String']>;
  /** Available sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>;
};

/** The type contains information about a store config. */
export type StoreConfig = {
  __typename?: 'StoreConfig';
  /** Footer Miscellaneous HTML */
  absolute_footer?: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No) */
  allow_guests_to_write_product_reviews?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allow_items?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allow_order?: Maybe<Scalars['String']>;
  /** Login Buttons Position. */
  amsociallogin_general_button_position?: Maybe<Scalars['String']>;
  /** Login Buttons Shape. */
  amsociallogin_general_button_shape?: Maybe<Scalars['Int']>;
  /** Redirect Url after Login. */
  amsociallogin_general_custom_url?: Maybe<Scalars['String']>;
  /** Enable Social Login. */
  amsociallogin_general_enabled?: Maybe<Scalars['Boolean']>;
  /** Add Social Login to. */
  amsociallogin_general_login_position?: Maybe<Scalars['String']>;
  /** Enable Ajax Popup. */
  amsociallogin_general_popup_enabled?: Maybe<Scalars['Boolean']>;
  /** Redirect after Login. */
  amsociallogin_general_redirect_type?: Maybe<Scalars['Int']>;
  /** Enable autocomplete on login and forgot password forms */
  autocomplete_on_storefront?: Maybe<Scalars['Boolean']>;
  /** Base currency code */
  base_currency_code?: Maybe<Scalars['String']>;
  /** Base link URL for the store */
  base_link_url?: Maybe<Scalars['String']>;
  /** Base media URL for the store */
  base_media_url?: Maybe<Scalars['String']>;
  /** Base static URL for the store */
  base_static_url?: Maybe<Scalars['String']>;
  /** Base URL for the store */
  base_url?: Maybe<Scalars['String']>;
  /** Braintree cc vault status. */
  braintree_cc_vault_active?: Maybe<Scalars['String']>;
  /** Default Sort By. */
  catalog_default_sort_by?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices In Product Lists' field. It indicates how FPT information is displayed on category pages */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Category URL Suffix. */
  category_url_suffix?: Maybe<Scalars['String']>;
  /** CMS Home Page */
  cms_home_page?: Maybe<Scalars['String']>;
  /** CMS No Cookies Page */
  cms_no_cookies?: Maybe<Scalars['String']>;
  /** CMS No Route Page */
  cms_no_route?: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']>;
  /** The configuration setting determines which thumbnail should be used in the cart for configurable products. */
  configurable_thumbnail_source?: Maybe<Scalars['String']>;
  /** Copyright */
  copyright?: Maybe<Scalars['String']>;
  /** Default Meta Description */
  default_description?: Maybe<Scalars['String']>;
  /** Default display currency code */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** Default Meta Keywords */
  default_keywords?: Maybe<Scalars['String']>;
  /** Default Page Title */
  default_title?: Maybe<Scalars['String']>;
  /** Display Demo Store Notice */
  demonotice?: Maybe<Scalars['Int']>;
  /** Default Web URL */
  front?: Maybe<Scalars['String']>;
  /** Products per Page on Grid Default Value. */
  grid_per_page?: Maybe<Scalars['Int']>;
  /** Products per Page on Grid Allowed Values. */
  grid_per_page_values?: Maybe<Scalars['String']>;
  /** Scripts and Style Sheets */
  head_includes?: Maybe<Scalars['String']>;
  /** Favicon Icon */
  head_shortcut_icon?: Maybe<Scalars['String']>;
  /** Logo Image */
  header_logo_src?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the store view has been designated as the default within the store group */
  is_default_store?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website */
  is_default_store_group?: Maybe<Scalars['Boolean']>;
  /** List Mode. */
  list_mode?: Maybe<Scalars['String']>;
  /** Products per Page on List Default Value. */
  list_per_page?: Maybe<Scalars['Int']>;
  /** Products per Page on List Allowed Values. */
  list_per_page_values?: Maybe<Scalars['String']>;
  /** Store locale */
  locale?: Maybe<Scalars['String']>;
  /** Logo Image Alt */
  logo_alt?: Maybe<Scalars['String']>;
  /** Logo Attribute Height */
  logo_height?: Maybe<Scalars['Int']>;
  /** Logo Attribute Width */
  logo_width?: Maybe<Scalars['Int']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0) */
  magento_wishlist_general_is_enabled?: Maybe<Scalars['String']>;
  /** The minimum number of characters required for a valid password. */
  minimum_password_length?: Maybe<Scalars['String']>;
  /** Default No-route URL */
  no_route?: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  payment_payflowpro_cc_vault_active?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices On Product View Page' field. It indicates how FPT information is displayed on product pages */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No) */
  product_reviews_enabled?: Maybe<Scalars['String']>;
  /** Product URL Suffix. */
  product_url_suffix?: Maybe<Scalars['String']>;
  /** The number of different character classes required in a password (lowercase, uppercase, digits, special characters). */
  required_character_classes_number?: Maybe<Scalars['String']>;
  /**
   * The ID of the root category
   * @deprecated Use `root_category_uid` instead
   */
  root_category_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  root_category_uid?: Maybe<Scalars['ID']>;
  /** Corresponds to the 'Display Prices In Sales Modules' field. It indicates how FPT information is displayed on cart, checkout, and order pages */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Secure base link URL for the store */
  secure_base_link_url?: Maybe<Scalars['String']>;
  /** Secure base media URL for the store */
  secure_base_media_url?: Maybe<Scalars['String']>;
  /** Secure base static URL for the store */
  secure_base_static_url?: Maybe<Scalars['String']>;
  /** Secure base URL for the store */
  secure_base_url?: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  send_friend?: Maybe<SendFriendConfiguration>;
  /** Show Breadcrumbs for CMS Pages */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']>;
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope */
  store_code?: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name */
  store_group_code?: Maybe<Scalars['ID']>;
  /** The label assigned to the store group */
  store_group_name?: Maybe<Scalars['String']>;
  /** The label assigned to the store view */
  store_name?: Maybe<Scalars['String']>;
  /** The store view sort order */
  store_sort_order?: Maybe<Scalars['Int']>;
  /** Timezone of the store */
  timezone?: Maybe<Scalars['String']>;
  /** Page Title Prefix */
  title_prefix?: Maybe<Scalars['String']>;
  /** Page Title Separator. */
  title_separator?: Maybe<Scalars['String']>;
  /** Page Title Suffix */
  title_suffix?: Maybe<Scalars['String']>;
  /** The configuration determines if the store code should be used in the URL */
  use_store_in_url?: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website */
  website_code?: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store
   * @deprecated The field should not be used on the storefront
   */
  website_id?: Maybe<Scalars['Int']>;
  /** The label assigned to the website */
  website_name?: Maybe<Scalars['String']>;
  /** The unit of weight */
  weight_unit?: Maybe<Scalars['String']>;
  /** Welcome Text */
  welcome?: Maybe<Scalars['String']>;
};

export type StripePaymentsInput = {
  /** Specify whether the card should be saved */
  cc_save?: Maybe<Scalars['Boolean']>;
  /** Stripe.js generated payment method token */
  cc_stripejs_token: Scalars['String'];
};

export type SubscribeEmailToNewsletterOutput = {
  __typename?: 'SubscribeEmailToNewsletterOutput';
  /** Returns the status of the subscription request */
  status?: Maybe<SubscriptionStatusesEnum>;
};

export type SubscriptionStatusesEnum =
  | 'NOT_ACTIVE'
  | 'SUBSCRIBED'
  | 'UNSUBSCRIBED'
  | 'UNCONFIRMED';

export type SwatchData = {
  __typename?: 'SwatchData';
  /** Type of swatch filter item: 1 - text, 2 - image */
  type?: Maybe<Scalars['String']>;
  /** Value for swatch item (text or image link) */
  value?: Maybe<Scalars['String']>;
};

export type SwatchDataInterface = {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItem = LayerFilterItemInterface & SwatchLayerFilterItemInterface & {
  __typename?: 'SwatchLayerFilterItem';
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItemInterface = {
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>;
};

export type Tag = {
  __typename?: 'Tag';
  /** Timestamp indicating when the tag was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The description of the tag. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether a tag is enabled. */
  enabled?: Maybe<Scalars['Int']>;
  /** The import source of the tag. */
  import_source?: Maybe<Scalars['String']>;
  /** The meta description of the tag. */
  meta_description?: Maybe<Scalars['String']>;
  /** The meta keywords of the tag. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** The meta robot of the tag. */
  meta_robots?: Maybe<Scalars['String']>;
  /** The meta title of the tag. */
  meta_title?: Maybe<Scalars['String']>;
  /** The name of the tag. */
  name?: Maybe<Scalars['String']>;
  /** The posts assigned to a tag */
  posts?: Maybe<PostsOutputNoPage>;
  /** An ID that identifies the store. */
  store_ids?: Maybe<Scalars['String']>;
  /** An ID that uniquely identifies the tag. */
  tag_id?: Maybe<Scalars['Int']>;
  /** Timestamp indicating when the tag was updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key of the tag. */
  url_key?: Maybe<Scalars['String']>;
};

export type TagsFilterInput = {
  /** Timestamp indicating when the tag was created */
  created_at?: Maybe<FilterTypeInput>;
  /** Enabled */
  enabled?: Maybe<FilterTypeInput>;
  /** Import Source */
  import_source?: Maybe<FilterTypeInput>;
  /** The tag name. Customers use this name to identify the tag. */
  name?: Maybe<FilterTypeInput>;
  /** The store id */
  store_ids?: Maybe<FilterTypeInput>;
  /** The tag id. Customers use this name to identify the tag. */
  tag_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the tag was updated */
  updated_at?: Maybe<FilterTypeInput>;
  /** The tag url_key */
  url_key?: Maybe<FilterTypeInput>;
};

export type TagsOutput = {
  __typename?: 'TagsOutput';
  /** An array of tags that match the specified search criteria */
  items?: Maybe<Array<Maybe<Tag>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of tags returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type TagsOutputNoPage = {
  __typename?: 'TagsOutputNoPage';
  /** An array of tags that match the specified search criteria */
  items?: Maybe<Array<Maybe<Tag>>>;
  /** The number of tags returned */
  total_count?: Maybe<Scalars['Int']>;
};

/** The tax item details */
export type TaxItem = {
  __typename?: 'TaxItem';
  /** The amount of tax applied to the item */
  amount: Money;
  /** The rate used to calculate the tax */
  rate: Scalars['Float'];
  /** A title that describes the tax */
  title: Scalars['String'];
};

export type TextSwatchData = SwatchDataInterface & {
  __typename?: 'TextSwatchData';
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
};

/** A price based on the quantity purchased. */
export type TierPrice = {
  __typename?: 'TierPrice';
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>;
  final_price?: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>;
};

export type Topic = {
  __typename?: 'Topic';
  /** Timestamp indicating when the topic was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The description of the topic. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether a topic is enabled. */
  enabled?: Maybe<Scalars['Int']>;
  /** The import source of the topic. */
  import_source?: Maybe<Scalars['String']>;
  /** The meta description of the topic. */
  meta_description?: Maybe<Scalars['String']>;
  /** The meta keywords of the topic. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** The meta robot of the topic. */
  meta_robots?: Maybe<Scalars['String']>;
  /** The meta title of the topic. */
  meta_title?: Maybe<Scalars['String']>;
  /** The name of the topic. */
  name?: Maybe<Scalars['String']>;
  /** The post assigned to a topic */
  posts?: Maybe<PostsOutputNoPage>;
  /** An ID that identifies the store. */
  store_ids?: Maybe<Scalars['String']>;
  /** An ID that uniquely identifies the topic. */
  topic_id?: Maybe<Scalars['Int']>;
  /** Timestamp indicating when the topic was updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key of the topic. */
  url_key?: Maybe<Scalars['String']>;
};

export type TopicsFilterInput = {
  /** Timestamp indicating when the topic was created */
  created_at?: Maybe<FilterTypeInput>;
  /** Enabled */
  enabled?: Maybe<FilterTypeInput>;
  /** Import Source */
  import_source?: Maybe<FilterTypeInput>;
  /** The topic name. Customers use this name to identify the topic. */
  name?: Maybe<FilterTypeInput>;
  /** The store id */
  store_ids?: Maybe<FilterTypeInput>;
  /** The topic id. Customers use this name to identify the topic. */
  topic_id?: Maybe<FilterTypeInput>;
  /** Timestamp indicating when the topic was updated */
  updated_at?: Maybe<FilterTypeInput>;
  /** The topic url_key */
  url_key?: Maybe<FilterTypeInput>;
};

export type TopicsOutput = {
  __typename?: 'TopicsOutput';
  /** An array of topics that match the specified search criteria */
  items?: Maybe<Array<Maybe<Topic>>>;
  /** An object that includes the page_info and currentPage values specified in the query */
  pageInfo?: Maybe<PageInfo>;
  /** The number of topics returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type TopicsOutputNoPage = {
  __typename?: 'TopicsOutputNoPage';
  /** An array of topics that match the specified search criteria */
  items?: Maybe<Array<Maybe<Topic>>>;
  /** The number of topics returned */
  total_count?: Maybe<Scalars['Int']>;
};

export type UpdateCartItemsInput = {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<CartItemUpdateInput>>;
};

export type UpdateCartItemsOutput = {
  __typename?: 'UpdateCartItemsOutput';
  cart: Cart;
};

export type UpdateCheckoutSessionOutput = {
  __typename?: 'UpdateCheckoutSessionOutput';
  redirectUrl?: Maybe<Scalars['String']>;
};

/** Contains the customer's wish list and any errors encountered */
export type UpdateProductsInWishlistOutput = {
  __typename?: 'UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated */
  wishlist: Wishlist;
};

/** The object contains URL rewrite details */
export type UrlRewrite = {
  __typename?: 'UrlRewrite';
  /** Request parameters */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** Request URL */
  url?: Maybe<Scalars['String']>;
};

/** This enumeration defines the entity type. */
export type UrlRewriteEntityTypeEnum =
  | 'CMS_PAGE'
  | 'PRODUCT'
  | 'CATEGORY';

/** Required input for payment methods with Vault support. */
export type VaultTokenInput = {
  /** The public hash of the payment token */
  public_hash: Scalars['String'];
};

/** Virtual Cart Item */
export type VirtualCartItem = CartItemInterface & {
  __typename?: 'VirtualCartItem';
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** @deprecated Use `uid` instead tests */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  selected_customizable_options: Array<Maybe<SelectedCustomOptionValue>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
};

/** A virtual product is non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProduct = ProductInterface & CustomizableProductInterface & {
  __typename?: 'VirtualProduct';
  allarme_sonoro?: Maybe<Scalars['Int']>;
  alt_image?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attributi?: Maybe<Scalars['Int']>;
  bloccoetichetta1?: Maybe<Scalars['String']>;
  block_creative?: Maybe<Scalars['String']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  cartografia?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  classe_efficienza_energetica?: Maybe<Scalars['String']>;
  classe_efficienza_energetica_l?: Maybe<Scalars['String']>;
  /** A Block of Classe Energetica of a product */
  classe_energetica_id_cms_block: Scalars['String'];
  /** A Classe Energetica link of a product */
  classe_energetica_link: Scalars['String'];
  /** A Classe Energetica sheet of a product */
  classe_energetica_sheet: Scalars['String'];
  codice_ean?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An array of options for a customizable product. */
  custom_options?: Maybe<Array<Maybe<CustomOptionValue>>>;
  day_one?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  digital?: Maybe<Scalars['Int']>;
  dimensione_schermo?: Maybe<Scalars['Int']>;
  dual_sim?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  fotocamera?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  inchoo_featured_product?: Maybe<Scalars['Int']>;
  intervallo_misurazione?: Maybe<Scalars['Int']>;
  lettore?: Maybe<Scalars['Int']>;
  lettore_mp3_mp4?: Maybe<Scalars['Int']>;
  lunghezza_filo?: Maybe<Scalars['Int']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  memori?: Maybe<Scalars['Int']>;
  memoria?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  metri_cubi?: Maybe<Scalars['Int']>;
  mrbytecno?: Maybe<Scalars['String']>;
  /** A msrp price of a product */
  msrp: Scalars['Float'];
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  peso_cellulari?: Maybe<Scalars['Int']>;
  peso_supportato?: Maybe<Scalars['Int']>;
  pollici_filtro?: Maybe<Scalars['Int']>;
  pollici_schermo?: Maybe<Scalars['Int']>;
  potenza_casse?: Maybe<Scalars['Int']>;
  potenza_tot?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  prodotto_lastminute?: Maybe<Scalars['Int']>;
  prodotto_novita?: Maybe<Scalars['Int']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  promo?: Maybe<Scalars['Int']>;
  radio_fm?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  rete?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  risoluzione_display?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  speciale_natale?: Maybe<Scalars['Int']>;
  spedizione_dal?: Maybe<Scalars['String']>;
  staffe_tv?: Maybe<Scalars['Int']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  supporti?: Maybe<Scalars['Int']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  tecnologia_3d?: Maybe<Scalars['Int']>;
  tempi_consegna?: Maybe<Scalars['String']>;
  terminiecondizioni?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  vetrinatempo?: Maybe<Scalars['String']>;
  video_promo?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/** A virtual product is non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type VirtualProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  data: CartItemInput;
};

/** A virtual product wish list item */
export type VirtualWishlistItem = WishlistItemInterface & {
  __typename?: 'VirtualWishlistItem';
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

/** Website is deprecated because it is should not be used on storefront. The type contains information about a website */
export type Website = {
  __typename?: 'Website';
  /**
   * A code assigned to the website to identify it
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * The default group ID that the website has
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Specifies if this is the default website
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']>;
};

/** An error encountered while performing operations with WishList. */
export type WishListUserInputError = {
  __typename?: 'WishListUserInputError';
  /** Wishlist-specific error code */
  code: WishListUserInputErrorType;
  /** A localized error message */
  message: Scalars['String'];
};

export type WishListUserInputErrorType =
  | 'PRODUCT_NOT_FOUND'
  | 'UNDEFINED';

export type Wishlist = {
  __typename?: 'Wishlist';
  /** The unique ID for a `Wishlist` object */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use field `items_v2` from type `Wishlist` instead */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list */
  items_count?: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list */
  items_v2?: Maybe<WishlistItems>;
  /** An encrypted code that Magento uses to link to the wish list */
  sharing_code?: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list */
  updated_at?: Maybe<Scalars['String']>;
};


export type WishlistItems_V2Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type WishlistItem = {
  __typename?: 'WishlistItem';
  /** The time when the customer added the item to the wish list */
  added_at?: Maybe<Scalars['String']>;
  /** The customer's comment about this item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object */
  id?: Maybe<Scalars['Int']>;
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>;
};

/** Defines the items to add to a wish list */
export type WishlistItemInput = {
  /** An array of options that the customer entered */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of items to add */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU */
  sku: Scalars['String'];
};

export type WishlistItemInterface = {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
};

/** Defines updates to items in a wish list */
export type WishlistItemUpdateInput = {
  /** Customer-entered comments about the item */
  description?: Maybe<Scalars['String']>;
  /** An array of options that the customer entered */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** The new amount or number of this item */
  quantity?: Maybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object */
  wishlist_item_id: Scalars['ID'];
};

export type WishlistItems = {
  __typename?: 'WishlistItems';
  /** A list of items in the wish list */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>;
};

/** Deprecated: `Wishlist` type should be used instead */
export type WishlistOutput = {
  __typename?: 'WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use field `items` from type `Wishlist` instead
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list
   * @deprecated Use field `items_count` from type `Wishlist` instead
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source edition
   */
  name?: Maybe<Scalars['String']>;
  /**
   * An encrypted code that Magento uses to link to the wish list
   * @deprecated Use field `sharing_code` from type `Wishlist` instead
   */
  sharing_code?: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list
   * @deprecated Use field `updated_at` from type `Wishlist` instead
   */
  updated_at?: Maybe<Scalars['String']>;
};

export type CreateEmptyCartInput = {
  cart_id?: Maybe<Scalars['String']>;
};

export type CreateKlarnaPaymentsSessionInput = {
  cart_id: Scalars['String'];
};

export type CreateKlarnaPaymentsSessionOutput = {
  __typename?: 'createKlarnaPaymentsSessionOutput';
  /** The payment method client token */
  client_token?: Maybe<Scalars['String']>;
  /** The payment method categories */
  payment_method_categories?: Maybe<Array<Maybe<Categories>>>;
};

export type GeneralConfig = {
  __typename?: 'generalConfig';
  allow_delete_customer?: Maybe<Scalars['String']>;
  allow_delete_default_address?: Maybe<Scalars['String']>;
  delete_customer_message?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['String']>;
};

export type AddressFieldsFragment = (
  { __typename?: 'CustomerAddress' }
  & Pick<CustomerAddress, 'city' | 'country_code' | 'default_billing' | 'default_shipping' | 'firstname' | 'id' | 'lastname' | 'postcode' | 'street' | 'telephone' | 'company' | 'vat_id'>
  & { region?: Maybe<(
    { __typename?: 'CustomerAddressRegion' }
    & Pick<CustomerAddressRegion, 'region_id' | 'region_code'>
  )>, custom_attributes?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerAddressAttribute' }
    & Pick<CustomerAddressAttribute, 'attribute_code' | 'value'>
  )>>> }
);

export type CartFieldFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'email' | 'id' | 'is_virtual' | 'total_quantity'>
  & { applied_coupon?: Maybe<(
    { __typename?: 'AppliedCoupon' }
    & Pick<AppliedCoupon, 'code'>
  )>, applied_coupons?: Maybe<Array<Maybe<(
    { __typename?: 'AppliedCoupon' }
    & Pick<AppliedCoupon, 'code'>
  )>>>, available_payment_methods?: Maybe<Array<Maybe<(
    { __typename?: 'AvailablePaymentMethod' }
    & Pick<AvailablePaymentMethod, 'code' | 'title'>
  )>>>, billing_address?: Maybe<(
    { __typename?: 'BillingCartAddress' }
    & Pick<BillingCartAddress, 'city' | 'company' | 'customer_notes' | 'firstname' | 'lastname' | 'postcode' | 'street' | 'telephone' | 'customer_type' | 'codice_fiscale' | 'sdi_pec' | 'vat_id'>
    & { country: (
      { __typename?: 'CartAddressCountry' }
      & Pick<CartAddressCountry, 'code' | 'label'>
    ), region?: Maybe<(
      { __typename?: 'CartAddressRegion' }
      & Pick<CartAddressRegion, 'code' | 'label' | 'region_id'>
    )> }
  )>, gift_message?: Maybe<(
    { __typename?: 'GiftMessage' }
    & Pick<GiftMessage, 'from' | 'message' | 'to'>
  )>, items?: Maybe<Array<Maybe<(
    { __typename?: 'BundleCartItem' }
    & Pick<BundleCartItem, 'id' | 'quantity' | 'uid'>
    & { prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { discounts?: Maybe<Array<Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        ) }
      )>>>, price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total_including_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), total_item_discount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )>, product: { __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }, selected_customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomOptionValue' }
      & Pick<SelectedCustomOptionValue, 'customizable_option_uid' | 'id' | 'is_required' | 'label' | 'sort_order' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomOptionDetail' }
        & Pick<SelectedCustomOptionDetail, 'customizable_option_value_uid' | 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
        ) }
      )>> }
    )>> }
  ) | (
    { __typename?: 'ConfigurableCartItem' }
    & Pick<ConfigurableCartItem, 'id' | 'quantity' | 'uid'>
    & { prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { discounts?: Maybe<Array<Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        ) }
      )>>>, price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total_including_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), total_item_discount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )>, product: { __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }, selected_customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomOptionValue' }
      & Pick<SelectedCustomOptionValue, 'customizable_option_uid' | 'id' | 'is_required' | 'label' | 'sort_order' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomOptionDetail' }
        & Pick<SelectedCustomOptionDetail, 'customizable_option_value_uid' | 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
        ) }
      )>> }
    )>> }
  ) | (
    { __typename?: 'DownloadableCartItem' }
    & Pick<DownloadableCartItem, 'id' | 'quantity' | 'uid'>
    & { prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { discounts?: Maybe<Array<Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        ) }
      )>>>, price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total_including_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), total_item_discount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )>, product: { __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }, selected_customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomOptionValue' }
      & Pick<SelectedCustomOptionValue, 'customizable_option_uid' | 'id' | 'is_required' | 'label' | 'sort_order' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomOptionDetail' }
        & Pick<SelectedCustomOptionDetail, 'customizable_option_value_uid' | 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
        ) }
      )>> }
    )>> }
  ) | (
    { __typename?: 'SimpleCartItem' }
    & Pick<SimpleCartItem, 'id' | 'quantity' | 'uid'>
    & { prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { discounts?: Maybe<Array<Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        ) }
      )>>>, price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total_including_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), total_item_discount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )>, product: { __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }, selected_customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomOptionValue' }
      & Pick<SelectedCustomOptionValue, 'customizable_option_uid' | 'id' | 'is_required' | 'label' | 'sort_order' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomOptionDetail' }
        & Pick<SelectedCustomOptionDetail, 'customizable_option_value_uid' | 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
        ) }
      )>> }
    )>> }
  ) | (
    { __typename?: 'VirtualCartItem' }
    & Pick<VirtualCartItem, 'id' | 'quantity' | 'uid'>
    & { prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { discounts?: Maybe<Array<Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        ) }
      )>>>, price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), row_total_including_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), total_item_discount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )>, product: { __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }, selected_customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomOptionValue' }
      & Pick<SelectedCustomOptionValue, 'customizable_option_uid' | 'id' | 'is_required' | 'label' | 'sort_order' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomOptionDetail' }
        & Pick<SelectedCustomOptionDetail, 'customizable_option_value_uid' | 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
        ) }
      )>> }
    )>> }
  )>>>, prices?: Maybe<(
    { __typename?: 'CartPrices' }
    & { applied_taxes?: Maybe<Array<Maybe<(
      { __typename?: 'CartTaxItem' }
      & Pick<CartTaxItem, 'label'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>>>, discount?: Maybe<(
      { __typename?: 'CartDiscount' }
      & Pick<CartDiscount, 'label'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>, discounts?: Maybe<Array<Maybe<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'label'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>>>, grand_total?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'value'>
    )>, subtotal_excluding_tax?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'value'>
    )>, subtotal_including_tax?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'value'>
    )>, subtotal_with_discount_excluding_tax?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'value'>
    )> }
  )>, selected_payment_method?: Maybe<(
    { __typename?: 'SelectedPaymentMethod' }
    & Pick<SelectedPaymentMethod, 'code' | 'purchase_order_number' | 'title'>
  )>, shipping_addresses: Array<Maybe<(
    { __typename?: 'ShippingCartAddress' }
    & Pick<ShippingCartAddress, 'city' | 'company' | 'customer_notes' | 'firstname' | 'items_weight' | 'lastname' | 'pickup_location_code' | 'postcode' | 'street' | 'telephone' | 'customer_type' | 'codice_fiscale' | 'sdi_pec' | 'vat_id'>
    & { available_shipping_methods?: Maybe<Array<Maybe<(
      { __typename?: 'AvailableShippingMethod' }
      & Pick<AvailableShippingMethod, 'available' | 'carrier_code' | 'carrier_title' | 'error_message' | 'method_code' | 'method_title'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), price_excl_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ), price_incl_tax: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>>>, cart_items?: Maybe<Array<Maybe<(
      { __typename?: 'CartItemQuantity' }
      & Pick<CartItemQuantity, 'cart_item_id' | 'quantity'>
    )>>>, country: (
      { __typename?: 'CartAddressCountry' }
      & Pick<CartAddressCountry, 'code' | 'label'>
    ), region?: Maybe<(
      { __typename?: 'CartAddressRegion' }
      & Pick<CartAddressRegion, 'code' | 'label' | 'region_id'>
    )>, selected_shipping_method?: Maybe<(
      { __typename?: 'SelectedShippingMethod' }
      & Pick<SelectedShippingMethod, 'carrier_code' | 'carrier_title' | 'method_code' | 'method_title'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )> }
  )>> }
);

export type CategoryFieldFragment = (
  { __typename?: 'CategoryTree' }
  & Pick<CategoryTree, 'id' | 'name' | 'uid' | 'description' | 'filter_price_range' | 'position' | 'product_count' | 'image'>
);

export type PageInfoFieldFragment = (
  { __typename?: 'SearchResultPageInfo' }
  & Pick<SearchResultPageInfo, 'current_page' | 'page_size' | 'total_pages'>
);

export type CountryFieldsFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'full_name_locale' | 'id' | 'two_letter_abbreviation'>
  & { available_regions?: Maybe<Array<Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'code' | 'id' | 'name'>
  )>>> }
);

export type CustomerFieldsFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'firstname' | 'lastname' | 'email' | 'is_subscribed' | 'default_billing' | 'default_shipping'>
  & { addresses?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFieldsFragment
  )>>> }
);

export type OrderFieldsFragment = (
  { __typename?: 'CustomerOrders' }
  & { page_info?: Maybe<(
    { __typename?: 'SearchResultPageInfo' }
    & Pick<SearchResultPageInfo, 'current_page' | 'page_size' | 'total_pages'>
  )>, items: Array<Maybe<(
    { __typename?: 'CustomerOrder' }
    & Pick<CustomerOrder, 'order_date' | 'id' | 'number' | 'status'>
    & { total?: Maybe<(
      { __typename?: 'OrderTotal' }
      & { subtotal: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ), grand_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    )>, items?: Maybe<Array<Maybe<(
      { __typename?: 'BundleOrderItem' }
      & Pick<BundleOrderItem, 'id' | 'product_name' | 'product_sku' | 'product_url_key' | 'status'>
      & { product_sale_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ), entered_options?: Maybe<Array<Maybe<(
        { __typename?: 'OrderItemOption' }
        & Pick<OrderItemOption, 'label' | 'value'>
      )>>> }
    ) | (
      { __typename?: 'DownloadableOrderItem' }
      & Pick<DownloadableOrderItem, 'id' | 'product_name' | 'product_sku' | 'product_url_key' | 'status'>
      & { product_sale_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ), entered_options?: Maybe<Array<Maybe<(
        { __typename?: 'OrderItemOption' }
        & Pick<OrderItemOption, 'label' | 'value'>
      )>>> }
    ) | (
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'product_name' | 'product_sku' | 'product_url_key' | 'status'>
      & { product_sale_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ), entered_options?: Maybe<Array<Maybe<(
        { __typename?: 'OrderItemOption' }
        & Pick<OrderItemOption, 'label' | 'value'>
      )>>> }
    )>>>, billing_address?: Maybe<(
      { __typename?: 'OrderAddress' }
      & Pick<OrderAddress, 'company' | 'firstname' | 'lastname'>
    )>, shipping_address?: Maybe<(
      { __typename?: 'OrderAddress' }
      & Pick<OrderAddress, 'city' | 'company' | 'firstname' | 'lastname' | 'postcode' | 'region' | 'street' | 'telephone'>
    )> }
  )>> }
);

export type ProductLightFieldFragment = (
  { __typename?: 'SimpleProduct' }
  & Pick<SimpleProduct, 'name' | 'manufacturer' | 'special_price' | 'special_from_date' | 'special_to_date' | 'sku' | 'id' | 'uid' | 'stock_status' | 'tempi_consegna' | 'codice_ean' | 'bloccoetichetta1' | 'block_creative' | 'classe_efficienza_energetica' | 'classe_efficienza_energetica_l' | 'classe_energetica_id_cms_block' | 'classe_energetica_link' | 'classe_energetica_sheet' | 'msrp'>
  & { short_description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    ), maximum_price?: Maybe<(
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )> }
  ), price?: Maybe<(
    { __typename?: 'ProductPrices' }
    & { regularPrice?: Maybe<(
      { __typename?: 'Price' }
      & { amount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      )> }
    )> }
  )>, image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url'>
  )>, custom_options?: Maybe<Array<Maybe<(
    { __typename?: 'CustomOptionValue' }
    & Pick<CustomOptionValue, 'option_id' | 'required' | 'sort_order' | 'title' | 'uid'>
    & { value?: Maybe<Array<Maybe<(
      { __typename?: 'CustomOptionDetail' }
      & Pick<CustomOptionDetail, 'option_type_id' | 'price' | 'price_type' | 'sku' | 'title' | 'sort_order' | 'uid'>
    )>>> }
  )>>> }
);

export type ProductFieldFragment = (
  { __typename?: 'SimpleProduct' }
  & { description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )>, media_gallery_entries?: Maybe<Array<Maybe<(
    { __typename?: 'MediaGalleryEntry' }
    & Pick<MediaGalleryEntry, 'position' | 'disabled' | 'media_type' | 'file'>
  )>>>, product_links?: Maybe<Array<Maybe<(
    { __typename?: 'ProductLinks' }
    & Pick<ProductLinks, 'link_type' | 'linked_product_sku' | 'linked_product_type' | 'position' | 'sku'>
  )>>> }
  & ProductLightFieldFragment
);

export type TokenFieldsFragment = (
  { __typename?: 'CustomerToken' }
  & Pick<CustomerToken, 'token'>
);

export type WishlistFieldsFragment = (
  { __typename?: 'Wishlist' }
  & Pick<Wishlist, 'id'>
  & { items_v2?: Maybe<(
    { __typename?: 'WishlistItems' }
    & WishlistItemsV2FieldsFragment
  )> }
);

export type WishlistItemsV2FieldsFragment = (
  { __typename?: 'WishlistItems' }
  & { page_info?: Maybe<(
    { __typename?: 'SearchResultPageInfo' }
    & Pick<SearchResultPageInfo, 'current_page' | 'page_size' | 'total_pages'>
  )>, items: Array<Maybe<(
    { __typename?: 'BundleWishlistItem' }
    & Pick<BundleWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  ) | (
    { __typename?: 'ConfigurableWishlistItem' }
    & Pick<ConfigurableWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  ) | (
    { __typename?: 'DownloadableWishlistItem' }
    & Pick<DownloadableWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  ) | (
    { __typename?: 'GroupedProductWishlistItem' }
    & Pick<GroupedProductWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  ) | (
    { __typename?: 'SimpleWishlistItem' }
    & Pick<SimpleWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  ) | (
    { __typename?: 'VirtualWishlistItem' }
    & Pick<VirtualWishlistItem, 'added_at' | 'description' | 'id' | 'quantity'>
    & { product?: Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }> }
  )>> }
);

export type CreateAddressMutationVariables = Exact<{
  region_id: Scalars['Int'];
  city: Scalars['String'];
  default_billing: Scalars['Boolean'];
  default_shipping: Scalars['Boolean'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode: Scalars['String'];
  street: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  vat_id?: Maybe<Scalars['String']>;
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>> | Maybe<CustomerAddressAttributeInput>>;
}>;


export type CreateAddressMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerAddress?: Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFieldsFragment
  )> }
);

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<CountryCodeEnum>;
  region_id?: Maybe<Scalars['Int']>;
  default_billing?: Maybe<Scalars['Boolean']>;
  default_shipping?: Maybe<Scalars['Boolean']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  telephone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vat_id?: Maybe<Scalars['String']>;
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>> | Maybe<CustomerAddressAttributeInput>>;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerAddress?: Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFieldsFragment
  )> }
);

export type CreateGuestCartMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateGuestCartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEmptyCart'>
);

export type MergeCartsMutationVariables = Exact<{
  source: Scalars['String'];
  destination: Scalars['String'];
}>;


export type MergeCartsMutation = (
  { __typename?: 'Mutation' }
  & { mergeCarts: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id'>
  ) }
);

export type AddToCartMutationVariables = Exact<{
  sku: Scalars['String'];
  quantity: Scalars['Float'];
  cart: Scalars['String'];
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>> | Maybe<CustomizableOptionInput>>;
}>;


export type AddToCartMutation = (
  { __typename?: 'Mutation' }
  & { addSimpleProductsToCart?: Maybe<(
    { __typename?: 'AddSimpleProductsToCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type UpdateCartItemMutationVariables = Exact<{
  input?: Maybe<UpdateCartItemsInput>;
}>;


export type UpdateCartItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCartItems?: Maybe<(
    { __typename?: 'UpdateCartItemsOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type RemoveCartItemMutationVariables = Exact<{
  input: RemoveItemFromCartInput;
}>;


export type RemoveCartItemMutation = (
  { __typename?: 'Mutation' }
  & { removeItemFromCart?: Maybe<(
    { __typename?: 'RemoveItemFromCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type SetBillingAddressToCartMutationVariables = Exact<{
  input?: Maybe<SetBillingAddressOnCartInput>;
}>;


export type SetBillingAddressToCartMutation = (
  { __typename?: 'Mutation' }
  & { setBillingAddressOnCart?: Maybe<(
    { __typename?: 'SetBillingAddressOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type SetShippingAddressToCartMutationVariables = Exact<{
  input?: Maybe<SetShippingAddressesOnCartInput>;
}>;


export type SetShippingAddressToCartMutation = (
  { __typename?: 'Mutation' }
  & { setShippingAddressesOnCart?: Maybe<(
    { __typename?: 'SetShippingAddressesOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type SetShippingMethodToCartMutationVariables = Exact<{
  input?: Maybe<SetShippingMethodsOnCartInput>;
}>;


export type SetShippingMethodToCartMutation = (
  { __typename?: 'Mutation' }
  & { setShippingMethodsOnCart?: Maybe<(
    { __typename?: 'SetShippingMethodsOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type SetPaymentMethodOnCartMutationVariables = Exact<{
  input?: Maybe<SetPaymentMethodOnCartInput>;
}>;


export type SetPaymentMethodOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setPaymentMethodOnCart?: Maybe<(
    { __typename?: 'SetPaymentMethodOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type ApplyCouponToCartMutationVariables = Exact<{
  input?: Maybe<ApplyCouponToCartInput>;
}>;


export type ApplyCouponToCartMutation = (
  { __typename?: 'Mutation' }
  & { applyCouponToCart?: Maybe<(
    { __typename?: 'ApplyCouponToCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type RemoveCouponFromCartMutationVariables = Exact<{
  input?: Maybe<RemoveCouponFromCartInput>;
}>;


export type RemoveCouponFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeCouponFromCart?: Maybe<(
    { __typename?: 'RemoveCouponFromCartOutput' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & CartFieldFragment
    )> }
  )> }
);

export type PlaceOrderMutationVariables = Exact<{
  input?: Maybe<PlaceOrderInput>;
}>;


export type PlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder?: Maybe<(
    { __typename?: 'PlaceOrderOutput' }
    & { order: (
      { __typename?: 'Order' }
      & Pick<Order, 'order_number'>
    ) }
  )> }
);

export type CreatePaypalExpressTokenMutationVariables = Exact<{
  input: PaypalExpressTokenInput;
}>;


export type CreatePaypalExpressTokenMutation = (
  { __typename?: 'Mutation' }
  & { createPaypalExpressToken?: Maybe<(
    { __typename?: 'PaypalExpressTokenOutput' }
    & Pick<PaypalExpressTokenOutput, 'token'>
    & { paypal_urls?: Maybe<(
      { __typename?: 'PaypalExpressUrlList' }
      & Pick<PaypalExpressUrlList, 'start' | 'edit'>
    )> }
  )> }
);

export type SetGuestEmailOnCartMutationVariables = Exact<{
  input: SetGuestEmailOnCartInput;
}>;


export type SetGuestEmailOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setGuestEmailOnCart?: Maybe<(
    { __typename?: 'SetGuestEmailOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & CartFieldFragment
    ) }
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CustomerOutput' }
    & { customer: (
      { __typename?: 'Customer' }
      & CustomerFieldsFragment
    ) }
  )> }
);

export type ChangeCustomerPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangeCustomerPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeCustomerPassword?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFieldsFragment
  )> }
);

export type ResetCustomerPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetCustomerPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordResetEmail'>
);

export type DeleteCustomerMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & { delete_customer?: Maybe<(
    { __typename?: 'DeleteCustomerOutput' }
    & Pick<DeleteCustomerOutput, 'response'>
  )> }
);

export type GenerateTokenLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GenerateTokenLoginMutation = (
  { __typename?: 'Mutation' }
  & { generateCustomerToken?: Maybe<(
    { __typename?: 'CustomerToken' }
    & TokenFieldsFragment
  )> }
);

export type RevokeCustomerTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RevokeCustomerTokenMutation = (
  { __typename?: 'Mutation' }
  & { revokeCustomerToken?: Maybe<(
    { __typename?: 'RevokeCustomerTokenOutput' }
    & Pick<RevokeCustomerTokenOutput, 'result'>
  )> }
);

export type AddWishlistItemMutationVariables = Exact<{
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput> | WishlistItemInput;
}>;


export type AddWishlistItemMutation = (
  { __typename?: 'Mutation' }
  & { addProductsToWishlist?: Maybe<(
    { __typename?: 'AddProductsToWishlistOutput' }
    & { user_errors: Array<Maybe<(
      { __typename?: 'WishListUserInputError' }
      & Pick<WishListUserInputError, 'message' | 'code'>
    )>>, wishlist: (
      { __typename?: 'Wishlist' }
      & WishlistFieldsFragment
    ) }
  )> }
);

export type RemoveWishlistItemMutationVariables = Exact<{
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveWishlistItemMutation = (
  { __typename?: 'Mutation' }
  & { removeProductsFromWishlist?: Maybe<(
    { __typename?: 'RemoveProductsFromWishlistOutput' }
    & { user_errors: Array<Maybe<(
      { __typename?: 'WishListUserInputError' }
      & Pick<WishListUserInputError, 'message' | 'code'>
    )>>, wishlist: (
      { __typename?: 'Wishlist' }
      & WishlistFieldsFragment
    ) }
  )> }
);

export type GetCartQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCartQuery = (
  { __typename?: 'Query' }
  & { cart?: Maybe<(
    { __typename?: 'Cart' }
    & CartFieldFragment
  )> }
);

export type GetCustomerCartIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerCartIdQuery = (
  { __typename?: 'Query' }
  & { customerCart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id'>
  ) }
);

export type GetCategoriesQueryVariables = Exact<{
  filters?: Maybe<CategoryFilterInput>;
}>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { categoryList?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & CategoryFieldFragment
  )>>> }
);

export type GetCmsBlocksQueryVariables = Exact<{
  blockIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetCmsBlocksQuery = (
  { __typename?: 'Query' }
  & { cmsBlocks?: Maybe<(
    { __typename?: 'CmsBlocks' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CmsBlock' }
      & Pick<CmsBlock, 'content' | 'identifier' | 'title'>
    )>>> }
  )> }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & CountryFieldsFragment
  )>>> }
);

export type GetCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFieldsFragment
  )> }
);

export type GetWishlistQueryVariables = Exact<{
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetWishlistQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & { wishlists: Array<Maybe<(
      { __typename?: 'Wishlist' }
      & Pick<Wishlist, 'id'>
      & { items_v2?: Maybe<(
        { __typename?: 'WishlistItems' }
        & WishlistItemsV2FieldsFragment
      )> }
    )>> }
  )> }
);

export type GetMpBlogCategoriesQueryVariables = Exact<{
  action: Scalars['String'];
}>;


export type GetMpBlogCategoriesQuery = (
  { __typename?: 'Query' }
  & { mpBlogCategories?: Maybe<(
    { __typename?: 'CategoriesOutput' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'category_id' | 'name' | 'enabled'>
    )>>> }
  )> }
);

export type GetCustomerOrderQueryVariables = Exact<{
  filter?: Maybe<CustomerOrdersFilterInput>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetCustomerOrderQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & { orders?: Maybe<(
      { __typename?: 'CustomerOrders' }
      & OrderFieldsFragment
    )> }
  )> }
);

export type GetProductsLightQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
}>;


export type GetProductsLightQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & { page_info?: Maybe<(
      { __typename?: 'SearchResultPageInfo' }
      & PageInfoFieldFragment
    )>, items?: Maybe<Array<Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductLightFieldFragment
    ) | { __typename?: 'VirtualProduct' }>>> }
  )> }
);

export type GetProductsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
}>;


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & { page_info?: Maybe<(
      { __typename?: 'SearchResultPageInfo' }
      & PageInfoFieldFragment
    )>, items?: Maybe<Array<Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductFieldFragment
    ) | { __typename?: 'VirtualProduct' }>>> }
  )> }
);

export type GetProductDetailQueryVariables = Exact<{
  sku: Scalars['String'];
}>;


export type GetProductDetailQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & { items?: Maybe<Array<Maybe<{ __typename?: 'BundleProduct' } | { __typename?: 'ConfigurableProduct' } | { __typename?: 'DownloadableProduct' } | { __typename?: 'GroupedProduct' } | (
      { __typename?: 'SimpleProduct' }
      & ProductFieldFragment
    ) | { __typename?: 'VirtualProduct' }>>> }
  )> }
);

export const ProductLightFieldFragmentDoc = gql`
    fragment ProductLightField on SimpleProduct {
  name
  manufacturer
  short_description {
    html
  }
  special_price
  special_from_date
  special_to_date
  price_range {
    minimum_price {
      final_price {
        currency
        value
      }
    }
    maximum_price {
      final_price {
        currency
        value
      }
    }
  }
  price {
    regularPrice {
      amount {
        currency
        value
      }
    }
  }
  sku
  id
  uid
  image {
    url
  }
  stock_status
  tempi_consegna
  codice_ean
  bloccoetichetta1
  block_creative
  classe_efficienza_energetica
  classe_efficienza_energetica_l
  classe_energetica_id_cms_block
  classe_energetica_link
  classe_energetica_sheet
  msrp
  custom_options {
    option_id
    required
    sort_order
    title
    uid
    value {
      option_type_id
      price
      price_type
      sku
      title
      sort_order
      uid
    }
  }
}
    `;
export const CartFieldFragmentDoc = gql`
    fragment CartField on Cart {
  applied_coupon {
    code
  }
  applied_coupons {
    code
  }
  available_payment_methods {
    code
    title
  }
  billing_address {
    city
    company
    country {
      code
      label
    }
    customer_notes
    firstname
    lastname
    postcode
    region {
      code
      label
      region_id
    }
    street
    telephone
    customer_type
    codice_fiscale
    sdi_pec
    vat_id
  }
  email
  gift_message {
    from
    message
    to
  }
  id
  is_virtual
  items {
    id
    prices {
      discounts {
        amount {
          currency
          value
        }
        label
      }
      price {
        currency
        value
      }
      row_total {
        currency
        value
      }
      row_total_including_tax {
        currency
        value
      }
      total_item_discount {
        currency
        value
      }
    }
    product {
      ...ProductLightField
    }
    quantity
    selected_customizable_options {
      customizable_option_uid
      id
      is_required
      label
      sort_order
      type
      values {
        customizable_option_value_uid
        id
        label
        price {
          type
          units
          value
        }
        value
      }
    }
    uid
  }
  prices {
    applied_taxes {
      amount {
        currency
        value
      }
      label
    }
    discount {
      amount {
        currency
        value
      }
      label
    }
    discounts {
      amount {
        currency
        value
      }
      label
    }
    grand_total {
      currency
      value
    }
    subtotal_excluding_tax {
      currency
      value
    }
    subtotal_including_tax {
      currency
      value
    }
    subtotal_with_discount_excluding_tax {
      currency
      value
    }
  }
  selected_payment_method {
    code
    purchase_order_number
    title
  }
  shipping_addresses {
    available_shipping_methods {
      amount {
        currency
        value
      }
      available
      carrier_code
      carrier_title
      error_message
      method_code
      method_title
      price_excl_tax {
        currency
        value
      }
      price_incl_tax {
        currency
        value
      }
    }
    cart_items {
      cart_item_id
      quantity
    }
    city
    company
    country {
      code
      label
    }
    customer_notes
    firstname
    items_weight
    lastname
    pickup_location_code
    postcode
    region {
      code
      label
      region_id
    }
    selected_shipping_method {
      amount {
        currency
        value
      }
      carrier_code
      carrier_title
      method_code
      method_title
    }
    street
    telephone
    customer_type
    codice_fiscale
    sdi_pec
    vat_id
  }
  total_quantity
}
    ${ProductLightFieldFragmentDoc}`;
export const CategoryFieldFragmentDoc = gql`
    fragment CategoryField on CategoryTree {
  id
  name
  uid
  description
  filter_price_range
  position
  product_count
  image
}
    `;
export const PageInfoFieldFragmentDoc = gql`
    fragment PageInfoField on SearchResultPageInfo {
  current_page
  page_size
  total_pages
}
    `;
export const CountryFieldsFragmentDoc = gql`
    fragment CountryFields on Country {
  available_regions {
    code
    id
    name
  }
  full_name_locale
  id
  two_letter_abbreviation
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on CustomerAddress {
  city
  region {
    region_id
    region_code
  }
  country_code
  default_billing
  default_shipping
  firstname
  id
  lastname
  postcode
  street
  telephone
  company
  vat_id
  custom_attributes {
    attribute_code
    value
  }
}
    `;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on Customer {
  id
  firstname
  lastname
  email
  is_subscribed
  default_billing
  default_shipping
  addresses {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const OrderFieldsFragmentDoc = gql`
    fragment OrderFields on CustomerOrders {
  page_info {
    current_page
    page_size
    total_pages
  }
  items {
    order_date
    total {
      subtotal {
        value
      }
      grand_total {
        value
      }
    }
    id
    items {
      id
      product_name
      product_sku
      product_url_key
      product_sale_price {
        value
      }
      entered_options {
        label
        value
      }
      status
    }
    number
    status
    billing_address {
      company
      firstname
      lastname
    }
    shipping_address {
      city
      company
      firstname
      lastname
      postcode
      region
      street
      telephone
    }
  }
}
    `;
export const ProductFieldFragmentDoc = gql`
    fragment ProductField on SimpleProduct {
  ...ProductLightField
  description {
    html
  }
  media_gallery_entries {
    position
    disabled
    media_type
    file
  }
  product_links {
    link_type
    linked_product_sku
    linked_product_type
    position
    sku
  }
}
    ${ProductLightFieldFragmentDoc}`;
export const TokenFieldsFragmentDoc = gql`
    fragment TokenFields on CustomerToken {
  token
}
    `;
export const WishlistItemsV2FieldsFragmentDoc = gql`
    fragment WishlistItemsV2Fields on WishlistItems {
  page_info {
    current_page
    page_size
    total_pages
  }
  items {
    added_at
    description
    id
    quantity
    product {
      ...ProductLightField
    }
  }
}
    ${ProductLightFieldFragmentDoc}`;
export const WishlistFieldsFragmentDoc = gql`
    fragment WishlistFields on Wishlist {
  id
  items_v2 {
    ...WishlistItemsV2Fields
  }
}
    ${WishlistItemsV2FieldsFragmentDoc}`;
export const CreateAddressDocument = gql`
    mutation CreateAddress($region_id: Int!, $city: String!, $default_billing: Boolean!, $default_shipping: Boolean!, $firstname: String!, $lastname: String!, $postcode: String!, $street: [String]!, $telephone: String!, $company: String, $vat_id: String, $custom_attributes: [CustomerAddressAttributeInput]) {
  createCustomerAddress(
    input: {region: {region_id: $region_id}, city: $city, country_code: IT, default_billing: $default_billing, default_shipping: $default_shipping, firstname: $firstname, lastname: $lastname, postcode: $postcode, street: $street, telephone: $telephone, company: $company, vat_id: $vat_id, custom_attributes: $custom_attributes}
  ) {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      region_id: // value for 'region_id'
 *      city: // value for 'city'
 *      default_billing: // value for 'default_billing'
 *      default_shipping: // value for 'default_shipping'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      postcode: // value for 'postcode'
 *      street: // value for 'street'
 *      telephone: // value for 'telephone'
 *      company: // value for 'company'
 *      vat_id: // value for 'vat_id'
 *      custom_attributes: // value for 'custom_attributes'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: Int!, $city: String, $country_code: CountryCodeEnum, $region_id: Int, $default_billing: Boolean, $default_shipping: Boolean, $firstname: String, $lastname: String, $postcode: String, $street: [String], $telephone: String, $company: String, $vat_id: String, $custom_attributes: [CustomerAddressAttributeInput]) {
  updateCustomerAddress(
    id: $id
    input: {city: $city, country_code: $country_code, region: {region_id: $region_id}, default_billing: $default_billing, default_shipping: $default_shipping, firstname: $firstname, lastname: $lastname, postcode: $postcode, street: $street, telephone: $telephone, company: $company, vat_id: $vat_id, custom_attributes: $custom_attributes}
  ) {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      city: // value for 'city'
 *      country_code: // value for 'country_code'
 *      region_id: // value for 'region_id'
 *      default_billing: // value for 'default_billing'
 *      default_shipping: // value for 'default_shipping'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      postcode: // value for 'postcode'
 *      street: // value for 'street'
 *      telephone: // value for 'telephone'
 *      company: // value for 'company'
 *      vat_id: // value for 'vat_id'
 *      custom_attributes: // value for 'custom_attributes'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const CreateGuestCartDocument = gql`
    mutation CreateGuestCart {
  createEmptyCart
}
    `;
export type CreateGuestCartMutationFn = Apollo.MutationFunction<CreateGuestCartMutation, CreateGuestCartMutationVariables>;

/**
 * __useCreateGuestCartMutation__
 *
 * To run a mutation, you first call `useCreateGuestCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestCartMutation, { data, loading, error }] = useCreateGuestCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateGuestCartMutation(baseOptions?: Apollo.MutationHookOptions<CreateGuestCartMutation, CreateGuestCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGuestCartMutation, CreateGuestCartMutationVariables>(CreateGuestCartDocument, options);
      }
export type CreateGuestCartMutationHookResult = ReturnType<typeof useCreateGuestCartMutation>;
export type CreateGuestCartMutationResult = Apollo.MutationResult<CreateGuestCartMutation>;
export type CreateGuestCartMutationOptions = Apollo.BaseMutationOptions<CreateGuestCartMutation, CreateGuestCartMutationVariables>;
export const MergeCartsDocument = gql`
    mutation MergeCarts($source: String!, $destination: String!) {
  mergeCarts(source_cart_id: $source, destination_cart_id: $destination) {
    id
  }
}
    `;
export type MergeCartsMutationFn = Apollo.MutationFunction<MergeCartsMutation, MergeCartsMutationVariables>;

/**
 * __useMergeCartsMutation__
 *
 * To run a mutation, you first call `useMergeCartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCartsMutation, { data, loading, error }] = useMergeCartsMutation({
 *   variables: {
 *      source: // value for 'source'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useMergeCartsMutation(baseOptions?: Apollo.MutationHookOptions<MergeCartsMutation, MergeCartsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeCartsMutation, MergeCartsMutationVariables>(MergeCartsDocument, options);
      }
export type MergeCartsMutationHookResult = ReturnType<typeof useMergeCartsMutation>;
export type MergeCartsMutationResult = Apollo.MutationResult<MergeCartsMutation>;
export type MergeCartsMutationOptions = Apollo.BaseMutationOptions<MergeCartsMutation, MergeCartsMutationVariables>;
export const AddToCartDocument = gql`
    mutation AddToCart($sku: String!, $quantity: Float!, $cart: String!, $customizable_options: [CustomizableOptionInput]) {
  addSimpleProductsToCart(
    input: {cart_id: $cart, cart_items: [{data: {quantity: $quantity, sku: $sku}, customizable_options: $customizable_options}]}
  ) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type AddToCartMutationFn = Apollo.MutationFunction<AddToCartMutation, AddToCartMutationVariables>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      sku: // value for 'sku'
 *      quantity: // value for 'quantity'
 *      cart: // value for 'cart'
 *      customizable_options: // value for 'customizable_options'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(AddToCartDocument, options);
      }
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<AddToCartMutation, AddToCartMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($input: UpdateCartItemsInput) {
  updateCartItems(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const RemoveCartItemDocument = gql`
    mutation RemoveCartItem($input: RemoveItemFromCartInput!) {
  removeItemFromCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type RemoveCartItemMutationFn = Apollo.MutationFunction<RemoveCartItemMutation, RemoveCartItemMutationVariables>;

/**
 * __useRemoveCartItemMutation__
 *
 * To run a mutation, you first call `useRemoveCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItemMutation, { data, loading, error }] = useRemoveCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCartItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument, options);
      }
export type RemoveCartItemMutationHookResult = ReturnType<typeof useRemoveCartItemMutation>;
export type RemoveCartItemMutationResult = Apollo.MutationResult<RemoveCartItemMutation>;
export type RemoveCartItemMutationOptions = Apollo.BaseMutationOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>;
export const SetBillingAddressToCartDocument = gql`
    mutation SetBillingAddressToCart($input: SetBillingAddressOnCartInput) {
  setBillingAddressOnCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type SetBillingAddressToCartMutationFn = Apollo.MutationFunction<SetBillingAddressToCartMutation, SetBillingAddressToCartMutationVariables>;

/**
 * __useSetBillingAddressToCartMutation__
 *
 * To run a mutation, you first call `useSetBillingAddressToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillingAddressToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillingAddressToCartMutation, { data, loading, error }] = useSetBillingAddressToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBillingAddressToCartMutation(baseOptions?: Apollo.MutationHookOptions<SetBillingAddressToCartMutation, SetBillingAddressToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBillingAddressToCartMutation, SetBillingAddressToCartMutationVariables>(SetBillingAddressToCartDocument, options);
      }
export type SetBillingAddressToCartMutationHookResult = ReturnType<typeof useSetBillingAddressToCartMutation>;
export type SetBillingAddressToCartMutationResult = Apollo.MutationResult<SetBillingAddressToCartMutation>;
export type SetBillingAddressToCartMutationOptions = Apollo.BaseMutationOptions<SetBillingAddressToCartMutation, SetBillingAddressToCartMutationVariables>;
export const SetShippingAddressToCartDocument = gql`
    mutation SetShippingAddressToCart($input: SetShippingAddressesOnCartInput) {
  setShippingAddressesOnCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type SetShippingAddressToCartMutationFn = Apollo.MutationFunction<SetShippingAddressToCartMutation, SetShippingAddressToCartMutationVariables>;

/**
 * __useSetShippingAddressToCartMutation__
 *
 * To run a mutation, you first call `useSetShippingAddressToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShippingAddressToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShippingAddressToCartMutation, { data, loading, error }] = useSetShippingAddressToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetShippingAddressToCartMutation(baseOptions?: Apollo.MutationHookOptions<SetShippingAddressToCartMutation, SetShippingAddressToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetShippingAddressToCartMutation, SetShippingAddressToCartMutationVariables>(SetShippingAddressToCartDocument, options);
      }
export type SetShippingAddressToCartMutationHookResult = ReturnType<typeof useSetShippingAddressToCartMutation>;
export type SetShippingAddressToCartMutationResult = Apollo.MutationResult<SetShippingAddressToCartMutation>;
export type SetShippingAddressToCartMutationOptions = Apollo.BaseMutationOptions<SetShippingAddressToCartMutation, SetShippingAddressToCartMutationVariables>;
export const SetShippingMethodToCartDocument = gql`
    mutation SetShippingMethodToCart($input: SetShippingMethodsOnCartInput) {
  setShippingMethodsOnCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type SetShippingMethodToCartMutationFn = Apollo.MutationFunction<SetShippingMethodToCartMutation, SetShippingMethodToCartMutationVariables>;

/**
 * __useSetShippingMethodToCartMutation__
 *
 * To run a mutation, you first call `useSetShippingMethodToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShippingMethodToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShippingMethodToCartMutation, { data, loading, error }] = useSetShippingMethodToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetShippingMethodToCartMutation(baseOptions?: Apollo.MutationHookOptions<SetShippingMethodToCartMutation, SetShippingMethodToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetShippingMethodToCartMutation, SetShippingMethodToCartMutationVariables>(SetShippingMethodToCartDocument, options);
      }
export type SetShippingMethodToCartMutationHookResult = ReturnType<typeof useSetShippingMethodToCartMutation>;
export type SetShippingMethodToCartMutationResult = Apollo.MutationResult<SetShippingMethodToCartMutation>;
export type SetShippingMethodToCartMutationOptions = Apollo.BaseMutationOptions<SetShippingMethodToCartMutation, SetShippingMethodToCartMutationVariables>;
export const SetPaymentMethodOnCartDocument = gql`
    mutation SetPaymentMethodOnCart($input: SetPaymentMethodOnCartInput) {
  setPaymentMethodOnCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type SetPaymentMethodOnCartMutationFn = Apollo.MutationFunction<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>;

/**
 * __useSetPaymentMethodOnCartMutation__
 *
 * To run a mutation, you first call `useSetPaymentMethodOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaymentMethodOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaymentMethodOnCartMutation, { data, loading, error }] = useSetPaymentMethodOnCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPaymentMethodOnCartMutation(baseOptions?: Apollo.MutationHookOptions<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>(SetPaymentMethodOnCartDocument, options);
      }
export type SetPaymentMethodOnCartMutationHookResult = ReturnType<typeof useSetPaymentMethodOnCartMutation>;
export type SetPaymentMethodOnCartMutationResult = Apollo.MutationResult<SetPaymentMethodOnCartMutation>;
export type SetPaymentMethodOnCartMutationOptions = Apollo.BaseMutationOptions<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>;
export const ApplyCouponToCartDocument = gql`
    mutation ApplyCouponToCart($input: ApplyCouponToCartInput) {
  applyCouponToCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type ApplyCouponToCartMutationFn = Apollo.MutationFunction<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>;

/**
 * __useApplyCouponToCartMutation__
 *
 * To run a mutation, you first call `useApplyCouponToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponToCartMutation, { data, loading, error }] = useApplyCouponToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyCouponToCartMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>(ApplyCouponToCartDocument, options);
      }
export type ApplyCouponToCartMutationHookResult = ReturnType<typeof useApplyCouponToCartMutation>;
export type ApplyCouponToCartMutationResult = Apollo.MutationResult<ApplyCouponToCartMutation>;
export type ApplyCouponToCartMutationOptions = Apollo.BaseMutationOptions<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>;
export const RemoveCouponFromCartDocument = gql`
    mutation RemoveCouponFromCart($input: RemoveCouponFromCartInput) {
  removeCouponFromCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type RemoveCouponFromCartMutationFn = Apollo.MutationFunction<RemoveCouponFromCartMutation, RemoveCouponFromCartMutationVariables>;

/**
 * __useRemoveCouponFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveCouponFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponFromCartMutation, { data, loading, error }] = useRemoveCouponFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCouponFromCartMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCouponFromCartMutation, RemoveCouponFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCouponFromCartMutation, RemoveCouponFromCartMutationVariables>(RemoveCouponFromCartDocument, options);
      }
export type RemoveCouponFromCartMutationHookResult = ReturnType<typeof useRemoveCouponFromCartMutation>;
export type RemoveCouponFromCartMutationResult = Apollo.MutationResult<RemoveCouponFromCartMutation>;
export type RemoveCouponFromCartMutationOptions = Apollo.BaseMutationOptions<RemoveCouponFromCartMutation, RemoveCouponFromCartMutationVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($input: PlaceOrderInput) {
  placeOrder(input: $input) {
    order {
      order_number
    }
  }
}
    `;
export type PlaceOrderMutationFn = Apollo.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: Apollo.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, options);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const CreatePaypalExpressTokenDocument = gql`
    mutation CreatePaypalExpressToken($input: PaypalExpressTokenInput!) {
  createPaypalExpressToken(input: $input) {
    token
    paypal_urls {
      start
      edit
    }
  }
}
    `;
export type CreatePaypalExpressTokenMutationFn = Apollo.MutationFunction<CreatePaypalExpressTokenMutation, CreatePaypalExpressTokenMutationVariables>;

/**
 * __useCreatePaypalExpressTokenMutation__
 *
 * To run a mutation, you first call `useCreatePaypalExpressTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaypalExpressTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaypalExpressTokenMutation, { data, loading, error }] = useCreatePaypalExpressTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaypalExpressTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaypalExpressTokenMutation, CreatePaypalExpressTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaypalExpressTokenMutation, CreatePaypalExpressTokenMutationVariables>(CreatePaypalExpressTokenDocument, options);
      }
export type CreatePaypalExpressTokenMutationHookResult = ReturnType<typeof useCreatePaypalExpressTokenMutation>;
export type CreatePaypalExpressTokenMutationResult = Apollo.MutationResult<CreatePaypalExpressTokenMutation>;
export type CreatePaypalExpressTokenMutationOptions = Apollo.BaseMutationOptions<CreatePaypalExpressTokenMutation, CreatePaypalExpressTokenMutationVariables>;
export const SetGuestEmailOnCartDocument = gql`
    mutation SetGuestEmailOnCart($input: SetGuestEmailOnCartInput!) {
  setGuestEmailOnCart(input: $input) {
    cart {
      ...CartField
    }
  }
}
    ${CartFieldFragmentDoc}`;
export type SetGuestEmailOnCartMutationFn = Apollo.MutationFunction<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>;

/**
 * __useSetGuestEmailOnCartMutation__
 *
 * To run a mutation, you first call `useSetGuestEmailOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGuestEmailOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGuestEmailOnCartMutation, { data, loading, error }] = useSetGuestEmailOnCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGuestEmailOnCartMutation(baseOptions?: Apollo.MutationHookOptions<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>(SetGuestEmailOnCartDocument, options);
      }
export type SetGuestEmailOnCartMutationHookResult = ReturnType<typeof useSetGuestEmailOnCartMutation>;
export type SetGuestEmailOnCartMutationResult = Apollo.MutationResult<SetGuestEmailOnCartMutation>;
export type SetGuestEmailOnCartMutationOptions = Apollo.BaseMutationOptions<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: CustomerInput!) {
  createCustomer(input: $input) {
    customer {
      ...CustomerFields
    }
  }
}
    ${CustomerFieldsFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const ChangeCustomerPasswordDocument = gql`
    mutation ChangeCustomerPassword($currentPassword: String!, $newPassword: String!) {
  changeCustomerPassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;
export type ChangeCustomerPasswordMutationFn = Apollo.MutationFunction<ChangeCustomerPasswordMutation, ChangeCustomerPasswordMutationVariables>;

/**
 * __useChangeCustomerPasswordMutation__
 *
 * To run a mutation, you first call `useChangeCustomerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCustomerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCustomerPasswordMutation, { data, loading, error }] = useChangeCustomerPasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangeCustomerPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCustomerPasswordMutation, ChangeCustomerPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCustomerPasswordMutation, ChangeCustomerPasswordMutationVariables>(ChangeCustomerPasswordDocument, options);
      }
export type ChangeCustomerPasswordMutationHookResult = ReturnType<typeof useChangeCustomerPasswordMutation>;
export type ChangeCustomerPasswordMutationResult = Apollo.MutationResult<ChangeCustomerPasswordMutation>;
export type ChangeCustomerPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeCustomerPasswordMutation, ChangeCustomerPasswordMutationVariables>;
export const ResetCustomerPasswordDocument = gql`
    mutation ResetCustomerPassword($email: String!) {
  requestPasswordResetEmail(email: $email)
}
    `;
export type ResetCustomerPasswordMutationFn = Apollo.MutationFunction<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>;

/**
 * __useResetCustomerPasswordMutation__
 *
 * To run a mutation, you first call `useResetCustomerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCustomerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCustomerPasswordMutation, { data, loading, error }] = useResetCustomerPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetCustomerPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>(ResetCustomerPasswordDocument, options);
      }
export type ResetCustomerPasswordMutationHookResult = ReturnType<typeof useResetCustomerPasswordMutation>;
export type ResetCustomerPasswordMutationResult = Apollo.MutationResult<ResetCustomerPasswordMutation>;
export type ResetCustomerPasswordMutationOptions = Apollo.BaseMutationOptions<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation DeleteCustomer($token: String!) {
  delete_customer(token: $token) {
    response
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const GenerateTokenLoginDocument = gql`
    mutation GenerateTokenLogin($email: String!, $password: String!) {
  generateCustomerToken(email: $email, password: $password) {
    ...TokenFields
  }
}
    ${TokenFieldsFragmentDoc}`;
export type GenerateTokenLoginMutationFn = Apollo.MutationFunction<GenerateTokenLoginMutation, GenerateTokenLoginMutationVariables>;

/**
 * __useGenerateTokenLoginMutation__
 *
 * To run a mutation, you first call `useGenerateTokenLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTokenLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTokenLoginMutation, { data, loading, error }] = useGenerateTokenLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGenerateTokenLoginMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTokenLoginMutation, GenerateTokenLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTokenLoginMutation, GenerateTokenLoginMutationVariables>(GenerateTokenLoginDocument, options);
      }
export type GenerateTokenLoginMutationHookResult = ReturnType<typeof useGenerateTokenLoginMutation>;
export type GenerateTokenLoginMutationResult = Apollo.MutationResult<GenerateTokenLoginMutation>;
export type GenerateTokenLoginMutationOptions = Apollo.BaseMutationOptions<GenerateTokenLoginMutation, GenerateTokenLoginMutationVariables>;
export const RevokeCustomerTokenDocument = gql`
    mutation revokeCustomerToken {
  revokeCustomerToken {
    result
  }
}
    `;
export type RevokeCustomerTokenMutationFn = Apollo.MutationFunction<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>;

/**
 * __useRevokeCustomerTokenMutation__
 *
 * To run a mutation, you first call `useRevokeCustomerTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCustomerTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCustomerTokenMutation, { data, loading, error }] = useRevokeCustomerTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeCustomerTokenMutation(baseOptions?: Apollo.MutationHookOptions<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>(RevokeCustomerTokenDocument, options);
      }
export type RevokeCustomerTokenMutationHookResult = ReturnType<typeof useRevokeCustomerTokenMutation>;
export type RevokeCustomerTokenMutationResult = Apollo.MutationResult<RevokeCustomerTokenMutation>;
export type RevokeCustomerTokenMutationOptions = Apollo.BaseMutationOptions<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>;
export const AddWishlistItemDocument = gql`
    mutation AddWishlistItem($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
  addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
    user_errors {
      message
      code
    }
    wishlist {
      ...WishlistFields
    }
  }
}
    ${WishlistFieldsFragmentDoc}`;
export type AddWishlistItemMutationFn = Apollo.MutationFunction<AddWishlistItemMutation, AddWishlistItemMutationVariables>;

/**
 * __useAddWishlistItemMutation__
 *
 * To run a mutation, you first call `useAddWishlistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWishlistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWishlistItemMutation, { data, loading, error }] = useAddWishlistItemMutation({
 *   variables: {
 *      wishlistId: // value for 'wishlistId'
 *      wishlistItems: // value for 'wishlistItems'
 *   },
 * });
 */
export function useAddWishlistItemMutation(baseOptions?: Apollo.MutationHookOptions<AddWishlistItemMutation, AddWishlistItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWishlistItemMutation, AddWishlistItemMutationVariables>(AddWishlistItemDocument, options);
      }
export type AddWishlistItemMutationHookResult = ReturnType<typeof useAddWishlistItemMutation>;
export type AddWishlistItemMutationResult = Apollo.MutationResult<AddWishlistItemMutation>;
export type AddWishlistItemMutationOptions = Apollo.BaseMutationOptions<AddWishlistItemMutation, AddWishlistItemMutationVariables>;
export const RemoveWishlistItemDocument = gql`
    mutation RemoveWishlistItem($wishlistId: ID!, $wishlistItemsIds: [ID!]!) {
  removeProductsFromWishlist(
    wishlistId: $wishlistId
    wishlistItemsIds: $wishlistItemsIds
  ) {
    user_errors {
      message
      code
    }
    wishlist {
      ...WishlistFields
    }
  }
}
    ${WishlistFieldsFragmentDoc}`;
export type RemoveWishlistItemMutationFn = Apollo.MutationFunction<RemoveWishlistItemMutation, RemoveWishlistItemMutationVariables>;

/**
 * __useRemoveWishlistItemMutation__
 *
 * To run a mutation, you first call `useRemoveWishlistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWishlistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWishlistItemMutation, { data, loading, error }] = useRemoveWishlistItemMutation({
 *   variables: {
 *      wishlistId: // value for 'wishlistId'
 *      wishlistItemsIds: // value for 'wishlistItemsIds'
 *   },
 * });
 */
export function useRemoveWishlistItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWishlistItemMutation, RemoveWishlistItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWishlistItemMutation, RemoveWishlistItemMutationVariables>(RemoveWishlistItemDocument, options);
      }
export type RemoveWishlistItemMutationHookResult = ReturnType<typeof useRemoveWishlistItemMutation>;
export type RemoveWishlistItemMutationResult = Apollo.MutationResult<RemoveWishlistItemMutation>;
export type RemoveWishlistItemMutationOptions = Apollo.BaseMutationOptions<RemoveWishlistItemMutation, RemoveWishlistItemMutationVariables>;
export const GetCartDocument = gql`
    query GetCart($id: String!) {
  cart(cart_id: $id) {
    ...CartField
  }
}
    ${CartFieldFragmentDoc}`;

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartQuery(baseOptions: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
      }
export function useGetCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
        }
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>;
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>;
export type GetCartQueryResult = Apollo.QueryResult<GetCartQuery, GetCartQueryVariables>;
export function refetchGetCartQuery(variables?: GetCartQueryVariables) {
      return { query: GetCartDocument, variables: variables }
    }
export const GetCustomerCartIdDocument = gql`
    query GetCustomerCartID {
  customerCart {
    id
  }
}
    `;

/**
 * __useGetCustomerCartIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerCartIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCartIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCartIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerCartIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerCartIdQuery, GetCustomerCartIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerCartIdQuery, GetCustomerCartIdQueryVariables>(GetCustomerCartIdDocument, options);
      }
export function useGetCustomerCartIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerCartIdQuery, GetCustomerCartIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerCartIdQuery, GetCustomerCartIdQueryVariables>(GetCustomerCartIdDocument, options);
        }
export type GetCustomerCartIdQueryHookResult = ReturnType<typeof useGetCustomerCartIdQuery>;
export type GetCustomerCartIdLazyQueryHookResult = ReturnType<typeof useGetCustomerCartIdLazyQuery>;
export type GetCustomerCartIdQueryResult = Apollo.QueryResult<GetCustomerCartIdQuery, GetCustomerCartIdQueryVariables>;
export function refetchGetCustomerCartIdQuery(variables?: GetCustomerCartIdQueryVariables) {
      return { query: GetCustomerCartIdDocument, variables: variables }
    }
export const GetCategoriesDocument = gql`
    query GetCategories($filters: CategoryFilterInput) {
  categoryList(filters: $filters) {
    ...CategoryField
  }
}
    ${CategoryFieldFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export function refetchGetCategoriesQuery(variables?: GetCategoriesQueryVariables) {
      return { query: GetCategoriesDocument, variables: variables }
    }
export const GetCmsBlocksDocument = gql`
    query getCmsBlocks($blockIds: [String]!) {
  cmsBlocks(identifiers: $blockIds) {
    items {
      content
      identifier
      title
    }
  }
}
    `;

/**
 * __useGetCmsBlocksQuery__
 *
 * To run a query within a React component, call `useGetCmsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCmsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCmsBlocksQuery({
 *   variables: {
 *      blockIds: // value for 'blockIds'
 *   },
 * });
 */
export function useGetCmsBlocksQuery(baseOptions: Apollo.QueryHookOptions<GetCmsBlocksQuery, GetCmsBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCmsBlocksQuery, GetCmsBlocksQueryVariables>(GetCmsBlocksDocument, options);
      }
export function useGetCmsBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCmsBlocksQuery, GetCmsBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCmsBlocksQuery, GetCmsBlocksQueryVariables>(GetCmsBlocksDocument, options);
        }
export type GetCmsBlocksQueryHookResult = ReturnType<typeof useGetCmsBlocksQuery>;
export type GetCmsBlocksLazyQueryHookResult = ReturnType<typeof useGetCmsBlocksLazyQuery>;
export type GetCmsBlocksQueryResult = Apollo.QueryResult<GetCmsBlocksQuery, GetCmsBlocksQueryVariables>;
export function refetchGetCmsBlocksQuery(variables?: GetCmsBlocksQueryVariables) {
      return { query: GetCmsBlocksDocument, variables: variables }
    }
export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    ...CountryFields
  }
}
    ${CountryFieldsFragmentDoc}`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export function refetchGetCountriesQuery(variables?: GetCountriesQueryVariables) {
      return { query: GetCountriesDocument, variables: variables }
    }
export const GetCustomerDocument = gql`
    query GetCustomer {
  customer {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export function refetchGetCustomerQuery(variables?: GetCustomerQueryVariables) {
      return { query: GetCustomerDocument, variables: variables }
    }
export const GetWishlistDocument = gql`
    query GetWishlist($currentPage: Int, $pageSize: Int) {
  customer {
    wishlists {
      items_v2(currentPage: $currentPage, pageSize: $pageSize) {
        ...WishlistItemsV2Fields
      }
      id
    }
  }
}
    ${WishlistItemsV2FieldsFragmentDoc}`;

/**
 * __useGetWishlistQuery__
 *
 * To run a query within a React component, call `useGetWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishlistQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetWishlistQuery(baseOptions?: Apollo.QueryHookOptions<GetWishlistQuery, GetWishlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWishlistQuery, GetWishlistQueryVariables>(GetWishlistDocument, options);
      }
export function useGetWishlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWishlistQuery, GetWishlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWishlistQuery, GetWishlistQueryVariables>(GetWishlistDocument, options);
        }
export type GetWishlistQueryHookResult = ReturnType<typeof useGetWishlistQuery>;
export type GetWishlistLazyQueryHookResult = ReturnType<typeof useGetWishlistLazyQuery>;
export type GetWishlistQueryResult = Apollo.QueryResult<GetWishlistQuery, GetWishlistQueryVariables>;
export function refetchGetWishlistQuery(variables?: GetWishlistQueryVariables) {
      return { query: GetWishlistDocument, variables: variables }
    }
export const GetMpBlogCategoriesDocument = gql`
    query GetMpBlogCategories($action: String!) {
  mpBlogCategories(action: $action) {
    items {
      category_id
      name
      enabled
    }
  }
}
    `;

/**
 * __useGetMpBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMpBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMpBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMpBlogCategoriesQuery({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useGetMpBlogCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetMpBlogCategoriesQuery, GetMpBlogCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMpBlogCategoriesQuery, GetMpBlogCategoriesQueryVariables>(GetMpBlogCategoriesDocument, options);
      }
export function useGetMpBlogCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMpBlogCategoriesQuery, GetMpBlogCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMpBlogCategoriesQuery, GetMpBlogCategoriesQueryVariables>(GetMpBlogCategoriesDocument, options);
        }
export type GetMpBlogCategoriesQueryHookResult = ReturnType<typeof useGetMpBlogCategoriesQuery>;
export type GetMpBlogCategoriesLazyQueryHookResult = ReturnType<typeof useGetMpBlogCategoriesLazyQuery>;
export type GetMpBlogCategoriesQueryResult = Apollo.QueryResult<GetMpBlogCategoriesQuery, GetMpBlogCategoriesQueryVariables>;
export function refetchGetMpBlogCategoriesQuery(variables?: GetMpBlogCategoriesQueryVariables) {
      return { query: GetMpBlogCategoriesDocument, variables: variables }
    }
export const GetCustomerOrderDocument = gql`
    query GetCustomerOrder($filter: CustomerOrdersFilterInput, $currentPage: Int, $pageSize: Int) {
  customer {
    orders(filter: $filter, currentPage: $currentPage, pageSize: $pageSize) {
      ...OrderFields
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useGetCustomerOrderQuery__
 *
 * To run a query within a React component, call `useGetCustomerOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOrderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCustomerOrderQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>(GetCustomerOrderDocument, options);
      }
export function useGetCustomerOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>(GetCustomerOrderDocument, options);
        }
export type GetCustomerOrderQueryHookResult = ReturnType<typeof useGetCustomerOrderQuery>;
export type GetCustomerOrderLazyQueryHookResult = ReturnType<typeof useGetCustomerOrderLazyQuery>;
export type GetCustomerOrderQueryResult = Apollo.QueryResult<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>;
export function refetchGetCustomerOrderQuery(variables?: GetCustomerOrderQueryVariables) {
      return { query: GetCustomerOrderDocument, variables: variables }
    }
export const GetProductsLightDocument = gql`
    query GetProductsLight($search: String, $filter: ProductAttributeFilterInput, $pageSize: Int, $currentPage: Int, $sort: ProductAttributeSortInput) {
  products(
    search: $search
    filter: $filter
    pageSize: $pageSize
    currentPage: $currentPage
    sort: $sort
  ) {
    page_info {
      ...PageInfoField
    }
    items {
      ...ProductLightField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ProductLightFieldFragmentDoc}`;

/**
 * __useGetProductsLightQuery__
 *
 * To run a query within a React component, call `useGetProductsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsLightQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      currentPage: // value for 'currentPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProductsLightQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsLightQuery, GetProductsLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsLightQuery, GetProductsLightQueryVariables>(GetProductsLightDocument, options);
      }
export function useGetProductsLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsLightQuery, GetProductsLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsLightQuery, GetProductsLightQueryVariables>(GetProductsLightDocument, options);
        }
export type GetProductsLightQueryHookResult = ReturnType<typeof useGetProductsLightQuery>;
export type GetProductsLightLazyQueryHookResult = ReturnType<typeof useGetProductsLightLazyQuery>;
export type GetProductsLightQueryResult = Apollo.QueryResult<GetProductsLightQuery, GetProductsLightQueryVariables>;
export function refetchGetProductsLightQuery(variables?: GetProductsLightQueryVariables) {
      return { query: GetProductsLightDocument, variables: variables }
    }
export const GetProductsDocument = gql`
    query getProducts($search: String, $filter: ProductAttributeFilterInput, $pageSize: Int, $currentPage: Int, $sort: ProductAttributeSortInput) {
  products(
    search: $search
    filter: $filter
    pageSize: $pageSize
    currentPage: $currentPage
    sort: $sort
  ) {
    page_info {
      ...PageInfoField
    }
    items {
      ...ProductField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ProductFieldFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      currentPage: // value for 'currentPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export function refetchGetProductsQuery(variables?: GetProductsQueryVariables) {
      return { query: GetProductsDocument, variables: variables }
    }
export const GetProductDetailDocument = gql`
    query getProductDetail($sku: String!) {
  products(search: $sku, pageSize: 1, currentPage: 1) {
    items {
      ...ProductField
    }
  }
}
    ${ProductFieldFragmentDoc}`;

/**
 * __useGetProductDetailQuery__
 *
 * To run a query within a React component, call `useGetProductDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductDetailQuery(baseOptions: Apollo.QueryHookOptions<GetProductDetailQuery, GetProductDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductDetailQuery, GetProductDetailQueryVariables>(GetProductDetailDocument, options);
      }
export function useGetProductDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductDetailQuery, GetProductDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductDetailQuery, GetProductDetailQueryVariables>(GetProductDetailDocument, options);
        }
export type GetProductDetailQueryHookResult = ReturnType<typeof useGetProductDetailQuery>;
export type GetProductDetailLazyQueryHookResult = ReturnType<typeof useGetProductDetailLazyQuery>;
export type GetProductDetailQueryResult = Apollo.QueryResult<GetProductDetailQuery, GetProductDetailQueryVariables>;
export function refetchGetProductDetailQuery(variables?: GetProductDetailQueryVariables) {
      return { query: GetProductDetailDocument, variables: variables }
    }