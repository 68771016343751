import React from 'react';
import { LogoApple, LogoFacebook } from 'react-ionicons';
import BTButton from '../../../core/bt_button/BTButton';

type SocialLoginButtonProps = { onClick: () => void };

function SocialLoginButton(props: SocialLoginButtonProps) {
  const { onClick } = props;
  return (
    <BTButton
      className={`btn-block`}
      variant="outline-primary"
      onPress={onClick}
    >
      Login
      <LogoFacebook
        cssClasses={'ml-2 mr-2'}
        color={'#00000'}
        title={'facebook'}
      />
      <LogoApple color={'#00000'} title={'apple'} />
    </BTButton>
  );
}

export default SocialLoginButton;
