import omit from 'lodash.omit';
import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './BTGreenForm.module.scss';

function BTGreenForm(props: React.ComponentProps<typeof Form>) {
  return (
    <Form
      {...omit(props, ['className'])}
      className={`${styles.greenForm} ${props.className}`}
    />
  );
}

export default BTGreenForm;
