import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Cart } from '../../core/apollo/gql-generate';
import PriceText from '../../core/price_text/PriceText';
import styles from './CartOrderSummary.module.scss';
import EnergyRating from '../energy_rating/EnergyRating';
import ProductPrice from '../product_price/ProductPrice';

function CartOrderSummary({
  cart,
  orderNumber,
}: {
  cart?: Cart;
  orderNumber?: string;
}) {
  const { t } = useTranslation();

  const shippingPrice = useMemo(() => {
    const shipping = cart?.shipping_addresses[0];
    if (!isNil(shipping)) {
      const code = shipping?.selected_shipping_method?.method_code;
      if (code) {
        const sh = shipping.available_shipping_methods?.find(
          s => s?.method_code === code
        );
        return sh ? sh.price_incl_tax.value ?? 0 : 0;
      }
    }
    return 0;
  }, [cart]);
  return (
    <div>
      {orderNumber && (
        <>
          <Row>
            <Col className={`${styles.summaryText}`}>
              Riepilogo ordine #{orderNumber}
            </Col>
          </Row>
          <Row>
            <Col className={`${styles.summaryText}`}>E-mail: {cart?.email}</Col>
          </Row>
        </>
      )}
      {cart && (
        <div id="cart-summary">
          {cart.items?.map(item => (
            <div key={item?.uid} className={`border-bottom py-3`}>
              <Row className={``}>
                <Col className={`col-3`}>
                  <img
                    className={`${styles.image}`}
                    src={item?.product.image?.url}
                    alt="icon"
                  />
                </Col>
                <Col className={`col-7`}>{item?.product.name}</Col>
                <Col
                  className={`col-2 font-weight-bold text-right`}
                >{`x${item?.quantity}`}</Col>
                {item?.selected_customizable_options.map((opt, index) => (
                  <>
                    {index === 0 && (
                      <Col className={`offset-3 col-9 font-weight-bold pt-2`}>
                        {t('common.extraServices')}
                      </Col>
                    )}
                    {opt?.values.map(value => (
                      <Col className={`offset-3 col-9`}>
                        {`+ ${value?.label}`}
                      </Col>
                    ))}
                  </>
                ))}
              </Row>
              <Row className={`pr-5`}>
                <Col className="pr-0">
                  <EnergyRating product={item?.product!} justifyContent="end" />
                  <ProductPrice
                    product={item?.product!}
                    size="xs"
                    advisedDirection="row"
                    justifyContent="end"
                    historyTooltips={false}
                    priceColor="var(--text)"
                  />
                </Col>
              </Row>
            </div>
          ))}
          <Row className={`mt-3`}>
            <>
              <Col className={`col-6`}>{t('common.partialTot')}</Col>
              <Col className={`col-6 text-right`}>
                <PriceText
                  value={cart.prices?.subtotal_including_tax?.value || 0}
                />
              </Col>
            </>
            <>
              <Col className={`col-6`}>{t('common.shipping')}</Col>
              <Col className={`col-6 text-right`}>
                <PriceText value={shippingPrice} />
              </Col>
            </>
            {cart.prices?.discounts?.map(item => (
              <>
                <Col className={`col-6`}>{t('common.discount')}</Col>
                <Col className={`col-6 text-right`}>
                  <PriceText value={(item?.amount.value || 0) * -1} />
                </Col>
              </>
            ))}
            <>
              <Col className={`col-6 font-weight-bold`}>
                {t(`common.total`)} {t(`common.taxIncluded`)}
              </Col>
              <Col className={`col-6 text-right font-weight-bold`}>
                <PriceText value={cart.prices?.grand_total?.value || 0} />
              </Col>
            </>
          </Row>
        </div>
      )}
    </div>
  );
}

export default CartOrderSummary;
