import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { AxervePaymentRedirect } from '../models/Cart';
import MpBlog from '../models/MpBlog';
import ProductsFilterType from '../models/ProductsFilterRes';

const BASE_URL = '/rest/Bytecno/V1';
const BASE_URL_MOBILE = `${BASE_URL}/mobile`;

export async function createAxervePayment(
  cartId: string,
  guest: boolean
): Promise<AxervePaymentRedirect | null> {
  const { data }: { data: AxervePaymentRedirect[] } = await axios.post(
    `${BASE_URL_MOBILE}/${
      !guest ? 'initAxervePayment' : 'initAxervePaymentGuest'
    }`,
    {
      cart_id: cartId,
    }
  );

  return isEmpty(data) ? null : data[0];
}

export async function getOrderTracking({
  orderNumber,
  customerEmail,
}: {
  orderNumber: string;
  customerEmail: string;
}) {
  const { data }: { data: any[] } = await axios.get(
    `${BASE_URL_MOBILE}/getOrderTracking`,
    { params: { orderNumber, customerEmail } }
  );

  return isEmpty(data) ? null : data[0];
}

export async function getCategoryApiFilter(
  id: string
): Promise<ProductsFilterType[]> {
  const response = await axios.get(`${BASE_URL_MOBILE}/getCategoryFilter`, {
    params: { categoryId: id },
  });

  return isEmpty(response.data) ? [] : response.data;
}
export async function getSearchApiFilter(
  keyword: string,
  categoryId?: string
): Promise<ProductsFilterType[]> {
  const response = await axios.get(`${BASE_URL_MOBILE}/getSearchFilter`, {
    params: { keyword, categoryId },
  });

  return isEmpty(response.data) ? [] : response.data;
}

export async function getMpBlogList(): Promise<MpBlog[]> {
  const response = await axios.get(`${BASE_URL}/mpblog/post/list`, {
    params: {
      'searchCriteria[sortOrders][0][field]': 'publish_date',
      'searchCriteria[sortOrders][0][direction]': 'DESC',
    },
  });
  return isEmpty(response.data?.items) ? [] : response.data.items;
}

export async function getDockIcons({
  currentPage,
  parameterUrl,
}: {
  currentPage: string;
  parameterUrl: string;
}): Promise<string[]> {
  const response = await axios.get(`${BASE_URL_MOBILE}/dockRoutes`, {
    params: { parameterUrl, currentPage },
  });
  return isEmpty(response.data) ? [] : response.data;
}

export async function meAccount(): Promise<any> {
  return await axios.get(`${BASE_URL}/customers/me`);
}

export async function getVersion(): Promise<{ version: string }> {
  const { data } = await axios.get(`${window.location.origin}/app-version`);
  return data;
}
