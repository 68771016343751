import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import {
  CmsBlocks,
  ProductFieldFragment,
  useGetCmsBlocksLazyQuery,
  useGetProductDetailQuery,
} from '../core/apollo/gql-generate';

export type ProductInfoApi = {
  id: string;
  sku: string;
  availabilityInformation: string;
  deliveryInformation?: any;
  stockprogress: number;
  shippingInformation: string;
  shippingInformationNote: string;
  rmaInformation: string;
  warrantyInformation: string;
  cartaDocenteInformation: string;
  boseInformation: string;
  boseInformationNote: string;
  msrp: string;
  manufacturer: string;
};

export type ProductDetailData = {
  info?: ProductInfoApi;
  product?: ProductFieldFragment;
  cmsBlocks?: CmsBlocks;
};

export const GARANZIA_BLOCK = 'block_product_tab1';
export const RAEE_BLOCK = 'block_product_tab2';

async function getProductApiInfo(sku: string): Promise<ProductInfoApi> {
  const response = await axios.get(
    '/rest/Bytecno/V1/mobile/getProductPageData',
    {
      params: { sku },
    }
  );

  return isEmpty(response.data) ? null : response.data[0];
}

function useProductDetails({ sku }: { sku: string }) {
  const [data, setData] = useState<ProductDetailData>({} as ProductDetailData);

  const [loading, setLoading] = useState(true);

  const [getBlocks] = useGetCmsBlocksLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setData(prev => ({ ...prev, cmsBlocks: data.cmsBlocks }));
      setLoading(false);
    },
    onError: err => setLoading(false),
  });

  const { data: products } = useGetProductDetailQuery({
    variables: {
      sku,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const { products } = data;
      getProductApiInfo(sku).then(info => {
        const product = !isEmpty(products?.items)
          ? (products!.items![0] as ProductFieldFragment)
          : undefined;
        setData(prev => ({
          product,
          info,
        }));
        getBlocks({
          variables: {
            blockIds: [
              GARANZIA_BLOCK,
              RAEE_BLOCK,
              ...(product?.bloccoetichetta1 ? [product?.bloccoetichetta1] : []),
            ],
          },
        });
      });
    },
  });

  useEffect(() => {
    const product = !isEmpty(products?.products?.items)
      ? (products?.products?.items![0] as ProductFieldFragment)
      : undefined;
    setData(prev => ({ ...prev, product: product }));
  }, [products]);

  return { data, loading };
}

export default useProductDetails;
