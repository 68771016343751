import { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function getBreakpoint() {
    const { width } = size;
    if (width < 576) {
      return 'sm';
    } else if (width < 768) {
      return 'md';
    } else if (width < 992) {
      return 'lg';
    } else {
      return 'xl';
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { size, getBreakpoint };
};

export default useWindowSize;
