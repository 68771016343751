import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

type BTButtonProps = Omit<ButtonProps, 'onClick'> & {
  onPress?: () => void;
  pressDelay?: number;
  unstyled?: boolean;
};

function BTButton(props: BTButtonProps) {
  const { onPress, pressDelay = 150, unstyled = false, ...buttonProps } = props;

  function handleClick() {
    setTimeout(() => {
      onPress && onPress();
    }, pressDelay);
  }

  if (unstyled) {
    return (
      <button className={props.className} onClick={handleClick}>
        {props.children}
      </button>
    );
  } else {
    return (
      <Button {...buttonProps} onClick={handleClick}>
        {props.children}
      </Button>
    );
  }
}

export default BTButton;
