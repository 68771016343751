import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  CountryFieldsFragment,
  useGetCountriesQuery,
} from '../../core/apollo/gql-generate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import BTButton from '../../core/bt_button/BTButton';
export enum AddressFormCountryEnum {
  IT = 'IT',
}
export type AddressFormValueType = {
  firstname: string;
  lastname: string;
  region_id: number;
  city: string;
  country_id: AddressFormCountryEnum;
  default_billing: boolean;
  default_shipping: boolean;
  postcode: string;
  street: string | undefined;
  telephone: string;
  company?: string;
  vat_id?: string;
  customer_type: string;
  codice_fiscale: string;
  sdi_pec: string;
  cf_invoce?: string;
};

type AddressFromValidation = {
  [key in keyof AddressFormValueType]: yup.AnySchema;
};

export type AddressFormPropsType = {
  initialValues: AddressFormValueType;
  onSubmit: (value: AddressFormValueType) => void;
  showDefaultBillding?: boolean;
  showDefaultShipping?: boolean;
  showCfInvoce?: boolean;
  submitText?: string;
  showCustomerTypeCheck?: boolean;
  customButton?: JSX.Element;
};

function AddressForm(props: AddressFormPropsType) {
  const { t } = useTranslation();
  const {
    initialValues,
    onSubmit,
    showDefaultBillding = true,
    showDefaultShipping = true,
    showCfInvoce = false,
    submitText = t('common.save'),
    showCustomerTypeCheck = true,
    customButton = <></>,
  } = props;

  const [countries, setCountries] = useState<CountryFieldsFragment[]>([]);

  const [selectedCountry, setSelectedCountry] = useState(
    {} as CountryFieldsFragment
  );

  const formik = useFormik<AddressFormValueType>({
    initialValues,
    validationSchema: yup.object().shape<AddressFromValidation>({
      city: yup.string().required(t('formMessage.required')),
      codice_fiscale: yup
        .string()
        .matches(
          /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/,
          t('formMessage.cf')
        )
        .when('customer_type', {
          is: '0',
          then: s =>
            s.when('cf_invoce', {
              is: '1',
              then: s => s.required(t('formMessage.required')),
            }),
        }),
      company: yup.string().when('customer_type', {
        is: '1',
        then: s => s.required(t('formMessage.required')),
      }),
      country_id: yup
        .string()
        .notOneOf(['-1', null, undefined, '', '0'], t('formMessage.required'))
        .required(t('formMessage.required')),
      customer_type: yup.string().required(t('formMessage.required')),
      cf_invoce: yup
        .string()
        .default('0')
        .when('customer_type', {
          is: '0',
          then: s => s.required(t('formMessage.required')),
        }),
      default_billing: yup.boolean(),
      default_shipping: yup.boolean(),
      firstname: yup.string().required(t('formMessage.required')),
      lastname: yup.string().required(t('formMessage.required')),
      postcode: yup
        .string()
        .matches(/^[0-9]{5}$/, t('formMessage.postcode'))
        .required(t('formMessage.required')),
      region_id: yup
        .string()
        .notOneOf(['-1', null, undefined, '', '0'], t('formMessage.required'))
        .required(t('formMessage.required')),
      sdi_pec: yup.string(),
      street: yup.string().required(t('formMessage.required')),
      telephone: yup
        .string()
        .matches(/^[0-9]*$/, t('formMessage.telephone'))
        .required(t('formMessage.required')),
      vat_id: yup
        .string()
        .matches(/^[0-9]{11}$/, t('formMessage.vat_id'))
        .when('customer_type', {
          is: '1',
          then: s => s.required(t('formMessage.required')),
        }),
    }),
    onSubmit: values => {
      onSubmit(
        values.customer_type === '0'
          ? { ...values, company: undefined, vat_id: undefined }
          : values
      );
    },
  });

  useGetCountriesQuery({
    onCompleted: data => {
      setCountries(data?.countries as CountryFieldsFragment[]);
    },
  });

  useEffect(() => {
    if (initialValues.country_id) {
      onSelectedCountryHandler(initialValues.country_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  function onSelectedCountryHandler(value: string) {
    setSelectedCountry(
      countries?.find(c => c.id === value) as CountryFieldsFragment
    );
  }

  return (
    <BTGreenForm onSubmit={formik.handleSubmit}>
      <Form.Group hidden={!showCustomerTypeCheck}>
        <Form.Check
          name="customer_type"
          type="radio"
          label={'Privato'}
          id={`radio-1`}
          value={'0'}
          checked={formik.values.customer_type === '0'}
          defaultChecked={true}
          onChange={event => formik.handleChange(event)}
        />
        <Form.Check
          className="mb-3"
          name="customer_type"
          type="radio"
          label={'Azienda'}
          id={`radio-2`}
          value={'1'}
          defaultChecked={false}
          checked={formik.values.customer_type === '1'}
          onChange={event => formik.handleChange(event)}
        />
      </Form.Group>

      <Form.Group
        hidden={!(showCfInvoce && formik.values.customer_type === '0')}
      >
        <span>{t('formMessage.cf_invoce')}</span>
        <Form.Check
          name="cf_invoce"
          type="radio"
          label={'No'}
          id={`radio-no-invoce`}
          value={'0'}
          checked={formik.values.cf_invoce === '0'}
          defaultChecked={true}
          onChange={event => formik.handleChange(event)}
        />
        <Form.Check
          className="mb-3"
          name="cf_invoce"
          type="radio"
          label={'Si'}
          id={`radio-ye-invoce`}
          value={'1'}
          defaultChecked={false}
          checked={formik.values.cf_invoce === '1'}
          onChange={event => formik.handleChange(event)}
        />
      </Form.Group>

      {formik.values.customer_type === '1' && (
        <>
          <Form.Group>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="company"
                value={formik.values.company}
                placeholder={t('addressCreation.company')}
                onChange={event => formik.handleChange(event)}
                required={false}
                isInvalid={!!formik.errors.company}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.company}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="vat_id"
                value={formik.values.vat_id}
                placeholder={t('addressCreation.vat_id')}
                onChange={event => formik.handleChange(event)}
                required={false}
                isInvalid={!!formik.errors.vat_id}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.vat_id}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </>
      )}
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="firstname"
            placeholder={`${t('addressCreation.firstName')}*`}
            value={formik.values.firstname}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.firstname}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.firstname}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="lastname"
            value={formik.values.lastname}
            placeholder={`${t('addressCreation.lastName')}*`}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.lastname}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.lastname}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="codice_fiscale"
            value={formik.values.codice_fiscale}
            placeholder={t('addressCreation.cf')}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.codice_fiscale}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.codice_fiscale}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          name="sdi_pec"
          value={formik.values.sdi_pec}
          placeholder={t('addressCreation.sdi_pec')}
          onChange={event => formik.handleChange(event)}
          required={false}
        />
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            name="telephone"
            type="text"
            value={formik.values.telephone}
            placeholder={`${t('addressCreation.telephone')}*`}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.telephone}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.telephone}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            as="select"
            name="country"
            isInvalid={!!formik.errors.country_id}
            onChange={e => {
              onSelectedCountryHandler(e.target.value);
              formik.handleChange(e);
            }}
          >
            <option value="-1">Seleziona nazione</option>
            {countries.map((county, index) => (
              <option
                key={index}
                value={county.id}
                selected={county.id === initialValues?.country_id}
              >
                {county.full_name_locale}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {formik.errors.country_id}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            as="select"
            name="region_id"
            disabled={!selectedCountry}
            onChange={e => formik.handleChange(e)}
            isInvalid={!!formik.errors.region_id}
          >
            <option value="-1">Seleziona provincia</option>
            {selectedCountry?.available_regions?.map((region, index) => (
              <option
                key={index}
                value={region?.id}
                selected={region?.id === initialValues.region_id}
              >
                {region?.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {formik.errors.region_id}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="city"
            value={formik.values.city}
            placeholder={`${t('addressCreation.city')}*`}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.city}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.city}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="street"
            value={formik.values.street}
            placeholder={`${t('addressCreation.street')}*`}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.street}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.street}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="postcode"
            value={formik.values.postcode}
            placeholder={`${t('addressCreation.postcode')}*`}
            onChange={event => formik.handleChange(event)}
            isInvalid={!!formik.errors.postcode}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.postcode}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          name="default_billing"
          checked={formik.values.default_billing}
          label={showDefaultBillding && t('addressModify.checkBoxBilling')}
          onChange={event => formik.handleChange(event)}
          hidden={!showDefaultBillding}
        />
        <Form.Check
          type="checkbox"
          name="default_shipping"
          checked={formik.values.default_shipping}
          label={showDefaultShipping && t('addressModify.checkBoxShipping')}
          onChange={event => formik.handleChange(event)}
          hidden={!showDefaultShipping}
        />
      </Form.Group>
      <BTButton className={`btn-block mt-5`} variant="primary" type="submit">
        {submitText}
      </BTButton>
      {customButton}
    </BTGreenForm>
  );
}

export default AddressForm;
