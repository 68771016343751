import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrderSummaryCookie } from '../../models/Cookies';
import AddressCard from '../address_card/AddressCard';
import styles from './OrderConfirm.module.scss';
export type OrderConfirmProps = {
  order: OrderSummaryCookie;
};

const OrderConfirm = (props: OrderConfirmProps) => {
  const { order } = props;

  const { firstname, lastname } = order.summary.billing_address!;
  const shipping = order.summary.shipping_addresses![0]!;
  const { email } = order.summary;

  const { t } = useTranslation();
  return (
    <div>
      {order && (
        <>
          <Row>
            <Col className={`col-12 ${styles.summaryText}`}>{`${t(
              'order.number'
            )} ${order.number}`}</Col>
            <Col className={`col-12`}>
              {firstname} {lastname}
            </Col>
            <Col className={`col-12`}>{email}</Col>
            <Col className={`col-12`}>
              <AddressCard
                address={{
                  shipping: true,
                  billing: false,
                  city: shipping.city,
                  street: shipping.street[0],
                  postcode: shipping.postcode,
                  regionCode: shipping.region?.code,
                  firstname: shipping.firstname,
                  lastname: shipping.lastname,
                }}
                showTitle={`${t('order.shippingData')}`}
              />
            </Col>
          </Row>
          <Row>
            <Col>{t('order.successMessage')}</Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default OrderConfirm;
