import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { SearchOutline } from 'react-ionicons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import logo from '../../logo.svg';
import styles from './Header.module.scss';
import isEmpty from 'lodash.isempty';

function Header() {
  const history = useHistory();

  const HEADER_MOBILE_BUTTONS = 'mobile_header-buttons';

  const { blocks } = useCmdBlocks([HEADER_MOBILE_BUTTONS]);

  const [searchText, setSearchText] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    pathname !== '/products' && setSearchText('');
  }, [pathname]);

  function searchHandler(ev: any) {
    ev.preventDefault();
    history.push({
      pathname: `/products-search/${searchText}`,
    });
  }

  return (
    <>
      <BTContainer className={`${styles.header}`}>
        <Row>
          <Col className={`text-center ${styles.logo}`}>
            <Link to={`/home`}>
              <img src={logo} width="300" height="27" alt="logo_bytecno" />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={searchHandler}>
              <InputGroup className={`mb-3 ${styles.search}`}>
                <FormControl
                  className={`${styles.input}`}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Cerca prodotto"
                  value={searchText}
                  onChange={(event: any) => setSearchText(event.target.value)}
                />
                <InputGroup.Text
                  className={`${styles.icon}`}
                  type="submit"
                  as={Button}
                >
                  <SearchOutline height="24px" width="24px" />
                </InputGroup.Text>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        {!isEmpty(blocks) && (
          <BTHtmlWrapper content={blocks[HEADER_MOBILE_BUTTONS]} />
        )}
      </BTContainer>
    </>
  );
}

export default Header;
