import { useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import LoginButton from '../../components/login-button/LoginButton';
import { tokenIdVar } from '../../core/apollo/Cache';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import { useCart } from '../../core/contexts/CartContext';
import BillingData from './components/billing_data/BillingData';
import { Confirm } from './components/confirm/Confirm';
import { PaymentsData } from './components/payment_data/PaymentData';
import ShippingData from './components/shipping_data/ShippingData';
import StepTitle from './components/StepTitle/StepTitle';

function Checkout() {
  const { t } = useTranslation();

  const tokenVar = useReactiveVar(tokenIdVar);

  const { cart } = useCart();

  const [accrodion, setAccrodion] = useState<{
    activeStep: number;
    complitedStep: number;
  }>({ activeStep: 0, complitedStep: 0 });

  const [isNotValidEmail, setIsNotValidEmail] = useState<boolean>(false);

  function step2Disable() {
    return isEmpty(cart.billing_address);
  }
  function step3Disable() {
    return (
      step2Disable() ||
      isEmpty(cart.shipping_addresses) ||
      isEmpty(cart.shipping_addresses[0]?.selected_shipping_method)
    );
  }
  function step4Disable() {
    return (
      step3Disable() ||
      isEmpty(cart.selected_payment_method) ||
      isEmpty(cart.selected_payment_method?.code)
    );
  }

  const steps = [
    {
      title: t(`checkout.billingData.title`),
      component: <BillingData />,
      disabled: isNotValidEmail || (isEmpty(tokenVar) && isEmpty(cart.email)),
    },
    {
      title: t(`checkout.shippingData.title`),
      component: <ShippingData />,
      disabled: isNotValidEmail || step2Disable(),
    },
    {
      title: t(`checkout.payment.title`),
      component: <PaymentsData />,
      disabled: isNotValidEmail || step3Disable(),
    },
    {
      title: t(`checkout.confirm.title`),
      component: <Confirm />,
      disabled: isNotValidEmail || step4Disable(),
    },
  ] as { component: JSX.Element; title: string; disabled: boolean }[];

  useEffect(() => {
    if (!isEmpty(tokenVar) || cart.email) {
      setIsNotValidEmail(false);
    }
  }, [tokenVar, cart]);

  useEffect(() => {
    let toActive = 0;
    steps.forEach((step, index) => {
      if (!step.disabled) {
        toActive = index;
      }
    });
    setAccrodion({
      activeStep: toActive,
      complitedStep: toActive,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const { activeStep, complitedStep } = accrodion;

    let toActive = -10;
    steps.forEach((step, index) => {
      if (!step.disabled) {
        toActive = index;
      }
    });
    if (
      (activeStep === -10 && toActive > -1) ||
      (toActive - 1 === activeStep && complitedStep < toActive)
    ) {
      setAccrodion({
        activeStep: toActive,
        complitedStep: toActive,
      });
    } else if (toActive === -10 && activeStep !== -10) {
      setAccrodion(prev => ({
        ...prev,
        activeStep: -10,
      }));
    }
  });

  return (
    <>
      {!isEmpty(cart) && (
        <>
          {isEmpty(tokenVar) && (
            <BTContainer className={`mt-3`}>
              <UserData
                email={cart.email}
                onEmailFormChange={email =>
                  setIsNotValidEmail(email !== cart.email)
                }
              />
            </BTContainer>
          )}
          {(!isEmpty(tokenVar) || !isEmpty(cart.email)) && (
            <Accordion
              defaultActiveKey={`${accrodion.complitedStep}`}
              className={`mt-4 mx-2`}
              activeKey={`${accrodion.activeStep}`}
            >
              {steps.map((step, index) => (
                <Card className={`border-0`} key={index}>
                  <Accordion.Toggle
                    className={`p-0`}
                    as={Button}
                    variant={''}
                    eventKey={index.toString()}
                    disabled={step.disabled}
                    onClick={() =>
                      setAccrodion(prev => ({
                        ...prev,
                        activeStep: prev.activeStep === index ? -1 : index,
                      }))
                    }
                  >
                    <StepTitle title={step.title} number={index + 1} />
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={index.toString()}
                    className={`my-3`}
                  >
                    {step.component}
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          )}
        </>
      )}
    </>
  );
}
Checkout.displayName = 'Checkout';
export default Checkout;

type UserDataProps = {
  email?: string;
  onEmailFormChange?: (email: string) => void;
};

function UserData(props: UserDataProps) {
  const { onEmailFormChange } = props;
  const { setGuestEmailOnCart } = useCart();
  const { t } = useTranslation();
  type FormType = { email: string };
  type FormValidationType = { [key in keyof FormType]: yup.AnySchema };

  const formik = useFormik<FormType>({
    initialValues: { email: props.email || '' },
    validationSchema: yup.object().shape<FormValidationType>({
      email: yup.string().email(t('formMessage.email')),
    }),
    onSubmit: ({ email }) => {
      email && setGuestEmailOnCart(email);
    },
  });

  return (
    <BTGreenForm onSubmit={formik.handleSubmit}>
      <InputGroup hasValidation>
        <Form.Control
          type="email"
          placeholder="Inserisci email"
          name="email"
          value={formik.values.email}
          onChange={event => {
            formik.handleChange(event);
            onEmailFormChange && onEmailFormChange(event.target.value);
          }}
          isInvalid={!!formik.errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.email}
        </Form.Control.Feedback>
      </InputGroup>
      {props.email !== formik.values.email && (
        <>
          <BTButton
            className={`mt-2 btn-block`}
            variant="primary"
            type="submit"
          >
            Conferma
          </BTButton>
          <div className={`d-flex justify-content-center mt-2`}>
            --- oppure ---
          </div>
        </>
      )}
      {props.email === formik.values.email && <hr />}
      <LoginButton className={'mt-2 btn-block'} />
      <hr />
    </BTGreenForm>
  );
}
