import React, { useEffect } from 'react';
import BTContainer from '../../core/bt_container/BTContainer';

function AxerveCancel() {
  useEffect(() => {}, []);

  return (
    <BTContainer className="my-4">
      Qualcosa è andato storto con Axerve. Riprovare o contatta l'assistenza.
    </BTContainer>
  );
}
AxerveCancel.displayName = 'AxerveCancel';
export default AxerveCancel;
