import { useReactiveVar } from '@apollo/client';
import isEmpty from 'lodash.isempty';
import pickBy from 'lodash.pickby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HeartCircleOutline } from 'react-ionicons';
import { useParams } from 'react-router-dom';
import ProductCarousel from '../../components/product-carousel/ProductCarousel';
import ProductExtraServices, {
  ProductExtraServicesValues,
} from '../../components/product-extra-services/ProductExtraServices';
import SmallProductCard from '../../components/small_product_card/SmallProductCard';
import { tokenIdVar } from '../../core/apollo/Cache';
import {
  CustomizableOptionInput,
  ProductFieldFragment,
  ProductLightFieldFragment,
  WishlistFieldsFragment,
  refetchGetWishlistQuery,
  useAddWishlistItemMutation,
  useGetProductsLightLazyQuery,
  useGetWishlistLazyQuery,
  useRemoveWishlistItemMutation,
} from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTCarousel from '../../core/bt_carousel/BTCarousel';
import BTContainer from '../../core/bt_container/BTContainer';
import BTList from '../../core/bt_list/BTList';
import BTModal from '../../core/bt_modal/BTModal';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import { useCart } from '../../core/contexts/CartContext';
import { addSuccesNotification } from '../../core/notification/Notification';
import PriceText from '../../core/price_text/PriceText';
import useProductDetails, {
  GARANZIA_BLOCK,
  ProductDetailData,
  RAEE_BLOCK,
} from '../../hooks/useProductDetails';
import styles from './ProductDetail.module.scss';
import ProductPrice from '../../components/product_price/ProductPrice';
import EnergyRating from '../../components/energy_rating/EnergyRating';

export type ImageType = {
  img: any;
};

function ProductDetail() {
  const { sku: skuEncoded } = useParams<{ sku: string }>();
  const sku = decodeURIComponent(skuEncoded);

  const { t } = useTranslation();
  const tokenVar = useReactiveVar(tokenIdVar);

  const { data: productDetails, loading } = useProductDetails({ sku });

  const [relatedProduct, setReleatedProduct] = useState(
    [] as ProductLightFieldFragment[]
  );
  const [upsellProduct, setUpsellProduct] = useState(
    [] as ProductLightFieldFragment[]
  );
  const [wishlist, setWishlist] = useState({} as WishlistFieldsFragment);

  const [getRelatedProduct] = useGetProductsLightLazyQuery({
    onCompleted: data => {
      const res = data.products?.items;
      if (res) {
        setReleatedProduct(res as ProductLightFieldFragment[]);
      }
    },
  });

  // useScript(productDetails.product?.block_creative);

  const [addWishlist] = useAddWishlistItemMutation({
    refetchQueries: [refetchGetWishlistQuery()],
  });
  const [removeItem] = useRemoveWishlistItemMutation({
    refetchQueries: [refetchGetWishlistQuery()],
  });

  const [getUpsellProduct] = useGetProductsLightLazyQuery({
    onCompleted: data => {
      const res = data.products?.items;
      if (res) {
        setUpsellProduct(res as ProductLightFieldFragment[]);
      }
    },
  });

  const [getWishlist] = useGetWishlistLazyQuery({
    onCompleted: data => {
      setWishlist(data.customer?.wishlists[0]!);
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (tokenVar) {
      getWishlist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenVar]);

  useEffect(() => {
    const { product } = productDetails;
    if (product && !loading) {
      retriveRelatedProducts(product);
      retriveUpsellProducts(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails, loading]);

  function retriveUpsellProducts(product: ProductFieldFragment) {
    const skusUpsell: string[] =
      product?.product_links
        ?.filter(i => i!.link_type === 'upsell')
        .map(p => p!.linked_product_sku!) || [];

    !isEmpty(skusUpsell) &&
      getUpsellProduct({
        variables: {
          search: skusUpsell.reduce(
            (acc, curr: string) => `${acc} ${curr}`,
            ''
          ),
        },
      });
  }

  function retriveRelatedProducts(product: ProductFieldFragment) {
    const skusRelated =
      product?.product_links
        ?.filter(i => i!.link_type === 'related')
        .map(p => p!.linked_product_sku!) || [];

    !isEmpty(skusRelated) &&
      getRelatedProduct({
        variables: {
          search: skusRelated.reduce(
            (acc, curr: string) => `${acc} ${curr}`,
            ''
          ),
        },
      });
  }

  async function addProductToWishlist() {
    if (!isProductInWishlist()) {
      try {
        await addWishlist({
          variables: {
            wishlistId: wishlist?.id!,
            wishlistItems: [
              {
                sku,
                quantity: 1,
              },
            ],
          },
        });
        addSuccesNotification({
          title: 'Aggiunto ai preferiti',
          message: 'prodotto aggiunto alla lista preferiti',
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const wishlistItem = wishlist?.items_v2?.items.find(
          p => (p?.product as ProductFieldFragment).sku === sku
        );
        await removeItem({
          variables: {
            wishlistId: wishlist?.id!,
            wishlistItemsIds: [wishlistItem?.id!],
          },
        });
        addSuccesNotification({
          message: 'Prodotto rimosso dalla wishlist',
          title: 'Prodotto rimosso',
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  function isProductInWishlist() {
    const isPresent = wishlist?.items_v2?.items.find(
      p => (p?.product as ProductFieldFragment).sku === sku
    );
    return !isEmpty(isPresent);
  }

  const getHtmlBlock = useCallback(
    (identifier: string) => {
      return productDetails.cmsBlocks?.items?.find(
        b => b?.identifier === identifier
      )?.content;
    },
    [productDetails]
  );

  return (
    <>
      {!loading && !isEmpty(productDetails.product) && (
        <BTContainer className={`mt-3`}>
          <Row>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.product?.name}
            </Col>
          </Row>
          {/*productDetails.product?.special_to_date &&
            new Date(productDetails.product?.special_to_date) >= new Date() && (
              <Row>
                <Col>
                  <OfferBanner
                    end={new Date(productDetails.product?.special_to_date)}
                  />
                </Col>
              </Row>
            )*/}
          <Row>
            <Col className={'text-center'}>
              <ProductCarousel
                height={240}
                imagesSrc={
                  productDetails.product?.media_gallery_entries?.map(
                    (i: any) =>
                      `${process.env.REACT_APP_MAGENTO_SERVER}/media/catalog/product${i.file}`
                  ) || []
                }
              />
              {productDetails?.product?.bloccoetichetta1 && (
                <BTHtmlWrapper
                  className={`${styles.etichetta}`}
                  style={{ position: 'absolute' }}
                  content={getHtmlBlock(
                    productDetails.product?.bloccoetichetta1
                  )}
                />
              )}
              <BTButton
                hidden={isEmpty(wishlist)}
                variant="outline-link"
                onPress={addProductToWishlist}
                className={`${styles.wishlistButton}`}
                style={{
                  position: 'absolute',
                }}
              >
                <HeartCircleOutline
                  color={
                    isProductInWishlist() ? 'var(--primary)' : 'var(--border)'
                  }
                  title={'addToFavourite'}
                  height="50px"
                  width="50px"
                />
              </BTButton>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={2} className={`${styles.boldDescription}`}>
              {t('productDetail.availability')}
            </Col>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.info?.availabilityInformation || '-'}
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={2} className={`${styles.boldDescription}`}>
              {t('productDetail.delivery')}
            </Col>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.info?.deliveryInformation ||
                productDetails.product?.tempi_consegna ||
                '-'}
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={2} className={`${styles.boldDescription}`}>
              {t('common.shipping')}:
            </Col>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.info?.shippingInformationNote || '-'}
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={2} className={`${styles.boldDescription}`}>
              {t('productDetail.return')}
            </Col>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.info?.rmaInformation || '-'}
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={2} className={`${styles.boldDescription}`}>
              {t('productDetail.coverage')}
            </Col>
            <Col className={`${styles.normalDescription}`}>
              {productDetails.info?.warrantyInformation || '-'}
            </Col>
          </Row>
          {!isEmpty(relatedProduct) && (
            <>
              <Row>
                <Col className={`text-center mt-4 ${styles.relatedProducts}`}>
                  {t('productDetail.addAccessory')}
                </Col>
              </Row>
              <Row>
                <BTCarousel
                  className={`px-2`}
                  itemClassName={`${styles.container} p-2`}
                  items={relatedProduct}
                  renderItem={item => <SmallProductCard product={item} />}
                />
              </Row>
            </>
          )}
          <AddToCartBox className="mt-4" product={productDetails.product!} />
          {!isEmpty(productDetails.product?.block_creative) && (
            <BTHtmlWrapper
              content={`<div>BLOCCO CREATIVE ${productDetails.product?.block_creative?.replaceAll(
                '\\"',
                '"'
              )}</div>`}
            />
          )}
          <InfosAccordion productDetails={productDetails} />
          {!isEmpty(upsellProduct) && (
            <>
              <Row className={`mt-4 ${styles.relatedProducts}`}>
                <Col>{t('productDetail.relatedProducts')}</Col>
              </Row>
              <Row>
                <BTList
                  itemClassName={`p-2`}
                  items={upsellProduct}
                  renderItem={item => (
                    <SmallProductCard key={item.sku} product={item} />
                  )}
                  numerOfRowElement={2}
                />
              </Row>
            </>
          )}
        </BTContainer>
      )}
    </>
  );
}

function AddToCartBox(props: {
  product: ProductFieldFragment;
  className?: string;
}) {
  const { className = '', product } = props;

  const { t } = useTranslation();

  const { addToCart } = useCart();

  const [options, setOptions] = useState<ProductExtraServicesValues>({});

  const [openExtraServicesModal, setOpenExtraServicesModal] =
    useState<boolean>(false);

  const raeeOption = useMemo(
    () => product.custom_options?.find(c => c?.title?.toUpperCase() === 'RAEE'),
    [product]
  );
  const notRaeeOptions = useMemo(
    () =>
      product.custom_options?.filter(c => c?.title?.toUpperCase() !== 'RAEE'),
    [product]
  );

  const addToCartHandler = (
    addOpts: typeof options,
    withOut: boolean = false
  ) => {
    addOpts = pickBy(addOpts, (_, k) => (withOut ? false : true));
    const opts = addOpts
      ? Object.keys(addOpts).map<CustomizableOptionInput>(key => ({
          value_string: addOpts[key],
          id: Number(key),
        }))
      : undefined;
    const needOpenModal =
      opts?.length === 1 && !isEmpty(notRaeeOptions)
        ? raeeOption?.option_id === opts[0].id
        : isEmpty(opts) && !isEmpty(notRaeeOptions);
    if (needOpenModal && !openExtraServicesModal) {
      setOpenExtraServicesModal(() => true);
    } else {
      setOpenExtraServicesModal(() => false);
      addToCart(product.sku!, 1, opts);
    }
    setOptions(() => addOpts ?? {});
  };

  return (
    <div>
      <BTContainer
        className={`py-3 ${styles.addToCartBox} text-center ${className}`}
      >
        <Row>
          <Col>{product.name}</Col>
        </Row>
        <Row className={`py-3`}>
          <Col className={`col-12`}>
            <EnergyRating product={product} />
            <ProductPrice product={product} size="lg" />
          </Col>
        </Row>
        {!openExtraServicesModal && (
          <ProductExtraServices
            values={options}
            customOptions={product.custom_options}
            onChange={setOptions}
          />
        )}
        <Row className="pt-3">
          <Col>
            <BTButton
              className={`${styles.buttonBox}`}
              variant="secondary"
              onPress={() => addToCartHandler(options, false)}
            >
              {t('common.addToCart').toUpperCase()}
            </BTButton>
          </Col>
        </Row>
      </BTContainer>
      <BTModal
        closeButton
        onClose={() => setOpenExtraServicesModal(false)}
        show={openExtraServicesModal}
        title={<b> {t('common.extraServices')}</b>}
        body={
          <ProductExtraServices
            values={options}
            customOptions={product.custom_options}
            onChange={setOptions}
          />
        }
        footer={
          <>
            <BTButton
              variant="secondary"
              onPress={() => addToCartHandler(options, false)}
              size="sm"
            >
              {t('common.addToCart').toUpperCase()}
            </BTButton>
            <span>{t('common.or')}</span>
            <BTButton
              variant="outline-secondary"
              onPress={() => addToCartHandler(options, true)}
              size="sm"
            >
              {t('common.goWithoutToCart').toUpperCase()}
            </BTButton>
          </>
        }
      />
    </div>
  );
}

function InfosAccordion({
  productDetails,
}: {
  productDetails: ProductDetailData;
}) {
  const { product, info, cmsBlocks } = productDetails;

  const moreInfoData = useMemo<
    {
      key: string;
      value: string;
      type?: 'text' | 'price';
    }[]
  >(
    () => [
      {
        key: 'SKU',
        value: product?.sku || '-',
      },
      {
        key: 'Codice EAN',
        value: product?.codice_ean || '-',
      },
      {
        key: 'Marca',
        value: info?.manufacturer || '-',
      },
      {
        key: 'Prezzo mercato',
        value: info?.msrp || '0',
        type: 'price',
      },
      {
        key: 'Reso prodotti',
        value: info?.rmaInformation || '-',
      },
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    [product, info, cmsBlocks]
  );

  const infos = useMemo<
    { title: string; html?: string; element?: React.ReactNode }[]
  >(
    () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        {
          title: 'Descrizione',
          html: product?.description?.html,
        },
        {
          title: 'Maggiori informazioni',
          element: (
            <Table
              striped
              hover
              responsive
              size="sm"
              className={`${styles.moreInfo}`}
            >
              <tbody>
                {moreInfoData.map(({ key, value, type }) => (
                  <tr key={key}>
                    <td className="font-weight-bold">{key}</td>
                    <td>
                      {type !== 'price' && <div>{value}</div>}
                      {type === 'price' && <PriceText value={value} />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
        {
          title: 'Garanzia',
          html:
            cmsBlocks?.items?.find(i => i?.identifier === GARANZIA_BLOCK)
              ?.content || '',
        },
        {
          title: 'RAEE',
          html:
            cmsBlocks?.items?.find(i => i?.identifier === RAEE_BLOCK)
              ?.content || '',
        },
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product, info, cmsBlocks]
  );

  return (
    <Accordion className={`mt-4 ${styles.infosAccordion}`}>
      {infos.map((item, index) => (
        <Card key={index} className={`${styles.accordionHeader}`}>
          <Card.Header
            className={`${styles.accordionHeaderContent} border-bottom `}
          >
            <Accordion.Toggle
              className={`border-bottom pt-2 pb-2`}
              as={BTContainer}
              eventKey={`${index}`}
            >
              {item.title}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse className={`border-bottom`} eventKey={`${index}`}>
            <Card.Body>
              <BTHtmlWrapper content={item.html} />
              {item.element}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
}

ProductDetail.displayName = 'ProductDetail';
export default ProductDetail;
