import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styles from './Area.module.scss';

function Area({
  name,
  icon,
  link,
}: {
  name: string;
  icon: string;
  link: string;
}) {
  const history = useHistory();

  return (
    <Row
      className={`mr-1 mb-3 ${styles.box}`}
      onClick={() => history.push(link)}
    >
      <Col className={'text-center'} xs={2} md={1}>
        <img src={icon} height="40" width="40" alt={`icon ${name}`} />
      </Col>
      <Col className={`pr-0 pl-1`} xs={10} md={11}>
        <div className={`d-flex align-items-center ${styles.areaName}`}>
          <span>{name}</span>
        </div>
      </Col>
    </Row>
  );
}

export default Area;
