import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { matchPath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { appStateVar, tokenIdVar } from '../../core/apollo/Cache';
import { mergeAppState } from '../../core/apollo/operations/mutations/appState';
import BTButton from '../../core/bt_button/BTButton';
import { useCart } from '../../core/contexts/CartContext';
import useRestApi from '../../hooks/useRestApi';
import routes from '../../routers';
import globalStyles from '../../scss/Global.module.scss';
import { getDockIcons } from '../../services/services';
import LoginRegisterModal from '../login_register_modal/LoginRegisterModal';
import styles from './Dock.module.scss';
import { nanoid } from 'nanoid';
import useIsIOS from '../../hooks/useIsIOS';

type CTADockItem = {
  name: string;
  icon: string;
  onClick: () => void;
  size: number;
  badge?: any;
  className?: string;
};

const ICONS_CDN_URL = process.env.REACT_APP_ICONS_CDN_URL;
const UID = encodeURI(nanoid());

function Dock() {
  const history = useHistory();

  const { pathname, search } = useLocation();

  const { cartSize } = useCart();

  const tokenVar = useReactiveVar(tokenIdVar);

  const appState = useReactiveVar(appStateVar);

  const { isIOS } = useIsIOS();

  const { showCategoryFilter } = appState;
  const mergeAppStateDispatch = mergeAppState(appState, appStateVar);

  const [show, setShow] = useState(false);

  const route = routes.find(({ path }) => !!matchPath(pathname, path));
  const { data, refetch } = useRestApi(() => {
    const componentName =
      (route?.component as any)?.displayName || route?.component?.name || '';
    const parameterUrl =
      componentName === 'CmsBlocks'
        ? `${route?.path}${search || ''}`
        : `${route?.path}`;
    return getDockIcons({
      currentPage: componentName,
      parameterUrl,
    });
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  const ctaDockItems: CTADockItem[] = [
    {
      name: 'Area Personale',
      icon: `${ICONS_CDN_URL}/icon_area_personale.svg?e=${UID}`,
      size: 30,
      onClick: () => (tokenVar ? history.push('/accounts') : setShow(true)),
    },
    {
      name: 'Assistenza',
      icon: `${ICONS_CDN_URL}/icon_assistance.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_assistance'),
    },
    {
      name: 'Carrello',
      icon: `${ICONS_CDN_URL}/icon_cart.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cart'),
      badge: (
        <>
          {cartSize > 0 && (
            <Badge pill variant="primary" className="badge-bytecno">
              {cartSize}
            </Badge>
          )}
        </>
      ),
    },
    {
      name: 'Menu',
      icon: `${ICONS_CDN_URL}/icon_main_menu.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_cta-menu'),
    },
    {
      name: 'Contattaci - Block content',
      icon: `${ICONS_CDN_URL}/icon_contact.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_contattaci'),
    },
    {
      name: 'Info e Condizioni - Block content',
      icon: `${ICONS_CDN_URL}/icon_conditions.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_info_condizioni'),
    },
    {
      name: 'Whishlist',
      icon: `${ICONS_CDN_URL}/icon_wishlist.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/accounts/wishlist'),
    },
    {
      name: 'Visti di recente',
      icon: `${ICONS_CDN_URL}/icon_last_viewed.svg?e=${UID}`,
      size: 30,
      onClick: () =>
        history.push('/cms-blocks?blocks=mobile_cta-vistidirecente'),
    },
    {
      name: 'Aggiungi al carrello',
      icon: `${ICONS_CDN_URL}/icon_add_tocart.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks'),
    },
    {
      name: 'Offerte - Block content',
      icon: `${ICONS_CDN_URL}/icon_offers.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_cta-offerte'),
    },
    {
      name: 'Completa ordine',
      icon: `${ICONS_CDN_URL}/icon_close_order.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/Checkout'),
    },
    {
      name: 'Completa pagamento',
      icon: `${ICONS_CDN_URL}/icon_close-payment.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks'),
    },
    {
      name: 'Aggiungi ai preferiti',
      icon: `${ICONS_CDN_URL}/icon_add_towish.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks'),
    },
    {
      name: 'Best Seller',
      icon: `${ICONS_CDN_URL}/icon_bestseller.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/cms-blocks?blocks=mobile_cta-bestseller'),
    },
    {
      name: 'Track',
      icon: `${ICONS_CDN_URL}/icon_track.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/trackings/info'),
    },
    {
      name: 'Filtro',
      icon: `${ICONS_CDN_URL}/icon_filter${
        showCategoryFilter ? 'ok' : ''
      }.svg?e=${UID}`,
      size: 30,
      onClick: () =>
        mergeAppStateDispatch({ showCategoryFilter: !showCategoryFilter }),
    },
    {
      name: 'Home',
      icon: `${ICONS_CDN_URL}/icon_home.svg?e=${UID}`,
      size: 30,
      onClick: () => history.push('/home'),
    },
    {
      name: 'Back',
      icon: `${ICONS_CDN_URL}/icon_back.svg?e=${UID}`,
      size: 30,
      onClick: () => history.goBack(),
    },
  ];

  const ctaFilterdItems = data
    ?.map(v => ctaDockItems.find(cta => cta.name === v))
    .filter(v => v !== undefined);
  //ctaFilterdItems?.splice(2, 0, dynamicCTA());

  return (
    <>
      <LoginRegisterModal show={show} onClose={() => setShow(false)} />
      <div className={`${styles.phantom}`} />
      <div className={`${styles.dock} ${isIOS && styles['dock-ios']}`}>
        <div className="d-flex justify-content-around">
          {ctaFilterdItems?.map((cta, index) => {
            if (cta) {
              const path = new URL(cta.icon).pathname;
              const btnClassName = `btn-bytecno-${path
                .split('/')
                .reverse()[0]
                .replace('.svg', '')
                .replaceAll('_', '-')}`;
              return (
                <div key={index}>
                  {cta && (
                    <div className={`${globalStyles.noPadding}`}>
                      <BTButton
                        className={`btn-bytecno ${btnClassName} ${styles.button}`}
                        onPress={() => cta.onClick && cta.onClick()}
                        unstyled
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            src={cta.icon}
                            width={`${cta.size}`}
                            height={`${cta.size}`}
                            alt={`${cta.name} icon`}
                          />
                        </div>
                        {cta.badge}
                      </BTButton>
                    </div>
                  )}
                </div>
              );
            }
            return <></>;
          })}
        </div>
      </div>
    </>
  );
}

export default Dock;
