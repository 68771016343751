import React from 'react';
import { Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import BTContainer from '../../core/bt_container/BTContainer';
import styles from './ProductCarousel.module.scss';

function ProductCarousel({
  imagesSrc,
  height,
  width,
}: {
  imagesSrc: string[];
  height?: number;
  width?: number;
}) {
  return (
    <BTContainer>
      <Swiper
        className="bt-swiper-container"
        pagination={{
          clickable: true,
        }}
        loop={true}
      >
        {imagesSrc.map((value, index) => (
          <SwiperSlide key={index}>
            <Image className={`${styles.image}`} src={value} />
          </SwiperSlide>
        ))}
      </Swiper>
    </BTContainer>
  );
}

export default ProductCarousel;
