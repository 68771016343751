import isBoolean from 'lodash.isboolean';
import React from 'react';

function PriceText({
  value,
  currency,
  position,
  sup,
}: {
  value: number | string;
  currency?: string;
  position?: 'left' | 'right';
  sup?: boolean;
}) {
  const v = Number(value).toFixed(2);

  function euro() {
    return v.split('.')[0];
  }
  function cent() {
    return v.split('.')[1];
  }
  function full() {
    return v.replace('.', ',');
  }

  return (
    <>
      {position === 'left' && <>{currency || '€'} </>}
      {isBoolean(sup) && sup === true ? (
        <>
          {euro()}
          <sup style={{ fontSize: 'smaller', verticalAlign: 'sub' }}>
            ,{cent()}
          </sup>
        </>
      ) : (
        <>{full()}</>
      )}
      {position !== 'left' && <> {currency || '€'}</>}
    </>
  );
}
export default PriceText;
