import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { asyncMap } from '@apollo/client/utilities';
import React from 'react';
import Storage from '../storage/Storage';
import {
  default as ApolloEvent,
  default as ApolloEvents,
} from './ApolloEvents';
import { cache, tokenIdVar } from './Cache';
import { cleanToken } from './operations/mutations/token';

const cleanSessionToken = cleanToken(tokenIdVar);

function Apollo(_props: { children: any }) {
  const withToken = setContext((request, previousContext) => {
    const token = Storage.readToken();
    if (!token) {
      return {};
    }
    return {
      headers: { authorization: `Bearer ${token}` },
    };
  });

  const resetToken = onError(errors => {
    const errorsGQL = errors.graphQLErrors?.map(
      err => err.extensions?.category
    );
    !errorsGQL?.includes('graphql-no-such-entity') &&
      ApolloEvent.errors.forEach(ele => ele && ele(errors));

    if (errorsGQL?.includes('graphql-authorization')) {
      cleanSessionToken();
      ApolloEvents.connect.forEach(ele => ele && ele());
      window.location = '/' as any;
    }
  });

  const addDateLink = new ApolloLink((operation, forward) => {
    ApolloEvents.request.forEach(ele => ele && ele(operation));
    return asyncMap(forward(operation), async response => {
      ApolloEvent.response.forEach(ele => ele && ele(response));
      return response;
    });
  });

  const retry = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
    },
  });

  const authFlowLink = withToken.concat(resetToken).concat(addDateLink);

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER });

  const client = new ApolloClient({
    cache: cache,
    link: concat(retry, concat(authFlowLink, httpLink)),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });

  return (
    <>
      <ApolloProvider client={client}>{_props.children}</ApolloProvider>
    </>
  );
}

export default Apollo;
