import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ProductFieldFragment,
  ProductInterface,
} from '../../core/apollo/gql-generate';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import PriceText from '../../core/price_text/PriceText';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import styles from './ProductPrice.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
export type ProductPriceProps = {
  product: ProductFieldFragment | ProductInterface;
  size?: 'sm' | 'lg' | 'xs' | 'md';
  advisedDirection?: 'column' | 'row';
  justifyContent?: 'start' | 'center' | 'end';
  historyTooltips?: boolean;
  priceColor?: string;
};

const PRICE_HISTORY = 'price_history_message';

const ProductPrice = (props: ProductPriceProps) => {
  const {
    product,
    size = 'sm',
    advisedDirection = 'column',
    justifyContent = 'center',
    historyTooltips = true,
    priceColor,
  } = props;

  const { t } = useTranslation();

  const { blocks } = useCmdBlocks([PRICE_HISTORY]);

  const finalPrice = useMemo<number>(
    () =>
      product.special_price ??
      product.price_range.maximum_price?.final_price.value ??
      0,
    [product]
  );

  return (
    <div className={`d-flex flex-column`}>
      {product.msrp && product.msrp > finalPrice && (
        <div className={`d-flex justify-content-${justifyContent}`}>
          <div
            className={`d-flex flex-${advisedDirection} ${
              styles[`price-avised-${size}`]
            }`}
            style={{ gap: advisedDirection === 'row' ? '10px' : 0 }}
          >
            <span>{t('common.advisedPrice')}</span>
            <div
              className={`d-flex justify-content-center ${styles['price-avised']}`}
            >
              <PriceText value={product.msrp} />
              {historyTooltips && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" id="tooltip-top">
                      <BTHtmlWrapper
                        className={`my-2`}
                        content={blocks[PRICE_HISTORY]}
                      />
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size={'lg'}
                    color="black"
                  />
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={`d-flex justify-content-${justifyContent} ${styles.price} ${
          styles[`price-${size}`]
        }`}
        style={{ color: priceColor }}
      >
        <PriceText value={finalPrice > 0 ? finalPrice : ''} />
      </div>
    </div>
  );
};

export default ProductPrice;
