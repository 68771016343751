import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import BTContainer from '../../../../core/bt_container/BTContainer';
import { useCart } from '../../../../core/contexts/CartContext';

export function PaymentsData() {
  const { cart, addPaymentMethod } = useCart();

  function onChange(value: string) {
    addPaymentMethod({
      code: value,
    });
  }

  const acceptedPayments: string[] = [
    'paypal_express',
    'banktransfer',
    'easynolo_bancasellapro',
  ];
  const payments = (cart.available_payment_methods || []).filter(pay =>
    acceptedPayments.includes(pay?.code || 'niet')
  );

  return (
    <>
      <BTContainer>
        <Row>
          <Col>
            <Form>
              {payments.map(pay => (
                <div key={`div-${pay?.code}`} className="mb-3">
                  <Form.Check
                    name="payment"
                    key={pay?.code}
                    type="radio"
                    label={`${pay?.title}`}
                    id={`radio-${pay?.code}`}
                    value={pay?.code}
                    onChange={e => onChange(e.target.value)}
                    defaultChecked={
                      pay?.code === cart.selected_payment_method?.code
                    }
                  />
                </div>
              ))}
            </Form>
          </Col>
        </Row>
      </BTContainer>
    </>
  );
}
