import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCart } from '../../core/contexts/CartContext';

function PayPalReturn() {
  const { placeOrder, addPaymentMethod, cart, loaded } = useCart();
  const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();

  useEffect(() => {
    if (loaded) {
      addPaymentMethod({
        code: cart.selected_payment_method?.code!,
        paypal_express: {
          payer_id: queryParams.get('PayerID') || '',
          token: queryParams.get('token') || '',
        },
      }).then(() => {
        placeOrder().then(() => history.push('/order-complited'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return <></>;
}
PayPalReturn.displayName = 'PayPalReturn';
export default PayPalReturn;
