// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

const version = process.env.REACT_APP_TAG_VERSION || 'no-version-set';

function useVersion() {
  return version;
}

export default useVersion;
