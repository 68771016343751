import React, { useState } from 'react';
import SocialLoginButton from './button/SocialLoginButton';
import SocialLoginModal from './modal/SocialLoginModal';

function SocialLogin() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <SocialLoginButton onClick={() => setShow(true)} />
      <SocialLoginModal show={show} onClose={() => setShow(false)} />
    </div>
  );
}
export default SocialLogin;
