import isEmpty from 'lodash.isempty';
import { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddCircleSharp } from 'react-ionicons';
import AddressCard from '../../../../components/address_card/AddressCard';
import AddressForm, {
  AddressFormCountryEnum,
  AddressFormValueType,
} from '../../../../components/address_form/AddressForm';
import Title from '../../../../components/title/Title';
import { ShippingCartAddress } from '../../../../core/apollo/gql-generate';
import BTButton from '../../../../core/bt_button/BTButton';
import BTContainer from '../../../../core/bt_container/BTContainer';
import { useCart } from '../../../../core/contexts/CartContext';
import PriceText from '../../../../core/price_text/PriceText';

function ShippingData() {
  const { t } = useTranslation();

  const { cart, addShippingMethod } = useCart();

  const [showUpsert, setShowUpsert] = useState(false);

  const address = useMemo(() => {
    if (!isEmpty(cart.shipping_addresses)) {
      return cart.shipping_addresses[0] as ShippingCartAddress;
    }
    return {} as ShippingCartAddress;
  }, [cart]);

  function onMethodSelected(carrier_code: string) {
    const method = address.available_shipping_methods?.find(
      a => a?.carrier_code === carrier_code
    );
    addShippingMethod({
      carrier_code,
      method_code: method?.method_code!,
    });
  }

  return (
    <>
      <BTContainer>
        <Title name={t(`checkout.shippingData.subtitle`)}></Title>
        {!showUpsert && isEmpty(address) && (
          <BTButton
            className={'text-left px-0'}
            onPress={() => setShowUpsert(true)}
            variant={'outline-link'}
          >
            <AddCircleSharp
              color={'var(--primary)'}
              title={'addAddress'}
              height="30px"
              width="30px"
            />
            {t(`checkout.billingData.addAddress`)}
          </BTButton>
        )}
        {!showUpsert && !isEmpty(address) && (
          <AddressCard
            address={{
              billing: false,
              shipping: true,
              city: address.city,
              company: address.company,
              countryCode: address.country.code,
              firstname: address.firstname,
              lastname: address.lastname,
              postcode: address.postcode,
              regionCode: address.region?.code,
              street:
                address.street && address.street.length > 0
                  ? address.street[0]
                  : undefined,
            }}
            onUpdate={() => setShowUpsert(true)}
          />
        )}
        {showUpsert && (
          <UpsertBillingData
            address={address}
            onClose={() => setShowUpsert(false)}
          />
        )}
      </BTContainer>
      <BTContainer>
        <Title name={'Metodo di spedizione'} className={`my-4`}></Title>
        <Row>
          <Col>
            <Form>
              {address?.available_shipping_methods
                ?.filter(m => m?.available)
                .map((method, index) => (
                  <div key={`div-${method?.carrier_code}`} className="mb-3">
                    <Form.Check
                      name="payment"
                      type="radio"
                      label={
                        <>
                          <span>{method?.carrier_title} - </span>
                          <PriceText value={method?.amount.value || 0} />
                        </>
                      }
                      id={`radio-${method?.carrier_code}`}
                      value={method?.carrier_code}
                      onChange={e => onMethodSelected(e.target.value)}
                      defaultChecked={
                        method?.carrier_code ===
                        address.selected_shipping_method?.carrier_code
                      }
                    />
                  </div>
                ))}
            </Form>
          </Col>
        </Row>
      </BTContainer>
    </>
  );
}

function UpsertBillingData({
  address,
  onClose,
}: {
  address: ShippingCartAddress | undefined;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const { addShippingAddress } = useCart();

  const addressForm: AddressFormValueType = {
    firstname: address?.firstname || '',
    lastname: address?.lastname || '',
    region_id: address?.region?.region_id || 0,
    city: address?.city || '',
    country_id: AddressFormCountryEnum.IT,
    postcode: address?.postcode || '',
    street:
      address?.street && address?.street.length > 0 ? address?.street[0] : '',
    telephone: address?.telephone || '',
    codice_fiscale: address?.codice_fiscale || '',
    vat_id: address?.vat_id || '',
    company: address?.company || '',
    sdi_pec: address?.sdi_pec || '',
    default_billing: false,
    default_shipping: false,
    customer_type:
      address?.customer_type !== undefined ? `${address?.customer_type}` : '0',
  };

  const customButton: JSX.Element = (
    <BTButton
      className={`btn-block mt-2`}
      variant="dark-grey"
      onPress={onClose}
    >
      {t('common.cancel')}
    </BTButton>
  );

  function onSubmit(value: AddressFormValueType) {
    addShippingAddress({
      address: {
        firstname: value.firstname,
        lastname: value.lastname,
        city: value.city,
        country_code: value.country_id,
        street: [value.street],
        telephone: value.telephone,
        postcode: value.postcode,
        region_id: value.region_id,
        codice_fiscale: value.codice_fiscale,
        sdi_pec: value.sdi_pec,
        vat_id: value.vat_id || '',
        company: value.company,
        customer_type: value.customer_type
          ? parseInt(value.customer_type)
          : undefined,
      },
    }).then(onClose);
  }

  return (
    <>
      <Row className={'mt-4'}>
        <Col>
          <AddressForm
            initialValues={addressForm}
            showDefaultBillding={false}
            showDefaultShipping={false}
            showCustomerTypeCheck={true}
            customButton={customButton}
            onSubmit={onSubmit}
            submitText={t('common.save')}
          />
        </Col>
      </Row>
    </>
  );
}

export default ShippingData;
