import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCmsBlocksQuery } from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';

function CmsBlocks() {
  const queryParams = new URLSearchParams(useLocation().search);
  const blockIds = queryParams.get('blocks')?.split(';');

  const { data, loading } = useGetCmsBlocksQuery({
    variables: {
      blockIds,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <BTContainer>
      <div className="d-flex flex-column">
        {!loading &&
          data?.cmsBlocks?.items?.map((item, index) => (
            <BTHtmlWrapper
              className={`my-2`}
              content={item?.content}
              key={index}
            />
          ))}
      </div>
    </BTContainer>
  );
}
CmsBlocks.displayName = 'CmsBlocks';
export default CmsBlocks;
