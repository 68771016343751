import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddCircleSharp } from 'react-ionicons';
import { useHistory, useLocation } from 'react-router-dom';
import AddressCard from '../../components/address_card/AddressCard';
import Title from '../../components/title/Title';
import {
  AddressFieldsFragment,
  CustomerAddress,
  useGetCustomerQuery,
} from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import styles from './AddressManagement.module.scss';

function AddressManagement() {
  const { t } = useTranslation();
  const history = useHistory();
  let { state }: { state: { isToRefetch: boolean } } = useLocation();
  const [profileAddresses, setProfileAddresses] = useState(
    [] as AddressFieldsFragment[]
  );

  const { data, loading, refetch } = useGetCustomerQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      let sortedAddresses = data.customer?.addresses?.slice() as
        | AddressFieldsFragment[]
        | [];
      if (sortedAddresses) {
        sortedAddresses
          .sort((a, b) =>
            ordina(a?.default_billing as boolean, b?.default_billing as boolean)
          )
          .sort((a, b) =>
            ordina(
              a?.default_shipping as boolean,
              b?.default_shipping as boolean
            )
          );
        setProfileAddresses(sortedAddresses);
      }
    }
  }, [data, profileAddresses]);

  useEffect(() => {
    if (state?.isToRefetch) {
      refetch();
    }
  }, [refetch, state?.isToRefetch]);

  function ordina(x: boolean, y: boolean) {
    return x === y ? 0 : x ? -1 : 1;
  }

  function handleUpdate(address: CustomerAddress) {
    history.push({
      pathname: '/accounts/upsert_address',
      state: {
        newAddress: false,
        address: address,
        addressType: t('addressManagement.billingAddress'),
      },
    });
  }

  return (
    <>
      <Title
        className={`${styles.title}`}
        name={t('addressManagement.title')}
      />
      {!loading && data && (
        <BTContainer>
          <Row className={`mt-2`}>
            <Col
              className={`${styles.add}`}
              onClick={() => {
                history.push({
                  pathname: '/accounts/upsert_address',
                  state: {
                    newAddress: true,
                    address: null,
                    addressType: '',
                  },
                });
              }}
            >
              <AddCircleSharp
                color={'var(--primary)'}
                title={'addAddress'}
                height="30px"
                width="30px"
              />
              {t('addressCreation.add')}
            </Col>
          </Row>
          {profileAddresses.map((address, index) => (
            <Row key={address.id}>
              <Col>
                <AddressCard
                  address={{
                    vatId: address.vat_id,
                    fiscalCode: (address.custom_attributes || []).find(
                      f => f?.attribute_code === 'codice_fiscale'
                    )?.value,
                    sdiPec: (address.custom_attributes || []).find(
                      f => f?.attribute_code === 'sdi_pec'
                    )?.value,
                    city: address.city,
                    company: address.company,
                    countryCode: address.country_code,
                    billing: address.default_billing || false,
                    shipping: address.default_shipping || false,
                    firstname: address.firstname,
                    lastname: address.lastname,
                    postcode: address.postcode,
                    regionCode: address.region?.region_code,
                    street:
                      address.street && address.street.length > 0
                        ? address.street[0]
                        : undefined,
                  }}
                  showTitle={true}
                  onUpdate={() => handleUpdate(address)}
                />
              </Col>
            </Row>
          ))}
        </BTContainer>
      )}
    </>
  );
}
AddressManagement.displayName = 'AddressManagement';
export default AddressManagement;
