import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/title/Title';
import {
  CategoryFieldFragment,
  useGetCategoriesQuery,
} from '../../core/apollo/gql-generate';
import BTList from '../../core/bt_list/BTList';
import CategoryRow from '../../components/category_row/CategoryRow';
import { generatePath, useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-redeclare

function Menu() {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: categories, loading } = useGetCategoriesQuery({
    variables: {
      filters: {
        parent_category_uid: {
          in: [process.env.REACT_APP_PARENT_UID_MENU],
        },
      },
    },
  });

  function categoryHandler(category: CategoryFieldFragment) {
    history.push(
      generatePath('/categories/:uid', {
        uid: category.uid || '',
      })
    );
  }

  return (
    <>
      <Title name={t('menu.title')} />
      {!loading && (
        <BTList
          className={`mt-2`}
          items={
            (categories?.categoryList?.filter(
              i => i?.name !== 'shop-in-shop'
            ) || []) as CategoryFieldFragment[]
          }
          renderItem={item => (
            <CategoryRow
              title={item.name || 'noname'}
              onClick={() => categoryHandler(item)}
            />
          )}
          numerOfRowElement={1}
        />
      )}
    </>
  );
}

Menu.displayName = 'Menu';

export default Menu;
