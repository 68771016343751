import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteCustomerMutation } from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTModal from '../../core/bt_modal/BTModal';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import { addSuccesNotification } from '../../core/notification/Notification';
import Storage from '../../core/storage/Storage';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import useLogout from '../../hooks/useLogout';

const CMS_BLOCKS_DELETE_ACCOUNT: Record<string, string> = {
  TOP: 'mobile_account_delete_top',
  BOTTOM: 'mobile_account_delete_bottom',
};

const AccountDelete = () => {
  const { t } = useTranslation();

  const logout = useLogout();

  const [show, setShow] = useState<boolean>(false);

  const [deleteCustomer] = useDeleteCustomerMutation();

  const { blocks } = useCmdBlocks([
    ...Object.keys(CMS_BLOCKS_DELETE_ACCOUNT).map(
      key => CMS_BLOCKS_DELETE_ACCOUNT[key]
    ),
  ]);

  function handleDeleteAccount() {
    deleteCustomer({
      variables: {
        token: Storage.readToken()!,
      },
    }).then(() => {
      addSuccesNotification({
        title: t('account.removeAccount'),
        message: t('successMessage.removeAccountMessage'),
      });
      logout();
    });
  }

  function onCloseHandler() {
    setShow(false);
  }

  return (
    <div>
      <BTModal
        show={show}
        closeButton
        title={<div>Cancellazione account!</div>}
        body={<p>{`${t('account.confirmDeleteMessage')}`}</p>}
        onClose={onCloseHandler}
        footer={
          <div className="d-flex" style={{ gap: 10 }}>
            <BTButton variant="outline-primary" onPress={onCloseHandler}>
              {t('common.cancel')}
            </BTButton>
            <BTButton onPress={handleDeleteAccount}>
              {t('common.confirm')}
            </BTButton>
          </div>
        }
      />
      <BTContainer>
        <BTHtmlWrapper content={blocks[CMS_BLOCKS_DELETE_ACCOUNT.TOP]} />
        <div className="d-flex justify-content-center">
          <BTButton onPress={() => setShow(true)} block>
            {t('account.removeAccount')}
          </BTButton>
        </div>
        <BTHtmlWrapper content={blocks[CMS_BLOCKS_DELETE_ACCOUNT.BOTTOM]} />
      </BTContainer>
    </div>
  );
};

export default AccountDelete;
