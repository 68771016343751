import { generatePath, useHistory } from 'react-router-dom';
import { ProductLightFieldFragment } from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import EnergyRating from '../energy_rating/EnergyRating';
import ProductPrice from '../product_price/ProductPrice';
import styles from './ProductCard.module.scss';

type ProductCardProps = { product: ProductLightFieldFragment };

function ProductCard(props: ProductCardProps) {
  const { product } = props;
  const history = useHistory();

  const { blocks } = useCmdBlocks(
    product.bloccoetichetta1 ? [product.bloccoetichetta1] : []
  );

  /*const hasOffer = useMemo(() => {
    return (
      product?.special_to_date &&
      new Date(product?.special_to_date) >= new Date()
    );
  }, [product]);*/

  return (
    <BTButton
      variant="light"
      className={`d-flex align-items-start flex-column ${styles.container}`}
      onPress={() => {
        const path = generatePath('/products/:sku', {
          sku: product.sku!,
        });
        history.push(path);
      }}
    >
      <BTContainer className="d-flex align-items-start flex-column bd-highlight p-2 h-100 w-100">
        {/*hasOffer && product?.special_to_date && (
          <OfferBanner end={new Date(product?.special_to_date)} size={'xs'} />
        )*/}
        <div className={`p-0`}>
          <img
            className={`${styles.image}`}
            src={product.image?.url}
            alt="icon"
          />
          {product.bloccoetichetta1 && (
            <BTHtmlWrapper
              className={`${styles.etichetta}`}
              content={blocks[product.bloccoetichetta1]}
              style={{
                position: 'absolute',
              }}
            />
          )}
        </div>

        <div className={`text-left ${styles.description} p-0`}>
          <p className={`${styles.textTruncate}`}>{product.name}</p>
        </div>
        <div className="mt-auto w-100">
          <EnergyRating product={product} justifyContent="center" />
          <ProductPrice
            product={product}
            justifyContent="center"
            size="md"
            historyTooltips={false}
          />
        </div>
      </BTContainer>
    </BTButton>
  );
}

export default ProductCard;
