import isEmpty from 'lodash.isempty';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BTContainer from '../bt_container/BTContainer';

type BTListProps<T> = React.ComponentProps<typeof BTContainer> & {
  itemClassName?: string;
  renderItem: (item: T) => JSX.Element;
  items: T[];
  numerOfRowElement?: number;
};

function BTList<T>(props: BTListProps<T>) {
  const {
    items,
    numerOfRowElement,
    itemClassName,
    renderItem,
    ...containerProps
  } = props;

  return (
    <BTContainer {...containerProps}>
      {!isEmpty(items) && (
        <Row className="d-flex align-items-stretch">
          {items.map((item, index) => (
            <Col
              key={index}
              xs={12 / (numerOfRowElement || 1)}
              className={`${itemClassName}`}
            >
              {renderItem(item)}
            </Col>
          ))}
        </Row>
      )}
    </BTContainer>
  );
}

export default BTList;
