import { useReactiveVar } from '@apollo/client';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Badge, Card, Col, Row } from 'react-bootstrap';
import { appStateVar } from '../../core/apollo/Cache';
import { mergeAppState } from '../../core/apollo/operations/mutations/appState';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import styles from './ProductsFilters.module.scss';

export type FilterInputType = 'radio' | 'checkbox';
type Filter = {
  label: string;
  value: string;
  selected: boolean;
};

type CategoryFilters = {
  label: string;
  inputType: FilterInputType;
  type?: string;
  filters: Filter[];
  value?: string | string[];
  onClick?: (id?: string) => Promise<void>;
  resetOnRemove: boolean;
};

export type CategoriesFilters = {
  [key: string]: CategoryFilters;
};
type ProductsFiltersProps = {
  position: 'left' | 'center' | 'right';
  filters: CategoriesFilters;
  onChange: (filters: CategoriesFilters, init?: boolean) => void;
  onShow?: () => void;
  onHide?: () => void;
};

const ICONS_CDN_URL = process.env.REACT_APP_ICONS_CDN_URL;

function ProductsFilters(props: ProductsFiltersProps) {
  const { filters, position, onChange } = props;

  const [categoriesFilters, setCategoriesFilters] =
    useState<CategoriesFilters>(filters);

  const [countOpenEvent, setCountOpenEvent] = useState<number>(0);

  const appState = useReactiveVar(appStateVar);
  const { showCategoryFilter } = appState;
  const mergeAppStateDispatch = mergeAppState(appState, appStateVar);

  function resetHandler() {
    const cat = categoriesFilters['category_id'];
    cat && cat.onClick && cat.onClick();
    setCategoriesFilters(prev => {
      const result = {} as CategoriesFilters;
      Object.keys(prev).forEach(key => {
        result[key] = { ...prev[key], value: undefined };
      });
      return result;
    });
    mergeAppStateDispatch({ showCategoryFilter: false });
  }

  useEffect(() => {
    return () => mergeAppStateDispatch({ showCategoryFilter: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showCategoryFilter === false) {
      onChange(categoriesFilters, countOpenEvent === 0);
    }
    showCategoryFilter && setCountOpenEvent(c => c + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCategoryFilter]);

  useEffect(() => {
    !isEmpty(filters) && setCategoriesFilters(filters);
  }, [filters]);

  function onValueChange(filterCategoryName: string, value: string) {
    const app = Object.assign({}, categoriesFilters);
    const category = app[filterCategoryName];

    if (category.inputType === 'radio') {
      category.value = value;
      category.onClick && category.onClick(value);
    } else {
      const values = (category.value || []) as string[];
      const index = values.findIndex(v => v === value);
      index === -1 && values.push(value);
      index > -1 && values.splice(index, 1);
      category.value = values;
    }
    setCategoriesFilters(app);
  }

  function activeAccordion(categoryKey: string) {
    return !isEmpty(categoriesFilters[categoryKey].value) ? categoryKey : '';
  }

  function removeFilterHandler(filter: Filter, categoryKey: string) {
    const category = { ...categoriesFilters[categoryKey] };

    if (category.value && Array.isArray(category.value)) {
      const array = category.value as string[];
      const index = array.findIndex(item => item === filter.value);
      array.splice(index, 1);
    } else if (category.value) {
      category.value = undefined;
    }
    const updateSelected = { ...categoriesFilters, [categoryKey]: category };
    category.resetOnRemove &&
      Object.keys(updateSelected).forEach(key => {
        updateSelected[key].value = undefined;
      });
    setCategoriesFilters(updateSelected);
    onChange(updateSelected);
  }

  const filtersSelected = useMemo(() => {
    return Object.keys(categoriesFilters).reduce((acc, key) => {
      const categoryFilters = { ...categoriesFilters[key] };
      return isEmpty(categoryFilters.value)
        ? acc
        : { ...acc, [key]: categoryFilters };
    }, {} as CategoriesFilters);
  }, [categoriesFilters]);

  const FiltersBar = (
    <BTContainer>
      <Row>
        <Col>Filtri attivi:</Col>
      </Row>
      {map(filtersSelected, ({ value, filters, onClick }, key) => {
        const items = filters.filter(f => value?.includes(f.value));
        return (
          <div key={key}>
            {items.map(item => (
              <Badge
                key={`${key}-${item.value}`}
                className="m-1"
                onClick={() => {
                  onClick && onClick();
                  removeFilterHandler(item, key);
                }}
                variant="primary"
              >{`${item.label} X`}</Badge>
            ))}
          </div>
        );
      })}
    </BTContainer>
  );

  return (
    <>
      {!showCategoryFilter && !isEmpty(filtersSelected) && FiltersBar}
      {showCategoryFilter && (
        <BTContainer className={`${styles.filtersContainer}`}>
          <Row className={`${styles.filterTitleRow}`}>
            <Col
              className={`col-8 offset-2 text-center justify-content-center align-self-center`}
            >
              scegli caratteristiche
            </Col>
            <Col className={`col-2 text-${position} p-0`}>
              <BTButton variant="link" onPress={resetHandler}>
                <img
                  src={`${ICONS_CDN_URL}/icon_resetfilter.svg`}
                  width={'30'}
                  height={'30'}
                  alt={'Reset Filter icon'}
                />
              </BTButton>
            </Col>
          </Row>
          {map(categoriesFilters, (categoryValue, categoryKey) => (
            <div key={categoryKey}>
              <Row>
                <Col className={`p-0`}>
                  <Accordion defaultActiveKey={activeAccordion(categoryKey)}>
                    <Card className={`${styles.borderBottom}`}>
                      <Accordion.Toggle
                        className={`p-0 ${styles.accordionHeader}`}
                        as={Card.Header}
                        eventKey={`${categoryKey}`}
                      >
                        <BTContainer>
                          <Row className={`p-2 ${styles.borderBottom}`}>
                            <Col>{categoryValue.label}</Col>
                          </Row>
                        </BTContainer>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${categoryKey}`}>
                        <BTContainer>
                          {map(categoryValue.filters, filter => (
                            <Row
                              key={`${categoryKey}-${filter.value}`}
                              className="p-2"
                              onClick={() =>
                                onValueChange(categoryKey, filter.value)
                              }
                            >
                              <Col className="d-flex justify-content-between">
                                <div>{filter.label}</div>
                                <div>
                                  {categoryValue.inputType === 'checkbox' && (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={filter.label}
                                      checked={
                                        (
                                          categoryValue.value as string[]
                                        )?.findIndex(i => i === filter.value) >
                                        -1
                                      }
                                    />
                                  )}
                                  {categoryValue.inputType === 'radio' && (
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={categoryKey}
                                      id={categoryKey}
                                      checked={
                                        categoryValue.value === filter.value
                                      }
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </BTContainer>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </div>
          ))}
        </BTContainer>
      )}
    </>
  );
}

export default ProductsFilters;
