import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import BTContainer from '../bt_container/BTContainer';
import useWindowSize from '../../hooks/useWindowSize';

type BTCarouselProps<T> = React.ComponentProps<typeof BTContainer> & {
  itemClassName?: string;
  renderItem: (item: T) => JSX.Element;
  items: T[];
  slidesPerView?: {
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

function BTCarousel<T>(props: BTCarouselProps<T>) {
  const {
    itemClassName,
    renderItem,
    items,
    slidesPerView: slidesPerViewProps,
    ...containerProps
  } = props;

  const slidesPerView = {
    ...{ sm: 2, md: 3, lg: 4, xl: 5 },
    ...slidesPerViewProps,
  };

  const { getBreakpoint } = useWindowSize();
  const breackpoint = getBreakpoint();

  return (
    <BTContainer {...containerProps}>
      <Swiper
        slidesPerView={slidesPerView[breackpoint]}
        centeredSlides={false}
        loop={true}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            className={`${itemClassName}`}
            style={{ height: 'auto' }}
          >
            <div className={`h-100`} style={{ display: 'block' }}>
              {renderItem(item)}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </BTContainer>
  );
}

export default BTCarousel;
