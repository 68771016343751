import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TrashOutline } from 'react-ionicons';
import { generatePath, useHistory } from 'react-router-dom';
import { CartItemInterface } from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import BTSnippet from '../../core/bt_snippet/BTSnippet';
import EnergyRating from '../energy_rating/EnergyRating';
import ProductExtraServices, {
  ProductExtraServicesValues,
} from '../product-extra-services/ProductExtraServices';
import ProductPrice from '../product_price/ProductPrice';
import styles from './ProductCart.module.scss';

type ProductCartProps = {
  product: CartItemInterface;
  onChange: (value: UpdateProductCartItem) => void;
  onRemove: () => void;
};

export type UpdateProductCartItem = {
  total: number;
  selectOpts: ProductExtraServicesValues;
};

function ProductCart(props: ProductCartProps) {
  const { onChange, onRemove } = props;
  const { product, quantity, selected_customizable_options } = props.product;
  const history = useHistory();

  const selectdOptions = useMemo<ProductExtraServicesValues>(() => {
    const opts: ProductExtraServicesValues = {};
    selected_customizable_options.forEach(item => {
      if (item?.values[0]?.value && item?.id) {
        opts[`${item.id}`] = item?.values[0].value;
      }
    });
    return opts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_customizable_options]);

  function goToProductDetail() {
    history.push({
      pathname: generatePath('/products/:sku', {
        sku: props.product.product?.sku!,
      }),
    });
  }

  return (
    <BTContainer>
      <Row className={'mt-3'}>
        <Col className={'col-3'} onClick={goToProductDetail}>
          <img
            className={`${styles.image}`}
            src={product?.image?.url}
            alt="icon"
          />
        </Col>
        <Col className={'col-9'}>
          <Row>
            <Col className={`${styles.description}`}>{product?.name}</Col>
          </Row>
          <Row className="py-1">
            <Col>
              <ProductPrice
                product={product}
                size="sm"
                advisedDirection="row"
                justifyContent="start"
                historyTooltips={false}
                priceColor="var(--text)"
              />
              <EnergyRating product={product} justifyContent="start" />
            </Col>
          </Row>
          <ProductExtraServices
            customOptions={product.custom_options}
            values={selectdOptions}
            onChange={selectOpts => onChange({ selectOpts, total: quantity })}
          />
        </Col>
      </Row>
      <Row className={`my-1`}>
        <Col className="text-left col-6">
          <BTSnippet
            value={quantity}
            minQty={1}
            isBundle={false}
            onChange={total => onChange({ total, selectOpts: selectdOptions })}
          />
        </Col>
        <Col className="text-right col-6">
          <TrashOutline
            color={'var(--primary)'}
            height="26px"
            width="26px"
            onClick={onRemove}
          />
        </Col>
      </Row>
    </BTContainer>
  );
}

export default ProductCart;
