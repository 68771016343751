import isBoolean from 'lodash.isboolean';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BTButton from '../../core/bt_button/BTButton';
import styles from './AddressCard.module.scss';

export type AddressCardProps = {
  address: {
    shipping: boolean;
    billing: boolean;
    firstname?: string;
    lastname?: string;
    company?: string;
    street?: string;
    postcode?: string;
    city?: string;
    regionCode?: string;
    countryCode?: string;
    vatId?: string;
    fiscalCode?: string;
    sdiPec?: string;
  };
  showTitle?: boolean | string;
  onUpdate?: () => void;
};

function AddressCard(props: AddressCardProps) {
  const { address, showTitle = false, onUpdate } = props;
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (!isBoolean(showTitle)) {
      return showTitle;
    } else if (address.shipping && address.billing) {
      return t('addressManagement.shippingAndBllingAddress');
    } else if (address.shipping) {
      return t('addressManagement.shippingAddress');
    } else if (address.billing) {
      return t('addressManagement.billingAddress');
    } else {
      return t('addressManagement.optionalAddress');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, showTitle]);

  return (
    <div className={`d-flex flex-column bd-highlight ${styles.text} py-3`}>
      {!!showTitle && <div className={`py-1 ${styles.title}`}>{title}</div>}
      <div className={'py-2'}>
        {address.firstname} {address.lastname}{' '}
        {address.company && <>{`c/o ${address.company}`}</>}
      </div>
      {address.billing && (
        <>
          {address.vatId && (
            <div className={`py-2`}>P.Iva: {address.vatId}</div>
          )}
          {address.fiscalCode && (
            <div className={`py-2`}>CF: {address.fiscalCode}</div>
          )}
          {address.sdiPec && (
            <div className={`py-2`}>SPI/PEC: {address.sdiPec}</div>
          )}
        </>
      )}
      <div className={`py-2`}>{address.street}</div>
      <div className={`py-2`}>
        {address.postcode} - {address.city} ({address.regionCode})
      </div>
      {onUpdate && (
        <div className="d-flex justify-content-between py-2">
          <div>{address.countryCode}</div>
          <BTButton
            variant="outline-primary"
            className={`${styles.button}`}
            size="sm"
            onPress={onUpdate}
          >
            {t('addressManagement.modify').toUpperCase()}
          </BTButton>
        </div>
      )}
    </div>
  );
}

export default AddressCard;
