import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './BTLoader.module.scss';

function BTLoader({ show }: { show: boolean }) {
  return (
    <>
      {show && (
        <div className={`${styles.loader} d-flex align-items-center `}>
          <div className={`${styles.center} text-center`}>
            <Loader type="Puff" color="var(--primary)" />
          </div>
        </div>
      )}
    </>
  );
}

export default BTLoader;
