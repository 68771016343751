import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import pick from 'lodash.pick';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ProductsFilters, {
  CategoriesFilters,
  FilterInputType,
} from '../../components/products_filters/ProductsFilters';
import SearchProductsPagination from '../../components/search_products_pagination/SearchProductsPagination';
import Title from '../../components/title/Title';
import { GetProductsLightQueryVariables } from '../../core/apollo/gql-generate';
import useCategory from '../../hooks/useCategory';
import ProductsFilterType from '../../models/ProductsFilterRes';
import { getCategoryApiFilter } from '../../services/services';
export type CategoryStateType = {
  name?: string;
  offer?: {
    duration: moment.DurationInputArg1;
    unit: moment.unitOfTime.DurationConstructor;
  };
};

type FiltersParams = { [key in string]: string | string[] };

function Category() {
  const { uid: uidEcoded } = useParams<{ uid: string }>();
  const uid = decodeURIComponent(uidEcoded);

  const queryParams = new URLSearchParams(useLocation().search);
  const paginationParams = Number(queryParams.get('p') || '1');
  const filtersParams64 = queryParams.get('filters');
  const filtersParams: FiltersParams = filtersParams64
    ? JSON.parse(atob(filtersParams64))
    : {};

  const { state }: { state: CategoryStateType } = useLocation();
  const history = useHistory();

  const { category, loading } = useCategory({ uid });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const [filtersInitialized, setFiltersInitialized] = useState<boolean>(false);

  const defaultQuery: GetProductsLightQueryVariables = {
    filter: {
      category_uid: {
        eq: uid,
      },
    },
    currentPage: paginationParams,
    pageSize: 8,
    sort: {
      position: 'DESC',
    },
  };

  const [filters, setFilters] = useState<CategoriesFilters>();
  const [query, setQuery] =
    useState<GetProductsLightQueryVariables>(defaultQuery);

  useEffect(() => {
    if (category && category.filters) {
      if (isEmpty(filtersParams)) {
        const { filters } = category;
        !isEmpty(filters) && filters && onFilterCategoryChange(filters);
        if (state && state.offer) {
          setQuery({
            ...query,
            filter: {
              ...query.filter,
              special_from_date: {
                from: `${moment()
                  .subtract(1, 'year')
                  .format('YYYY-MM-DD')} 00:00:00`,
                to: `${moment().format('YYYY-MM-DD')} 00:00:00`,
              },
              special_to_date: {
                from: `${moment().format('YYYY-MM-DD')} 00:00:00`,
                to: `${moment()
                  .add(state.offer.duration, state.offer.unit)
                  .format('YYYY-MM-DD')} 00:00:00`,
              },
            },
          });
        }
      } else {
        // GESTISCO IL BACK
        const catId = filtersParams['category_id'];
        catId &&
          getCategoryApiFilter(catId as string).then(res =>
            onFilterCategoryChange(
              [
                ...(category.filters || []).filter(c => c.id === 1),
                ...res.filter(c => c.id !== 1),
              ],
              filtersParams
            )
          );
        !catId && onFilterCategoryChange(category.filters, filtersParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, category.filters]);

  useEffect(() => {
    if (query && filters && filtersInitialized) {
      const _filters = Object.keys(filters).reduce((obj, key) => {
        const cat = filters[key];
        const sel = cat.value;
        return sel && isEmpty(sel)
          ? obj
          : ({ ...obj, [key]: sel } as FiltersParams);
      }, {} as FiltersParams);

      history.replace(
        `${history.location.pathname}?p=${query.currentPage}&filters=${btoa(
          JSON.stringify(_filters)
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filters, filtersInitialized]);

  function onFiltersSelected(filters: CategoriesFilters, init?: boolean) {
    init && setFiltersInitialized(init);
    const CATEGORY_ID = 'category_id';
    const newQuery: GetProductsLightQueryVariables = {
      ...query,
      filter: {
        ...pick(query.filter, ['special_to_date', 'special_from_date']),
        category_id: { eq: category.category?.id?.toString() || '' },
      },
      currentPage: init ? paginationParams : 1,
    };

    map(filters, (value, key) => {
      const allSelected = value.value;
      if (!isEmpty(allSelected)) {
        newQuery.filter = {
          ...newQuery.filter,
          [key]: {
            ...(Array.isArray(value.value) && { in: value.value }),
            ...(key === CATEGORY_ID && {
              in: [value.value],
              eq: category.category?.id?.toString() || '',
            }),
            ...(key !== CATEGORY_ID && {
              ...(value.type !== 'price' &&
                !Array.isArray(value.value) && { eq: value.value }),
              ...(value.type === 'price' && {
                from: (value.value as string)?.split(',')[0],
                to: (value.value as string)?.split(',')[1],
              }),
            }),
          },
        };
      }
    });
    setQuery(newQuery);
  }

  function onFilterCategoryChange(
    _filters: ProductsFilterType[],
    fromParams?: FiltersParams
  ) {
    const CATEGORY_ID = 'category_id';
    const newFilters: CategoriesFilters = {};

    function getInputType({
      field,
      type,
    }: ProductsFilterType): FilterInputType {
      if (field === CATEGORY_ID || type === 'price') {
        return 'radio';
      } else {
        return 'checkbox';
      }
    }

    _filters?.forEach(filter => {
      newFilters[filter.field] = {
        label: filter.label,
        inputType: getInputType(filter),
        type: filter.type,
        value: fromParams ? fromParams[filter.field] : undefined,
        filters: filter.value.map(({ label, value, count }) => ({
          label: `${label} (${count})`,
          value,
          selected: false,
        })),
        resetOnRemove: filter.field === CATEGORY_ID,
        onClick:
          filter.field === CATEGORY_ID
            ? async (id?: string) => {
                const response = await getCategoryApiFilter(
                  (id || category.category?.id)!.toString()
                );
                const newFilter = id
                  ? response.filter(i => i.field !== CATEGORY_ID)
                  : response;
                onFilterCategoryChange([...newFilter]);
              }
            : undefined,
      };
    });

    setFilters(prev => {
      if (!prev || isEmpty(prev)) {
        return newFilters;
      }
      return {
        category_id: prev[CATEGORY_ID],
        ...newFilters,
      };
    });
  }

  return (
    <>
      {!loading && category && (
        <>
          <Title name={category.category?.name || ''} />
          {!isEmpty(filters) && filters && (
            <ProductsFilters
              filters={filters}
              onChange={onFiltersSelected}
              position={'right'}
              onShow={() => setFilterOpen(true)}
              onHide={() => setFilterOpen(false)}
            />
          )}
          {query && (
            <SearchProductsPagination
              query={query}
              onPageChange={page => {
                setQuery({ ...query, currentPage: page });
              }}
            />
          )}
        </>
      )}
    </>
  );
}

Category.displayName = 'Category';

export default Category;
