import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import BTButton from '../bt_button/BTButton';
import styles from './BTSnippet.module.scss';

type BTSnippetType = {
  value: number;
  minQty: number;
  isBundle: boolean;
  onChange: (qty: number) => void;
  variant?: string;
  size?: number;
};

function BTSnippet(props: BTSnippetType) {
  const [btDisable, setBtDisable] = useState(props.value === props.minQty);

  const variant = props.variant || 'primary';

  const btnStyle = {
    height: props.size || 26,
    width: props.size || 26,
  };

  const textFieldStyle = {
    height: props.size || 26,
    border: `1px solid var(--${variant})`,
  };

  function increaseQty() {
    const _qty = props.isBundle ? props.value + props.minQty : props.value + 1;
    setBtDisable(_qty === props.minQty);
    props.value !== _qty && props.onChange(_qty);
  }
  function decreaseQty() {
    const _qty = props.isBundle ? props.value - props.minQty : props.value - 1;
    setBtDisable(_qty === props.minQty);
    props.value !== _qty && props.onChange(_qty);
  }

  return (
    <InputGroup>
      <InputGroup.Text className={`p-0 border-0 mr-2 bg-light`}>
        <BTButton
          className={`btn p-0 ${styles.button}`}
          style={btnStyle}
          variant={variant}
          onPress={decreaseQty}
          disabled={btDisable}
        >
          -
        </BTButton>
      </InputGroup.Text>
      <FormControl
        className={`${styles.field} text-center`}
        style={textFieldStyle}
        type="number"
        disabled={true}
        value={props.value}
      />
      <InputGroup.Text className={`p-0 border-0 ml-2 bg-light`}>
        <BTButton
          className={`btn p-0 ${styles.button}`}
          style={btnStyle}
          variant={variant}
          onPress={increaseQty}
        >
          +
        </BTButton>
      </InputGroup.Text>
    </InputGroup>
  );
}

export default BTSnippet;
