import { useReactiveVar } from '@apollo/client';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import { mpBlogsListVar } from '../core/apollo/Cache';
import { useGetMpBlogCategoriesQuery } from '../core/apollo/gql-generate';
import { getMpBlogList } from '../services/services';

function useMpBlog() {
  const [loading, setLoading] = useState<boolean>(true);

  const mpBlogsList = useReactiveVar(mpBlogsListVar);

  const { loading: loadingCat, data: dataCat } = useGetMpBlogCategoriesQuery({
    variables: {
      action: 'get_category_list',
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (isEmpty(mpBlogsList)) {
      getMpBlogList().then(mps => {
        mps && mpBlogsListVar(mps);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMpBlog(id: number) {
    return mpBlogsList.find(i => i.id === id);
  }

  return {
    loading: loadingCat || loading,
    mpBlogsList,
    total: mpBlogsList.length,
    categories: dataCat?.mpBlogCategories?.items,
    getMpBlog,
  };
}

export default useMpBlog;
