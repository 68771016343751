import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BTButton from '../../../core/bt_button/BTButton';
import BTContainer from '../../../core/bt_container/BTContainer';
import styles from './SocialLoginModal.module.scss';
import { LogoFacebook } from 'react-ionicons';
import { LogoApple } from 'react-ionicons';
type ButtonItem = {
  name: string;
  icon: any;
  onClick: () => void;
};

function SocialLoginModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const buttonItems = [
    {
      name: 'Facebook',
      icon: <LogoFacebook cssClasses={'mr-2'} height="36px" width="36px" />,
    },
    {
      name: 'Apple',
      icon: <LogoApple cssClasses={'mr-2'} height="36px" width="36px" />,
    },
  ] as ButtonItem[];

  return (
    <>
      <Modal
        className={`${styles.modalFont}`}
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onClose}
      >
        <Modal.Body>
          <BTContainer className={`${styles.modalTitleFont}`}>
            <Row>
              <Col className={`text-center`}>Social login</Col>
            </Row>
            <Row>
              <Col className={`${styles.modalHeader}`}></Col>
            </Row>
            <div className={`my-5`}>
              {buttonItems.map((value, index) => (
                <Row key={index} className={`mt-2`}>
                  <Col>
                    <BTButton
                      className={`${styles.modalButton} ${styles.modalButtonFont}`}
                      variant="outline-primary"
                      size="lg"
                      block
                    >
                      {value.icon}
                      {t('socialLogin.buttonText')} {value.name}
                    </BTButton>
                  </Col>
                </Row>
              ))}
            </div>
          </BTContainer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SocialLoginModal;
