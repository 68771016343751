import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ProductsFilters, {
  CategoriesFilters,
  FilterInputType,
} from '../../components/products_filters/ProductsFilters';
import SearchProductsPagination from '../../components/search_products_pagination/SearchProductsPagination';
import Title from '../../components/title/Title';
import {
  GetProductsLightQueryVariables,
  useGetCmsBlocksLazyQuery,
} from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import ProductsFilterType from '../../models/ProductsFilterRes';
import { getSearchApiFilter } from '../../services/services';
export type SearchProductState = {
  query: GetProductsLightQueryVariables;
  title: string;
};

type FiltersParams = { [key in string]: string | string[] };

function SearchProduct() {
  const { text } = useParams<{ text: string }>();

  const queryParams = new URLSearchParams(useLocation().search);
  const paginationParams = Number(queryParams.get('p') || '1');
  const filtersParams64 = queryParams.get('filters');
  const title = queryParams.get('title') || decodeURIComponent(text);
  const topCmsBlock = queryParams.get('topCmsBlock');
  const bottomCmsBlock = queryParams.get('bottomCmsBlock');

  const searchText = decodeURIComponent(text).split(';').join(' ');

  const filtersParams: FiltersParams = filtersParams64
    ? JSON.parse(atob(filtersParams64))
    : {};

  const history = useHistory();

  const [filters, setFilters] = useState<CategoriesFilters>();

  const defaultQuery: GetProductsLightQueryVariables = {
    search: searchText,
    currentPage: paginationParams,
    pageSize: 8,
    sort: {
      relevance: 'DESC',
    },
  };

  const [query, setQuery] = useState<GetProductsLightQueryVariables>();

  const [filtersInitialized, setFiltersInitialized] = useState<boolean>(false);

  const [getBlocks, { data: blocks }] = useGetCmsBlocksLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const blockIds = [topCmsBlock, bottomCmsBlock].filter(
      item => !isEmpty(item)
    ) as string[];
    if (!isEmpty(blockIds)) {
      getBlocks({
        variables: {
          blockIds,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topCmsBlock, bottomCmsBlock]);

  useEffect(() => {
    setFilters(undefined);
    setQuery(undefined);
    setFiltersInitialized(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (query && filtersInitialized) {
      const _filters = filters
        ? Object.keys(filters).reduce((obj, key) => {
            const cat = filters[key];
            const sel = cat.value;
            return sel && isEmpty(sel)
              ? obj
              : ({ ...obj, [key]: sel } as FiltersParams);
          }, {} as FiltersParams)
        : {};
      const qs = queryString.stringify({
        p: query.currentPage,
        filters: btoa(JSON.stringify(_filters)),
        title,
        ...(topCmsBlock ? { topCmsBlock } : {}),
        ...(bottomCmsBlock ? { bottomCmsBlock } : {}),
      });
      history.replace(`${history.location.pathname}?${qs}`);
    } else if (!query && !filtersInitialized && !filters) {
      if (isEmpty(filtersParams)) {
        getSearchApiFilter(searchText).then(res => onFilterCategoryChange(res));
        // GESTISCO IL BACK
      } else {
        const categoryId = filtersParams['category_id'];
        getSearchApiFilter(searchText).then(res => {
          if (categoryId) {
            getSearchApiFilter(searchText, categoryId as string).then(res2 =>
              onFilterCategoryChange(
                [
                  ...(res || []).filter(c => c.id === 1),
                  ...res2.filter(c => c.id !== 1),
                ],
                filtersParams
              )
            );
          } else {
            onFilterCategoryChange(res, filtersParams);
          }
        });
      }
      setQuery(defaultQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filters, filtersInitialized]);

  function onFiltersSelected(filters: CategoriesFilters, init?: boolean) {
    init && setFiltersInitialized(init);
    const newQuery: GetProductsLightQueryVariables = {
      ...(init ? defaultQuery : query),
      filter: {},
      currentPage: init ? paginationParams : 1,
    };

    map(filters, (value, key) => {
      const allSelected = value.value;
      if (!isEmpty(allSelected)) {
        newQuery.filter = {
          ...newQuery.filter,
          [key]: {
            ...(Array.isArray(value.value) && { in: value.value }),
            ...(value.type !== 'price' &&
              !Array.isArray(value.value) && { eq: value.value }),
            ...(value.type === 'price' && {
              from: (value.value as string)?.split(',')[0],
              to: (value.value as string)?.split(',')[1],
            }),
          },
        };
      }
    });

    setQuery(newQuery);
  }

  function onFilterCategoryChange(
    _filters: ProductsFilterType[],
    fromParams?: FiltersParams
  ) {
    if (isEmpty(_filters)) {
      onFiltersSelected({}, true);
      return;
    }

    const CATEGORY_ID = 'category_id';
    const newFilters: CategoriesFilters = {};

    function getInputType({
      field,
      type,
    }: ProductsFilterType): FilterInputType {
      if (field === CATEGORY_ID || type === 'price') {
        return 'radio';
      } else {
        return 'checkbox';
      }
    }

    _filters?.forEach(filter => {
      newFilters[filter.field] = {
        label: filter.label,
        inputType: getInputType(filter),
        type: filter.type,
        value: fromParams ? fromParams[filter.field] : undefined,
        filters: filter.value.map(({ label, value, count }) => ({
          label: `${label} (${count})`,
          value,
          selected: false,
        })),
        resetOnRemove: filter.field === CATEGORY_ID,
        onClick:
          filter.field === CATEGORY_ID
            ? async (id?: string) => {
                const response = await getSearchApiFilter(
                  searchText,
                  id?.toString()
                );
                const newFilter = id
                  ? response.filter(i => i.field !== CATEGORY_ID)
                  : response;
                onFilterCategoryChange([...newFilter]);
              }
            : undefined,
      };
    });
    setFilters(prev => {
      if (!prev || isEmpty(prev)) {
        return newFilters;
      }
      return {
        category_id: prev[CATEGORY_ID],
        ...newFilters,
      };
    });
  }

  const getHtmlBlock = (identifier: string) => {
    return blocks?.cmsBlocks?.items?.find(b => b?.identifier === identifier)
      ?.content;
  };

  return (
    <>
      {!isEmpty(topCmsBlock) && topCmsBlock && (
        <BTContainer>
          <BTHtmlWrapper
            className={`my-2`}
            content={getHtmlBlock(topCmsBlock)}
          />
        </BTContainer>
      )}
      <Title name={`${title}`} />
      {!isEmpty(filters) && filters && (
        <ProductsFilters
          filters={filters}
          onChange={onFiltersSelected}
          position={'right'}
        />
      )}
      {query && filtersInitialized && (
        <SearchProductsPagination
          query={query}
          onPageChange={page => {
            setQuery({ ...query, currentPage: page });
          }}
        />
      )}
      {!isEmpty(bottomCmsBlock) && bottomCmsBlock && (
        <BTContainer>
          <BTHtmlWrapper
            className={`my-2`}
            content={getHtmlBlock(bottomCmsBlock)}
          />
        </BTContainer>
      )}
    </>
  );
}
SearchProduct.displayName = 'SearchProduct';
export default SearchProduct;
