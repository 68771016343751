import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BTContainer from '../../core/bt_container/BTContainer';

function NotFound() {
  return (
    <BTContainer className="my-4 text-center">
      <Row>
        <Col>
          <h2 className="font-weight-bold">OOPS!!!</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="font-weight-bold">Qualcosa è andato storto.</h4>
        </Col>
      </Row>
      <Row>
        <Col>La pagina che stai cercando non esiste.</Col>
      </Row>
    </BTContainer>
  );
}

export default NotFound;
