import React, { useEffect, useState } from 'react';
import {
  GetProductsLightQueryVariables,
  ProductLightFieldFragment,
  SearchResultPageInfo,
  useGetProductsLightLazyQuery,
} from '../../core/apollo/gql-generate';
import BTList from '../../core/bt_list/BTList';
import BTPagination from '../pagination/BTPagination';
import ProductCard from '../product_card/ProductCard';
import isEmpty from 'lodash.isempty';

export type SearchProductsPaginationProps = {
  query: GetProductsLightQueryVariables;
  onPageChange: (page: number) => void;
};

function SearchProductsPagination(props: SearchProductsPaginationProps) {
  const { query, onPageChange } = props;

  const [products, setProducts] = useState([] as ProductLightFieldFragment[]);
  const [pagination, setPagination] = useState({} as SearchResultPageInfo);

  const [getProducts, { loading }] = useGetProductsLightLazyQuery({
    onCompleted: data => {
      setProducts(data.products?.items as ProductLightFieldFragment[]);
      setPagination(data.products?.page_info as SearchResultPageInfo);
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading) {
      getProducts({
        variables: {
          ...query,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function nextPage() {
    if (!pagination) {
      return;
    }
    const next = pagination.current_page! + 1;
    next <= pagination.total_pages! && onPageChange(next);
  }

  function backPage() {
    if (!pagination) {
      return;
    }
    const back = pagination.current_page! - 1;
    back > 0 && onPageChange(back);
  }

  return (
    <>
      {!loading && query && (
        <>
          <BTList
            itemClassName={`p-2`}
            items={products.filter(p => !isEmpty(p))}
            renderItem={item => (
              <ProductCard product={item as ProductLightFieldFragment} />
            )}
            numerOfRowElement={2}
          />
          <BTPagination
            currentPage={pagination.current_page}
            totalPages={pagination.total_pages}
            onBack={backPage}
            onNext={nextPage}
            size={(products && products.length) || 0}
          />
        </>
      )}
    </>
  );
}

export default SearchProductsPagination;
