import React, { useState } from 'react';
import { Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import Login from '../login/Login';
import SocialLoginButton from '../social_login/button/SocialLoginButton';
import SocialLoginModal from '../social_login/modal/SocialLoginModal';
import RegisterUser from '../register_user/RegisterUser';
import styles from './LoginRegisterModal.module.scss';
import './LoginRegisterModal.scss';

function LoginRegisterModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  function onCloseHandler() {
    onClose();
  }

  function socialLoginHandler() {
    onCloseHandler();
    setshowSocialLogin(true);
  }

  const [showSocialLogin, setshowSocialLogin] = useState(false);

  return (
    <div>
      <SocialLoginModal
        show={showSocialLogin}
        onClose={() => setshowSocialLogin(false)}
      />
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onCloseHandler}
      >
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Login">
            <Row>
              <Col>
                <Nav variant="pills" justify>
                  <Nav.Item>
                    <Nav.Link className={`${styles.tabTitle}`} eventKey="Login">
                      Login
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Register">Registrati</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="Login">
                    <Login
                      onCompleted={onCloseHandler}
                      className={'mt-5'}
                      socialLogin={false}
                      socialComponent={
                        <SocialLoginButton onClick={socialLoginHandler} />
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Register">
                    <RegisterUser onCompleted={onCloseHandler} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LoginRegisterModal;
