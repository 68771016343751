import isEmpty from 'lodash.isempty';
import { useEffect } from 'react';
import useVersion from '../hooks/useVersion';
import { getVersion } from '../services/services';

const VersionCheck = () => {
  const versionHook = useVersion();
  useEffect(() => {
    if (!isEmpty(versionHook)) {
      getVersion().then(({ version }) => {
        if (!isEmpty(version) && version !== versionHook) {
          clearCache(true);
        }
      });
    }
  }, [versionHook]);

  return <></>;
};

const clearCache = (reloadAfterClear = true) => {
  if ('caches' in window) {
    caches.keys().then(names => {
      names.forEach(async name => {
        await caches.delete(name);
      });
    });

    if (reloadAfterClear) window.location.reload();
  }
};

export default VersionCheck;
