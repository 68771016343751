import React from 'react';
import { Container, ContainerProps } from 'react-bootstrap';

type BTContainerProps = {
  style?: React.CSSProperties;
} & ContainerProps;

function BTContainer(props: BTContainerProps) {
  return (
    <Container fluid {...props} style={props.style}>
      {props.children}
    </Container>
  );
}

export default BTContainer;
