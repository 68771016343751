import { useEffect, useState } from 'react';
import { useGetCmsBlocksLazyQuery } from '../core/apollo/gql-generate';
import isEmpty from 'lodash.isempty';

function useCmdBlocks(identifiers: string[]) {
  const [blocks, setBlocks] = useState<Record<string, string>>({});

  const [fetch] = useGetCmsBlocksLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: data => {
      const _blocks: typeof blocks = {};
      data.cmsBlocks?.items?.forEach(item => {
        if (item && item.identifier && item.content) {
          _blocks[item.identifier] = item.content;
        }
      });
      setBlocks(() => _blocks);
    },
  });

  useEffect(() => {
    if (!isEmpty(identifiers)) {
      fetch({
        variables: {
          blockIds: identifiers,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    blocks,
  };
}

export default useCmdBlocks;
