import isEmpty from 'lodash.isempty';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CouponForm, {
  CouponFormValueType,
} from '../../components/coupon_form/CouponForm';
import ProductCart, {
  UpdateProductCartItem,
} from '../../components/product_cart/ProductCart';
import { appStateVar } from '../../core/apollo/Cache';
import {
  CartItemInterface,
  CartItemUpdateInput,
  CustomizableOptionInput,
} from '../../core/apollo/gql-generate';
import { mergeAppState } from '../../core/apollo/operations/mutations/appState';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import { useCart } from '../../core/contexts/CartContext';
import {
  addErrorNotification,
  addSuccesNotification,
} from '../../core/notification/Notification';
import PriceText from '../../core/price_text/PriceText';
import styles from './Cart.module.scss';

function Cart() {
  const { t } = useTranslation();
  const history = useHistory();

  const appState = appStateVar();

  const mergeAppStateDispatch = mergeAppState(appState, appStateVar);

  const {
    cartSize,
    cart,
    updateCartItem,
    removeCartItem,
    clearCart,
    applyCoupon,
    removeCoupon,
  } = useCart();

  const [coupon, setCoupon] = useState<CouponFormValueType>({ couponCode: '' });

  useLayoutEffect(() => {
    mergeAppStateDispatch({ dockStatus: 'cart' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cart && cart.applied_coupons && cart.applied_coupons[0]?.code) {
      setCoupon({ couponCode: cart.applied_coupons[0]?.code });
    } else {
      setCoupon({ couponCode: '' });
    }
  }, [cart]);

  function handleRemoveItems() {
    clearCart();
  }

  function handleRemoveItem(product: CartItemInterface) {
    removeCartItem(product.uid);
  }

  function handleUpdateProduct(
    value: UpdateProductCartItem,
    product: CartItemInterface
  ) {
    const opts = !isEmpty(value.selectOpts)
      ? Object.keys(value.selectOpts).map<CustomizableOptionInput>(key => ({
          value_string: value.selectOpts![key],
          id: Number(key),
        }))
      : [{ id: 0, value_string: '0' }];
    updateCartItem({
      cart_item_uid: product.uid,
      quantity: value.total,
      customizable_options: opts,
    } as CartItemUpdateInput);
  }

  function handlerCoupon(value: CouponFormValueType) {
    if (!isEmpty(value)) {
      applyCoupon(value.couponCode)
        .then((res: any) => {
          addSuccesNotification({
            message: `Codice coupon ${t('cart.couponTextApplied')}`,
          });
        })
        .catch(err => {
          addErrorNotification({
            title: 'COUPON ERROR',
            message: t('cart.couponTextError'),
          });
        });
    }
  }

  function handlerRemoveCoupon() {
    removeCoupon()
      .then(res => {
        addSuccesNotification({
          message: t('cart.couponTextRemoved'),
        });
      })
      .catch(err => {
        addErrorNotification({
          title: 'COUPON ERROR',
          message: t('cart.couponTextError'),
        });
      });
  }

  return (
    <>
      {cart && (
        <BTContainer>
          <Row>
            <Col className={`${styles.boldDescription} mt-3`}>
              {t('cart.summary')}
            </Col>
          </Row>
          <Row>
            <Col className={`${styles.smallText} mt-3 ml-1`}>
              {t('cart.shippingPrice')}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className={`text-left ${styles.normalDescription} mt-3 ml-1`}>
              {t('common.partialTot')}
            </Col>
            <Col className={`text-right ${styles.normalDescription} mt-3 mr-3`}>
              <PriceText
                value={cart.prices?.subtotal_including_tax?.value || 0}
              />
            </Col>
          </Row>
          {false && (
            <Row>
              <Col
                className={`text-left ${styles.normalDescription} mt-1 ml-1`}
              >
                {t('common.tax')}
              </Col>
              <Col
                className={`text-right ${styles.normalDescription} mt-1 mr-3`}
              >
                <PriceText
                  value={
                    (cart.prices?.subtotal_including_tax?.value || 0) -
                    (cart.prices?.subtotal_excluding_tax?.value || 0)
                  }
                />
              </Col>
            </Row>
          )}
          {cart.prices?.discounts?.map(item => (
            <Row className="mt-3">
              <Col className={`text-left ${styles.normalDescription} ml-1`}>
                {t('common.discount')}
              </Col>
              <Col className={`text-right ${styles.normalDescription} mr-3`}>
                <PriceText value={(item?.amount.value || 0) * -1} />
              </Col>
            </Row>
          ))}
          <Row className="mt-3">
            <Col className={`text-left ${styles.normalDescription} ml-1`}>
              {t('common.total')}
            </Col>
            <Col className={`text-right ${styles.boldTotal} mr-3`}>
              <PriceText value={cart.prices?.grand_total?.value || 0} />
            </Col>
          </Row>
          <hr />
          <Row>
            <CouponForm
              initialValues={coupon}
              onSubmit={handlerCoupon}
              onRemove={handlerRemoveCoupon}
              styles={styles}
            />
          </Row>
          <hr />
          <Row>
            <Col className={'text-center'}>
              <BTButton
                variant="secondary"
                size="lg"
                className={`text-center`}
                onPress={() => history.push('/cart/checkout')}
                disabled={cartSize === 0}
              >
                {t('cart.checkout')}
              </BTButton>
            </Col>
          </Row>
          {cart.items?.map((value, index) => (
            <Row className={'mt-3'} key={value?.uid}>
              <Col>
                <ProductCart
                  product={value as CartItemInterface}
                  onChange={qty =>
                    handleUpdateProduct(qty, value as CartItemInterface)
                  }
                  onRemove={() => handleRemoveItem(value as CartItemInterface)}
                />
              </Col>
            </Row>
          ))}
          <hr />
          <Row className={'mt-2 mb-5'}>
            <Col className={'col-6 pr-1'}>
              <BTButton
                variant="outline-primary"
                className={`${styles.smallBoldText}`}
                onPress={handleRemoveItems}
                block
              >
                {t('cart.emptyCart')}
              </BTButton>
            </Col>
            <Col className={'col-6 pl-1'}>
              <BTButton
                variant="outline-primary"
                className={`${styles.smallBoldText}`}
                onPress={() => history.goBack()}
                block
              >
                {t('cart.continue')}
              </BTButton>
            </Col>
          </Row>
        </BTContainer>
      )}
    </>
  );
}
Cart.displayName = 'Cart';
export default Cart;
