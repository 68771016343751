import React from 'react';
import styles from './StepTitle.module.scss';

function StepTitle({ title, number }: { title: string; number: number }) {
  return (
    <div className={`${styles.stepTitle}`}>
      <div className={`${styles.stepNumber}`}>{number}</div>
      <div className={`${styles.stepText}`}>{title}</div>
    </div>
  );
}

export default StepTitle;
