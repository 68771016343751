import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchProductsPagination from '../../components/search_products_pagination/SearchProductsPagination';
import Title from '../../components/title/Title';
import { GetProductsLightQueryVariables } from '../../core/apollo/gql-generate';
export type SearchProductState = {
  query: GetProductsLightQueryVariables;
  title: string;
};

function StateSearchProduct() {
  const state: SearchProductState = useLocation().state as SearchProductState;
  const history = useHistory();

  const defaultQuery: GetProductsLightQueryVariables = {
    ...state.query,
    currentPage: state.query.currentPage || 1,
    pageSize: 8,
    sort: {
      relevance: 'DESC',
    },
  };

  return (
    <>
      <Title name={`${state.title}`} />
      {defaultQuery && (
        <SearchProductsPagination
          query={defaultQuery}
          onPageChange={page => {
            history.push({
              pathname: history.location.pathname,
              state: {
                ...state,
                query: { ...defaultQuery, currentPage: page },
              } as SearchProductState,
            });
          }}
        />
      )}
    </>
  );
}

StateSearchProduct.displayName = 'SearchProduct';
export default StateSearchProduct;
