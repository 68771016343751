import React from 'react';
import { useTranslation } from 'react-i18next';
import menuIcon from '../../assets/main_btn.svg';
import Logout from '../../components/logout/Logout';
import Title from '../../components/title/Title';
import BTList from '../../core/bt_list/BTList';
import Area from './components/Area';

function Account() {
  const { t } = useTranslation();

  const areas = [
    {
      name: t('account.anagraphic.title'),
      icon: menuIcon,
      link: '/accounts/user-registry',
    },
    {
      name: t('account.address'),
      icon: menuIcon,
      link: '/accounts/address',
    },
    {
      name: t('account.orders'),
      icon: menuIcon,
      link: '/accounts/orders',
    },
    {
      name: t('account.payments'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.coupons'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.whishlist'),
      icon: menuIcon,
      link: '/accounts/wishlist',
    },
    {
      name: t('account.rma'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.consents'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.cookies'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.newsletter'),
      icon: menuIcon,
      link: '',
    },
    {
      name: t('account.removeAccount'),
      icon: menuIcon,
      link: '/accounts/delete',
    },
  ];

  return (
    <>
      <Title name={t('account.title')} rightElement={<Logout />} />
      <BTList
        className={`mt-2`}
        items={areas.filter(a => a.link)}
        renderItem={item => (
          <Area icon={item.icon} name={item.name} link={item.link} />
        )}
        numerOfRowElement={1}
      />
    </>
  );
}
Account.displayName = 'Account';
export default Account;
