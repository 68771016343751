import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from '@fortawesome/free-solid-svg-icons';

type BTPaginationProps = {
  onBack: () => void;
  onNext: () => void;
  currentPage?: number;
  totalPages?: number;
  size: number;
  emptyText?: string;
};

function BTPagination(props: BTPaginationProps) {
  const { emptyText, onBack, onNext, size, currentPage, totalPages } = props;
  const { t } = useTranslation();
  return (
    <BTContainer>
      {size > 0 && (
        <Row className={`border-bottom mt-2`}>
          <Col className={`text-center`}>
            <BTButton onPress={onBack} variant="link">
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </BTButton>
          </Col>
          <Col
            className={`text-center font-weight-bold p-2`}
          >{`${currentPage}/${totalPages}`}</Col>
          <Col className={`text-center`}>
            <BTButton onPress={onNext} variant="link">
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </BTButton>
          </Col>
        </Row>
      )}
      {!size && (
        <Row className={`text-center font-italic my-2`}>
          <Col>{emptyText || t('common.noResult')}</Col>
        </Row>
      )}
    </BTContainer>
  );
}

export default BTPagination;
