import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tokenIdVar } from '../core/apollo/Cache';
import { useRevokeCustomerTokenMutation } from '../core/apollo/gql-generate';
import { cleanToken } from '../core/apollo/operations/mutations/token';
import { useCart } from '../core/contexts/CartContext';
import { addErrorNotification } from '../core/notification/Notification';

const useLogout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [revokeCustomerToken] = useRevokeCustomerTokenMutation();
  const cleanSessionToken = cleanToken(tokenIdVar);

  const { reloadCart: loggedOut } = useCart();

  async function logout() {
    try {
      const { data: result } = await revokeCustomerToken();
      if (result) {
        cleanSessionToken();
        await loggedOut();
        history.push('/home');
      } else {
        addErrorNotification({
          title: 'LOGOUT ERROR',
          message: t('logout.error'),
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return logout;
};

export default useLogout;
