import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  CheckmarkCircle,
  ChevronForwardOutline,
  CloseCircle,
  Ellipse,
} from 'react-ionicons';
import { generatePath, useHistory } from 'react-router-dom';
import {
  CustomerOrder,
  CustomerOrdersFilterInput,
  SearchResultPageInfo,
  useGetCustomerOrderLazyQuery,
} from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import PriceText from '../../core/price_text/PriceText';
import BTPagination from '../pagination/BTPagination';
import styles from './OrdersPagination.module.scss';

export type OrderViewInputType = {
  filter?: CustomerOrdersFilterInput;
  pageSize: number;
  currentPage: number;
};

function OrderPagination({ input }: { input: OrderViewInputType }) {
  const [orders, setOrders] = useState<CustomerOrder[]>([]);
  const [pagination, setPagination] = useState<SearchResultPageInfo>({});
  const [getOrders, { loading }] = useGetCustomerOrderLazyQuery({
    onCompleted: data => {
      setOrders(data.customer?.orders?.items as CustomerOrder[]);
      setPagination(data.customer?.orders?.page_info as SearchResultPageInfo);
    },
  });

  useEffect(() => {
    if (!loading) {
      getOrders({
        variables: {
          ...input,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextPage() {
    if (!pagination) {
      return;
    }
    const next = pagination.current_page! + 1;
    if (next <= pagination.total_pages!) {
      getOrders({
        variables: {
          ...input,
          currentPage: next,
        },
      });
    }
  }

  function backPage() {
    if (!pagination) {
      return;
    }
    const back = pagination.current_page! - 1;
    if (back > 0) {
      getOrders({
        variables: {
          ...input,
          currentPage: back,
        },
      });
    }
  }

  return (
    <>
      {!loading && input && (
        <>
          <BTContainer className={'text-center'}>
            {orders?.map(value => (
              <OrderItem key={value.id} order={value} />
            ))}
          </BTContainer>
          <BTPagination
            currentPage={pagination.current_page}
            totalPages={pagination.total_pages}
            onBack={backPage}
            onNext={nextPage}
            size={orders?.length || 0}
          />
        </>
      )}
    </>
  );
}

function OrderItem({ order }: { order: CustomerOrder }) {
  const history = useHistory();
  const { t } = useTranslation();

  const statusCircle: { [key in string]: JSX.Element } = {
    Elaborazione: <></>, //ordine registrato
    Pending: <></>, //ordine in attesa di pagamento
    'In fase di preparazione': (
      <Ellipse color={'var(--secondary)'} width={'40px'} height={'40px'} />
    ),
    Spedito: <></>,
    Consegnato: (
      <CheckmarkCircle
        color={'var(--primary)'}
        width={'40px'}
        height={'40px'}
      />
    ),
    Cancellato: <CloseCircle color={'red'} width={'40px'} height={'40px'} />,
    Ricevuto: (
      <CheckmarkCircle
        color={'var(--primary)'}
        width={'40px'}
        height={'40px'}
      />
    ),
  };

  return (
    <Row
      className={`${styles.box} py-3 align-items-center text-left`}
      onClick={() =>
        history.push({
          pathname: generatePath('/accounts/orders/:number', {
            number: order.number,
          }),
        })
      }
    >
      <Col className={'text-left'} xs={2} md={1}>
        {statusCircle[order.status]}
      </Col>
      <Col className={`pr-0 pl-2 align-items-left`} xs={8} md={10}>
        <Row className={`${styles.orderCode}`}>
          <Col>{order.number}</Col>
        </Row>
        <Row className={`${styles.orderSmall} `}>
          <Col>
            <span className={`${styles.bold} pr-2`}>
              {t('order.orderDate')}
            </span>
            {moment(order.order_date).format('DD/MM/YYYY')}
          </Col>
        </Row>
        <Row className={`${styles.orderSmall}`}>
          <Col>
            <span className={`${styles.bold} pr-2`}>{t('order.total')}</span>
            <PriceText value={order?.total?.grand_total.value || 0} />
          </Col>
        </Row>
        <Row className={`${styles.orderSmall}`}>
          <Col>
            <span className={`${styles.bold} pr-2`}>{t('order.state')}</span>
            {order.status}
          </Col>
        </Row>
      </Col>
      <Col xs={2} md={1}>
        <ChevronForwardOutline
          color={'var(--primary)'}
          width={'40px'}
          height={'40px'}
        />
      </Col>
    </Row>
  );
}

export default OrderPagination;
