import { useEffect, useState } from 'react';

function useRestApi<T>(serviceFn: () => Promise<T>) {
  const [response, setResponse] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refetch() {
    serviceFn()
      .then(res => setResponse(res))
      .catch(err => {
        setError(err);
        setResponse(undefined);
      })
      .finally(() => setLoading(false));
  }

  return {
    data: response,
    loading,
    error,
    refetch,
  };
}

export default useRestApi;
