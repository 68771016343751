import { ReactiveVar } from '@apollo/client';
import { set } from 'lodash';
import { AppState } from '../../types';
import merge from 'lodash.merge';

export function updateAppState(appStateVar: ReactiveVar<AppState>) {
  return (value: AppState) => {
    appStateVar(value);
  };
}

export function updateFieldAppState(
  appState: AppState,
  appStateVar: ReactiveVar<AppState>
) {
  const clone = { ...appState };
  return <T>(path: keyof AppState, value: T) => {
    appStateVar(set(clone, path, value));
  };
}

export function mergeAppState(
  appState: AppState,
  appStateVar: ReactiveVar<AppState>
) {
  const clone = { ...appState };
  return (newValues: Partial<AppState>) => {
    appStateVar(merge(clone, newValues));
  };
}
