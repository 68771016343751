import isEmpty from 'lodash.isempty';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../../components/product_card/ProductCard';
import Title from '../../components/title/Title';
import { appStateVar } from '../../core/apollo/Cache';
import {
  GetProductsLightQueryVariables,
  ProductLightFieldFragment,
  useGetProductsLightQuery,
} from '../../core/apollo/gql-generate';
import { mergeAppState } from '../../core/apollo/operations/mutations/appState';
import BTButton from '../../core/bt_button/BTButton';
import BTCarousel from '../../core/bt_carousel/BTCarousel';
import BTContainer from '../../core/bt_container/BTContainer';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import useVersion from '../../hooks/useVersion';
import styles from './Home.module.scss';

const CMS_BLOCKS_SLIDER_HOME: { [key in string]: string } = {
  HOME_MOBILE_SLIDERHOME1: 'mobile_sliderhome1',
  HOME_MOBILE_SLIDERHOME2: 'mobile_sliderhome2',
  HOME_MOBILE_SLIDERHOME3: 'mobile_sliderhome3',
};
const CMS_BLOCKS_HOME: { [key in string]: string } = {
  HOME_CARD_HIGHLIGHTS: 'mobile_homecard_highliths',
  HOME_MOBILE_SIS_LINKS: 'mobile_home_sis_links',
  HOME_MOBILE_PROMO_FIN: 'mobile_promo-fin-home',
  HOME_MOBILE_TOP_CATEGORY: 'mobile_top-category',
};

function Home() {
  const { t } = useTranslation();
  const history = useHistory();

  const appState = appStateVar();

  const mergeAppStateDispatch = mergeAppState(appState, appStateVar);

  const version = useVersion();

  const { blocks } = useCmdBlocks([
    ...Object.keys(CMS_BLOCKS_HOME).map(key => CMS_BLOCKS_HOME[key]),
    ...Object.keys(CMS_BLOCKS_SLIDER_HOME).map(
      key => CMS_BLOCKS_SLIDER_HOME[key]
    ),
  ]);

  const { loading: newsLoading, data: newsData } = useGetProductsLightQuery({
    variables: {
      filter: {
        prodotto_novita: {
          eq: '1',
        },
      },
      currentPage: 1,
      pageSize: 10,
    },
  });

  useLayoutEffect(() => {
    mergeAppStateDispatch({ dockStatus: 'home' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSilders(cms: { [key in string]: string }) {
    return (
      <Swiper
        className="bt-swiper-container"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
      >
        {Object.keys(cms)
          .filter(key => {
            const identifier = cms[key];
            const block = blocks[identifier];
            return block ? true : false;
          })
          .map(key => {
            const identifier = cms[key];
            return (
              <SwiperSlide key={key}>
                <BTHtmlWrapper content={blocks[identifier]} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    );
  }

  function newsHandler() {
    history.push({
      pathname: '/products-state-search',
      state: {
        query: {
          filter: {
            prodotto_novita: {
              eq: '1',
            },
          },
          currentPage: 1,
          pageSize: 8,
        } as GetProductsLightQueryVariables,
        title: t('home.title'),
      },
    });
  }

  return (
    <>
      {!isEmpty(blocks) && (
        <BTContainer className={`my-3`}>
          {getSilders(CMS_BLOCKS_SLIDER_HOME)}
        </BTContainer>
      )}
      <BTContainer>
        <BTHtmlWrapper
          content={blocks[CMS_BLOCKS_HOME.HOME_MOBILE_TOP_CATEGORY]}
        />
      </BTContainer>
      {!newsLoading && !isEmpty(newsData?.products?.items) && (
        <>
          <Title className={`mt-4`} name={t('home.title')} />
          <BTCarousel
            className={`px-2`}
            itemClassName={`p-2`}
            items={(
              (newsData?.products?.items as ProductLightFieldFragment[]) ?? []
            ).filter(p => !isEmpty(p))}
            renderItem={item => <ProductCard product={item} />}
          />
          <BTContainer className="text-right mt-1">
            <BTButton onPress={newsHandler}>Esplora tutte</BTButton>
          </BTContainer>
        </>
      )}
      {!isEmpty(blocks) && (
        <BTContainer className={`my-3`}>
          <BTHtmlWrapper
            content={blocks[CMS_BLOCKS_HOME.HOME_CARD_HIGHLIGHTS]}
          />
          <BTHtmlWrapper
            content={blocks[CMS_BLOCKS_HOME.HOME_MOBILE_SIS_LINKS]}
          />
          <BTHtmlWrapper
            content={blocks[CMS_BLOCKS_HOME.HOME_MOBILE_PROMO_FIN]}
          />
        </BTContainer>
      )}
      <div className={`d-flex justify-content-center ${styles.version}`}>
        Versione: {version}
      </div>
    </>
  );
}
Home.displayName = 'Home';
export default Home;
