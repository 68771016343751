const TOKEN = 'TOKEN';

const CART = 'CART';

function readCart() {
  return localStorage.getItem(CART);
}

function saveCart(cart: string) {
  return localStorage.setItem(CART, cart);
}

function cleanCart() {
  localStorage.removeItem(CART);
}

function readToken() {
  return localStorage.getItem(TOKEN);
}

function saveToken(token: string) {
  return localStorage.setItem(TOKEN, token);
}

function cleanToken() {
  localStorage.removeItem(TOKEN);
}

function clean() {
  cleanCart();
  cleanToken();
}

const Storage = {
  readToken,
  saveToken,
  cleanToken,
  readCart,
  saveCart,
  cleanCart,
  clean,
};

export default Storage;
