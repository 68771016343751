import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import CategoryRow from '../../components/category_row/CategoryRow';
import Title from '../../components/title/Title';
import {
  CategoryFieldFragment,
  GetProductsLightQueryVariables,
  useGetCategoriesQuery,
} from '../../core/apollo/gql-generate';
import BTContainer from '../../core/bt_container/BTContainer';
import BTList from '../../core/bt_list/BTList';
import { CategoryStateType } from '../category/Category';

function Offers() {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: categories, loading } = useGetCategoriesQuery({
    variables: {
      filters: {
        parent_id: {
          in: ['36'],
        },
      },
    },
  });

  function categoryHandler(category: CategoryFieldFragment) {
    history.push({
      pathname: generatePath('/categories/:uid', {
        uid: category.uid,
      }),
      state: {
        offer: {
          duration: 1,
          unit: 'year',
        },
      } as CategoryStateType,
    });
  }

  function allHandler() {
    history.push({
      pathname: '/products',
      state: {
        query: {
          filter: {
            category_uid: {
              in: categories?.categoryList?.map(i => i!.uid),
            },
            special_from_date: {
              from: `${moment()
                .subtract(1, 'year')
                .format('YYYY-MM-DD')} 00:00:00`,
              to: `${moment().format('YYYY-MM-DD')} 00:00:00`,
            },
            special_to_date: {
              from: `${moment().format('YYYY-MM-DD')} 00:00:00`,
              to: `${moment().add(1, 'year').format('YYYY-MM-DD')} 00:00:00`,
            },
          },
          currentPage: 1,
          pageSize: 8,
        } as GetProductsLightQueryVariables,
        title: `${t('offersPage.all')}`,
      },
    });
  }

  return (
    <>
      <Title name={t('offersPage.title')} />
      {!loading && (
        <>
          <BTContainer className={`mt-2`}>
            <CategoryRow
              title={t('offersPage.all')}
              onClick={() => allHandler()}
            />
          </BTContainer>
          <BTList
            items={
              (categories?.categoryList?.filter(c => c!.product_count! > 0) ||
                []) as CategoryFieldFragment[]
            }
            renderItem={item => (
              <CategoryRow
                title={item.name || 'noname'}
                onClick={() => categoryHandler(item)}
              />
            )}
            numerOfRowElement={1}
          />
        </>
      )}
    </>
  );
}
Offers.displayName = 'Offers';
export default Offers;
