import { faEye, faHistory, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Title from '../../components/title/Title';
import BTContainer from '../../core/bt_container/BTContainer';
import useMpBlog from '../../hooks/useMpBlog';
import styles from './NewsDetail.module.scss';

function NewsDetail() {
  const { id: idEcoded } = useParams<{ id: string }>();
  const id = decodeURIComponent(idEcoded);

  const { loading, getMpBlog } = useMpBlog();

  const mpBlog = useMemo(() => {
    return !loading ? getMpBlog(Number(id)) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {!loading && mpBlog && (
        <>
          <Title name={mpBlog.meta_title || mpBlog.name} />
          <BTContainer>
            <div className={`d-flex flex-column ${styles.container}`}>
              <div>
                <FontAwesomeIcon className="mt-2 mr-1" icon={faHistory} />
                {moment(mpBlog.publish_date).format('DD/MM/YYYY')}
              </div>
              <img
                className={`${styles.image}`}
                src={`${process.env.REACT_APP_MAGENTO_SERVER}/media/mageplaza/blog/post/resize/600x/${mpBlog.image}`}
                alt={`${mpBlog.id}`}
              />
              <div
                dangerouslySetInnerHTML={{ __html: mpBlog.post_content }}
              ></div>
              <div className={`d-flex flex-row mt-3 ${styles.infos}`}>
                <div className="ml-1">
                  <FontAwesomeIcon className="mr-1" icon={faUser} />
                  By {mpBlog.author_name} |
                </div>
                <div className="ml-1">
                  <FontAwesomeIcon className="mr-1" icon={faEye} />
                  {mpBlog.view_traffic}
                </div>
              </div>
            </div>
          </BTContainer>
        </>
      )}
    </>
  );
}
NewsDetail.displayName = 'NewsDetail';
export default NewsDetail;
