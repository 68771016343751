import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderPagination, {
  OrderViewInputType,
} from '../../components/orders_pagination/OrdersPagination';
import Title from '../../components/title/Title';

function Orders() {
  const { t } = useTranslation();

  const query: OrderViewInputType = {
    currentPage: 1,
    pageSize: 8,
  };

  return (
    <>
      <Title name={t('account.orders')} />
      <OrderPagination input={query} />
    </>
  );
}
Orders.displayName = 'Orders';
export default Orders;
