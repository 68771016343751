import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Title from '../../components/title/Title';
import {
  CustomerOrder,
  ProductLightFieldFragment,
  useGetCustomerOrderQuery,
  useGetCustomerQuery,
  useGetProductsLightLazyQuery,
} from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import PriceText from '../../core/price_text/PriceText';
import styles from './OrderDetail.module.scss';
type ButtonItem = {
  name: string;
  color: string;
  onClick: () => void;
};

function OrderDetail() {
  const { number: numEncoded } = useParams<{ number: string }>();
  const number = decodeURIComponent(numEncoded);

  const { t } = useTranslation();
  const history = useHistory();

  const [order, setOrder] = useState<CustomerOrder>();
  const [productsInfo, setProductsInfo] = useState<ProductLightFieldFragment[]>(
    []
  );

  const { data: customerRes } = useGetCustomerQuery({
    fetchPolicy: 'cache-first',
  });

  useGetCustomerOrderQuery({
    variables: {
      filter: {
        number: {
          eq: number,
        },
      },
    },
    onCompleted: data => {
      setOrder(data.customer?.orders?.items[0] as CustomerOrder);
    },
  });

  const [getProductsInfo, { loading }] = useGetProductsLightLazyQuery({
    onCompleted: data => {
      setProductsInfo(data.products?.items as ProductLightFieldFragment[]);
    },
  });

  useEffect(() => {
    if (order) {
      const skus = order.items?.map(i => i!.product_sku);
      getProductsInfo({
        variables: {
          search: skus?.join(' '),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  function getProductUrlImage(sku: string) {
    return productsInfo.find(p => p.sku === sku)?.image?.url || '';
  }

  const buttonItems = [
    {
      name: t('order.trace'),
      color: 'primary',
      onClick: () => {
        history.push({
          pathname: generatePath(
            '/trackings/data/:orderNumber/:customerEmail',
            {
              orderNumber: number,
              customerEmail: customerRes?.customer?.email || '',
            }
          ),
        });
      },
    },
    {
      name: t('order.return'),
      color: 'light-grey',
    },
    {
      name: t('order.query'),
      color: 'light-grey',
    },
    {
      name: t('order.help'),
      color: 'light-grey',
    },
    {
      name: t('order.invoice'),
      color: 'light-grey',
    },
    {
      name: t('order.evaluate'),
      color: 'light-grey',
    },
    {
      name: t('order.review'),
      color: 'light-grey',
    },
    {
      name: t('order.hide'),
      color: 'light-grey',
    },
  ] as ButtonItem[];

  return (
    <>
      <Title name={t('account.orders')} />
      {order && (
        <BTContainer>
          <Row className={`${styles.orderCode} mt-3`}>
            <Col>
              {t('order.number')}{' '}
              <span className={`${styles.bold}`}>{order.number}</span>
            </Col>
          </Row>
          <Row className={`${styles.normal} ${styles.bold} mt-4`}>
            <Col>{order.status === 'Ricevuto' && t('order.delivered')}</Col>
          </Row>
          <Row className={`${styles.normal} mb-3`}>
            <Col>{order.status}</Col>
          </Row>
          {!loading &&
            order.items?.map((value, index: number) => (
              <Row
                key={index}
                className={'mt-4 mb-3'}
                onClick={() =>
                  history.push({
                    pathname: generatePath('/products/:sku', {
                      sku: value?.product_sku!,
                    }),
                  })
                }
              >
                <Col className={'col-5'}>
                  <img
                    className={`${styles.image}`}
                    src={getProductUrlImage(value!.product_sku)}
                    alt=""
                  />
                </Col>
                <Col>
                  <Row className={`${styles.normal} ${styles.bold} text-left`}>
                    <Col>{value!.product_name}</Col>
                  </Row>
                  <Row className={`${styles.price} mt-5`}>
                    <Col>
                      <PriceText
                        value={value!.product_sale_price.value as number}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}

          {buttonItems.map((value, index) => (
            <Row key={index} className={`mt-2`}>
              <Col className={`text-center`}>
                <BTButton
                  className={`${styles.button}`}
                  variant={value.color}
                  size="lg"
                  block
                  onPress={value.onClick}
                >
                  {value.name}
                </BTButton>
              </Col>
            </Row>
          ))}
        </BTContainer>
      )}
    </>
  );
}
OrderDetail.displayName = 'OrderDetail';
export default OrderDetail;
