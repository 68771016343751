import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import {
  CategoryFieldFragment,
  useGetCategoriesQuery,
} from '../core/apollo/gql-generate';
import ProductsFilterType from '../models/ProductsFilterRes';
import { getCategoryApiFilter } from '../services/services';

export type CategoryData = {
  filters?: ProductsFilterType[];
  category?: CategoryFieldFragment;
};

function useCategory({ uid }: { uid: string }) {
  const [data, setData] = useState<CategoryData>({} as CategoryData);

  const [loading, setLoading] = useState<boolean>(true);

  useGetCategoriesQuery({
    variables: {
      filters: {
        category_uid: {
          eq: uid,
        },
      },
    },
    onCompleted: async data => {
      if (data.categoryList && !isEmpty(data.categoryList)) {
        const category: CategoryFieldFragment = data.categoryList[0]!;
        let filters = await getCategoryApiFilter(category.id!.toString());
        filters = filters
          .map<ProductsFilterType>(customFilter)
          .filter(filter => !isEmpty(filter.value));
        setData(() => ({
          category,
          filters,
        }));
      } else {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      setLoading(false);
    }
  }, [data]);

  return { category: data, loading };
}

export default useCategory;

const customFilter = (filter: ProductsFilterType): ProductsFilterType => {
  switch (filter.id) {
    case 7:
      return {
        ...filter,
        value: filter.value.filter(v => v.label.toLowerCase() !== 'no'),
      };
    default:
      return filter;
  }
};
