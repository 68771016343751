import { I18nTranslation } from './Resources';

const it: I18nTranslation = {
  translation: {
    common: {
      welcome: 'Benvenuto',
      close: 'Chiudi',
      click: 'Clicca',
      addToCart: 'Aggiungi al carrello',
      modify: 'Modifica',
      save: 'Salva',
      cancel: 'Annulla',
      partialTot: 'Subtotale',
      shipping: 'Spedizione',
      tax: 'Tasse',
      total: 'Totale',
      totalCart: 'Totale carrello',
      noResult: 'Nessun risultato',
      confirm: 'Conferma',
      goWithoutToCart: 'Procedi senza',
      or: 'o',
      extraServices: 'Servizi aggiuntivi',
      discount: 'Sconto',
      taxIncluded: 'Iva inclusa',
      taxEx: 'Iva Esclusa',
      advisedPrice: 'Prezzo consigliato',
    },
    formMessage: {
      required: 'Campo obbligatorio',
      cf: 'Codice Fiscale errato',
      vat_id: 'Partita Iva errato',
      postcode: 'Codice postale errato',
      telephone: 'Numero errato',
      email: 'Formato email errato',
      cf_invoce: 'Desidera ricevere fattura con codice fiscale?',
    },
    errorMessage: {
      confirmPasswordFailed: 'Le password non coincidono',
    },
    successMessage: {
      customerRegistrationOk: 'Registrazione avvenuta con successo.',
      customerLoginOk: 'Login avvenuto con successo.',
      customerChangePasswordOk: 'Modifica password avvenuta con successo.',
      customerOperationOk: 'Operazione avvenuta con successo.',
      removeAccountMessage: `L'account è stato rimosso dai nostri sistemi`,
    },
    menu: {
      title: 'Naviga tra le categorie',
    },
    installPWA: {
      title: 'Installa ByTecno',
      experience:
        'Installa questa applicazione sulla schermata Home per migliorare la tua esperienza.',
      addHome: 'poi "Aggiungi alla schermata Home"',
    },
    home: {
      title: 'Novità',
      description: 'SCOPRI TUTTE LE OFFFERTE DELLA SETTIMANA',
    },
    productDetail: {
      price: 'Prezzo: ',
      availability: 'Disponibilità:',
      delivery: 'Consegna:',
      return: 'Reso:',
      relatedProducts: 'Prodotti correlati',
      currency: '€',
      coverage: 'Copertura',
      addAccessory: 'Aggiungi un accessorio',
    },
    socialLogin: {
      buttonText: 'Accedi con',
    },
    login: {
      register: 'Registrati',
      login: 'Accedi',
      user: 'E-mail',
      forgotPassword: 'Password dimenticata',
      confirmPassword: 'Conferma password',
      oldPassword: 'Vecchia password',
      newPassword: 'Nuova password',
      name: 'Nome',
      surname: 'Cognome',
      CheckBoxprivacyCondition: 'Accetta condizioni privacy',
    },
    cart: {
      summary: 'Riepilogo acquisti',
      shippingPrice: 'Calcola spedizioni e opzioni',
      couponTextInput: 'Coupon di sconto',
      couponTextButton: 'APPLICA',
      couponTextApplied: 'Coupon applicato',
      couponTextError: 'Coupon non trovato',
      couponTextRemoved: 'Coupon rimosso',
      checkout: 'PROCEDI AL CHECKOUT',
      emptyCart: 'SVUOTA CARRELLO',
      continue: 'CONTINUA GLI ACQUISTI',
    },
    addressManagement: {
      title: 'Gestione Indirizzi', //c'è un doppione, decidiamo quale dei due tenere (riga 68)
      billingAddress: 'Indirizzo di fatturazione predefinito',
      billingAddressOptional: 'Indirizzo di fatturazione opzionale',
      shippingAddress: 'Indirizzo di spedizione predefinito',
      shippingAddressOptional: 'Indirizzo di spedizione opzionale',
      shippingAndBllingAddress: 'Indirizzo predefinito',
      optionalAddress: 'Indirizzo opzionale',
      modify: 'modifica', //anche questo è presente in common (riga 10)
    },
    account: {
      title: 'Area riservata',
      anagraphic: {
        title: 'Anagrafica utente',
        subTitle: 'Modifica Anagrafica',
      },
      address: 'Gestione indirizzi',
      orders: 'Gestione ordini',
      payments: 'Metodi di pagamento',
      coupons: 'Gestione dei coupon',
      whishlist: 'Gestione wishlist',
      rma: 'RMA',
      consents: 'Gestione consensi',
      cookies: 'Gestione dei cookie',
      newsletter: 'Gestione newsletter',
      removeAccount: 'Cancella account',
      confirmDeleteMessage: 'Sei sicuro di voler cancellare il tuo account?',
    },
    addressModify: {
      title: 'Modifica indirizzi',
      checkBoxBilling: 'Usa come indirizzo di fatturazione',
      checkBoxShipping: 'Usa come indirizzo di spedizione',
    },
    order: {
      number: 'Ordine N°',
      delivered: 'Consegnato il:',
      trace: 'Traccia il mio pacco',
      return: 'Restituisci il prodotto',
      query: 'Fai una domanda sul prodotto',
      help: 'Richiedi assistenza',
      invoice: 'Fattura',
      evaluate: 'Valuta la consegna',
      review: 'Scrivi una recensione',
      hide: 'Nascondi ordine',
      orderDate: 'Data ordine:',
      total: 'Totale ordine:',
      state: 'Stato:',
      successMessage:
        "Ti invieremo un'email con i dettagli e la conferma d'ordine e le informazioni di tracciamento.",
      shippingData: 'Indirizzo di spedizione',
    },
    wishlistMenu: {
      yourList: 'Le tue liste',
      addList: 'Aggiungi alla lista',
      favoriteWishlist: 'Lista Preferiti',
    },
    productWishlist: {
      date: 'Aggiunto il',
      offerTimer: 'Offerta scade tra:',
      addCart: 'Aggiungi al carrello',
      removeProduct: 'Elimina dalla lista',
    },
    checkout: {
      confirmAndPay: 'Conferma e paga',
      billingData: {
        title: 'Dati fatturazione',
        subtitle: 'Indirizzo di fatturazione',
        addAddress: 'Aggiungi indirizzo',
      },
      shippingData: {
        title: 'Dati spedizioni',
        subtitle: 'Indirizzo di spedizione',
        addAddress: 'Aggiungi indirizzo',
      },
      payment: {
        title: 'Pagamento',
      },
      confirm: {
        title: 'Conferma',
      },
    },
    addressCreation: {
      title: 'Creazione nuovo indirizzo',
      add: 'Aggiungi nuovo indirizzo',
      newAddress: 'Nuovo indirizzo',
      firstName: 'Nome',
      lastName: 'Cognome',
      city: 'Città',
      postcode: 'Codice postale',
      street: 'indirizzo',
      telephone: 'Numero di telefono',
      create: 'Registra indirizzo',
      cf: 'Codice Fiscale',
      sdi_pec: 'Codice SDI/PEC',
      company: 'Società',
      vat_id: 'Partita Iva',
    },
    searchPage: {
      title: 'Risultati per:',
    },
    offersPage: {
      title: 'Offerte',
      all: 'Tutte le offerte',
    },
    tracking: {
      title: 'Stato della spedizione',
      orderNumber: 'N° Ordine:',
      find: 'TROVA LA SPEDIZIONE',
    },
    news: {
      title: 'News',
    },
  },
};

export default it;
