import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/bytecno.png';
import share from '../../assets/share.png';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import styles from './InstallPWA.module.scss';

function InstallPWA() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={`${styles.modalHeader}`}>
        <BTContainer className={`text-center`}>
          <img src={logo} alt="Add to homescreen" width="200" />
        </BTContainer>
      </Modal.Header>
      <Modal.Body>
        <BTContainer className={`text-center`}>
          <h3>{t('installPWA.title')}</h3>
          <div className={`mb-4 ${styles.experience}`}>
            {t('installPWA.experience')}
          </div>
          {t('common.click')}
          <img
            src={share}
            style={{ margin: 'auto 4px 8px' }}
            alt="Add to homescreen"
            height="20"
            width="20"
          />
          {t('installPWA.addHome')}
        </BTContainer>
      </Modal.Body>
      <Modal.Footer className={`${styles.modalFooter}`}>
        <BTContainer className={`text-center`}>
          <BTButton variant="link" onPress={() => setShow(false)}>
            {t('common.close').toUpperCase()}
          </BTButton>
        </BTContainer>
      </Modal.Footer>
    </Modal>
  );
}
export default InstallPWA;
