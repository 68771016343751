import React, { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';
import BTButton from '../../core/bt_button/BTButton';
import LoginRegisterModal from '../login_register_modal/LoginRegisterModal';

function LoginButton(
  props: Pick<ButtonProps, 'children' | 'variant' | 'className'>
) {
  const {
    children = <>Accedi/Registrati</>,
    className = `btn-block`,
    variant = 'primary',
  } = props;
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  return (
    <>
      <BTButton
        className={className}
        variant={variant}
        onPress={() => setShowLoginModal(true)}
      >
        {children}
      </BTButton>
      <LoginRegisterModal
        onClose={() => setShowLoginModal(false)}
        show={showLoginModal}
      />
    </>
  );
}

export default LoginButton;
