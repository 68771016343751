import { LogOutOutline } from 'react-ionicons';
import useLogout from '../../hooks/useLogout';

function Logout() {
  const logout = useLogout();
  return (
    <div>
      <LogOutOutline
        color={'var(--secondary)'}
        height="30px"
        width="30px"
        onClick={logout}
      />
    </div>
  );
}

export default Logout;
