import { useFormik } from 'formik';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCreateCustomerMutation } from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTGreenForm from '../../core/components/bt_green_form/BTGreenForm';
import { addSuccesNotification } from '../../core/notification/Notification';

type UserFormValueType = {
  name: string;
  surname: string;
  username: string;
  password: string;
  confirmPassword: string;
};
type UserFormValidation = {
  [key in keyof UserFormValueType]: yup.AnySchema;
};

function RegisterUser({ onCompleted }: { onCompleted?: () => void }) {
  const { t } = useTranslation();

  const [register] = useCreateCustomerMutation();

  const formik = useFormik<UserFormValueType>({
    initialValues: {
      name: '',
      surname: '',
      username: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object().shape<UserFormValidation>({
      name: yup.string().required(t('formMessage.required')),
      surname: yup.string().required(t('formMessage.required')),
      username: yup
        .string()
        .email(t('formMessage.email'))
        .required(t('formMessage.required')),
      password: yup.string().required(t('formMessage.required')),
      confirmPassword: yup
        .string()
        .required(t('formMessage.required'))
        .oneOf(
          [yup.ref('password'), null],
          t('errorMessage.confirmPasswordFailed')
        ),
    }),
    onSubmit: values => {
      onSubmit(values);
    },
  });

  async function onSubmit(value: UserFormValueType) {
    try {
      await register({
        variables: {
          input: {
            firstname: value.name,
            lastname: value.surname,
            email: value.username,
            password: value.password,
            is_subscribed: true,
          },
        },
      });

      addSuccesNotification({
        message: t('successMessage.customerRegistrationOk'),
      });

      onCompleted && onCompleted();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <BTGreenForm onSubmit={formik.handleSubmit}>
        <Form.Group controlId="formBasicEmailRegister" className={'mt-5'}>
          <InputGroup hasValidation>
            <Form.Control
              type="email"
              name="username"
              placeholder={t('login.user')}
              value={formik.values.username}
              onChange={event => formik.handleChange(event)}
              required={true}
              isInvalid={!!formik.errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.username}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicNameRegister" className={''}>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="name"
              placeholder={t('login.name')}
              value={formik.values.name}
              onChange={event => formik.handleChange(event)}
              required={true}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicSurnameRegister" className={''}>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="surname"
              placeholder={t('login.surname')}
              value={formik.values.surname}
              onChange={event => formik.handleChange(event)}
              required={true}
              isInvalid={!!formik.errors.surname}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.surname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicConfirmPasswordRegister">
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={event => formik.handleChange(event)}
              required={true}
              autoComplete="new-password"
              isInvalid={!!formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicPasswordRegister">
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              name="confirmPassword"
              placeholder={t('login.confirmPassword')}
              onChange={event => formik.handleChange(event)}
              required={true}
              autoComplete="new-password"
              isInvalid={!!formik.errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.confirmPassword}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="checkPrivacyCondition"
            label={t('login.CheckBoxprivacyCondition')}
            required={true}
          />
        </Form.Group>
        <BTButton className={`btn-block mt-5`} variant="primary" type="submit">
          {t('login.register')}
        </BTButton>
      </BTGreenForm>
    </>
  );
}
export default RegisterUser;
