import React from 'react';
import useVersion from '../../hooks/useVersion';

function Version() {
  const version = useVersion();
  return (
    <div>
      <div>
        <span>version: {version}</span>
      </div>
      <div>
        <span>
          magento server: {process.env.REACT_APP_MAGENTO_SERVER || ''}
        </span>
      </div>
      <div>
        <span>rest server: {process.env.REACT_APP_REST_SERVER || ''}</span>
      </div>
      <div>
        <span>icon url: {process.env.REACT_APP_ICONS_CDN_URL || ''}</span>
      </div>
    </div>
  );
}
Version.displayName = 'Version';
export default Version;
