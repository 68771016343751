import isEmpty from 'lodash.isempty';
import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddCircleSharp } from 'react-ionicons';
import AddressCard from '../../../../components/address_card/AddressCard';
import AddressForm, {
  AddressFormCountryEnum,
  AddressFormValueType,
} from '../../../../components/address_form/AddressForm';
import Title from '../../../../components/title/Title';
import { BillingCartAddress } from '../../../../core/apollo/gql-generate';
import BTButton from '../../../../core/bt_button/BTButton';
import BTContainer from '../../../../core/bt_container/BTContainer';
import { useCart } from '../../../../core/contexts/CartContext';

function BillingData() {
  const { t } = useTranslation();

  const { cart } = useCart();
  const [showUpsert, setShowUpsert] = useState(false);

  const address = useMemo(() => {
    if (!isEmpty(cart.billing_address)) {
      return cart.billing_address as BillingCartAddress;
    }
    return {} as BillingCartAddress;
  }, [cart]);

  return (
    <>
      <BTContainer>
        <Title name={t(`checkout.billingData.subtitle`)}></Title>
        {!showUpsert && isEmpty(address) && (
          <BTButton
            className={'text-left px-0'}
            onPress={() => setShowUpsert(true)}
            variant={'outline-link'}
          >
            <AddCircleSharp
              color={'var(--primary)'}
              title={'addAddress'}
              height="30px"
              width="30px"
            />
            {t(`checkout.billingData.addAddress`)}
          </BTButton>
        )}

        {!showUpsert && !isEmpty(address) && (
          <AddressCard
            address={{
              vatId: address.vat_id,
              fiscalCode: address.codice_fiscale,
              sdiPec: address.sdi_pec,
              billing: true,
              shipping: false,
              city: address.city,
              company: address.company,
              countryCode: address.country.code,
              firstname: address.firstname,
              lastname: address.lastname,
              postcode: address.postcode,
              regionCode: address.region?.code,
              street:
                address.street && address.street.length > 0
                  ? address.street[0]
                  : undefined,
            }}
            onUpdate={() => setShowUpsert(true)}
          />
        )}

        {showUpsert && (
          <UpsertBillingData
            address={address}
            onClose={() => setShowUpsert(false)}
          />
        )}
      </BTContainer>
    </>
  );
}

type UpsertBillingDataProps = {
  address: BillingCartAddress | undefined;
  onClose: () => void;
};

function UpsertBillingData(props: UpsertBillingDataProps) {
  const { address, onClose } = props;
  const { t } = useTranslation();

  const { addBillingAddress } = useCart();

  const addressForm: AddressFormValueType = {
    firstname: address?.firstname || '',
    lastname: address?.lastname || '',
    region_id: address?.region?.region_id || 0,
    city: address?.city || '',
    country_id: AddressFormCountryEnum.IT,
    postcode: address?.postcode || '',
    street:
      address?.street && address?.street.length > 0 ? address?.street[0] : '',
    telephone: address?.telephone || '',
    codice_fiscale: address?.codice_fiscale || '',
    vat_id: address?.vat_id || '',
    company: address?.company || '',
    sdi_pec: address?.sdi_pec || '',
    default_billing: false,
    default_shipping: false,
    customer_type:
      address?.customer_type !== undefined ? `${address?.customer_type}` : '0',
    cf_invoce: '0',
  };

  function onSubmit(value: AddressFormValueType) {
    addBillingAddress({
      address: {
        firstname: value.firstname,
        lastname: value.lastname,
        city: value.city,
        country_code: value.country_id,
        street: [value.street],
        telephone: value.telephone,
        postcode: value.postcode,
        region_id: value.region_id,
        codice_fiscale: value.codice_fiscale,
        sdi_pec: value.sdi_pec,
        vat_id: value.vat_id || '',
        company: value.company,
        customer_type: value.customer_type
          ? parseInt(value.customer_type)
          : undefined,
      },
    }).then(onClose);
  }

  return (
    <Row className={'mt-4'}>
      <Col>
        <AddressForm
          initialValues={addressForm}
          showDefaultBillding={false}
          showDefaultShipping={false}
          showCustomerTypeCheck={true}
          showCfInvoce={true}
          customButton={
            <BTButton
              className={`btn-block mt-2`}
              variant="dark-grey"
              onPress={onClose}
            >
              {t('common.cancel')}
            </BTButton>
          }
          onSubmit={onSubmit}
          submitText={t('common.save')}
        />
      </Col>
    </Row>
  );
}

export default BillingData;
