import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import {
  BundleWishlistItem,
  SearchResultPageInfo,
  WishlistFieldsFragment,
  useGetWishlistQuery,
  useRemoveWishlistItemMutation,
} from '../../core/apollo/gql-generate';
import BTButton from '../../core/bt_button/BTButton';
import BTContainer from '../../core/bt_container/BTContainer';
import { useCart } from '../../core/contexts/CartContext';
import { addSuccesNotification } from '../../core/notification/Notification';
import PriceText from '../../core/price_text/PriceText';
import BTPagination from '../pagination/BTPagination';
import styles from './WishlistPagination.module.scss';
export type WishlistViewInputType = {
  pageSize: number;
  currentPage: number;
};
function WishlistPagination({ input }: { input: WishlistViewInputType }) {
  const [wishlist, setWishlist] = useState({} as WishlistFieldsFragment);
  const [pagination, setPagination] = useState({} as SearchResultPageInfo);
  const { refetch: refetchWishList } = useGetWishlistQuery({
    variables: input,
    onCompleted: data => {
      const wl = data.customer?.wishlists[0]! as WishlistFieldsFragment;
      setWishlist(wl);
      setPagination(wl.items_v2?.page_info as SearchResultPageInfo);
      if (isEmpty(wl.items_v2?.items)) {
        backPage();
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  function nextPage() {
    if (!pagination) {
      return;
    }
    const next = pagination.current_page! + 1;
    if (next <= pagination.total_pages! && refetchWishList) {
      refetchWishList({
        ...input,
        currentPage: next,
      });
    }
  }

  function backPage() {
    if (!pagination) {
      return;
    }
    const back = pagination.current_page! - 1;
    if (back > 0 && refetchWishList) {
      refetchWishList({
        ...input,
        currentPage: back,
      });
    }
  }

  return (
    <>
      {!isEmpty(wishlist) &&
        wishlist.items_v2?.items.map(item => (
          <WishlistProduct
            item={item as BundleWishlistItem}
            wishlistId={wishlist?.id!}
            refetchWishList={refetchWishList}
          />
        ))}

      <BTPagination
        currentPage={pagination.current_page}
        totalPages={pagination.total_pages}
        onBack={backPage}
        onNext={nextPage}
        size={wishlist.items_v2?.items.length || 0}
      />
    </>
  );
}

function WishlistProduct({
  item,
  wishlistId,
  refetchWishList,
}: {
  item: BundleWishlistItem;
  wishlistId: string;
  refetchWishList: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [removeItem] = useRemoveWishlistItemMutation();

  const { addToCart } = useCart();

  async function removeItemFromWishlist() {
    try {
      await removeItem({
        variables: {
          wishlistId: wishlistId,
          wishlistItemsIds: item.id,
        },
      });
      refetchWishList();
      addSuccesNotification({
        message: 'Prodotto rimosso dalla wishlist',
        title: 'Prodotto rimosso',
      });
    } catch (err) {
      console.error(err);
    }
  }

  function addToCartHandler() {
    addToCart(item.product?.sku!, 1);
  }

  function goToProductDetail() {
    history.push({
      pathname: generatePath('/products/:sku', {
        sku: item.product?.sku!,
      }),
    });
  }

  return (
    <BTContainer className={`my-2`}>
      <Row>
        <Col className={`${styles.offer} font-weight-bold pr-0`}>
          {`${t('productWishlist.date')}: ${moment(item.added_at).format(
            'DD/MM/YYYY'
          )} `}
        </Col>
      </Row>
      {/*item.product?.special_to_date &&
        new Date(item.product?.special_to_date) >= new Date() && (
          <Row>
            <Col className={`${styles.offer} pr-0`}>
              <span className={`pr-2`}>{t('productWishlist.offerTimer')}</span>
              <span className={`${styles.offerTimer}`}>
                <Countdown end={new Date(item.product?.special_to_date)} />
              </span>
            </Col>
          </Row>
        )*/}
      <Row className={`py-3`} onClick={goToProductDetail}>
        <Col xs={5} md={6}>
          <img
            className={`${styles.image}`}
            src={item.product?.image?.url}
            alt="icon"
          />
        </Col>
        <Col xs={7} md={6}>
          <Row>
            <Col className={`${styles.data} pl-0`}>{item.product?.name}</Col>
          </Row>
          <Row className={`pt-4`}>
            <Col className={`${styles.price} pl-0`}>
              <PriceText
                value={
                  item.product?.price_range.maximum_price?.final_price.value!
                }
                position="left"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <BTButton
            className={`${styles.button}`}
            size="lg"
            block
            onPress={addToCartHandler}
          >
            {t('productWishlist.addCart')}
          </BTButton>
          <BTButton
            className={`${styles.button} mt-2`}
            variant="light-grey"
            size="lg"
            block
            onPress={removeItemFromWishlist}
          >
            {t('productWishlist.removeProduct')}
          </BTButton>
        </Col>
      </Row>
      <hr />
    </BTContainer>
  );
}

export default WishlistPagination;
