import { useReactiveVar } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CartOrderSummary from '../../../../components/cart_order_summary/CartOrderSummary';
import CouponForm, {
  CouponFormValueType,
} from '../../../../components/coupon_form/CouponForm';
import { tokenIdVar } from '../../../../core/apollo/Cache';
import { useCreatePaypalExpressTokenMutation } from '../../../../core/apollo/gql-generate';
import BTButton from '../../../../core/bt_button/BTButton';
import BTContainer from '../../../../core/bt_container/BTContainer';
import { useCart } from '../../../../core/contexts/CartContext';
import {
  addErrorNotification,
  addSuccesNotification,
} from '../../../../core/notification/Notification';
import { createAxervePayment } from '../../../../services/services';
import styles from './Confirm.module.scss';
export function Confirm() {
  const { t } = useTranslation();
  const { cart, applyCoupon, placeOrder, removeCoupon, addPaymentMethod } =
    useCart();
  const [coupon, setCoupon] = useState<CouponFormValueType>({ couponCode: '' });
  const history = useHistory();

  const token = useReactiveVar(tokenIdVar);

  const [createPaypalExpressTokenMut] = useCreatePaypalExpressTokenMutation();

  const paymentMethod: { [key in string]: () => Promise<void> } = {
    paypal_express: paypalExpressPaymentMethod,
    banktransfer: banktransferPaymentMethod,
    easynolo_bancasellapro: easynoloBancasellapro,
  };

  useEffect(() => {
    if (cart && cart.applied_coupons && cart.applied_coupons[0]?.code) {
      setCoupon({ couponCode: cart.applied_coupons[0]?.code });
    } else {
      setCoupon({ couponCode: '' });
    }
  }, [cart]);

  async function handlerConfirmAndPay() {
    const selectedPaymentMethod = cart.selected_payment_method;
    if (selectedPaymentMethod && paymentMethod[selectedPaymentMethod.code]) {
      await paymentMethod[selectedPaymentMethod.code]();
    } else {
      addErrorNotification({ message: 'Metodo di pagamento non trovato' });
    }
  }

  async function banktransferPaymentMethod() {
    placeOrder().then(() => history.push('/order-complited'));
  }

  async function paypalExpressPaymentMethod() {
    const newWindow = window.open('', '_parent');
    const { data } = await createPaypalExpressTokenMut({
      variables: {
        input: {
          cart_id: cart.id!,
          code: 'paypal_express',
          express_button: true,
          urls: {
            cancel_url: 'bytecno/paypal/cancel',
            return_url: 'bytecno/paypal/success',
          },
        },
      },
    });
    if (data) {
      const { createPaypalExpressToken } = data;
      (newWindow as any).location =
        createPaypalExpressToken?.paypal_urls?.start;
    }
  }

  async function easynoloBancasellapro() {
    const newWindow = window.open('', '_parent');

    if (cart) {
      await addPaymentMethod({
        code: cart.selected_payment_method?.code!,
      });
      await placeOrder();
      const res = await createAxervePayment(cart.id, !token);
      if (res && res.success === 'true') {
        (newWindow as any).location.href = res.redirectUrl;
      }
    }
  }

  function handlerCoupon(value: CouponFormValueType) {
    if (!isEmpty(value)) {
      applyCoupon(value.couponCode)
        .then((res: any) => {
          addSuccesNotification({
            message: `Codice coupon ${t('cart.couponTextApplied')}`,
          });
        })
        .catch(err => {
          addErrorNotification({
            title: 'COUPON ERROR',
            message: t('cart.couponTextError'),
          });
        });
    }
  }

  function handlerRemoveCoupon() {
    removeCoupon()
      .then(res => {
        addSuccesNotification({
          message: t('cart.couponTextRemoved'),
        });
      })
      .catch(err => {
        addErrorNotification({
          title: 'COUPON ERROR',
          message: t('cart.couponTextError'),
        });
      });
  }

  return (
    <>
      <BTContainer>
        <CartOrderSummary cart={cart} />
        <Row className={'mt-4'}>
          <CouponForm
            initialValues={coupon}
            onSubmit={handlerCoupon}
            onRemove={handlerRemoveCoupon}
            styles={styles}
          />
        </Row>
        <Row className={'mt-5'}>
          <Col>
            <BTButton
              variant="secondary"
              size="lg"
              onPress={handlerConfirmAndPay}
              block
            >
              {t('checkout.confirmAndPay')}
            </BTButton>
          </Col>
        </Row>
      </BTContainer>
    </>
  );
}
