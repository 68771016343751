import {
  ProductFieldFragment,
  ProductInterface,
} from '../../core/apollo/gql-generate';
import BTHtmlWrapper from '../../core/components/bt_html_wrapper/BTHtmlWrapper';
import useCmdBlocks from '../../hooks/useCmdBlocks';
import './EnergyRating.css';
export type EnergyRatingProps = {
  product: ProductFieldFragment | ProductInterface;
  justifyContent?: 'start' | 'center' | 'end';
};

const EnergyRating = (props: EnergyRatingProps) => {
  const { product, justifyContent = 'center' } = props;

  const { blocks } = useCmdBlocks([product.classe_energetica_id_cms_block]);

  return (
    <div className={`d-flex justify-content-${justifyContent} w-100`}>
      {product.classe_energetica_id_cms_block && (
        <BTHtmlWrapper
          content={blocks[product.classe_energetica_id_cms_block]
            ?.replaceAll('classenergetica_link', product.classe_energetica_link)
            .replaceAll(
              'classenergetica_sheet',
              product.classe_energetica_sheet
            )}
        />
      )}
    </div>
  );
};

export default EnergyRating;
