import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './CategoryRow.module.scss';

type CategoryRowType = {
  image?: string;
  title: string;
  onClick: () => void;
};

function CategoryRow(_props: CategoryRowType) {
  return (
    <Row className={`mr-1 mb-3 ${styles.box}`} onClick={_props.onClick}>
      <Col className={'text-center'} xs={2} md={1}>
        <img
          src={_props.image || '/assets/main_btn.svg'}
          height="40"
          width="40"
          alt={`icon ${_props.title}`}
        />
      </Col>
      <Col className={`pr-0 pl-1`} xs={10} md={11}>
        <div className={`d-flex align-items-center ${styles.categoryName}`}>
          <span>{_props.title}</span>
        </div>
      </Col>
    </Row>
  );
}

export default CategoryRow;
