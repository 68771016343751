import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Title from '../../components/title/Title';
import BTContainer from '../../core/bt_container/BTContainer';
import { getOrderTracking } from '../../services/services';

function TrackingData() {
  const { t } = useTranslation();
  const paramsEcoded = useParams<{
    orderNumber: string;
    customerEmail: string;
  }>();

  const params = JSON.parse(
    decodeURIComponent(JSON.stringify(paramsEcoded))
  ) as {
    orderNumber: string;
    customerEmail: string;
  };

  const [orderTracking, setOrderTracking] = useState<any>();

  useEffect(() => {
    getOrderTracking(params).then(res => setOrderTracking(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title
        className={`mt-4`}
        name={`${t('tracking.title')} #${params.orderNumber}`}
      />
      <BTContainer className={`mt-3`}>
        {orderTracking?.message && (
          <div
            dangerouslySetInnerHTML={{
              __html: `${orderTracking.message}`,
            }}
          ></div>
        )}
      </BTContainer>
    </>
  );
}
TrackingData.displayName = 'TrackingData';
export default TrackingData;
