import React, { useEffect } from 'react';
import BTContainer from '../../core/bt_container/BTContainer';

function PayPalCancel() {
  useEffect(() => {}, []);

  return (
    <BTContainer className="my-4">
      Qualcosa è andato storto con Paypal. Riprovare o contatta l'assistenza.
    </BTContainer>
  );
}
PayPalCancel.displayName = 'PayPalCancel';
export default PayPalCancel;
