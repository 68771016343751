import { store } from 'react-notifications-component';

export function addSuccesNotification({
  message,
  title,
}: {
  message: string;
  title?: string;
}) {
  store.addNotification({
    title: title,
    message: message,
    type: 'success',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function addErrorNotification({
  message,
  title,
}: {
  message: string;
  title?: string;
}) {
  store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}
