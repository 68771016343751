import pickBy from 'lodash.pickby';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ProductFieldFragment } from '../../core/apollo/gql-generate';
import PriceText from '../../core/price_text/PriceText';
import styles from './ProductExtraServices.module.scss';

export type ProductExtraServicesValues = { [key in string]: string };

export type ProductExtraServicesProps = {
  customOptions: ProductFieldFragment['custom_options'];
  onChange: (values: ProductExtraServicesValues) => void;
  values?: ProductExtraServicesValues;
};

const ProductExtraServices = (props: ProductExtraServicesProps) => {
  const { customOptions, values = {}, onChange } = props;
  const [options, setOptions] = useState<ProductExtraServicesValues>(values);

  useEffect(() => {
    // console.log(values);
    setOptions(() => values);
  }, [values]);

  const onChengeOption = (parentId: string, optionId: string = 'null') => {
    const newValues = pickBy(
      { ...options, [parentId]: optionId },
      v => v !== 'null'
    );
    setOptions(() => newValues);
    onChange(newValues);
  };

  return (
    <form>
      {customOptions?.map((opt, key) => (
        <Row className={`pb-2 ${styles.optionsBox}`}>
          <Col className={`${styles.title} col-12`}>{opt?.title}</Col>
          {/** !!opt?.value && opt?.value.length > 1 && (
            <Col className={`${styles.desccription} col-12`}>
              <Form.Check
                name={opt.uid}
                type="radio"
                label={'Nessuno'}
                id={`null`}
                value={`null`}
                checked={!!!options[opt.option_id!]}
                defaultChecked={false}
                onChange={event =>
                  onChengeOption(
                    opt.option_id!.toString(),
                    event.currentTarget.value
                  )
                }
              />
            </Col>
              )**/}
          {opt?.value?.map(oValue => {
            const type =
              !!opt?.value && opt?.value.length > 1 ? 'radio' : 'checkbox';
            const checked =
              options[opt.option_id!] === `${oValue!.option_type_id}`;
            const value = `${oValue!.option_type_id}`;
            return (
              <Col className={`${styles.desccription} col-12`}>
                <Form.Check
                  name={opt.uid}
                  type={type}
                  label={
                    <>
                      {`${oValue?.title}`}
                      {!!oValue?.price && oValue?.price > 0 && (
                        <>
                          {` +`}
                          <PriceText value={oValue?.price || ''} />
                        </>
                      )}
                    </>
                  }
                  id={`${oValue!.option_type_id}`}
                  value={value}
                  checked={checked}
                  defaultChecked={false}
                  onChange={event =>
                    onChengeOption(
                      opt.option_id!.toString(),
                      type === 'checkbox' && checked
                        ? undefined
                        : event.currentTarget.value
                    )
                  }
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </form>
  );
};

export default ProductExtraServices;
