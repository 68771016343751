import axios from 'axios';
import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import pick from 'lodash.pick';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIE_ORDER } from '../../constants/cookies';
import {
  BillingAddressInput,
  Cart,
  CartItemUpdateInput,
  CustomizableOptionInput,
  PaymentMethodInput,
  ShippingAddressInput,
  ShippingMethodInput,
  useAddToCartMutation,
  useApplyCouponToCartMutation,
  useCreateGuestCartMutation,
  useGetCartLazyQuery,
  useGetCustomerCartIdLazyQuery,
  useGetCustomerLazyQuery,
  useMergeCartsMutation,
  usePlaceOrderMutation,
  useRemoveCartItemMutation,
  useRemoveCouponFromCartMutation,
  useSetBillingAddressToCartMutation,
  useSetGuestEmailOnCartMutation,
  useSetPaymentMethodOnCartMutation,
  useSetShippingAddressToCartMutation,
  useSetShippingMethodToCartMutation,
  useUpdateCartItemMutation,
} from '../../core/apollo/gql-generate';
import { OrderSummaryCookie } from '../../models/Cookies';
import {
  addErrorNotification,
  addSuccesNotification,
} from '../notification/Notification';
import Storage from '../storage/Storage';

type CartType = {
  addToCart: (
    sku: string,
    quantity: number,
    selectedOptions?: CustomizableOptionInput[]
  ) => Promise<void>;
  cart: Cart;
  cartSize: number;
  loaded: boolean;
  updateCartItem: (cartItem: CartItemUpdateInput) => Promise<void>;
  removeCartItem: (productUid: string) => Promise<void>;
  addBillingAddress: (address: BillingAddressInput) => Promise<void>;
  addShippingAddress: (address: ShippingAddressInput) => Promise<void>;
  addShippingMethod: (shippingMethod: ShippingMethodInput) => Promise<void>;
  addPaymentMethod: (paymentMethod: PaymentMethodInput) => Promise<void>;
  applyCoupon: (couponCode: string) => Promise<void>;
  removeCoupon: () => Promise<void>;
  placeOrder: () => Promise<string>;
  clearCart: () => Promise<void>;
  setGuestEmailOnCart: (email: string) => Promise<void>;
  loggedIn: () => Promise<void>;
  reloadCart: () => Promise<void>;
};

const CartContext = React.createContext<CartType>({} as CartType);

function CartProvider(_props: { children: any }) {
  // const token = useReactiveVar(tokenIdVar);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie, removeCookie] = useCookies([COOKIE_ORDER]);

  const [cart, setCart] = useState<Cart>();

  const cartSize = useMemo(
    () =>
      cart?.items?.reduce((acc, curr) => (curr?.quantity || 0) + acc, 0) || 0,
    [cart]
  );
  const cartLoaded = useMemo(() => !isNil(cart), [cart]);

  const [createGuestCartMut] = useCreateGuestCartMutation();
  const [mergeCartsMut] = useMergeCartsMutation();
  const [addProductToCartMut] = useAddToCartMutation();
  const [updatedCartItemMut] = useUpdateCartItemMutation();
  const [removeCartItemMut] = useRemoveCartItemMutation();
  const [setBillingAddressMut] = useSetBillingAddressToCartMutation();
  const [setPaymentMethodMut] = useSetPaymentMethodOnCartMutation();
  const [setShippingAddressMut] = useSetShippingAddressToCartMutation();
  const [setShippingMethodMut] = useSetShippingMethodToCartMutation();
  const [applyCouponMut] = useApplyCouponToCartMutation();
  const [placeOrderMut] = usePlaceOrderMutation();
  const [setGuestEmailOnCartMut] = useSetGuestEmailOnCartMutation();
  const [removeCouponMut] = useRemoveCouponFromCartMutation();

  const [getCustomerCart] = useGetCustomerCartIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [fetchCustomerAddress] = useGetCustomerLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [fetchCart] = useGetCartLazyQuery({
    onCompleted: data => {
      console.log('FETCH CART RETURNED');
      checkCart(data.cart as Cart);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    loadCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCart = (newCart: Cart) => {
    newCart.items = (newCart.items || []).filter(i => !isEmpty(i));
    setCart(() => newCart);
  };

  const loadCart = async () => {
    const cartIdSaved = Storage.readCart();
    const token = Storage.readToken();
    // SONO UN GEST?
    if (isNil(token) && isNil(cartIdSaved)) {
      const { data: guestCart } = await createGuestCartMut();
      if (!isNil(guestCart) && !isNil(guestCart.createEmptyCart)) {
        Storage.saveCart(guestCart.createEmptyCart);
        await fetchCart({
          variables: {
            id: guestCart.createEmptyCart,
          },
        });
      }
    } else if (!isNil(cartIdSaved)) {
      await fetchCart({
        variables: {
          id: cartIdSaved,
        },
      });
    } else if (!isNil(token)) {
      createCustomerCart(false);
    }
  };

  const loggedIn = async () => {
    createCustomerCart(true);
  };

  const createCustomerCart = async (loggedIn: boolean) => {
    const { data } = await getCustomerCart();
    if (data && data.customerCart) {
      const customerCartId = data.customerCart.id;
      const guestCart = Storage.readCart();
      if (guestCart && customerCartId !== guestCart && loggedIn) {
        console.log('START MERGE CART');
        await mergeCartsMut({
          variables: {
            source: guestCart,
            destination: customerCartId,
          },
        });
        console.log('END MERGE CART');
      }
      Storage.saveCart(customerCartId);
      const { data: addressResponse } = await fetchCustomerAddress();
      const defaultShipping = addressResponse?.customer?.addresses?.find(
        a => a?.default_shipping === true
      );
      const defaultBilling = addressResponse?.customer?.addresses?.find(
        a => a?.default_billing === true
      );
      if (defaultShipping) {
        await addShippingAddress({
          customer_address_id: defaultShipping?.id,
        });
      }
      if (defaultBilling) {
        await addBillingAddress({
          customer_address_id: defaultBilling?.id,
        });
      }
      await fetchCart({
        variables: {
          id: customerCartId,
        },
      });
    } else {
      throw Error('LoggedIn Cart Merge error');
    }
  };

  const addToCart = async (
    sku: string,
    quantity: number,
    selectedOptions?: CustomizableOptionInput[]
  ) => {
    const cart = Storage.readCart();
    try {
      const res = await addProductToCartMut({
        variables: {
          sku,
          quantity,
          cart: cart!,
          customizable_options: selectedOptions,
        },
      });

      checkCart(res.data?.addSimpleProductsToCart?.cart as Cart);
      addSuccesNotification({ message: 'Prodotto aggiunto con successo' });
    } catch (err) {
      console.error(err);
    }
  };

  const updateCartItem = async (cartItem: CartItemUpdateInput) => {
    const cart = Storage.readCart();
    try {
      const res = await updatedCartItemMut({
        variables: {
          input: {
            cart_id: cart!,
            cart_items: [cartItem],
          },
        },
      });
      checkCart(res.data?.updateCartItems?.cart as Cart);
    } catch (err) {
      console.error(err);
    }
  };

  const removeCartItem = async (productUid: string) => {
    const cart = Storage.readCart();
    try {
      const res = await removeCartItemMut({
        variables: {
          input: {
            cart_id: cart!,
            cart_item_uid: productUid,
          },
        },
      });
      checkCart(res.data?.removeItemFromCart?.cart as Cart);
    } catch (err) {
      console.error(err);
    }
  };

  const addBillingAddress = async (address: BillingAddressInput) => {
    const cart = Storage.readCart();
    const res = await setBillingAddressMut({
      variables: {
        input: {
          cart_id: cart!,
          billing_address: address,
        },
      },
    });
    checkCart(res.data?.setBillingAddressOnCart?.cart as Cart);
  };

  const addPaymentMethod = async (pm: PaymentMethodInput) => {
    const cart = Storage.readCart();
    const res = await setPaymentMethodMut({
      variables: {
        input: {
          cart_id: cart!,
          payment_method: pm,
        },
      },
    });
    checkCart(res.data?.setPaymentMethodOnCart?.cart as Cart);
  };

  const addShippingAddress = async (address: ShippingAddressInput) => {
    const cart = Storage.readCart();
    const res = await setShippingAddressMut({
      variables: {
        input: {
          cart_id: cart!,
          shipping_addresses: [address],
        },
      },
    });
    checkCart(res.data?.setShippingAddressesOnCart?.cart as Cart);
  };

  const addShippingMethod = async (method: ShippingMethodInput) => {
    const cart = Storage.readCart();
    const res = await setShippingMethodMut({
      variables: {
        input: {
          cart_id: cart!,
          shipping_methods: [method],
        },
      },
    });
    checkCart(res.data?.setShippingMethodsOnCart?.cart as Cart);
  };

  const applyCoupon = async (coupon_code: string) => {
    const cart = Storage.readCart();
    const res = await applyCouponMut({
      variables: {
        input: {
          cart_id: cart!,
          coupon_code,
        },
      },
    });
    checkCart(res.data?.applyCouponToCart?.cart as Cart);
  };

  const placeOrder = async () => {
    const cartId = Storage.readCart();
    let completed = false;
    try {
      if (cart && cart.id === cartId) {
        removeCookie('COOKIE_ORDER');
        const { data } = await placeOrderMut({
          variables: {
            input: {
              cart_id: cartId!,
            },
          },
        });
        if (data?.placeOrder?.order) {
          const { order_number } = data.placeOrder!.order;
          addSuccesNotification({
            message: `Ordine ${order_number} effettuato con successo`,
          });
          const cookieOrder: OrderSummaryCookie = {
            number: order_number,
            summary: pick(cart, [
              'email',
              'billing_address',
              'shipping_addresses',
            ]),
          };
          setCookie('COOKIE_ORDER', JSON.stringify(cookieOrder), {
            path: '/',
            sameSite: true,
            secure: true,
            maxAge: 1800,
            expires: moment().add(30, 'minute').toDate(),
            domain: window.location.hostname,
          });
          completed = true;
          return order_number;
        }
        return '-1';
      } else {
        const message = `Errore durante la creazione dell'ordine. Se il problema persiste contattare l'assistenza`;
        addErrorNotification({
          message,
        });
        throw Error(message);
      }
    } finally {
      if (completed) {
        Storage.cleanCart();
        setCart(() => undefined);
      }
    }
  };

  const clearCart = async () => {
    const token = Storage.readToken();
    if (cart) {
      await axios.post(
        `/rest/Bytecno/V1/mobile/${
          token ? 'cleanCustomerCart' : 'cleanGuestCart'
        }`,
        {
          cart_id: cart.id,
        }
      );
      await fetchCart({
        variables: {
          id: cart.id,
        },
      });
    }
  };

  const setGuestEmailOnCart = async (email: string) => {
    const cart = Storage.readCart();
    const token = Storage.readToken();
    if (!token && cart) {
      try {
        const res = await setGuestEmailOnCartMut({
          variables: {
            input: {
              email,
              cart_id: cart,
            },
          },
        });
        checkCart(res.data?.setGuestEmailOnCart?.cart as Cart);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const removeCoupon = async () => {
    const cart = Storage.readCart();
    const res = await removeCouponMut({
      variables: {
        input: {
          cart_id: cart!,
        },
      },
    });
    checkCart(res.data?.removeCouponFromCart?.cart as Cart);
  };

  return (
    <CartContext.Provider
      value={{
        addToCart,
        cart: cart || ({} as Cart),
        loaded: cartLoaded,
        updateCartItem,
        removeCartItem,
        cartSize,
        addBillingAddress,
        addPaymentMethod,
        addShippingAddress,
        addShippingMethod,
        clearCart,
        placeOrder,
        applyCoupon,
        setGuestEmailOnCart,
        removeCoupon,
        loggedIn,
        reloadCart: loadCart,
      }}
    >
      {_props.children}
    </CartContext.Provider>
  );
}

export default CartProvider;

export function useCart() {
  return useContext(CartContext);
}
